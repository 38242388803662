import { FormikProps } from "formik"
import { JoditContentEditorModule } from "../../../form-components/JoditContentEditorModule"
import { TextInput } from "../../../form-components/TextInput"
import emailIcon from '../../../img/icon-email.png'
import nameIcon from '../../../img/icon-name.png'
import phoneIcon from '../../../img/icon-phone.png'
import { CustomerModel } from "../model/CustomerV2"
import { CreatableAsyncCustomerTagSelectInputHandler } from "./CreatableAsyncCustomerTagSelectInputHandlerV2"

interface FormHelper{
    name: string
    email: string
    phone: string
}

let helper: FormHelper = {
    name: "Name of the customer",
    email: "Email of the customer",
    phone: "Phone of the customer"
}

export let CustomerForm: <T extends CustomerModel>(
    pageTitle: string, props: FormikProps<T>
    ) => JSX.Element =
    (pageTitle, props) => {
        const { values, errors, touched, handleChange, setFieldValue } = props

        const handleTagChange = (tags?: { label: string, value: string }[]) => {
            if (setFieldValue == undefined) { console.log("setFieldValue not set"); return }

            setFieldValue("tags", JSON.stringify(tags))
            setFieldValue("selectedTags", tags)
        }

        const handleNewTagChange = (tag: { label: string, value: string }) => {
            if (values && values.tags) {
                handleTagChange([...JSON.parse(values.tags), tag])
                return
            }

            handleTagChange([tag])
        }

        const contentEditorModule = new JoditContentEditorModule()

        return (<>
            <div className="page-title">{pageTitle} Customer {values.name}</div>
            <div className="row">
                <div className="col">
                    <CreatableAsyncCustomerTagSelectInputHandler 
                        fieldName="tags"
                        placeholder="Customer Category"
                        value={values.selectedTags}
                        onChange={handleTagChange}
                        onNewTagAdded={handleNewTagChange}
                    />
                    <div className="tagDescription">[Group Organisasi] [Komando Utama/Setingkat] [Satker]</div>
                    <TextInput icon={nameIcon} placeholder="Name" name="name"
                        helpText={helper.name}
                        value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                    <TextInput icon={emailIcon} placeholder="Email" name="email"
                        helpText={helper.email}
                        value={values.email} onChange={handleChange} message={errors.email} touched={touched.email} />
                    <TextInput icon={phoneIcon} placeholder="Phone" name="phone"
                        helpText={helper.phone}
                        value={values.phone} onChange={handleChange} message={errors.phone} touched={touched.phone} />
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col">
                    <div className="field-description">Note</div>
                    <TextInput type="text" placeholder="Note about the client" aclass="default full-width" name="note" value={values.note} onChange={handleChange} message={errors.note} touched={touched.note} />

                    <div className="field-description space">Job Description</div>
                    {contentEditorModule.get("description", "Customer's description", values.description ?? "", handleChange)}
                </div>
            </div>
        </>)
    }