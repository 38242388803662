import { useEffect } from 'react';
import { POBHeaderPath } from '../database_api/api';
import { AddResult } from './GenericAPI/GenericAddAPI';
import { useGetData } from './Shared/useGetData';

type ResultType = {label: string, value: string}

export const useSelectPOBHeaderHelper = (onComplete?: (datas: ResultType[]) => void) => {
    const { get } = useGetData(POBHeaderPath, "")

    useEffect(() => {
        get(undefined, {
            onSuccess: (data: AddResult) => {
                let returnedPOBHeaderList = data.data.map( (pobHeader: any) => {
                    return {
                        label: pobHeader.name,
                        value: pobHeader.id
                    }
                })

                if(onComplete) onComplete(returnedPOBHeaderList ?? [])
            }
        })
    }, [])
}