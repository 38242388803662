import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CustomerPersonnelPath, ImagePath } from "../../../database_api/api";
import { DateHelper } from "../../../helper/DateHelper";
import { useItem } from "../../Shared/useItem";
import { DatabaseTNIALSingleResult } from "../DatabaseTNIALSingleLoader";
import { DatabaseTNIALModel } from "../model/DatabaseTNIAL";

interface IDatabaseTNIALView extends DatabaseTNIALModel{
    photoBase64?: string
}

export const DatabaseTNIALViewer = () => {
    const params = useParams<{ id: string }>()
    const { get, data, isLoading } = useItem(CustomerPersonnelPath, params.id, "DBTNIAL")
    const [tnialData, setTnialData] = useState<IDatabaseTNIALView | undefined>(undefined)

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    let mapData = (result: DatabaseTNIALSingleResult) => {
        if (result.data == undefined) return

        const tnial: IDatabaseTNIALView = {...result.data.value}

        Object.entries(result.data.value).forEach(data => {
            if(data[0] == "photo_url"){
                let photo_url = data[1]
                if(photo_url == "") return
                tnial.photoBase64 = ImagePath + photo_url
                return
            }

            if (data[0] == "positions") {
                if (typeof data[1] == "string") {
                    try{
                        tnial.positions = JSON.parse(data[1])
                    }
                    catch(e){
                        tnial.positions = []
                    }
                }
                else {
                    tnial.positions = []
                }
                return
            }

            if (data[0] == "contactNumber") {
                
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) tnial.contactNumber = val
                        else tnial.contactNumber = [val]
                    }
                    catch(e){
                        if(data[1] == "") tnial.contactNumber =[]
                        else tnial.contactNumber =[data[1]]
                    }
                    
                }
                else {
                    tnial.contactNumber =[]
                }
                return
            }

            if (data[0] == "children") {
                
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) tnial.children = val
                        else tnial.children = [val]
                    }
                    catch(e){
                        if(data[1] == "") tnial.children = []
                        else tnial.children = [data[1]]
                    }
                    
                }
                else {
                    tnial.children = []
                }
                return
            }

            if (data[0] == "education") {
                
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) tnial.education = val
                        else tnial.education = [val]
                    }
                    catch(e){
                        if(data[1] == "") tnial.education = []
                        else tnial.education = [data[1]]
                    }
                    
                }
                else {
                    tnial.education = []
                }
                return
            }

            if (data[0] == "acknowledgement") {
                    
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) tnial.acknowledgement = val
                        else tnial.acknowledgement = [val]
                    }
                    catch(e){
                        if(data[1] == "") tnial.acknowledgement = []
                        else tnial.acknowledgement = [data[1]]
                    }
                    
                }
                else {
                    tnial.acknowledgement = []
                }
                return
            }
        })

        setTnialData(tnial)
    }

    if (isLoading) return <div>Loading...</div>
    if(!tnialData) return <div>Data not found</div>
    
    return (
        <div id="tnial-view-container">
            <h2>{tnialData.name}</h2>
            <div className="rowflex">
                <div className="col left">
                    <h3>Data</h3>

                    <table className="normal2">
                        <tr>
                            <td>Class Of</td>
                            <td>{tnialData.generation}</td>
                            <td>Sequence No</td>
                            <td>{tnialData.serial_number}</td>
                        </tr>
                        <tr>
                            <td>Corps</td>
                            <td>{tnialData.corps}</td>
                            <td>NRP</td>
                            <td>{tnialData.nrp}</td>
                        </tr>
                        <tr>
                            <td>Rank</td>
                            <td>{tnialData.rank}</td>
                            <td>Period</td>
                            <td>{tnialData.period}</td>
                        </tr>
                    </table>

                    <br />
                    <hr />
                    <br />
                    <h3>Education</h3>
                    <ul>
                    {!tnialData.education && <div>No Education data</div>}
                    {tnialData.education && tnialData.education.map((edu: string, index: number) => (
                        <li key={index}>{edu}</li>
                    ))}
                    </ul>
 
                    <br />
                    <br />
                    <h3>Acknowledgement</h3>
                    {!tnialData.acknowledgement && <div>No Acknowledgement data</div>}
                    <ul>
                    {tnialData.acknowledgement && tnialData.acknowledgement.map((ack: string, index: number) => (
                        <li key={index}>{ack}</li>
                    ))}
                    </ul>

                    <br />
                    <br />
                    <h3>Description</h3>
                    <div className="description" dangerouslySetInnerHTML={{__html: tnialData.description ?? "No description data"}}></div>
                </div>
                <div className="col right">
                    {tnialData.name}<br />
                    {tnialData.photoBase64 && <img src={tnialData.photoBase64} alt={tnialData.name} className="profile-pic" style={{width: "100%"}} />}<br />
                    <h3>Positions</h3>
                    <table className="normal position">
                    {tnialData.positions && tnialData.positions.map((pos:any, index: number) => (
                            <tr>
                                <td>{pos.position}</td>
                                <td>{pos.time}</td>
                            </tr>
                    ))}
                    </table>

                    <div className="section">Informasi Pribadi</div>
                    <table className="normal">
                        <tr>
                            <td>DOB</td>
                            <td>{tnialData.birthOfDate ? DateHelper.dateFormat(tnialData.birthOfDate, "MMMM DD, YYYY") : "-" }</td>
                        </tr>
                        <tr>
                            <td>Spouse</td>
                            <td>{tnialData.spouse}</td>
                        </tr>
                        <tr>
                            <td>Children</td>
                            <td>
                                <ul>
                                {tnialData.children && tnialData.children.map((child: string, index: number) => (
                                    <li key={index}>{child}</li>
                                ))}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Contact</td>
                            <td>
                                <ul style={{listStyle: "none", paddingLeft: "0"}}>
                                {tnialData.contactNumber && tnialData.contactNumber.map((num:string, index: number) => (
                                    <li key={index}>{num}</li>
                                ))}
                                </ul>
                            </td>
                        </tr>
                    </table>
                    
                    
                </div>
            </div>
        </div>
    );
}