import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomerPersonnelPath } from '../../../database_api/api';
import { DateHelper } from '../../../helper/DateHelper';
import { useUpdateFormData } from '../../Shared/useUpdateFormData';
import { DatabaseTNIALAddModel } from '../ui/DatabaseTNIALForm';

export const useDatabaseTNIALEditFormHandler = (initialValues: DatabaseTNIALAddModel, validationSchema: yup.ObjectSchema) => { 
    const { update } = useUpdateFormData(CustomerPersonnelPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            if(!values.contactNumber) values.contactNumber = []
            if(!values.children) values.children = []
            if(!values.education) values.education = []
            if(!values.acknowledgement) values.acknowledgement = []
            
            let customerPersonnelData = {
                id: values.id,
                name: values.name,
                generation: values.generation,
                rank: values.rank,
                position: values.position,
                corps: values.corps,
                serial_number: values.serial_number ? parseInt(values.serial_number) : 0,
                nrp: values.nrp,
                period: values.period,
                description: values.description,
                birthOfDate: !values.birthOfDate ? "" : DateHelper.dateISO8601(values.birthOfDate),
                spouse: values.spouse,
            }

            let customerPersonnelDataForm = new FormData()
            Object.entries(customerPersonnelData).forEach( field => {
                customerPersonnelDataForm.append(field[0], field[1] == undefined ? "" : field[1] as any )
            })

            customerPersonnelDataForm.append('positions', JSON.stringify(values.positions))
            customerPersonnelDataForm.append('contactNumber', JSON.stringify(values.contactNumber))
            customerPersonnelDataForm.append('children', JSON.stringify(values.children))
            customerPersonnelDataForm.append('education', JSON.stringify(values.education))
            customerPersonnelDataForm.append('acknowledgement', JSON.stringify(values.acknowledgement))

            customerPersonnelDataForm.append('isPhotoChanged', (values.isPhotoChanged ? "true" : "false"))

            if(values.photo_url) customerPersonnelDataForm.append('photo_url', values.photo_url)

            setSubmitting(false)
            update(customerPersonnelDataForm)
        }
    })
    
    return formik
}