import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { ResultStatus } from "../../Types/ResultStatus"
import { HTTPMethod } from "../../interfaces/HTTPHeader"
import { AddResult } from "../GenericAPI/GenericAddAPI"
import { useHeader } from "./useHeader"


export const usePostData = <T>(url: string, onPostSuccessMessage?: string) => {
    const { header } = useHeader()

    const requestOptions = {
        method: HTTPMethod.post,
        headers: header.header()
    }

    const postData = async (data: T) => {
        console.log("CALLED?")
        return fetch(`${url}`, {...requestOptions, body: JSON.stringify(data) })
            .then((response) => response.json())
    }

    const { isLoading, mutate } = useMutation(postData, {
        onSuccess: (data: AddResult) => {
            console.log(data)
            if(data.status === ResultStatus.fail){
                toast.error(data.message, { autoClose: 5000 })
                return
            }

            if(onPostSuccessMessage === "") return
            toast(onPostSuccessMessage ?? "Success Add")
        },
        onError: (error) => {
            toast.error(JSON.stringify(error), { autoClose: 5000 })
        }
    })
    

    return { post: mutate, isLoading };
}