import * as React from 'react';
import { useState } from 'react';
import { useURL } from './useURL';

export type OptionValue = {key: string, value: string}

export interface OptionInputProp{
    name: string, 
    options?: OptionValue[]
    initialValue: string
    [param: string]: any
}

export const OptionInputSearchComponent = (props: OptionInputProp) => {
    const { location, params, goTo } = useURL()

    const status = params.active ? params.active.toString() : props.initialValue

    const [selectedValue, setSelectedValue] = useState<string | undefined>(status)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(e.target.value)
        
        if(e.target.value === "all"){
            delete params.active
        }
        else{
            params.active = e.target.value
        }

        goTo(location.pathname, params)
    }

    return (
        <div className="option-input-normal">
            {!props.options ? 
                <React.Fragment key="default"><input className="option" type="radio" name={props.name} value="default" id="default" />
                    <label htmlFor="default">Default</label>
                </React.Fragment>
            :
                props.options.map((option) => 
                    <div className='option-container' key={option.key}>
                        <input className="option" type="radio" name={props.name} value={option.key} id={option.key + option.value} 
                            checked={selectedValue === option.key} 
                            onChange={handleChange}
                        />
                        <label htmlFor={option.key + option.value}>{option.value}</label>
                    </div>
                )
            }
        </div>
    )
}