import { FieldInformationComponentProp } from './ui/FieldInformationComponent';
import { ResultStatus } from '../../Types/ResultStatus';
import { SingleLoaderProtocol, GenericSingleLoader } from '../GenericSingleLoader';

type FieldInformationSingleDetail = {
    cas: string
    value: FieldInformationComponentProp
}

export type FieldInformationSingleResult = {
    status: ResultStatus,
    data?: FieldInformationSingleDetail,
    message?: string
}
export type FieldInformationSingleLoader = SingleLoaderProtocol<FieldInformationSingleResult>

export class APIFieldInformationSingleLoader extends GenericSingleLoader<FieldInformationSingleResult> implements FieldInformationSingleLoader{}

type FieldInformationResultSingleResult = {
    status: ResultStatus,
    data?: {
        name: string,
        result: string,
        resultCreatedDate: string,
        resultUpdatedDate: string
    },
    message?: string
}

export type FieldInformationResultSingleLoader = SingleLoaderProtocol<FieldInformationResultSingleResult>
export class APIFieldInformationResultSingleLoader extends GenericSingleLoader<FieldInformationResultSingleResult> implements FieldInformationResultSingleLoader{}