import * as React from 'react';
import { BoxButtonComponent } from '../../../components/BoxButtonComponent';
import { BoxComponent } from '../../../components/BoxComponent';
import PrevIcon from '../../../img/prev.png'
import NextIcon from '../../../img/next.png'

import { PagingDisplayUpdaterProtocol, PagingData } from '../PagingDisplayUpdaterProtocol';
import { ListLoaderControllerProtocol } from '../../ListLoaderControllerProtocol';
import { LoadingControlProtocol } from '../../Search/SearchComponent';
import { useEffect, useState } from 'react';

type PaginationComponentProp = {
    itemPerPage: number
    controller: ListLoaderControllerProtocol & LoadingControlProtocol
    pagingController: PagingDisplayUpdaterProtocol
}

type PaginationComponentState = {
    text: string,
    prevPage: number,
    nextPage: number,
    totalPage: number,
    nextPageEnabled: boolean,
    prevPageEnabled: boolean,
    isLoading: boolean
}


export const PaginationComponent = (props: PaginationComponentProp) => {
    let itemPerPage: number
    const [pagingData, setPagingData] = useState<PaginationComponentState>(
        {
            text: "",
            prevPage: 1,
            nextPage: 1,
            totalPage: 1,
            nextPageEnabled: false,
            prevPageEnabled: false,
            isLoading: false
        }
    )

    useEffect(() => {
        itemPerPage = props.itemPerPage
        props.pagingController.register(updateDisplay)
        props.controller.register(setLoading)
    }, [])

    const setLoading = (status: boolean) => {
        if(status){
            setPagingData({
                ...pagingData,
                isLoading: status,
                text: "Loading..."
            })
        }
        else{
            setPagingData({
                ...pagingData,
                isLoading: status
            })
        }
        
    }

    const updateDisplay = (data: PagingData) =>  {
        let startDataIndex = (((data.currentPage - 1) * itemPerPage) + 1)
        let endDataIndex = startDataIndex + (data.totalDataShowed - 1)
        let totalData = data.totalData

        setPagingData({
            ...pagingData,
            text: startDataIndex + " - " + endDataIndex + " from " + totalData + " data",
            prevPage: data.prevPage,
            nextPage: data.nextPage,
            totalPage: data.totalPage,
            nextPageEnabled: data.nextPageEnabled,
            prevPageEnabled: data.prevPageEnabled
        })
    }

    const goTo = (page: number) => {
        props.controller.load(page)
    }

    return (
        <div className="pagination-component">
            <BoxButtonComponent border="left-border" aclass="pagination-button" icon={PrevIcon} onClick={() => {goTo(pagingData.prevPage)}} {...(!pagingData.prevPageEnabled ? {disabled:"disabled"} : {}) } 
                {...(pagingData.isLoading ? {disabled:"disabled"} : {}) } />
            <BoxComponent aclass="pagination-description" text={pagingData.text} />
            <BoxButtonComponent border="right-border" aclass="pagination-button" icon={NextIcon} onClick={() => {goTo(pagingData.nextPage)}} {...(!pagingData.nextPageEnabled ? {disabled:"disabled"}: {}) } 
                {...(pagingData.isLoading ? {disabled:"disabled"} : {}) } />
        </div>
    )
}