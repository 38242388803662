import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, RouteComponentProps } from 'react-router-dom';
import { ActionPlanAdd } from '../../POBContent/ContentModule/ActionPlan/ActionPlanAdd';
import { ActionPlanEdit } from '../../POBContent/ContentModule/ActionPlan/ActionPlanEdit';

const queryClient = new QueryClient();

export const ActionPlan = (props: RouteComponentProps) => {
    return (
        <QueryClientProvider client={queryClient}>
        <React.Fragment>
            <Route path={props.match.url + "/add"} component={ActionPlanAdd} />
            <Route path={props.match.url + "/edit/:id"} component={ActionPlanEdit} />
        </React.Fragment>
        </QueryClientProvider>
    )
}