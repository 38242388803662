import { FormikProps } from "formik"
import { useEffect } from "react"
import { FormikSelectInputHandler } from "../../../form-components/FormikSelectInputHandlerV2"
import { OptionInputActiveNotActiveSelection } from "../../../form-components/OptionInputActiveNotActiveSelection"
import { TextInput } from "../../../form-components/TextInput"
import { FormikImageUploadFactory } from "../../../FormikHelper/FormikImageUploadFactoryV2"
import defaultProjectIcon from '../../../img/defaultProjectIcon.png'
import moneyIcon from '../../../img/icon-money.png'
import nameIcon from '../../../img/icon-project.png'
import { FormMode } from "../../Client/ui/ClientFormV3"
import { useSelectMemberHelper } from "../../useSelectMemberHelperV2"
import { useSelectPOBHeaderHelper } from "../../useSelectPOBHeaderHelperV2"
import { POBModel } from "../model/POBV2"
import { ClientPICResultBaseType } from "./AsyncClientPICSelectInputHandler"
import { AsyncClientPICSelectInput } from "./AsyncClientPICSelectInputV2"

export let POBForm: <T extends POBModel>(
    mode: FormMode,
    pageTitle: string, 
    props: FormikProps<T>
) => JSX.Element = 
    
    (mode, pageTitle, props) => {

    const { values, errors, touched, handleChange, setFieldValue } = props

    const handleClientChange = (client: ClientPICResultBaseType) => {
        setFieldValue("clientSelected", client)
        setFieldValue("clientId", client.value)
        setFieldValue("clientName", client.label)
    }

    useSelectPOBHeaderHelper((datas) => {
        setFieldValue("projectHeaderList", datas)
    })

    useSelectMemberHelper((datas) => {
        setFieldValue("memberList", datas)
    })
    
    useEffect(() => {
        if (!values.memberList) return
        if (!values.projectManagerId) return

        setFieldValue("memberSelected", values.memberList.find(data => data.value == values.projectManagerId))
    }, [values.projectManagerId, values.memberList])

    useEffect(() => {
        if (!values.projectHeaderList) return
        if (!values.projectHeaderId) return

        setFieldValue("projectHeaderSelected", values.projectHeaderList.find(data => data.value == values.projectHeaderId))
    }, [values.projectManagerId, values.memberList])
    
    return (
        <>
        <div className="page-title">{pageTitle} {values.name}</div>
            <div className="rowflex">
                <div className="col">
                    <FormikImageUploadFactory
                        mode={mode}
                        setFieldValue={setFieldValue}
                        file_fieldName="photo_url"
                        base64_fieldName="photo_base64"
                        fileChange_fieldName="isPhotoChanged"
                        className="pobHeader-profile-photo"
                        defaultImage={defaultProjectIcon}
                        file={values.photo_url}
                        base64={values.photo_base64}
                    />
                    <TextInput placeholder="Code" name="code" value={values.code} onChange={handleChange} message={errors.code} touched={touched.code} />
                </div>
                <div className="col default-padding-left">
                    <TextInput icon={nameIcon} placeholder="Name" name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                    <TextInput icon={moneyIcon} placeholder="Project Value" name="projectValue" value={values.projectValue} onChange={handleChange} message={errors.projectValue} touched={touched.projectValue} />
                    <div className="input-container">
                        <FormikSelectInputHandler
                            fieldName="projectHeaderId"
                            placeholder="Select Parent Project"
                            options={values.projectHeaderList ?? []}
                            selectedFieldName="projectHeaderSelected"
                            setFieldValue={setFieldValue}
                            selected={values.projectHeaderSelected}
                        />
                    </div>
                    <div className="input-container">
                        <FormikSelectInputHandler
                            fieldName="projectManagerId"
                            placeholder="Select Project Manager"
                            options={values.memberList ?? []}
                            selectedFieldName="memberSelected"
                            setFieldValue={setFieldValue}
                            selected={values.memberSelected}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="input-container">
                    {/* 
                    TEMPORARY LOCKED
                    {getCustomerHandlerComponent() } */}
                    <TextInput placeholder="Customer ( Temporary Field )" name="customerTemporary" value={values.customerTemporary} onChange={handleChange} message={errors.customerTemporary} touched={touched.customerTemporary} />
                </div>
                <div className="input-container">
                    <AsyncClientPICSelectInput 
                        fieldName="client"
                        placeholder="Select Client PIC"
                        onChange={handleClientChange}
                        value={values.clientSelected}
                    />
                </div>
            </div>

            <div className="row">
                <OptionInputActiveNotActiveSelection {...props} />
            </div>
        </>
    )
}