import { RouteComponentProps, useHistory } from "react-router";
import { ClientPagePath } from "../../../database_api/api";
import { ButtonInput } from "../../../form-components/ButtonInput";
import { ListLayoutFacade } from "../../../layout/ListLayoutFacade";
import { PaginationComponent } from "../../Paging/ui/PaginationComponentV2";
import { FlexibleSearchComponent } from "../../Search/FlexibleSearchComponentV2";
import { OptionInputSearchComponent } from "../../Search/OptionInputSearchComponentV2";
import { OptionInputSearchCustomComponent } from "../../Search/OptionInputSearchCustomComponentV2";
import { QueryCompareTypeEnum } from "../../Search/QueryFilterProtocol";
import { useDataList } from "../../Shared/useDataList";
import { ClientComponent, ClientComponentProp } from "./ClientComponentV2";

export const ClientList = (props: RouteComponentProps) => {
    const itemSize = 8
    const { data, totalData, totalPage, isLoading, params } = useDataList <ClientComponentProp>({
        url: ClientPagePath,
        size: itemSize,
        identifier: "clients",
        ignoreParamsAsURL: ["prospectiveMode"]
    })

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Name"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "phone",
            displayField: "Phone"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "email",
            displayField: "Email"
        },

    ]

    let prospectiveFiltercomponent = (
        <>
            <h2>Prospective Status</h2>
            {<OptionInputSearchCustomComponent name='prospectiveStatusFilter'
                fieldName='prospectiveStatus'
                options={[
                    { key: 'all', value: 'All' },
                    { key: '0', value: 'Neutral' },
                    { key: '1', value: 'Client' },
                    { key: '2', value: 'Competitor' }
                ]}
                initialValue={typeof params.prospectiveStatus === "string" ? params.prospectiveStatus : 'all'}
            />}
        </>
    )
    
    let filterComponents = [
        <>
            <h2>Status</h2>
            {<OptionInputSearchComponent name='filter' options={[
                { key: 'all', value: 'All' },
                { key: '1', value: 'Active' },
                { key: '0', value: 'Not Active' }
            ]}
                initialValue={ typeof params.active === "string" ? params.active : 'all' }
            />}
        </>
    ]

    if(params.prospectiveMode) filterComponents = [prospectiveFiltercomponent, ...filterComponents]

    let contentComponent = (
        <div id="content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="client-list">
                {
                    (data && data.length > 0 && data.map((client, index) => {
                        return <ClientComponent
                            id={client.id} name={client.name} address={client.address}
                            photo_urlString={client.photo_urlString} email={client.email}
                            businessUnitOfficer={client.businessUnitOfficer} product={client.product}
                            socialMedia={client.socialMedia} variant={client.variant}
                            note={client.note} active={client.active} prospectiveStatus={client.prospectiveStatus}
                            prospective={params.prospectiveMode ? true: false}
                            url={client.url} phone={client.phone} key={index} />
                    }))
                    ||
                    <div className="message">No data</div>
                }
            </div>

            <PaginationComponent 
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    )

    if(isLoading) return <div>Loading</div>

    return (
        <ListLayoutFacade
            contentComponents={contentComponent}
            topSearchComponent={
                <div id="search-container">
                    <FlexibleSearchComponent isLoading={isLoading} placeholder="Name, Phone, Email" queryOptions={queryOptions} />
                </div>
            }
            filterComponents={filterComponents}
            addComponent={<AddComponent basePath={props.match.url} />}
        />
    )
}

const AddComponent = (props: { basePath: string }) => {
    const history = useHistory()
    return <ButtonInput id="add" caption="+" title="Add New" onClick={() => history.push(props.basePath + "/add")} />
}