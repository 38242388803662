import * as React from 'react';
import { SearchButtonInput } from '../../form-components/SearchButtonInput';
import searchIcon from '../../img/icon-search.png';
import { QueryComponent, QueryCompareTypeEnum } from './QueryFilterProtocol';
import { SearchFeatureProtocol } from './SearchFeatureProtocol';
import { LoadingControlProtocol } from './SearchComponent';
import Select, { components } from 'react-select';

import dropdownIcon from '../../img/dropdown-arrow.png'

export type SearchQueryComponent = {
    label: string 
    value: string
    uniqueValue: string
    query: QueryComponent
}

export interface SearchDataSubmitterProtocol{
    register(submitMethod: () => SearchQueryComponent[] | undefined): void
    unregister(submitMethod: () => SearchQueryComponent[] | undefined): void
    getSubmittedData(): SearchQueryComponent[] | undefined
    triggerSubmit?(): void
}

export class SearchDataSubmitter implements SearchDataSubmitterProtocol{
    private submitMethodList: (() => SearchQueryComponent[] | undefined)[] = []
    private queryList: SearchQueryComponent[] = []

    register = (submitMethod: () => SearchQueryComponent[] | undefined) => {
        this.submitMethodList.push(submitMethod)
    }
    unregister = (submitMethod: () => SearchQueryComponent[] | undefined) => {
        this.submitMethodList = this.submitMethodList.filter( method => method !== submitMethod)
    }
    getSubmittedData = () => {
        this.queryList = []
        this.submitMethodList.forEach( submitMethod => {
            let newData = submitMethod()
            if(newData != undefined) this.queryList = [...this.queryList, ...newData]
        })
        return this.queryList
    }
    
}

type BuildableSearchComponentProp = {
    submitter: SearchDataSubmitterProtocol,
    searchModule: SearchFeatureProtocol, 
    loadingController: LoadingControlProtocol,
    fixedAppliedQuery?: QueryComponent
}

export class BuildableSearchComponent extends React.Component<BuildableSearchComponentProp, {isLoading: boolean}>{
    searchModule: SearchFeatureProtocol
    loadingController: LoadingControlProtocol

    constructor(props: BuildableSearchComponentProp){
        super(props)
        this.searchModule = props.searchModule
        this.loadingController = props.loadingController

        this.state = {
            isLoading: false
        }

        this.loadingController.register(this.setLoading)
        this.props.submitter.triggerSubmit = this.onClick
    }

    setLoading = (status: boolean) => {
        this.setState({
            isLoading: status
        })
    }

    onClick = () => {
        let data = this.props.submitter.getSubmittedData()
        let param: QueryComponent[] = ( data != undefined ? data.map((value) => {
            let query = value.query
            return {
                compareType: query.compareType,
                field: query.field,
                value: query.value,
                reference: query.reference
            }
        }) : [])

        if(this.props.fixedAppliedQuery){
            param.push(this.props.fixedAppliedQuery)
        }

        this.searchModule.search(param)
    }

    render(){
        return (
            <form id="search-container">
                {this.props.children}
                <SearchButtonInput id="find" title="Search" icon={searchIcon} onClick={this.onClick} {...(this.state.isLoading ? {disabled:"disabled"} : {}) }/>
            </form>
        )
    }
}

export interface BuildableSearchInputComponentProp{
    submitter: SearchDataSubmitterProtocol
}

interface SimpleSelectComponentProp extends BuildableSearchInputComponentProp{
    fieldSearchName: string,
    loadingController: LoadingControlProtocol
    placeholder: string
    queryOptions: {label: string, value: string}[]
}

export class SimpleSelectComponent extends React.Component<SimpleSelectComponentProp, {isLoading: boolean}>{

    searchInputValue: SearchQueryComponent[] = []
    loadingController: LoadingControlProtocol

    constructor(props: SimpleSelectComponentProp){
        super(props)
        this.loadingController = props.loadingController

        this.state = {
            isLoading: false
        }

        this.loadingController.register(this.setLoading)
        this.props.submitter.register(this.submit)
    }

    setLoading = (status: boolean) => {
        this.setState({
            isLoading: status
        })
    }

    submit = (): SearchQueryComponent[] | undefined => {
        return this.searchInputValue
    }

    handleChange = (inputValue: any, _: any) => {
        if(inputValue.value == ""){ 
            this.searchInputValue = []
            return
        }

        this.searchInputValue = [
            {
                label: inputValue.label,
                uniqueValue: inputValue.value,
                value: inputValue.value,
                query: {
                    compareType: QueryCompareTypeEnum.equal,
                    field: this.props.fieldSearchName,
                    value: inputValue.value
                }
            }
        ]
      };

    render(){
        return (
            <div className="simple-select-component">
                <Select className="search-input no-border" placeholder={this.props.placeholder} isLoading={this.state.isLoading} classNamePrefix="si" 
                    options={this.props.queryOptions} onChange={this.handleChange} defaultValue={this.props.queryOptions[0]}/>
                <div className="icon-container">
                    <img className="dropdownIcon" src={dropdownIcon} />
                </div>
            </div>
        )
    }
}