import { useFormik } from "formik"
import { ObjectSchema } from "yup"
import { ClientPath } from "../../../database_api/api"
import { useAddFormData } from "../../Shared/useAddFormData"
import { ClientModel } from "../model/Client"

export const useClientAddFormHandler = (initialValues: ClientModel, validationSchema: ObjectSchema) => {
    const { add } = useAddFormData(ClientPath)
    
    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(true)

            let clientData: ClientModel = {
                name: values.name,
                address: values.address,
                url: values.url,
                phone: values.phone,
                businessUnitOfficer: values.businessUnitOfficer,
                product: values.product,
                socialMedia: values.socialMedia,
                email: values.email,
                note: values.note,
                active: values.active,
                prospectiveStatus: values.prospectiveStatus,
                variant: values.variant
            }

            let clientDataForm = new FormData()
            Object.entries(clientData).forEach(field => {
                clientDataForm.append(field[0], field[1] || "")
            })

            if (values.photo_url) {
                clientDataForm.append('photo_url', values.photo_url)
            }

            setSubmitting(false)
            add(clientDataForm)
        }
    })

    return formik
}