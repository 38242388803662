import React from 'react'
import { MonthTagProps } from './MonthTagProps'

interface MonthTagComponentProps {
    monthTag: MonthTagProps
    selectedMonth: string
    onMonthChoosen: (month: string) => void
}

export let MonthTagComponent: React.FunctionComponent<MonthTagComponentProps> = ({ monthTag, selectedMonth, onMonthChoosen, ...props }) => {
    let monthList: {[name: string]: string} = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    }
    return (
        <li>
            <a onClick={() => { onMonthChoosen(monthTag.month) }}>{monthList[monthTag.month]}</a><br />
            {(monthTag.month == selectedMonth) &&
                props.children && props.children
            }
        </li>
    )
}