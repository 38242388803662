import { GetAPIHTTPClient } from "../../database_api/StandardAPIHTTPClient";
import { BudgetModel, POBHeaderModel } from "../POBHeader/model/POBHeader";
import { POBModel } from "../POB/model/POB";
import { ResultStatus } from "../../Types/ResultStatus"
import { DateHelper } from "../../helper/DateHelper";
import { asBlob } from "html-docx-js-typescript"
import { DownloadStatus } from "./DownloadStatus";
import { LatestStatusDownloadData } from "./LatestStatusDownloadData";
import { DateRangeFilter } from "./filter/DateRangeFilter";
import ReactDOM from "react-dom";
import { saveAs } from "file-saver";

const dateFormat = "DD MMMM YYYY"

export enum ProjectType {
    pob = "pob",
    pobHeader = "pobHeader"
}

interface ProjectHTML {
    contentHtml: {
        contentId: string,
        contentName: string,
        html: string
    }[],
    latestStatuses: LatestStatusDownloadData[]
}

interface ProjectManagerExtra{
    projectManagerName?: string
}

interface POBExtra {
    projectHeaderName?: string
    customerName?: string
}

interface ProjectDownloadPOBHeader extends POBHeaderModel, ProjectManagerExtra, ProjectHTML{}

interface ProjectDownloadPOB extends POBModel, ProjectManagerExtra, POBExtra, ProjectHTML{}

export interface DownloadProjectResult {
    status: ResultStatus
    message?: string
    data?: {
        value: ProjectDownloadPOBHeader | ProjectDownloadPOB
    }
}

export interface DownloadProjectToDocProtocol{
    download(type: ProjectType, projectId: string, result: (result: DownloadProjectResult | undefined) => void): void
    toWord(type: ProjectType, project: DownloadProjectResult, fileName: string): void
    getPostfix(): string
}

export class DownloadProjectToDoc implements DownloadProjectToDocProtocol{
    status = DownloadStatus.idle;
    client: GetAPIHTTPClient;
    basePath: string;
    downloadDecisionFn: ((download: boolean) => void) | undefined = undefined

    from: Date = new Date()
    to: Date = new Date()

    constructor(client: GetAPIHTTPClient, basePath: string) {
        this.client = client;
        this.basePath = basePath;
    }

    filterElement = () => <DateRangeFilter downloadDecisionFn={ this.downloadDecisionFn }
        setFromFilter = {(date) => { this.from = date }}
        setToFilter = {(date) => { this.to = date }}
    />

    download(type: ProjectType, projectId: string, result: (result: DownloadProjectResult | undefined) => void) {
        this.showFilter(download => {
            if (!download) {
                ReactDOM.render(<></>, document.getElementById("download-component"))
                result(undefined)
                return
            }
            
            this.status = DownloadStatus.request;
            this.client.get(this.basePath + type + "/" + projectId + "/" + this.from.toISOString() + "/" + this.to.toISOString(), clientResult => {
                this.status = DownloadStatus.idle;

                if (clientResult.message && clientResult.message == "13") {
                    clientResult.message = `Project ${projectId} is not registered`;
                }
                result(clientResult);
            });
        })
        
    }

    private showFilter(callback: (download: boolean) => void) {
        this.downloadDecisionFn = callback
        ReactDOM.render(this.filterElement(), document.getElementById("download-component"))
    }

    toWord(type: ProjectType, project: DownloadProjectResult, fileName: string) {
        if (!project.data || !project.data.value.id || !project.data.value.name)
            return "";
        
        
        let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            "<head><meta charset='utf-8'><title>" + project.data.value.name + "</title>" +
            "</head>" +
            "<body>";

        let htmlToPrint = this.useTemplate(type, project);
        let footer = "</body></html>";
        let htmlSource = header + htmlToPrint + footer;
        let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlSource);
        
        asBlob(htmlToPrint).then( data => {
            saveAs(data as Blob, fileName)
        })

        return source;
    }

    getPostfix = () => "( " + DateHelper.dateFormat(this.from.toLocaleDateString(), dateFormat) + " - " + DateHelper.dateFormat(this.to.toLocaleDateString(), dateFormat) + " )"

    private useTemplate(type: ProjectType, project: DownloadProjectResult) {
        if (!project.data)
            return "";
        let temp: any = project.data.value;

        var html = `<h1>Project ${project.data.value.name}</h1>`;

        if (type == ProjectType.pob) {
            let projectData: POBModel & ProjectManagerExtra & POBExtra = temp;

            html += `
            <table border=0 width="100%">
                <tr><td><b>Project Code</b></td><td><b>Project Manager</b></td></tr>
                <tr><td>${projectData.code}</td><td>${projectData.projectManagerName ?? "-"}</td></tr>
                
                <tr><td><b>Customer</b></td><td><b>Client</b></td></tr>
                <tr><td>${projectData.customerTemporary ?? "-"}</td><td>${projectData.clientName ?? "-"}</td></tr>

                <tr><td colspan=2><b>Project Value</b>: ${projectData.projectValue ?? "0"}</td></tr>
            </table>
            `;
        }
        else {
            let projectData: POBHeaderModel & ProjectManagerExtra = temp;

            html += `
            <table border=0 width="100%">
                <tr><td><b>Project Code</b></td><td><b>Project Manager</b></td></tr>
                <tr><td>${projectData.code}</td><td>${projectData.projectManagerName ?? "-"}</td></tr>
                
                <tr><td colspan=2><b>Fiscal Year</b>: ${projectData.fiscalYearFrom ?? "??"} - ${projectData.fiscalYearTo ?? "??"}</td></tr>
            </table>
            `;

            let budgetList: BudgetModel[] = []
            if(typeof projectData.budgetList == "string"){
                budgetList = JSON.parse(projectData.budgetList)
            }
            if (budgetList.length > 0) {
                var budgetListHtml = "";
                budgetList.forEach(budget => {
                    budgetListHtml += `<tr><td>${budget.budgetType}</td><td>${budget.value}</td></tr>`;
                });

                html += `
                <table width="100%">
                    <tr><td><b>Type</b></td><td><b>Budget</b></td></tr>
                    ${budgetListHtml}
                </table>
                `;
            }
        }

        project.data.value.contentHtml.forEach(contentHtmlData => {
            if(contentHtmlData.html){
                html += `<h2>${contentHtmlData.contentName}</h2>${contentHtmlData.html}`;
            }
        });

        html += this.useTemplateLatestStatusAndAnalysis(project.data.value.latestStatuses)

        return html;
    }

    private useTemplateLatestStatusAndAnalysis(latestStatuses: LatestStatusDownloadData[]){
        let html = ``
        if (!latestStatuses) return html

        html += `<h2>Latest Status & Analysis</h2>`

        latestStatuses.forEach(latestStatus => {
            html += "<h3><u>" + DateHelper.dateFormat(latestStatus.date, "MMMM DD, YYYY HH:mm")
            if (latestStatus.location != "") html += " at " + latestStatus.location ?? "-"
            html += "</u></h3>"

            if (latestStatus.personnelList) html += "Personnel List: " + latestStatus.personnelList + "<br>"
            if (latestStatus.contractors) html += "Contractors: " + latestStatus.contractors + "<br>"
            html += "<br><b>Information</b><br>"
            html += latestStatus.information ?? "-"
            html += "<br><br>"

            html += "<b>Analysis</b><br>"
            html += latestStatus.analysis ?? "-"
            html += "<br/><br/>"
        })

        return html;
    }
}
