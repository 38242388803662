export enum BackupStatus {
    process = "process",
    done = "done",
    failed = "failed"
}

export enum RestoreStatus {
    process = "process",
    done = "done",
    failed = "failed"
}

export type RestoreHistory = {
    startRestoreTime: string,
    status: RestoreStatus,
    error?: string,
    finishRestoreTime?: string
}

export interface DbBackupModel {
    id: string
    name: string
    date: string
    dbFilePath?: string
    error?: string
    status: BackupStatus
    restoreHistories?: RestoreHistory[]
}