import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalData from './GlobalData';
import client_icon from './img/icon-client.png';
import clientCompany_icon from './img/icon-clientCompany.png';
import pobHeader_icon from './img/icon-header.png';
import lawRegulation_icon from './img/icon-lawRegulation.png';
import logout_icon from './img/icon-logout.png';
import member_icon from './img/icon-members.png';
import profile_icon from './img/icon-profile.png';
import customer_icon from './img/icon-userOrganization.png';
import more_menu from './img/more-menu.png';
import pob_icon from './img/pob.png';
import { Lang } from './lang/Lang';

export const NavigationHeaderMenu = () => {
    const { setPageTitle } = useContext(GlobalData)
    const [ showMoreMenu, setShowMoreMenu ] = useState(false)
    return (
        <div id="NavigationHeaderMenu">
            <ul>
                {menus?.map((menu, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => { menu.name && setPageTitle(menu.name) }}
                            data-cy={`navMenu${index}`}
                        >
                            <Link to={menu.path}>
                                <img id="icon" src={menu.icon} />
                                {menu.name ? <span>{menu.name}</span> : ""}
                            </Link>
                        </li>
                    )
                })}
                <li onMouseEnter={() => { setShowMoreMenu(true) }} 
                    onMouseLeave={() => { setShowMoreMenu(false) }} 
                    onClick={() => { setShowMoreMenu(!showMoreMenu)}}
                >
                    <img id="moreIconMenu" src={more_menu} />
                    <div id="MoreNavigationMenuWrapper" {...(!showMoreMenu) ? { className: "hidden" } : {}}>
                        <div id="MoreNavigationMenu">
                            {moreMenus.map((menu, index) => {
                                if (menu.name && menu.name == "separator") {
                                    return <div key={index} className="separator" />
                                }

                                return (
                                    <div onClick={() => { setPageTitle(menu.name) }}>
                                        <Link to={menu.path ?? "#"}><img id="icon" src={menu.icon} />
                                            {menu.name ? <span>{menu.name}</span> : ""}
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export const menus = [
    {
        icon: pobHeader_icon,
        name: "Parent Project",
        path: "/pob-header/?active=1"
    },
    {
        icon: pob_icon,
        name: "Child Project",
        path: "/pob"
    }
]

export const moreMenus = [
    {
        icon: lawRegulation_icon,
        name: "Law & Regulation",
        path: "/law-regulation"
    },
    { name: "separator" },
    {
        icon: clientCompany_icon,
        name: Lang.mClient,
        path: "/client/v2?variant=client"
    },
    {
        icon: clientCompany_icon,
        name: Lang.mProspectiveClient,
        path: "/prospective-client/v2?variant=client&prospectiveMode=true"
    },
    {
        icon: clientCompany_icon,
        name: Lang.mClientPartner,
        path: "/client-partner/v2?variant=clientPartner"
    },
    {
        icon: client_icon,
        name: Lang.mClientPersonnel,
        path: "/client/v2/selector"
    },

    { name: "separator" },
    {
        icon: customer_icon,
        name: "Customer",
        path: "/customer"
    },
    {
        icon: client_icon,
        name: Lang.mDatabaseTNIAL,
        path: "/database-tni-al"
    },
    { name: "separator" },
    {
        icon: member_icon,
        name: Lang.mMembers,
        path: "/member"
    },
    {
        icon: profile_icon,
        name: Lang.mPersonnelProfile,
        path: "/profile"
    },
    {
        icon: member_icon,
        name: "Bravado Armada News",
        path: "/news"
    },
    { name: "separator" },
    {
        icon: member_icon,
        name: "Backup / Restore",
        path: "/backup"
    },
    {
        icon: logout_icon,
        name: "Logout",
        path: "/logout"
    }
]