import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { Lang } from '../../../lang/Lang';
import { RoutePath } from '../../../page/RoutePath';
import { AsyncClientSelectInputHandler, ClientResultType } from '../../ClientPIC/AsyncClientSelectInputHandler';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';
import history from '../../RouteManager';

export const ClientSelector = () => {
    let handleClientChange = (client: ClientResultType) => {
        history.push(RoutePath.clientPICList + "?clientId=" + client.value + "&clientName=" + client.label)
    }

    let client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))
    let clientSelector = new AsyncClientSelectInputHandler("client", "Select " + Lang.mClient, client, handleClientChange)

    return (
        <div id="form-container">
            <h2>Client Selector</h2>
            <div className="rowflex">
                <div className="col default-padding-left">
                    <div className="default-padding-top">
                        {clientSelector.getComponent()}
                    </div>
                </div>
            </div>
        </div>
    )
}