import projectIcon from '../../../img/icon-actionPlanProject.png';
import timerIcon from '../../../img/icon-actionPlanTimer.png';
import locationIcon from '../../../img/icon-location.png';

import { useState } from 'react';
import ReactDOM from 'react-dom';
import { ResultStatus } from '../../../Types/ResultStatus';
import { EditButton } from '../../../components/EditButton';
import { ActionPlanPath } from '../../../database_api/api';
import { DateHelper } from '../../../helper/DateHelper';
import statusMenu from '../../../img/checked.png';
import deleteMenu from '../../../img/close.png';
import statusPendingMenu from '../../../img/icon-plus.png';
import { Lang } from '../../../lang/Lang';
import { RoutePath } from '../../../page/RoutePath';
import { FieldInformationAddV3 } from '../../FieldInformation/ui/FieldInformationAddV3';
import { useURL } from '../../Search/useURL';
import { useDeleteData } from '../../Shared/useDeleteData';
import { ActionPlanModel } from '../model/ActionPlan';

export interface ActionPlanComponentProp extends ActionPlanModel {
    disableEdit?: Boolean
    disableDelete?: Boolean
}

export const ActionPlanComponent = (props: ActionPlanComponentProp) => {
    const { history, location, params } = useURL()
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const { remove } = useDeleteData(ActionPlanPath + props.id)  

    const goToResult = () => {
        ReactDOM.render(<FieldInformationAddV3
            projectId={props.projectId}
            actionPlan={props}
            aclass="ActionPlanResult" />, document.getElementById("field-information-add"))
    }

    const confirmDeleteActionPlan = () => {
        if (!props.id) return
        if (!window.confirm("Yakin hapus ActionPlan ini?")) return

        remove(undefined, {
            onSuccess: (result) => {
                if(result.status === ResultStatus.ok)
                    setIsDeleted(true)
            }
        })
    }

    if (isDeleted) return <div className="actionPlan-container full-border">
        <div className="row actionPlan-header">
            <div className="icon"><img src={timerIcon} /></div>
            <div className="date">{props.date && DateHelper.dateFormat(props.date, "MMMM DD, YYYY HH:mm")}</div>
        </div>
        <div className="line-decoration" />

        <div className="row actionPlan-description">
            Action Plan deleted.
        </div>
        
    </div>

    return (
        <div className="actionPlan-container full-border">
            <div className="row actionPlan-header">
                <div className="icon"><img src={timerIcon} /></div>
                <div className="date">{props.date && DateHelper.dateFormat(props.date, "MMMM DD, YYYY HH:mm")}</div>
            </div>
            <div className="line-decoration" />

            <div className="row actionPlan-description">
                <div className="actionPlan-name">{props.name}</div>
                <div className="actionPlan-location"><img src={locationIcon} />{props.location ?? "Location not decided yet"}</div>

                <div className="actionPlan-with">PIC:</div>
                <div className="actionPlan-personnelList">{props.picIds ? props.picIds.join(", ") : "-"}</div>
                <div className="actionPlan-with">{Lang.actionPlanCustomerPersonnel}:</div>
                <div className="actionPlan-personnelList">{props.personnelList ? (props.personnelList === "" ? "-" : props.personnelList) : "-"}<br />{props.contractors}</div>
                
                <div className={ props.googleCalendarEventId ? "googleCalendar-tag" : "no-googleCalendar-tag" }>
                    { props.googleCalendarEventId ? 'Has Google Calendar' : 'No Google Calendar' }
                </div>
            </div>
            <div className="line-decoration" />
            <div className="row actionPlan-description">
                <div className="actionPlan-with">{Lang.actionPlanObjective}:</div>
                <div className="actionPlan-objective" dangerouslySetInnerHTML={{
                    __html: props.objective ? props.objective : "N/A"
                }}></div>
            </div>

            <div className="line-decoration" />

            <div className="rowflex">
                <div className="actionPlan-project">
                    <div className="icon"><img src={projectIcon} /></div>
                    <div className="project">{props.projectName}</div>
                </div>

                <div className="actionPlan-options">
                    {!props.disableEdit && <EditButton basePath={RoutePath.actionPlanList} code={props.id} />}
                    <input className="status" type="image" src={props.fieldInformationId ? statusMenu : statusPendingMenu}
                        title={props.fieldInformationId ? "Update Existing Result" : "Add Result"}
                        onClick={() => {
                            history.push({
                                pathname: location.pathname,
                                search: location.search + "&resultId=" + props.id
                            })
                        }} />
                    {!props.disableEdit && <input className="option actionPlan-delete" title="Delete" type="image" src={deleteMenu} onClick={() => { 
                        confirmDeleteActionPlan()
                    }} />}
                </div>
            </div>

            <div className="clear"></div>

            {params.resultId && params.resultId === props.id &&
            <div id="field-information-add">
                <FieldInformationAddV3 projectId={props.projectId} actionPlan={props} aclass="ActionPlanResult" />
            </div>
            }
        </div>
    )
}