import { useEffect, useState } from 'react'
import { NewsYearNewsPath } from '../../../../database_api/api'
import { StandardAPIHTTPClient } from '../../../../database_api/StandardAPIHTTPClient'
import { HTTPHeader } from '../../../../interfaces/HTTPHeader'
import { AuthTokenSessionStorage } from '../../../Login/AuthPermission'
import { useURL } from '../../../Search/useURL'
import { MonthNewsComponent } from './MonthNewsComponent'
import { MonthNewsProps } from './MonthNewsProps'
import { NewsProps } from './NewsProps'
import { YearNewsComponent } from './YearNewsComponent'

interface RawMonthNewsListData {
    time: string
    id: string
    title: string
}

export const YearNewsListComponent = () => {
    const { location, params, goTo } = useURL()
    
    const [years, setYears] = useState<string[]>([])
    const [selectedYear, setSelectedYear] = useState("")
    const [monthsNewsList, setMonthsNewsList] = useState<MonthNewsProps[]>([])
    const [selectedMonth, setSelectedMonth] = useState("")
    
    const selectedNewsId = params.id ? params.id.toString() : ""
    const [selectedNews, setSelectedNews] = useState(selectedNewsId)

    const client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))

    const updateYear = () => {
        client.get(NewsYearNewsPath + "getYear", (result: {data: string[]}) => {
            console.log(result)
            setYears(result.data)
        })
    }

    const onYearChoosen = (year: string) => {
        setSelectedYear((year === selectedYear) ? "" : year)
        if (selectedYear) requestMonthNewsList(selectedYear)
    }

    const requestMonthNewsList = (year: string) => {
        let from = year + "-01-01"
        let to = (parseInt(year) + 1).toString() + "-01-01"

        client.get(NewsYearNewsPath + from + "/" + to, result => {
            updateMonths(result.data)
        })
    }

    const clearMonths = (callback: () => void) => {
        setMonthsNewsList([])
        callback()
    }

    const updateMonths = (datas: RawMonthNewsListData[]) => {
        clearMonths(() => {
            let months: MonthNewsProps[] = organizeMonthsNewsList(datas)
            setMonthsNewsList(months)
        })
    }

    const organizeMonthsNewsList = (datas: RawMonthNewsListData[]) => {
        let months: MonthNewsProps[] = []
        if(!Array.isArray(datas)) return []
        if(datas.length == 0) return []

        datas.forEach((data: RawMonthNewsListData) => {
            let time = new Date(data.time)
            let month = (time.getMonth() + 1).toString()
            let newsListJSON: NewsProps = {
                id: data.id,
                title: data.title
            }

            let existsMonth = months.filter(cMonth => cMonth.month === month)
            if (existsMonth.length == 0) {
                months.push({
                    month: month,
                    newsList: [newsListJSON]
                })
                return
            }

            existsMonth.forEach( month => {
                month.newsList = [...month.newsList, newsListJSON]
            })
            months = [...months.filter(cMonth => cMonth.month != month), ...existsMonth]
        })

        return months
    }

    const onMonthChoosen = (month: string) => {
        setSelectedMonth((month === selectedMonth) ? "" : month)
    }

    const onNewsChoosen = (newsId: string) => {
        let selectedNewsId: string = newsId

        if(selectedNews === newsId)
            selectedNewsId = ""

        setSelectedNews(selectedNewsId)
        delete params.page
        params.id = selectedNewsId
        
        goTo(location.pathname, params)
    }

    useEffect(() => {
        updateYear()
    }, [])

    return (
        <>
            <h2>NewsList</h2>
            <ul>
                {years && years.map( year => (
                    <YearNewsComponent year={year} selectedYear={selectedYear} onYearChoosen={onYearChoosen}>
                        <ul>
                            {monthsNewsList.map(monthNewsList => (
                                <MonthNewsComponent monthNews={monthNewsList} selectedMonth={selectedMonth} onMonthChoosen={onMonthChoosen}>
                                    <ul className="news-list">
                                        {monthNewsList.newsList.map( news => (
                                            <li className={selectedNews === news.id ? "news-button selected" : "news-button"}
                                                onClick={() => { onNewsChoosen(news.id) }}>
                                                    <a className={selectedNews === news.id ? "selected" : ""} >
                                                        {news.title}
                                                    </a>
                                            </li>
                                        ))}
                                    </ul>
                                </MonthNewsComponent>
                            ))}
                        </ul>
                    </YearNewsComponent>
                )) }
            </ul>
        </>
    )
}

