import { FormikProps } from 'formik';
import { OptionInput } from "./OptionInput";

export let OptionInputActiveNotActiveSelection: <T extends {active?: string}>(props: FormikProps<T>) => JSX.Element = 
    ({values, handleChange}) => (
    <OptionInput name='active'
        value={values.active} onChange={handleChange}
        options={[
            {
                key: "1",
                value: "Active"
            },
            {
                key: "0",
                value: "Not Active"
            }
        ]} 
    />
)