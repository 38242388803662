import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { LogsListBuilder } from '../builder/LogsListBuilder';
export class Logs extends React.Component<RouteComponentProps>{
    basePath: string
    constructor(props: RouteComponentProps){
        super(props)

        this.basePath = this.props.match.url
    }

    render(){
        return (
            <React.Fragment>
                <Route path={this.props.match.url} component={(props: RouteComponentProps) => { return <LogsListBuilder {...props} /> }} exact={true} />
            </React.Fragment>
        )
    }
}