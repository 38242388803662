import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { CustomerAdd } from './CustomerAddV2';
import { CustomerEdit } from './CustomerEditV2';
import { CustomerList } from './CustomerListV2';

export const Customer = (props: RouteComponentProps) => {
    return (
        <React.Fragment>
            <Route path={props.match.url} component={CustomerList} exact={true} />
            <Route path={props.match.url + "/add"} component={CustomerAdd}/>
            <Route path={props.match.url + "/edit/:id"} component={CustomerEdit}/>
        </React.Fragment>
    )
}