import { Jodit } from 'jodit';
import { useEffect, useRef, useState } from 'react';

export interface JoditReactProps {
    /** Config option from Jodit */
    config?: Partial<Jodit['options']>;
    /** Jodit default value */
    defaultValue?: string;
    placeHolder?: string;
    /** Callback to update the state */
    onChange?: (content: string) => void;
    onBlur?: (content: string) => void;
    onFocus?: () => void;
}

const JoditReact = ({ config, defaultValue, placeHolder, onChange, onBlur, onFocus }: JoditReactProps) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [jodit, setJodit] = useState<Jodit>();

    useEffect(() => {
        if (textAreaRef && textAreaRef.current) {
            const jodit = Jodit.make(textAreaRef.current, config);
            jodit.events.on('focus', () => onFocus && onFocus());
            jodit.events.on('change', (value) => onChange && onChange(value));
            jodit.events.on('blur', (_) => onBlur && textAreaRef.current && onBlur(textAreaRef.current.value));
            setJodit(jodit);

            return () => {
                jodit && jodit.destruct();
            }
        }
    }, [config]);

    useEffect(() => {
        if (jodit && defaultValue) {
            jodit.value = defaultValue;
        }
    }, [jodit, defaultValue]);

    return <textarea ref={ textAreaRef } placeholder={placeHolder} />;
};

export default JoditReact;
