import { TextInput } from '../../../../form-components/TextInputV2';

import nameIcon from '../../../../img/icon-actionPlanTimer.png';
import dateIcon from '../../../../img/icon-date.png';

import { FormikProps } from 'formik';
import DatePicker from 'react-datepicker';
import { FormikSelectMultiInputHandler } from '../../../../form-components/FormikSelectMultiInputHandlerV2';
import { JoditContentEditorModule } from '../../../../form-components/JoditContentEditorModuleV3';
import { Lang } from '../../../../lang/Lang';
import { ActionPlanModel } from '../../../ActionPlan/model/ActionPlan';

export const ActionPlanForm: <T extends ActionPlanModel>(pageTitle: string, props: FormikProps<T>) => JSX.Element = 
(pageTitle, props) => {
    const { values, errors, touched, handleChange, setFieldValue } = props

    let handleDateChange = (date: Date | [Date, Date] | null) => {
        setFieldValue('date_date', date)
        if (date) {
            setFieldValue('date', date.toLocaleString("en-US"))
        }
    }
    
    let handleObjectiveChange = (value: string) => {
        setFieldValue('objective', value)
    }

    return (
        <div style={{minWidth: "330px"}}>
        <div className="page-title">{pageTitle} Action Plan</div>
        <div className="row">
            <div className="col">
                <TextInput aclass="full-width default" icon={nameIcon} placeholder={Lang.actionPlanItem} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                <div className="input-container">
                    <DatePicker className="text-input default" placeholderText={Lang.actionPlanTime} selected={values.date_date} onChange={(date) => { handleDateChange(date) }}
                        showTimeSelect dateFormat="MMMM d, yyyy H:mm" timeFormat='HH:mm'
                        customInput={<TextInput icon={dateIcon} placeholder="Rule Date" name="ruleDate" value={values.date} />} />

                    <TextInput aclass="full-width default" icon={nameIcon} placeholder={Lang.actionPlanLocation} name="location" value={values.location} onChange={handleChange} message={errors.location} touched={touched.location} />
                    {/* <TextAreaInput aclass="full-width default" placeholder={Lang.actionPlanObjective} name="objective" value={values.objective} onChange={handleChange} message={errors.objective} touched={touched.objective} /> */}
                    
                    <div className="input-container">
                        <h2>{Lang.actionPlanObjective}</h2>
                        <JoditContentEditorModule
                            aclass="textarea"
                            placehoder={Lang.actionPlanObjective}
                            fieldName="objective" value={values.objective}
                            handleChange={handleObjectiveChange}
                        />
                    </div>
                    <input type="hidden" name="projectId" value={values.projectId} />

                </div>
            </div>
        </div>

        <div className="line-decoration default-margin-top" />

        <div>
            <div className="row default-margin-top">
                <div className="col">
                    <div className="input-container">
                        <FormikSelectMultiInputHandler
                            fieldName="picIds"
                            placeholder="Select PIC"
                            options={values.memberList ?? []}
                            selectedFieldName="membersSelected"
                            setFieldValue={setFieldValue}
                            selected={values.membersSelected}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="row default-margin-top">
            <div className="col">
                <div className="input-container">
                    <TextInput aclass="default full-width" placeholder={Lang.actionPlanCustomerPersonnel} name="personnelList" value={values.personnelList} onChange={handleChange} message={errors.personnelList} touched={touched.personnelList} />
                </div>
            </div>
        </div>

        <div className="row default-margin-top">
            <div className="col">
                <div className="input-container">
                    <TextInput aclass="default full-width" placeholder={Lang.actionPlanThirdParty} name="contractors" value={values.contractors} onChange={handleChange} message={errors.contractors} touched={touched.contractors} />
                </div>
            </div>
        </div>
        </div>
    )
}