import { Formik } from 'formik'
import * as React from 'react'
import { ButtonInput } from '../../../form-components/ButtonInput'
import { SimpleMessage } from '../../../form-components/SimpleMessage'
import { TextInput, TextInputProp } from '../../../form-components/TextInput'
import passwordIcon from '../../../img/icon-password.png'
import userIcon from '../../../img/icon-user.png'
import logo from '../../../img/logo.png'
import { DbBackupListRouter } from '../../DbBackup/builder/DbBackupListRouter'

import {
    HandleLoginSubmit,
    LoginFormikProp,
    errorValidationSchema
} from '../LoginHandler'

interface LoginProps{
    handleLoginSubmit: HandleLoginSubmit
    ifLoggedInThenRedirectToDashboard: () => void
}

export const Login = (props: LoginProps) => {

    let usernameProp: TextInputProp = {
        icon: userIcon,
        placeholder: "Username",
        name: "username",
        aclass: "login"
    }

    let passwordProp: TextInputProp = {
        icon: passwordIcon,
        placeholder: "Password",
        name: "password",
        type: "password",
        aclass: "login"
    }

    React.useEffect(() => {
        props.ifLoggedInThenRedirectToDashboard()
    }, [])

    const DBBackupRouter = new DbBackupListRouter()
    
    return (
        <>
        <Formik<LoginFormikProp> 
            initialValues={{
                global: "",
                username: "",
                password: ""
            }}
            validationSchema={errorValidationSchema}
            onSubmit={props.handleLoginSubmit}
        >
            {({errors, handleChange, handleSubmit, values, isSubmitting, setFieldValue, touched}) => 
            <div>
                { errors.global && <SimpleMessage title="LOGIN FAILED" body={errors.global} onClick={() => { setFieldValue("global", null) }} /> }
                <div className="decoration-top"></div>
                <div className="line-decoration-top"></div>
                <div id="loginContainer">
                <div className="formContainer">
                    <form onSubmit={handleSubmit}>
                    <div id="logoContainer"><img id="logo" src={ logo }/></div>
                    <TextInput {...usernameProp} data-cy="username" value={values.username} onChange={handleChange} autoComplete="disabled" message={errors.username} touched={touched.username} />
                    <TextInput {...passwordProp} data-cy="password" value={values.password} onChange={handleChange}  message={errors.password}  touched={touched.password} />
                    <div className="input-container">
                        <ButtonInput id="login-button" caption="LOGIN" title="LOGIN" disabled={isSubmitting} data-cy="login-button"/>
                    </div>
                    </form>

                    <div style={{
                        display: "none"
                    }}>
                        <ButtonInput id="restore-button" caption="Restore DB" title="Restore DB" onClick={() => {
                            DBBackupRouter.uploadBackup()
                        }} />
                    </div>
                </div>
                </div>
                <div className="line-decoration-bottom"></div>
                <div className="decoration-bottom"></div>
            </div>
            }
        </Formik>
        </>
    )
}