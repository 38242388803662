import { Lang } from '../../../lang/Lang';
import { FormMode } from '../../Client/ui/ClientFormV3';
import { useURL } from '../../Search/useURL';
import { AddFormTemplate } from '../../Shared/template/AddFormTemplate';
import { useClientPICAddFormHandler } from '../formHandler/useClientPICAddFormHandler';
import { ClientPICModel } from '../model/ClientPIC';
import { errorValidationSchema } from '../validation/errorValidationSchema';
import { ClientPICForm } from './ClientPICForm';

export const ClientPICAdd = () => {
    const { params } = useURL()
    const clientId = params.clientId as string
    const clientName = params.clientName as string

    const formik = useClientPICAddFormHandler({
        clientId: clientId
    }, errorValidationSchema)

    return (
        <AddFormTemplate<ClientPICModel> {...formik}>
            { ClientPICForm(FormMode.ADD, "Add New", Lang, formik, clientId, clientName)}
        </AddFormTemplate>
    )
}