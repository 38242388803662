


import { FormMode } from '../../Client/ui/ClientFormV3';
import { AddFormTemplate } from '../../Shared/template/AddFormTemplate';
import { errorValidationSchema, useDatabaseTNIALAddFormHandler } from '../formHandler/UseDatabaseTNIALAddFormHandler';
import { DatabaseTNIALAddModel, DatabaseTNIALForm } from './DatabaseTNIALForm';

export const DatabaseTNIALAdd = () => {
    const formik = useDatabaseTNIALAddFormHandler({
        name: ""
    }, errorValidationSchema)

    return (
        <AddFormTemplate<DatabaseTNIALAddModel> {...formik}>
            {DatabaseTNIALForm(FormMode.ADD, "Add New TNI AL", formik)}
        </AddFormTemplate>
    )
}