import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ClientPath, ImagePath } from '../../../database_api/api';
import { Lang } from '../../../lang/Lang';
import { LangClientPartner } from '../../../lang/LangClientPartner';
import { ResultStatus } from '../../../Types/ResultStatus';
import { EditFormTemplate } from '../../Shared/template/EditFormTemplate';
import { useItem } from '../../Shared/useItem';
import { ClientSingleResult } from "../ClientSingleResult";
import { useClientEditFormHandler } from '../formHandler/useClientEditFormHandler';
import { ClientModel, ClientVariant } from '../model/Client';
import { errorValidationSchema } from '../validation/errorValidationSchema';
import { ClientForm, FormMode } from './ClientFormV3';

interface ClientEditProps {
    prospective?: boolean
}

export const ClientEdit = ({ prospective }: ClientEditProps) => {
    const params = useParams<{id: string}>()

    const formik = useClientEditFormHandler({
        id: params.id, variant: ClientVariant.client,
        isPhotoChanged: false, isRequestData: true, active: "0",
        prospectiveStatus: "0", name: "", prospective
    }, errorValidationSchema)


    const {values, setFieldValue} = formik
    const history = useHistory()
    const { get, data, isLoading } = useItem(ClientPath, params.id, "client")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if(!data) return

        mapData(data)
    }, [data])


    let mapData = (result: ClientSingleResult) => {
        setFieldValue("isRequestData", false)

        if (result.status == ResultStatus.fail){
            history.goBack()
        }
        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if(data[0] == "photo_url"){
                let photo_url = data[1]
                if(photo_url == "") return
                setFieldValue("photo_base64", ImagePath + photo_url)
                return
            }

            setFieldValue(data[0], data[1])
        })
    }

    if(isLoading) return (<div>Loading...</div>)

    return (
        <EditFormTemplate<ClientModel> {...formik}>
            { ClientForm(FormMode.EDIT, "Update", values.isClientPartner ? LangClientPartner : Lang, formik, values.prospective) }
        </EditFormTemplate>
    )
}