import { withFormik } from 'formik';
import { ResultStatus } from '../../../Types/ResultStatus';
import * as React from 'react';
import { DatabaseTNIALImport } from '../ui/DatabaseTNIALImport';
import { DatabaseTNIALImportModuleInterface } from '../DatabaseTNIALImportAPI';

export type DatabaseTNIALImportModelProp = {
    file_url?: File,
    file_description?: string
}

type DatabaseTNIALImportBuilderProp = {
    handler: DatabaseTNIALImportModuleInterface
}

export class DatabaseTNIALImportWithFormHandler extends React.Component<DatabaseTNIALImportBuilderProp>{ 
    databaseTNIALImport = withFormik<DatabaseTNIALImportModelProp, DatabaseTNIALImportModelProp>({
        handleSubmit: (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true)

            let customerPersonnelDataForm = new FormData()

            if(values.file_url){
                customerPersonnelDataForm.append('file_url', values.file_url)
            }
            
            this.props.handler.add(customerPersonnelDataForm, (result) => {
                setSubmitting(false)
                if(result.status == ResultStatus.ok && result.data){
                    console.log("Success with data: " + JSON.stringify(result.data))
                }
                else{
                    setFieldError("global", result.message || '')
                }
            })
        }
    })(DatabaseTNIALImport)
    render(){
        return <this.databaseTNIALImport  />
    }
}