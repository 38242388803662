import * as React from 'react'
import { Session } from '../../interfaces/Session'
import { AuthPermissionProtocol, AuthPermissionSessionStorage } from './AuthPermission'
import { LoginSessionStorage } from './LoginSessionStorage'

interface LogoutProp{
    session: Session
    auth: AuthPermissionProtocol
}

export const Logout = () => {
    const auth = new AuthPermissionSessionStorage()
    const session = new LoginSessionStorage()

    React.useEffect(() => {
       session.logOut()
       auth.goToLogin()
    }, [])
    
    return <></>
}