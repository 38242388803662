import React from 'react'
import { RouteComponentProps, Route } from 'react-router-dom'
import { ClientSelector } from './ClientSelector';
import { ClientList } from './ClientListV2';

import { QueryClient, QueryClientProvider } from "react-query";
import { ClientAdd } from './ClientAddV3';
import { ClientEdit } from './ClientEditV3';
import { ClientVariant } from '../model/Client';
const queryClient = new QueryClient();

export const Client = (props: RouteComponentProps) => {
    return (
        <QueryClientProvider client={queryClient}>
        <React.Fragment>
            <Route path={props.match.url + "/v2/add"} component={ () => <ClientAdd variant={ClientVariant.client}  />} />
            <Route path={props.match.url + "/v2/edit/:id"} component={ClientEdit} />
            <Route exact path={props.match.url + "/v2"} component={ClientList} />
            <Route path={props.match.url + "/v2/selector"} component={ClientSelector} />
        </React.Fragment>
        </QueryClientProvider>
    )
}