import CreatableAsyncSelect from 'react-select/async-creatable';

import { FilterTagPath, TagPath } from '../../../database_api/api';
import { ResultStatus } from '../../../Types/ResultStatus';
import { useGetData } from '../../Shared/useGetDataWithParam';
import { usePostData } from '../../Shared/usePostData';

export type TagResultType = {
    label: string,
    value: string
}

export const CreatableAsyncTagSelectInput = (props: {
    fieldName: string,
    placeholder: string,
    value?: TagResultType[],
    onChange: (tags?: TagResultType[]) => void,
    onNewTagAdded: (tag: TagResultType) => void
}) => {
    const { fieldName, placeholder, value, onChange, onNewTagAdded } = props

    const { get } = useGetData(FilterTagPath, "")
    const { post } = usePostData(TagPath, "")

    const loadTagList = (inputValue: string, callback: (options: TagResultType[]) => void) => {
        if(inputValue == "" || inputValue == undefined) {
            callback([])
            return
        }

        get(inputValue, {
            onSuccess(result) {
                if (result.data == undefined) {
                    callback([])
                    return
                }

                let returnedResult: { id: string, name: string }[] = result.data
                if (returnedResult.length == 0) {
                    callback([])
                    return
                }

                callback(returnedResult.map(tag => {
                    return { label: tag.name, value: tag.id }
                }))
            }
        })
    }

    const handleSelectedChange = (inputValue: any, _: any) => {
        let selected: TagResultType[] | undefined = inputValue

        if(selected == undefined) {
            onChange(undefined)
            return
        }

        onChange(selected.map( sel => {
            return {
                label: sel.label,
                value: sel.label.replace(" ", "-").toLowerCase()
            }
        }))
    }

    const onCreateOption = (inputValue: string) => {
        post({name: inputValue}, {
            onSuccess(result) {
                if (result.status == ResultStatus.ok) {
                    onNewTagAdded({
                        label: result.data.name,
                        value: result.data.id
                    })
                }
            }
        })
    }

    return (
        <CreatableAsyncSelect 
            name={fieldName}
            placeholder={placeholder}
            className="selectionStyle full-border"
            classNamePrefix="si"
            isMulti
            onCreateOption={onCreateOption}
            value={value}
            loadOptions={loadTagList} defaultOptions 
            onChange={handleSelectedChange}
            />
    )
}

