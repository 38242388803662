import * as React from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps } from 'react-router-dom';
import { ActionPlanTodayPath, NewsItemWidget, NewsPagePath } from '../database_api/api';
import { AxiosAPIHTTPClient } from '../database_api/AxiosAPIHTTPClient';
import { GetAPIHTTPClient, StandardAPIHTTPClient } from '../database_api/StandardAPIHTTPClient';
import { HTTPHeader } from '../interfaces/HTTPHeader';
import { MessageType } from '../MessageType';
import { ActionPlanListDisplayUpdaterProtocol } from '../modules/ActionPlan/ActionPlanListDisplayUpdaterProtocol';
import { ActionPlanModel } from '../modules/ActionPlan/model/ActionPlan';
import { ActionPlanListRouteProtocol } from '../modules/ActionPlan/model/ActionPlanListModel';
import { ReactActionPlanPostResultController } from '../modules/ActionPlan/resultController/ActionPlanPostResultController';
import { ActionPlanComponentProp } from '../modules/ActionPlan/ui/ActionPlanComponent';
import { ActionPlanListPresenterProtocol } from '../modules/ActionPlan/ui/ActionPlanListPresenter';
import { FieldInformationRouter } from '../modules/FieldInformation/FieldInformationRouter';
import { FieldInformationAddV2 } from '../modules/FieldInformation/ui/FieldInformationAddV2';
import { ListLoaderControllerProtocol } from '../modules/ListLoaderControllerProtocol';
import { AuthTokenSessionStorage } from '../modules/Login/AuthPermission';
import { NewsListDisplayUpdaterProtocol } from '../modules/News/NewsListDisplayUpdaterProtocol';
import { APINewsListPagingLoader, NewsListPagingLoader } from '../modules/News/NewsListPagingLoader';
import { NewsComponentProp } from '../modules/News/ui/NewsComponent';
import { ActionPlanToFieldInformation } from '../modules/POBContent/ContentModule/ActionPlan/ActionPlanListBuilderForContentType';
import { ActionPlanTodayWidget, ListLoaderTodayControllerProtocol } from './Widget/ActionPlanTodayWidget';
import { NewsWidget } from './Widget/NewsWidget';

class ActionPlanTodayPresenter implements ActionPlanListRouteProtocol, ActionPlanListPresenterProtocol, ActionPlanToFieldInformation, FieldInformationRouter{
    controller: ListLoaderTodayControllerProtocol
    setState?: (data: any) => void

    constructor(controller: ListLoaderTodayControllerProtocol){
        this.controller = controller
    }
    goToAdd(): void {
    }
    goToEdit(id: string): void {
    }
    delete(id: string): void {
    }
    add(): void {
    }
    back(){
        ReactDOM.render(<></>, document.getElementById("field-information-add"))
    }
    cancel(): void {
        setTimeout(() => {
            this.onLoad()
        }, 300)
        ReactDOM.render(<></>, document.getElementById("field-information-add"))
    }

    successAdd(type: MessageType, message: string): void {
    }
    
    goToResult(actionPlan: ActionPlanModel) {
        ReactDOM.render(<FieldInformationAddV2 projectId={actionPlan.projectId ?? ""} actionPlan={actionPlan} router={this} />, document.getElementById("field-information-add"))
    }

    onLoad = () => {
        this.controller.load()
    }
    onAdd(): void {
    }
    onEdit(id: String): void {
    }

    onLoadActionPlans = (actionPlans: ActionPlanComponentProp[]) => {
        if(this.setState === undefined){
            console.log("You must define setState for the presenter")
            return
        }

        this.setState({
            actionPlans: actionPlans
        })
    }
}

class ListLoaderTodayController implements ListLoaderTodayControllerProtocol, ActionPlanListDisplayUpdaterProtocol{
    private client: GetAPIHTTPClient
    private mainPath: string

    constructor(client: GetAPIHTTPClient, mainPath: string){
        this.client = client
        this.mainPath = mainPath
    }

    onLoad(datas: ActionPlanComponentProp[]): void {
    }

    load(): void {
        this.client.get(this.mainPath, (result) => {
            this.onLoad(result.data)
        })
    }

}

export class NewsWidgetController implements ListLoaderControllerProtocol, NewsListDisplayUpdaterProtocol{
    private loader: NewsListPagingLoader
    constructor(loader: NewsListPagingLoader){
        this.loader = loader
    }

    onLoad(datas: NewsComponentProp[]): void {

    }
    load(page: number): void {
        this.loader.load(1, (result) => {
            this.onLoad(result.data ?? [])
        })
    }
    
}

export class DashboardWidget extends React.Component<RouteComponentProps>{
    header = new HTTPHeader(new AuthTokenSessionStorage())
    client = new StandardAPIHTTPClient(this.header)
    fileClient = new AxiosAPIHTTPClient(this.header)

    //ActionPlanTodayWidget
    controller = new ListLoaderTodayController(this.client, ActionPlanTodayPath)
    presenter = new ActionPlanTodayPresenter(this.controller)
    resultController = new ReactActionPlanPostResultController(this.client, this.fileClient)

    //NewsWidget
    newsWidgetController = new NewsWidgetController(new APINewsListPagingLoader(NewsPagePath, NewsItemWidget, this.client))

    render(){
        return (
            <div>
                <ActionPlanTodayWidget presenter={this.presenter} controller={this.controller} 
                    resultController={this.resultController}
                />

                <NewsWidget />

                <div id="field-information-add" />
            </div>
        )
    }
}