import React, { useState } from 'react';
import fileIcon from '../../../img/icon-file.png';

import { FormikProps } from 'formik';

import { CustomerPersonnelXlsxUploadFactory } from '../../../FormikHelper/CustomerPersonnelXlsxUploadFactory';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { DatabaseTNIALImportModelProp } from '../formHandler/DatabaseTNIALImportWithFormHandler';

export const DatabaseTNIALImport: React.FC<FormikProps<DatabaseTNIALImportModelProp>> = (props: FormikProps<DatabaseTNIALImportModelProp>) => {
    const { values, handleSubmit, setFieldValue, isSubmitting } = props
    const [ importOpened, setImportOpened ] = useState(false)

    let fileUploader = new CustomerPersonnelXlsxUploadFactory(setFieldValue, "file_url", "file_description", "customer-personnel-import", fileIcon, values.file_url, values.file_description)

    let onCancel = () => {
        setImportOpened(false)
    }

    let onClick = () => {
        setImportOpened(true)
    }

    return (<>
            
            <ButtonInput className="importButton" caption="i" title="Import" onClick={ () => onClick() } />

            <div id="import-customer-personnel-container" className={(importOpened ? "import-open" : "hidden")}>
                <div className="background"></div>
                <div id="form-container">
                    <form onSubmit={handleSubmit}>
                        
                        { fileUploader.getComponent() }
                        <div className="default-margin-top"/>
                        <ButtonInput type="button" caption="Cancel" title="Cancel" id="cancel" onClick={() => { onCancel() }} {...(isSubmitting ? { disabled: "disabled" } : {})} />
                        <ButtonInput id="submit" {...(isSubmitting ? { disabled: "disabled", caption: "Registering" } : { caption: "Submit" })} />
                    </form>
                </div>
            </div>
        </>)
}