import * as React from 'react';
import { DbBackupDownload } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { DateHelper } from '../../../helper/DateHelper';
import { DbBackupModel, RestoreStatus } from '../model/DbBackup';
import { DbBackupListRouteProtocol } from './DbBackupList';

export interface DbBackupComponentProp extends DbBackupModel{
    router: DbBackupListRouteProtocol
}

export class DbBackupComponent extends React.Component<DbBackupComponentProp>{
    render(){
        return (
            <div className="dbBackup-container full-border">
                <div className="dbBackup-description">
                    <ButtonInput aclass="restore" caption="Restore" title="Restore" onClick={() => { this.props.router.requestRestore(this.props.id)}} />
                    <div className="dbBackup-name">{this.props.name}</div>
                    { this.props.dbFilePath &&
                        <div className="dbBackup-fileDownload"><a href={DbBackupDownload + this.props.dbFilePath}>Click Here to Download Backup File</a></div>
                    }
                    <div className="dbBackup-date">Backup Start Date: {DateHelper.dateFormat(this.props.date, "MMMM DD, YYYY HH:mm")}</div>
                    <div className="dbBackup-status">Backup Status: {this.props.status}</div>
                    {this.props.error && <div className="dbBackup-error">Fail Reason: {this.props.error}</div>}

                    { this.props.restoreHistories &&
                        <div className="dbBackup-Histories"><h2>Restore History</h2>
                        {this.props.restoreHistories.map( history => {
                            return <div className={(history.status == RestoreStatus.failed) ? "history failed" : "history success" } key={history.startRestoreTime}>
                                Start: {history.startRestoreTime}<br/>
                                {history.finishRestoreTime && `Finish: ${history.finishRestoreTime}`}<br />
                                Status: {history.status}<br/>
                                {history.error && `Fail reason: ${history.error}`}
                            </div>
                        })}
                        </div>
                    }
                    
                    <div className="clear"></div>
                </div>
            </div>
        )
    }
}