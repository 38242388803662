import { useBlockContentList } from "../hook/useBlockContentList"
import { BlockContentDetailComponent } from "./BlockContentDetailComponent"

export const BlockContentListComponent = () => {
    const { content } = useBlockContentList()
    return (
        <>
            <div className="block-content-area">
                <div className="blockContent-container">
                    {content && content.map((bc, index) => {
                        return <BlockContentDetailComponent key={`bcd${bc.id}`}
                            index={index + 1}
                            content={bc}
                        />
                    })}
                </div>
            </div>
        </>
    )
}