import * as React from 'react'
import Select from 'react-select'
import { SetFieldValueFunctionType } from '../FormikHelper/FormikFileUploadFactory'

export type SelectOptionObject = {label: string, value: string}

export class FormikSelectInputHandler{
    private fieldName: string
    private placeholder: string
    private options: SelectOptionObject[]
    private selected?: SelectOptionObject
    private selectedFieldName: string
    private setFieldValue: SetFieldValueFunctionType

    constructor(fieldName: string, placeholder: string, options: SelectOptionObject[], selectedFieldName: string, setFieldValue: SetFieldValueFunctionType, selected?: SelectOptionObject){
        this.fieldName = fieldName
        this.placeholder = placeholder
        this.options = options
        this.selected = selected
        this.selectedFieldName = selectedFieldName
        this.setFieldValue = setFieldValue
    }

    private handleSelectedChange = (inputValue: any, _: any) => {
        this.setFieldValue(this.selectedFieldName,inputValue)
        this.setFieldValue(this.fieldName, inputValue.value)
    }

    getComponent = () => {
        return (
        <Select placeholder={this.placeholder} className="selectionStyle full-border" 
                isSearchable={true} name={this.fieldName} classNamePrefix="si" options={this.options} value={this.selected} 
                onChange={this.handleSelectedChange} />
        )
    }
}