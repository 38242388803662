import { faArrowCircleDown, faCircleDown, faCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { LayoutProps } from "./LayoutProps";

const mediumScreen = 700;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export let ListTwoColumnLayoutLeftNav: (props: LayoutProps) => JSX.Element = 
({filterComponents, contentComponents, topSearchComponent, addComponent}) => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [filterOpen, setFilterOpen] = useState(false)
    
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <>
            <div id="top-container">
                {topSearchComponent && topSearchComponent}
                <div className="clear"></div>
            </div>

            <div id="add-menu-container">
                {addComponent && addComponent}
            </div>

            <div id="two-column-layout">
                <div id="nav-left">
                    <div id="filter-header" onClick={() => {
                        setFilterOpen(!filterOpen)
                    }}>
                        <h2>Filter</h2>
                        <FontAwesomeIcon icon={ filterOpen ? faCircleUp : faCircleDown } size="lg" />
                    </div>

                    {(windowDimensions.width >= mediumScreen || (windowDimensions.width < mediumScreen && filterOpen )) && filterComponents &&
                        filterComponents.map((comp, index) => <div className="nav-filter-component" key={index}>{comp}</div>)
                    }
                </div>

                <div id="content-column">
                    {contentComponents}
                </div>
            </div>
        </>
    )
}