import moment from 'moment';

export class DateHelper{
    static dateFormat = (date: string, format: string): string => {
        return moment(date).format(format)
    }

    static dateISO8601 = (date: string): string => {
        return moment(date).format()
    }
}