import React from 'react'

type BoxButtonComponentProp = {
    border?: string, 
    icon?: string,
    aclass?: string,
    [param: string]: any
}
export class BoxButtonComponent extends React.Component<BoxButtonComponentProp>{
    icon?: JSX.Element
    aclass: string = ""
    defaultBorder: string = "no-border"
    constructor(props: BoxButtonComponentProp){
        super(props)

        if(this.props.icon){
            this.icon = <img src={this.props.icon}/>
        }

        this.aclass = (this.props.aclass ?? "") + " " + (this.props.border ?? this.defaultBorder)
    }

    render(){
        const {border, icon, aclass, ...props} = this.props
        return (
        <button className={this.aclass} {...props}>
            {this.icon ?? ""}
        </button>
        )
    }
}