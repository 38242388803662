import defaultProject from '../../../img/defaultProjectIcon.png';

import seeContentMenu from '../../../img/icon-menu-seeDetail.png';

import parse from 'html-react-parser';
import { useHistory } from 'react-router';
import { ImagePath } from '../../../database_api/api';
import { DateHelper } from '../../../helper/DateHelper';
import { RoutePath } from '../../../page/RoutePath';
import { LatestStatusModel } from '../../LatestStatus/model/LatestStatus';
import { POBContentType } from '../../POBContent/ui/POBContentType';
import { POBModel } from '../model/POBV2';

export const POBComponent = (props: POBModel) => {
    const history = useHistory()

    const getLatestStatusText = (status?: LatestStatusModel) => {
        if (!status) return <>-</>
        return <>
            <div className="latestStatus-date">Date: </div>
            {DateHelper.dateFormat(status.date, "MMMM DD, YYYY HH:mm")}<br />

            <div className="latestStatus-information"><h2>Status</h2></div>
            {status.information ? parse("" + status.information) : "-"}

            <div className="latestStatus-information"><h2>Analysis</h2></div>
            {status.analysis ? parse("" + status.analysis) : "-"}
        </>
    }
    
    return (
        
        <div className="pob-container">
            <div className="pob-id">{props.code} | {props.name}</div>
            <div className="pob-container-left full-border">
                <img src={props.photo_url ? ImagePath + props.photo_url : defaultProject}></img>
                <div className="pob-pobHeader">{props.projectHeaderName ?? "-"}</div>

                <div className="pob-description">
                    <input type="hidden" name="id" value={props.id} />
                    
                    <div className="projectManagerContainer row">
                        <div className="pob-title col">Project Manager</div>
                        <div className="pob-projectManagerId col">{props.projectManagerName ?? "-"}</div>
                    </div>

                    <div className="line-decoration"></div>

                    <div className="clientContainer row">
                        <div className="pob-title col">Client</div>
                        <div className="pob-clientId col">{props.clientName ?? "-"}</div>
                    </div>
                    <div className="line-decoration"></div>

                    <div className="customerContainer row">
                        <div className="pob-title col">Customer</div>
                        <div className="pob-customerId col">{/* TEMPORARY LOCKED state.customerName */ props.customerTemporary ?? "-"}</div>
                    </div>
                    <div className="line-decoration"></div>

                    <div className="customerContainer row">
                        <div className="pob-title col">Value</div>
                        <div className="pob-projectValue col">{props.projectValue ?? "0"}</div>
                    </div>
                    <div className="line-decoration"></div>

                    <div className="customerContainer row">
                        <div className="pob-title col">Status</div>
                        <div className="pob-projectValue col">{props.active === "1" ? "Active" : "Not Active"}</div>
                    </div>
                </div>
            </div>

            <div className="pob-container-right full-border">
                <div className="field-description">Last Status</div>
                <div className="line-decoration"></div>
                <div className="pob-description jodit-wysiwyg" suppressContentEditableWarning>{getLatestStatusText(props.latestStatus)}</div>

                <div className="pob-options">
                    <div className="option option-container" onClick={() => {
                        if (!props.id) {
                            console.log("No ID")
                            return
                        }
                        history.push(RoutePath.pobContentWithHeader
                            .replace(":type", POBContentType.POB)
                            .replace(":id", props.id.toString())
                            .replace(":pobHeaderId", props.projectHeaderId ?? "undefined")
                        )
                    }}>
                        <input className="option" type="image" src={seeContentMenu} />See Content
                    </div>
                    
                </div>
                <div className="clear"></div>
            </div>

            <div className="container-separator-block">
                <div className="separator-block"/>
                <div className="separator-block"/>
                <div className="separator-block"/>
            </div>
        </div>
    )
}