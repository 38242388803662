import statusMenuActive from '../../../img/checked.png';
import statusMenuNotActive from '../../../img/close.png';
import defaultCompany from '../../../img/default-company.png';

import { useHistory, useLocation } from 'react-router';
import { EditButton } from '../../../components/EditButton';
import { ImagePath } from '../../../database_api/api';
import { Lang } from '../../../lang/Lang';
import { RoutePath } from '../../../page/RoutePath';
import { ClientModel } from '../model/Client';

export interface ClientComponentProp extends ClientModel{
    id: string
    photo_urlString?: string
    prospective?: boolean
}
export const ClientComponent = (props: ClientComponentProp) => {
    const history = useHistory()
    const location = useLocation()

    return (
        <div className="client-container">
            <div className="client-container-left full-border">
                <div className="photo">
                    <img src={props.photo_urlString ? ImagePath + props.photo_urlString : defaultCompany}></img>
                    <div className="line-decoration" />
                </div>

                <div className="client-id">{props.name}</div>
                <div className="client-description">
                    <div className="client-data">{Lang.clientProduct}: {props.product ?? "-"}</div>
                </div>

                <div className="client-id">{Lang.clientBusinessUnitOfficer}</div>
                <div className="client-description">
                    <div className="client-data">{props.businessUnitOfficer ?? "-"}</div>
                </div>

                <div className="client-id">Data</div>
                <div className="client-description">
                    <div className="client-location">{props.address}</div><br/>
                    <div className="client-phone">{props.phone}</div>
                    <div className="client-data">{props.email}</div>
                    <div className="client-data">{props.url}</div><br/>
                    <div className="client-data">{props.socialMedia ?? "-"}</div>
                </div>

                {props.prospective &&
                    <div className="client-id">
                        { (props.prospectiveStatus === "0") ? "Neutral" : 
                            (props.prospectiveStatus === "1") ? "Client" : "Competitor" }
                    </div>
                }
                    
                <div className="client-description">
                    <div className="client-options">
                        <input className="option button" type="button" value="Personnel" onClick={() => { 
                            history.push(RoutePath.clientPICList + 
                                "?clientId=" + props.id + 
                                "&clientName=" + props.name +
                                "&prevURL=" + encodeURIComponent(location.pathname + location.search)
                            ) 
                        }} />
                        <EditButton basePath={location.pathname} code={props.id} />
                        <input className="status" type="image" src={props.active === "1" ? statusMenuActive : statusMenuNotActive}
                            title={props.active === "1" ? "Active" : "Not Active"}
                        />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </div>
    )
}