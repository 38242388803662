import { FormMode } from '../../Client/ui/ClientFormV3';
import { POBHeaderModel } from '../../POBHeader/model/POBHeader';
import { AddFormTemplate } from '../../Shared/template/AddFormTemplate';
import { errorValidationSchema } from '../builder/ErrorMessage/errorValidationSchema';
import { usePOBAddFormHandler } from '../builder/useAddFormHandler';
import { POBForm } from './POBForm';

export const POBAdd = () => {
    const formik = usePOBAddFormHandler({
        name: ""
    }, errorValidationSchema)

    
    return (
        <AddFormTemplate<POBHeaderModel> {...formik}>
            {POBForm(FormMode.ADD, "Add New Project", formik)}
        </AddFormTemplate>
    )
}