import { useMutation } from "react-query"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { ResultStatus } from "../../Types/ResultStatus"
import { HTTPMethod } from "../../interfaces/HTTPHeader"
import { AddResult } from "../GenericAPI/GenericAddAPI"
import { useHeader } from "./useHeader"


export const useAddFormData = (url: string) => {
    const history = useHistory()
    const { header } = useHeader()

    const requestFormOptions = {
        method: HTTPMethod.post,
        headers: header.formHeader()
    }

    const addFormData = async (data: FormData) => {
        return fetch(`${url}`, {...requestFormOptions, body: data})
            .then((response) => response.json())
    }

    const { isLoading, mutate } = useMutation(addFormData, {
        onSuccess: (data: AddResult) => {
            if(data.status === ResultStatus.fail){
                toast.error(data.message, { autoClose: 5000 })
                return
            }

            toast("Success Add")
            setTimeout(() => {
                history.goBack()
            }, 300)
            
        },
        onError: (error) => {
            toast.error(JSON.stringify(error), { autoClose: 5000 })
        }
    })
    

    return { add: mutate, isLoading };
}