import * as React from 'react'

export type SelectOptionProp = {
    value: string, 
    text: string
}
type SelectInputProp = {
    id?: string, 
    value?: string
    defaultOption?: SelectOptionProp,
    placeholder?: string
    options?: SelectOptionProp[] 
    onChange: (id: string) => void
    [key: string]: any
}

export class SelectInput extends React.Component<SelectInputProp>{
    className?: string
    optionsComponent?: JSX.Element[]

    constructor(props: SelectInputProp){
        super(props)

        this.className = props.className

        if(this.props.options){
            this.optionsComponent = []
            this.props.options.forEach( (value, index) => {
                this.optionsComponent?.push(<option key={`childSelectOption${value.value}`} value={value.value}>{value.text}</option>)
            })
        }
    }

    onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(e.currentTarget.value)
    }
    
    render(){
        return (
            <React.Fragment>
                <select id={this.props.id ?? ""} {...this.props.value ? {value: this.props.value} : {} } style={{width:"100%"}} placeholder={this.props.placeholder} onChange={this.onChange} {...this.className ? {
                    className: this.className
                } : {}} >
                    <option value={this.props.defaultOption?.value ?? "-"}>{this.props.defaultOption?.text ?? this.props.placeholder}</option>
                    {this.optionsComponent ?? ""}
                </select>
            </React.Fragment>
        )
    }
}