import { DatabaseTNIALImportAPI } from '../DatabaseTNIALImportAPI'
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient'
import * as React from 'react';
import { CustomerPersonnelImportPath } from '../../../database_api/api';
import { DatabaseTNIALImportWithFormHandler } from '../formHandler/DatabaseTNIALImportWithFormHandler';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';

export class DatabaseTNIALImportBuilder{
    handler = new DatabaseTNIALImportAPI(CustomerPersonnelImportPath, new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())))

    get(){
        return <DatabaseTNIALImportWithFormHandler handler={this.handler}/>
    }
}

