
import closeIcon from '../../../img/close.png';
import dateIcon from '../../../img/icon-date.png';

import { useUploady } from '@rpldy/uploady';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { FieldInformationUpdateFileResultPath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { JoditContentEditorModule } from '../../../form-components/JoditContentEditorModuleV3';
import { TextInput } from '../../../form-components/TextInput';
import { DateHelper } from '../../../helper/DateHelper';
import { useURL } from '../../Search/useURL';
import { ResultType, useSelectMemberHelper } from '../../useSelectMemberHelperV2';
import { FieldInformationModel } from '../model/FieldInformation';
import { FieldInformationFileUploadHandler } from '../uploader/FieldInformationFileUploadHandler';
import { ActionPlanFields } from './ActionPlanFieldsV3';
import { useFieldInformationComponent } from './useFieldInformationComponentV3';


export const FieldInformationComponent = (props: FieldInformationComponentProp) =>{
    const {
        back, confirmDeleteFieldInformation, date_date, deleteFile, fieldInformationData, fileUploadList,
        fileUploadOnProgress, handleDateChange, handleResultChange, isDeleted,
        onFinishUpload, onProgress, resultField, saveAndBack, uploadDisplay,
        files, header,
        onFileClick,
        setActionPlanId,
        actionPlanData
    } = useFieldInformationComponent(props)

    const [memberList, setMemberList] = useState<ResultType[]>([])
    const { params } = useURL()

    useSelectMemberHelper((datas) => {
        if(params.addForm) setMemberList(datas)
    })

    if(isDeleted) return (
        <div id="form-container">
            <div className="fieldInformation-container full-border">
                <div className="row fieldInformation-header">
                    <div className="col">
                        Field Information deleted.
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div id="form-container">
        <div className="fieldInformation-container full-border">
            <div className="row fieldInformation-header">
                <div className="col">
                    <div className="input-container">
                    <DatePicker className="text-input default time" placeholderText="Time" selected={date_date} onChange={(date: Date) => { handleDateChange(date) }}
                        showTimeSelect dateFormat="MMMM d, yyyy H:mm" timeFormat='HH:mm'
                        customInput={<TextInput icon={dateIcon} placeholder="" name="time" value={fieldInformationData.time} />} />
                    </div>
                    <div className="fieldInformation-delete" title="Delete Result" onClick={() => {confirmDeleteFieldInformation()}}>
                        <img src={closeIcon} />
                    </div>
                </div>
            </div>

            {date_date &&
            <div className="row fieldInformation-description">
                    <ActionPlanFields
                        onActionPlanIdUpdate={
                            (id, data) => {
                                setActionPlanId(id, data)
                            }
                        } projectId={props.projectId} time={actionPlanData.date ?? fieldInformationData.time} 
                        id={fieldInformationData.actionPlanId}
                    picIds={actionPlanData.picIds}
                    name={actionPlanData.name} personnelList={actionPlanData.personnelList} 
                    contractors={actionPlanData.contractors} location={actionPlanData.location} objective={actionPlanData.objective}
                    memberList={props.memberList ?? memberList}
                    showTimeField
                />
            </div>
            }

            { date_date &&

            <div className="row fieldInformation-description">
                <div className="fieldInformation-label result">
                    Result
                    {
                        props.resultCreatedDate && props.resultUpdatedDate &&
                        <div className="fieldInformation-dateResult">
                            <div className="status-text">{ (props.resultCreatedDate == props.resultUpdatedDate ? "created at" : "updated at") }</div>
                            <div className="value">{DateHelper.dateFormat(props.resultUpdatedDate,"MMMM DD, YYYY HH:mm")}</div>
                        </div>
                    }
                </div>
                    <div className="fieldInformation-objective">
                        <JoditContentEditorModule 
                            fieldName='result'
                            handleChange={(content) => {
                                handleResultChange(content)
                            }}
                            placehoder='Result'
                            value={fieldInformationData.result}
                        />
                    </div>

                <br />
                {!fieldInformationData.id && 
                    <pre>
                        <b>Note: </b><br />
                        * <b>Result field</b> must be filled to be saved.<br />
                        * Must fill the Result to make File Attachment available.
                    </pre>
                }
                {fieldInformationData.id &&
                    <div className="fieldInformation-fileResult">
                        <FieldInformationFileUploadHandler id={fieldInformationData.id} apiURL={FieldInformationUpdateFileResultPath} 
                            projectName={fieldInformationData.projectId || "NOPROJECTID"}
                            headers={header.formHeader()} 
                            progressUpload={{onProgress: onProgress}}
                            finishUpload={{onFinish: onFinishUpload}} >
                        
                            <div className={("rowflex file-container title " + (files ? "" : " hidden") )}>
                                <div className="col name flex5">File</div><div className="col date">Date Upload</div><div className="col delete flex1">Delete</div>
                            </div>
                            {
                                files && files.map((val, index) => {
                                    return (
                                    <div className="rowflex file-container center" key={index}>
                                        <div className="col name flex5">
                                            <a className='clickable' onClick={() => onFileClick(val.filename)}>{val.filename}</a>
                                        </div>
                                        <div className="col date">{DateHelper.dateFormat(val.date, "MMMM DD, YYYY")}</div>
                                            <div className="col delete flex1"><ButtonInput type="button" caption="Delete" title="Delete" onClick={() => deleteFile(val.filename) }/></div>
                                    </div>)
                                })

                            }

                            <div className={("rowflex file-container title " + (fileUploadOnProgress ? "" : "hidden"))}>
                                <div className="col name flex5">File</div><div className="col progress">Upload Progress</div>
                            </div>

                            {fileUploadOnProgress && uploadDisplay.displayFileUpload(fileUploadList)}

                            <div className="row dragHere">
                                <div className="col">
                                    Drag here to upload
                                    <br/>
                                    <NormalFileUpload />
                                </div>
                            </div>
                            
                        </FieldInformationFileUploadHandler>
                            
                    </div>
                }
            </div>

            }
        
            <div className="clear"></div>
        </div>

        <div className="container-separator-block">
            <div className="separator-block"/>
            <div className="separator-block"/>
            <div className="separator-block"/>
        </div>

        {props.enableBackButton &&
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <ButtonInput caption="Back" title="Back" onClick={() => back() } />
                <ButtonInput caption="Save & Back"
                    onClick={() => {
                        saveAndBack()
                    }} />
            </div>
        }
        </div>
    )
}

export interface ActionPlanFile {
    filename: string
    fileExt: string
    date: string
}

export interface FieldInformationComponentProp extends FieldInformationModel {
    personnelList?: string
    location?: string
    objective?: string
    contractors?: string
    memberList?: ResultType[]
    picIds?: string[]
    status: string
    actionPlanName?: string
    actionPlanTime?: string
    enableBackButton?: boolean
}

let NormalFileUpload = () => {
    let uploady = useUploady()
    let onClick = () => {
        uploady.showFileUpload()
    }
    return <ButtonInput caption="Select File to Upload" title="Select File to Upload" onClick={() => {onClick()}} />
}