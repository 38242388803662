import { ActionPlanModel } from '../../../ActionPlan/model/ActionPlan';
import { useURL } from '../../../Search/useURL';
import { AddFormTemplate } from '../../../Shared/template/AddFormTemplate';
import { useSelectMemberHelper } from '../../../useSelectMemberHelperV2';
import { ActionPlanForm } from './ActionPlanForm';
import { useActionPlanAddFormHandler } from './useActionPlanAddFormHandler';
import { errorValidationSchema } from './validation/errorValidationSchema';

export const ActionPlanAdd = () => {
    const { params } = useURL()
    const formik = useActionPlanAddFormHandler({
        projectId: params.projectId ? params.projectId as string : undefined,
        name: "", date: "", location: "", objective: ""
    }, errorValidationSchema)

    const {setFieldValue} = formik
    
    useSelectMemberHelper((datas) => {
        setFieldValue("memberList", datas)
    })

    return (
        <AddFormTemplate<ActionPlanModel> {...formik}>
            {ActionPlanForm("Add New", formik)}
        </AddFormTemplate>
    )
}