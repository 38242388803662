export class NameInput{
    static requiredMessageError(): string { return "Name is required" }
}

export class PhoneInput{
    static requiredNumberMessageError(): string { return "Phone number must be numbers" }
}

export class EmailInput{
    static requiredMessageError(): string { return "Email is required" }
    static invalidMessageError(): string { return "Email is not in a valid format" }
}

export class UsernameInput{
    static characterNeeded = 5
    static requiredMessageError(): string { return "Username is required" }
    static minCharacterError(): string { return "Username must be at least " + this.characterNeeded + " characters"}
}

export class PasswordInput{
    static characterNeeded = 6
    static requiredMessageError(): string { return "Password is required" }
    static minCharacterError(): string { return "Password must be at least " + this.characterNeeded + " characters"}
}

export class RetypePasswordInput{
    static characterNeeded = 6
    static requiredMessageError(): string { return "Retype Password is required" }
    static notMatchedError(): string { return "Retype Password didn't match to Password" }
}