import { AuthHTTPClient, HTTPMethod, HTTPHeader } from '../../interfaces/HTTPHeader'
import { LoginResult } from '../../Types/LoginResult';
import { ResultStatus } from '../../Types/ResultStatus';
import { LoginError } from '../../StandardLogin';
import { LoginPath } from '../../database_api/api';

enum RequestProcessStatus{
    none,
    process
}

export class LoginHTTPClient implements AuthHTTPClient{
    header: HTTPHeader
    timeout = 5000
    status = RequestProcessStatus.none
    

    constructor(header: HTTPHeader){
        this.header = header
    }

    auth(username: String, password: String, authCallback: (result: LoginResult) => void){
        let requestBody = JSON.stringify({
            username: username,
            password: password
        })
        let requestOptions = {
            method: HTTPMethod.post,
            headers: this.header.header(),
            body: requestBody
        }

        this.status = RequestProcessStatus.process
        
        const timer = setTimeout(() => {
            this.status = RequestProcessStatus.none
            authCallback({
                status: ResultStatus.fail,
                message: LoginError.timeout
            })
        }, this.timeout)

        fetch(LoginPath, requestOptions).then(response => response.json()).then( data => {
            if(this.status == RequestProcessStatus.none) return

            clearTimeout(timer)
            
            let loginResult: LoginResult = JSON.parse(JSON.stringify(data))
            this.status = RequestProcessStatus.none
            authCallback(loginResult)
        })
    }
}