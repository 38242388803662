import * as React from 'react';
import * as yup from 'yup'
import { UsernameInput, NameInput, PhoneInput, EmailInput, PasswordInput, RetypePasswordInput } from '../builder/ErrorMessage/Error';
import { MemberAddRouterProtocol, MemberAddProp, MemberAdd } from '../ui/MemberAdd';
import { AddMemberModuleInterface } from '../MemberAddAPI';
import { withFormik } from 'formik';
import { ResultStatus } from '../../../Types/ResultStatus';
import { MessageType } from '../../../MessageType';
import { DateHelper } from '../../../helper/DateHelper';

const errorValidationSchema = yup.object({
    username: yup.string().required(UsernameInput.requiredMessageError()).min(UsernameInput.characterNeeded, UsernameInput.minCharacterError()),
    name: yup.string().required(NameInput.requiredMessageError()),
    phone: yup.number().typeError(PhoneInput.requiredNumberMessageError()),
    email: yup.string().email(EmailInput.invalidMessageError()).required(EmailInput.requiredMessageError()),
    dob: yup.string().optional(),
    password: yup.string().required(PasswordInput.requiredMessageError()).min(6, PasswordInput.minCharacterError()),
    retype_password: yup.string().required(RetypePasswordInput.requiredMessageError()).oneOf([yup.ref("password")], RetypePasswordInput.notMatchedError())
})

interface MemberAddWithFormHandlerProps {
    router: MemberAddRouterProtocol
    handler: AddMemberModuleInterface
}

export class MemberAddWithFormHandler extends React.Component<MemberAddWithFormHandlerProps>{ 

    memberAdd = withFormik<MemberAddProp, MemberAddProp>({
        validationSchema: errorValidationSchema,
        handleSubmit: (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true)
            
            let memberData = {
                username: values.username,
                password: values.password,
                name: values.name,
                position: values.position,
                email: values.email,
                phone: values.phone,
                gender: values.gender,
                dob: DateHelper.dateISO8601(values.dob)
            }

            let memberDataForm = new FormData()
            Object.entries(memberData).forEach( field => {
                memberDataForm.append(field[0], field[1])
            })

            if(values.photo_url){
                memberDataForm.append('photo_url', values.photo_url)
            }
            
            this.props.handler.add(memberDataForm, (result) => {
                setSubmitting(false)
                if(result.status == ResultStatus.ok){
                    this.props.router.successAdd(MessageType.success, "Success add " + values.username)
                }
                else{
                    setFieldError("global", result.message || '')
                }
            })
            
            
        }
    })(MemberAdd)
    render(){
        return <this.memberAdd username="" password="" retype_password="" position=""
                                name="" email="" phone="" gender="male" global="" 
                                dob="" router={this.props.router}/>
    }
}