import { useEffect } from 'react';
import { useParams } from 'react-router';
import { CustomerPath } from '../../../database_api/api';
import { ResultStatus } from '../../../Types/ResultStatus';
import { EditFormTemplate } from '../../Shared/template/EditFormTemplate';
import { useItem } from '../../Shared/useItem';
import { errorValidationSchema } from '../errorValidationSchema';
import { useCustomerEditFormHandler } from '../formHandler/useCustomerEditFormHandler';
import { CustomerModel } from '../model/CustomerV2';
import { CustomerForm } from './CustomerForm';

export const CustomerEdit = () => {
    const params = useParams<{ id: string }>()

    const formik = useCustomerEditFormHandler({
        id: params.id
    }, errorValidationSchema)

    const { setFieldValue } = formik

    const { get, data, isLoading } = useItem(CustomerPath, params.id, "customer")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    if (isLoading) return (<div>Loading...</div>)

    const mapData = (result: CustomerSingleResult) => {
        if (result.data === undefined)
            return;

        Object.entries(result.data.value).forEach(data => {
            setFieldValue(data[0], data[1]);

            if (data[0] === "tags" && typeof data[1] == "string") {
                try {
                    let value: { label: string, value: string }[] = JSON.parse(data[1])
                    setFieldValue("selectedTags", value)
                } catch (error) {
                    console.log(error)
                }
            }
        });
    };

    return (
        <EditFormTemplate<CustomerModel> {...formik}>
            {CustomerForm("Update", formik)}
        </EditFormTemplate>
    )
}

type CustomerSingleDetail = {
    cas: string
    value: CustomerModel
}

type CustomerSingleResult = {
    status: ResultStatus,
    data?: CustomerSingleDetail,
    message?: string
}