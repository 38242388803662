import { FormikProps } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonInput } from '../../../form-components/ButtonInput';
import bottomOrnament from '../../../img/form-ornament-bottom.png';
import topOrnament from '../../../img/form-ornament-top.png';

export interface OnAdditionalSubmitTrigger {
    onAdditionalSubmitTrigger?: () => void
}

export const EditFormTemplate = 
<T extends {}>({ children, handleSubmit, isSubmitting, onAdditionalSubmitTrigger }: 
    React.PropsWithChildren<FormikProps<T>> & OnAdditionalSubmitTrigger) => {
    const history = useHistory()
    return (
        <div id="form-container">
            <div className="top-ornament"><img src={topOrnament} /></div>
            <div className="bottom-ornament"><img src={bottomOrnament} /></div>

            <form onSubmit={handleSubmit}>
                {children}
                <div className="row default-padding-top">
                    <div className="col">
                        <ButtonInput type="button" caption="Back" title="Back" id="cancel" onClick={() => { history.goBack() }} {...(isSubmitting ? { disabled: "disabled" } : {})} />
                        <ButtonInput id="submit"
                            onClick={() => { onAdditionalSubmitTrigger && onAdditionalSubmitTrigger() }}
                            {...(isSubmitting ? { disabled: "disabled", caption: "Updating" } : { caption: "Update" })} 
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}