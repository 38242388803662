import { useFormik } from "formik"
import { ObjectSchema } from "yup"
import { NewsPath } from "../../../database_api/api"
import { DateHelper } from "../../../helper/DateHelper"
import { useAddData } from "../../Shared/useAddData"
import { NewsModel } from "../model/News"

export const useNewsAddFormHandler = (initialValues: NewsModel, validationSchema?: ObjectSchema) => {
    const { add } = useAddData(NewsPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            console.log("HAHAHA")
            setSubmitting(true)

            let newsData: NewsModel = {
                title: values.title,
                link: values.link,
                tags: values.tags,
                description: values.description,
                time: DateHelper.dateISO8601(new Date().toLocaleString("en-US"))
            }

            setSubmitting(false)
            add(newsData)
        }
    })

    return formik
}