import moment from "moment"
import { useEffect } from "react"
import { useParams } from "react-router"
import { ActionPlanPath } from "../../../../database_api/api"
import { ActionPlanSingleResult } from "../../../ActionPlan/ActionPlanSingleLoader"
import { ActionPlanModel } from "../../../ActionPlan/model/ActionPlan"
import { EditFormTemplate } from "../../../Shared/template/EditFormTemplate"
import { useItem } from "../../../Shared/useItem"
import { useSelectMemberHelper } from "../../../useSelectMemberHelperV2"
import { ActionPlanForm } from "./ActionPlanForm"
import { useActionPlanEditFormHandler } from "./useActionPlanEditFormHandler"
import { errorValidationSchema } from "./validation/errorValidationSchema"

export const ActionPlanEdit = () => {
    const params = useParams<{ id: string }>()

    const formik = useActionPlanEditFormHandler({
        id: params.id, name: "", date: "", location: "", objective: ""
    }, errorValidationSchema)

    const {setFieldValue} = formik

    const { get, data, isLoading } = useItem(ActionPlanPath, params.id, "actionPlan")

    useSelectMemberHelper((datas) => {
        setFieldValue("memberList", datas)
    })
    
    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data, formik.values.memberList])

    if (isLoading) return (<div>Loading...</div>)

    const mapData = (result: ActionPlanSingleResult) => {
        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if (data[0] == "date" && typeof data[1] == "string") {
                let d = moment(data[1])
                if (d.isValid()) setFieldValue("date_date", d.toDate())
            }

            if (data[0] == "picIds") {
                const members = data[1].map((picId: string) => {
                    const member = formik.values.memberList?.find(data => data.value == picId)
                    return member
                })
                setFieldValue("membersSelected", members)
            }

            setFieldValue(data[0], data[1])
        })
    }

    return (
        <EditFormTemplate<ActionPlanModel> {...formik}>
            { ActionPlanForm("Update", formik) }
        </EditFormTemplate>
    )

}