import React, { useEffect } from "react"
import { POBBlockContentList, POBContentChangeOrder } from "../../../../../database_api/api"
import { useURL } from "../../../../Search/useURL"
import { useGetData } from "../../../../Shared/useGetData"
import { BlockContentWithData } from "../../POBBlockContentListComponent"
import { useChangeOrderBlockContent } from "../../useChangeOrderBlockContent"

export const useBlockContentList = () => {
    const { params: contentParams } = useURL()
    
    const [content, setContent] = React.useState<BlockContentWithData[]>([])
    const [prevBlockContentList, setPrevBlockContentList] = React.useState<BlockContentWithData[]>([])

    const { params } = useURL()
    const { get } = useGetData(POBBlockContentList + params.contentType + "/" + params.contentId, "")
    const { changeOrder } = useChangeOrderBlockContent(POBContentChangeOrder + contentParams.contentId + "/")

    const getContent = () => {
        get(undefined, {
            onSuccess(data) {
                setContent(data.data)
            }
        })
    }

    useEffect(() => {
        getContent()
    }, [])

    useEffect(() => {
        setPrevBlockContentList(content)
    }, [content])

    return { getContent, content, setContent }
}