import { QueryComponent, QueryFilterProtocol } from './QueryFilterProtocol'
import { ListLoaderControllerProtocol } from '../ListLoaderControllerProtocol'

export interface SearchFeatureProtocol{
    search(queryComponents: QueryComponent[]): void
}

export class SearchFeature implements SearchFeatureProtocol{
    private filter: QueryFilterProtocol
    private loader: ListLoaderControllerProtocol

    constructor(filter: QueryFilterProtocol, loader: ListLoaderControllerProtocol){
        this.filter = filter
        this.loader = loader
    }

    search(queryComponents: QueryComponent[]){ 
        this.filter.addFilter(queryComponents)
        this.loader.load(1)
    }
}