
import { useEffect, useState } from 'react';
import { FieldInformationPagePath } from "../../../database_api/api";
import { HTTPMethod } from "../../../interfaces/HTTPHeader";
import { useHeader } from "../../Shared/useHeader";

export const FieldInformationMoveFiles = () => {

    const [files, setFiles] = useState<{[key: string]: string[]}>()
    const { header } = useHeader()
    
    const requestOptions = {
        method: HTTPMethod.get,
        headers: header.header()
    }
    
    useEffect(() => {
        fetch(`${FieldInformationPagePath}filesList`, requestOptions)
            .then((response) => response.json()).then((data) => {
                console.log(data)
                setFiles(data)
            })
    }, [])

    const doMoveFiles = () => {
        fetch(`${FieldInformationPagePath}moveFiles`, requestOptions)
            .then((response) => response.json()).then((data) => {
                console.log(data.message)
                console.log(JSON.stringify(data.message))
            })
    }

    return (
        <>
        <div id="fieldInformation-list">
            {files && Object.keys(files).map((key) => {
                return (
                    <div key={key}>
                        <h3>{key}</h3>
                        <ul>
                            {files[key].map((file) => {
                                return (
                                    <li key={file}>{file}</li>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}
        </div>
        <button onClick={doMoveFiles}>Move Files</button>
        </>
    )
}