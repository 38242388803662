import React from 'react'
import { TextInput } from '../../../form-components/TextInput';
import nameIcon from '../../../img/icon-name.png'
import emailIcon from '../../../img/icon-email.png'
import phoneIcon from '../../../img/icon-phone.png'
import locationIcon from '../../../img/icon-location.png'
import defaultCompany from '../../../img/default-company.png'

import { FormikProps } from 'formik';
import { ClientModel } from '../model/Client';
import { OptionInputActiveNotActiveSelection } from '../../../form-components/OptionInputActiveNotActiveSelection';
import { OptionInputProspectiveStatusSelection } from './OptionInputProspectiveStatusSelection';
import { FormikImageUploadFactory } from '../../../FormikHelper/FormikImageUploadFactory';

interface ClientLangInterface{
    mClient: string
    clientName: string
    clientProduct: string
    clientBusinessUnitOfficer: string
    clientAddress: string
    clientPhone: string
    clientEmail: string
    clientWebsite: string
    clientSocialMedia: string
}

export enum FormMode{
    ADD, EDIT
}

export let ClientForm: <T extends ClientModel>(mode: FormMode, pageTitle: string, Lang: ClientLangInterface, props: FormikProps<T>, prospective?: boolean) => JSX.Element = 
    (mode, pageTitle, Lang, props, prospective) => {
    let { values, errors, touched, handleChange, setFieldValue } = props

    let photoHandler = new FormikImageUploadFactory(setFieldValue, "photo_url", "photo_base64", "isPhotoChanged", "client-profile-photo", defaultCompany, values.photo_url, values.photo_base64)
        
    return (
    <>
        <div className="page-title">{pageTitle} {Lang.mClient} {values.name}</div>
        <div className="rowflex">
            <div className="col clientProfile">
                { (mode === FormMode.ADD) ? photoHandler.getAddComponent() : photoHandler.getEditComponent() }
            </div>
            <div className="col default-padding-left">
                <TextInput icon={nameIcon} placeholder={Lang.clientName} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                <TextInput placeholder={Lang.clientProduct} name="product" value={values.product} onChange={handleChange} message={errors.product} touched={touched.product} />
                <TextInput placeholder={Lang.clientBusinessUnitOfficer} name="businessUnitOfficer" value={values.businessUnitOfficer} onChange={handleChange} message={errors.businessUnitOfficer} touched={touched.businessUnitOfficer} />
            </div>
        </div>

        <div className="row">
            <TextInput icon={locationIcon} placeholder={Lang.clientAddress} name="address" value={values.address} onChange={handleChange} message={errors.address} touched={touched.address} />
            <TextInput icon={phoneIcon} placeholder={Lang.clientPhone} name="phone" value={values.phone} onChange={handleChange} message={errors.phone} touched={touched.phone} />
            <TextInput icon={emailIcon} placeholder={Lang.clientEmail} name="email" value={values.email} onChange={handleChange} message={errors.email} touched={touched.email} />                
            <TextInput placeholder={Lang.clientWebsite} name="url" value={values.url} onChange={handleChange} message={errors.url} touched={touched.url} />
            <TextInput placeholder={Lang.clientSocialMedia} name="socialMedia" value={values.socialMedia} onChange={handleChange} message={errors.socialMedia} touched={touched.businessUnitOfficer} />
        </div>

        <div className="row">
            <div className="col">
            <div className="field-description">Note</div>
            <TextInput type="text" placeholder="Note about the client" aclass="default full-width" name="note" value={values.note} onChange={handleChange} message={errors.note} touched={touched.note}/>
            </div>
        </div>
        
        <div className="row default-padding-top">
            <div className="col">
                {
                    OptionInputActiveNotActiveSelection(props)
                }
            </div>
        </div>

        { prospective &&
        <div className="row default-padding-top">
            <div className="col">
                {
                    OptionInputProspectiveStatusSelection(props)
                }
            </div>
        </div>
        }
    </>
)}