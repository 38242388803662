import Select from 'react-select'
import { SetFieldValueFunctionType } from '../FormikHelper/FormikFileUploadFactory'

export type SelectOptionObject = {label: string, value: string}

export const FormikSelectInputHandler = (props: {
    fieldName: string,
    placeholder: string,
    options: SelectOptionObject[],
    selectedFieldName: string,
    setFieldValue: SetFieldValueFunctionType,
    selected?: SelectOptionObject
}) => {
    const {fieldName, placeholder, options, selectedFieldName, setFieldValue, selected} = props

    const handleSelectedChange = (inputValue: any, _: any) => {
        setFieldValue(selectedFieldName,inputValue)
        setFieldValue(fieldName, inputValue.value)
    }

    return (
        <Select placeholder={placeholder} className="selectionStyle full-border" 
            isSearchable={true} name={fieldName} classNamePrefix="si" options={options} value={selected} 
            onChange={handleSelectedChange} />
    )
}