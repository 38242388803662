import * as React from 'react';
import { useState } from 'react';
import { OptionValue } from './OptionInputSearchComponentV2';
import { useURL } from './useURL';

interface OptionInputCustomProp{
    name: string
    fieldName: string
    options?: OptionValue[]
    initialValue: string
}
export const OptionInputSearchCustomComponent = (props: OptionInputCustomProp) => {
    const { location, params, goTo } = useURL()

    const status = params[props.fieldName] as string ?? props.initialValue

    const [selectedValue, setSelectedValue] = useState<string | undefined>(status)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(e.target.value)

        if (e.target.value === "all") {
            delete params[props.fieldName]
        }
        else {
            params[props.fieldName] = e.target.value
        }

        goTo(location.pathname, params)
    }

    return (
    <div className="option-input-normal">
        {!props.options ?
            <React.Fragment key="default"><input className="option" type="radio" name={props.name} value="default" id="default" />
                <label htmlFor="default">Default</label>
            </React.Fragment>
        :
            props.options.map((option) => 
                <div className='option-container' key={option.key}>
                    <input className="option" type="radio" name={props.name} value={option.key} id={option.key} 
                        checked={selectedValue === option.key} 
                        onChange={handleChange} 
                    />
                    <label htmlFor={option.key}>{option.value}</label>
                </div>
            )
        }
    </div>
    )
}