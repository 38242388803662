import { DateHelper } from "../../helper/DateHelper";
import { DownloadDataToDoc } from "./DownloadDataToDocProtocol";
import { ContentListType } from "../POBContent/ContentListLoader";
import { LatestStatusDownloadData } from "./LatestStatusDownloadData";
import { DateRangeFilter } from "./filter/DateRangeFilter";

const dateFormat = "DD MMMM YYYY"

export class DownloadLatestStatusToDoc extends DownloadDataToDoc<LatestStatusDownloadData[]>{
    type = ContentListType.latestStatus
    from: Date = new Date()
    to: Date = new Date()
    apiPath = (projectId: string) => {
        return (this.basePath + this.type + "/" + projectId + "/" + this.from.toISOString() + "/" + this.to.toISOString())
    }

    filterElement = () => <DateRangeFilter downloadDecisionFn={this.downloadDecisionFn} 
        setFromFilter={(date) => {this.from = date}}
        setToFilter={(date) => { this.to = date }}
    />

    getPostfix = () => "( " + DateHelper.dateFormat(this.from.toLocaleDateString(), dateFormat) + " - " + DateHelper.dateFormat(this.to.toLocaleDateString(), dateFormat) + " )"
    
    protected useTemplate(projectName: string, data: LatestStatusDownloadData[]){
        let html = super.useTemplate(projectName, data)

        if (!data) return html
        if (data.length == 0) return html

        data.forEach(dt => {
            html += "<h3><u>" + DateHelper.dateFormat(dt.date, "MMMM DD, YYYY HH:mm")
            if (dt.location != "") html += " at " + dt.location ?? "-"
            html += "</u></h3>"

            if (dt.personnelList) html += "Personnel List: " + dt.personnelList + "<br>"
            if (dt.contractors) html += "Contractors: " + dt.contractors + "<br>"
            html += "<br><b>Information</b><br>"
            html += dt.information ?? "-"
            html += "<br><br>"

            html += "<b>Analysis</b><br>"
            html += dt.analysis ?? "-"
            html += "<br/><br/>"
        })

        return html;
    }
}