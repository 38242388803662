import * as React from 'react'
import CreatableAsyncSelect from 'react-select/async-creatable'

import { GetAPIHTTPClient, PostAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { FilterNewsTagPath, NewsTagPath } from '../../../database_api/api';
import { ResultStatus } from '../../../Types/ResultStatus';

export type NewsTagResultType = {
    label: string,
    value: string
}

export class CreatableAsyncNewsTagSelectInputHandler{
    private fieldName: string
    private placeholder: string
    private client: GetAPIHTTPClient & PostAPIHTTPClient
    private onChange: (tags?: NewsTagResultType[]) => void
    private onNewTagAdded: (tag: NewsTagResultType) => void

    constructor(fieldName: string, placeholder: string,
        client: GetAPIHTTPClient & PostAPIHTTPClient,
        onChange: (tags?: NewsTagResultType[]) => void, onNewTagAdded: (tag: NewsTagResultType) => void){
        this.fieldName = fieldName
        this.placeholder = placeholder
        this.client = client
        this.onChange = onChange
        this.onNewTagAdded = onNewTagAdded
    }

    private loadNewsTagList = (inputValue: string, callback: (options: NewsTagResultType[]) => void) => {
        if(inputValue == "" || inputValue == undefined) {
            callback([])
            return
        }

        this.client.get(FilterNewsTagPath + inputValue, result => {
            if(result.data == undefined){
                callback([])
                return
            }

            let returnedResult: {id: string, name: string}[] = result.data
            if(returnedResult.length == 0){
                callback([])
                return
            }

            callback(returnedResult.map( tag => {
                return {label: tag.name, value: tag.id}
            }))
        })
        
    }

    private handleSelectedChange = (inputValue: any, _: any) => {
        let selected: NewsTagResultType[] | undefined = inputValue

        if(selected == undefined) {
            this.onChange(undefined)
            return
        }


        this.onChange(selected.map( sel => {
            return {
                label: sel.label,
                value: sel.label.replace(" ", "-").toLowerCase()
            }
        }))
    }

    private onCreateOption = (inputValue: string) => {
        this.client.post(NewsTagPath, JSON.stringify({name: inputValue}), result => {
            if(result.status == ResultStatus.ok){
                this.onNewTagAdded({
                    label: result.data.name,
                    value: result.data.id
                })
            }
        })
    }


    getComponent = (value?: NewsTagResultType[]) => {
        return (
            <CreatableAsyncSelect 
                
                placeholder={this.placeholder}
                className="selectionStyle full-border"
                classNamePrefix="si"
                isMulti
                onCreateOption={this.onCreateOption}
                value={value}
                loadOptions={this.loadNewsTagList} defaultOptions 
                onChange={this.handleSelectedChange}
                />
        )
    }
}

