import { useEffect } from "react"
import { useParams } from "react-router"
import { ImagePath, POBHeaderPath } from "../../../database_api/api"
import { ResultStatus } from "../../../Types/ResultStatus"
import { FormMode } from "../../Client/ui/ClientFormV3"
import { EditFormTemplate } from "../../Shared/template/EditFormTemplate"
import { useItem } from "../../Shared/useItem"
import { errorValidationSchema } from "../builder/ErrorMessage/errorValidationSchema"
import { usePOBHeaderEditFormHandler } from "../builder/usePOBHeaderEditFormHandler"
import { POBHeaderModel } from "../model/POBHeader"
import { POBHeaderSingleResult } from "../POBHeaderSingleLoader"
import { POBHeaderForm } from "./POBHeaderForm"

export const POBHeaderEdit = () => {
    const params = useParams<{ id: string }>()

    const formik = usePOBHeaderEditFormHandler({
        id: params.id, name: "", budgetList: []
    }, errorValidationSchema)

    const {setFieldValue} = formik

    const { get, data, isLoading } = useItem(POBHeaderPath, params.id, "pobHeader")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    const mapData = (result: POBHeaderSingleResult) => {
        setFieldValue("isRequestData", false)

        if (result.status == ResultStatus.fail) {
            console.log("Failed get data")
        }

        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if (data[0] == "photo_url") {
                let photo_url = data[1]
                if (photo_url == "") return
                setFieldValue("photo_base64", ImagePath + photo_url)
                return
            }

            if (data[0] == "budgetList") {
                if (typeof data[1] == "string") {
                    setFieldValue("budgetList", JSON.parse(data[1]))
                }
                return
            }

            if (data[0] == "projectManagerId") {
                setFieldValue("projectManagerId", data[1])
                return
            }

            if (data[0] == "classificationBudget") {
                setFieldValue("classification", data[1])
                setFieldValue("classificationBudgetSelected", { label: data[1], value: data[1] })
                return
            }

            setFieldValue(data[0], data[1])
        })
    }

    const addBudget = () => {
        document.getElementById("budgetAddSubmit")?.click()
    }

    return (
        <EditFormTemplate<POBHeaderModel> {...formik} onAdditionalSubmitTrigger={addBudget} >
            { POBHeaderForm(FormMode.EDIT, "Update", formik)}
        </EditFormTemplate>
    )
}