import React from 'react';
import ReactDOM from 'react-dom';
import { DbBackupListRouteProtocol } from "../ui/DbBackupList";
import { DbBackupPath, DbBackupRestore, DbBackupFindFilePath, DbBackupUpload } from "../../../database_api/api";
import { StandardAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient";
import { HTTPHeader } from "../../../interfaces/HTTPHeader";
import { AuthTokenSessionStorage } from "../../Login/AuthPermission";
import { ResultStatus } from '../../../Types/ResultStatus';
import { AxiosAPIHTTPClient } from '../../../database_api/AxiosAPIHTTPClient';
import { AskDBKey, UploadBackupForm } from './DbBackupListBuilder';

export class DbBackupListRouter implements DbBackupListRouteProtocol {
    requestBackup(): void {
        ReactDOM.render(<AskDBKey submitTitle="Backup" onConfirm={this.onConfirmBackup} onCancel={() => { this.onCancel(); }} />, document.getElementById("DbRequestBackupAuth"));
    }

    requestRestore(id: string): void {
        ReactDOM.render(<AskDBKey submitTitle="Restore" onConfirm={(username, password) => { this.onConfirmRestore(id, username, password); }} onCancel={() => { this.onCancel(); }} />, document.getElementById("DbRequestBackupAuth"));
    }

    uploadBackup() {
        ReactDOM.render(<UploadBackupForm progressUpdater={this.uploadClient} upload={this.onConfirmUpload} onCancel={() => { this.onCancelUpload(); }} />, document.getElementById("DbUploadBackup"));
    }

    private header = new HTTPHeader(new AuthTokenSessionStorage());
    private client = new StandardAPIHTTPClient(this.header);
    private uploadClient = new AxiosAPIHTTPClient(this.header);

    private closeWindow = () => {
        ReactDOM.render(<></>, document.getElementById("DbRequestBackupAuth"));
    };

    private onConfirmBackup = (username?: string, password?: string) => {
        this.client.post(DbBackupPath, JSON.stringify({
            username: username,
            password: password
        }), (result) => {
            alert("Restore request has been processed. You can't access the data while backup is on progress");
            this.closeWindow();
        });
    };

    private onConfirmRestore = (id: string, username?: string, password?: string) => {
        this.client.get(DbBackupRestore + id + "/" + username + "/" + password, (result) => {
            alert("Restore request has been processed. You can't access the data while backup is on progress");
            this.closeWindow();
        });
    };

    private onConfirmUpload = (file?: File, fileName?: string) => {
        if (!file || !fileName) {
            alert("There's no file to upload.");
            return;
        }

        this.client.get(DbBackupFindFilePath + fileName, (result) => {
            if (result.status == ResultStatus.ok) {
                this.closeUploadThenRequestRestore(result.data.id);
                return;
            }

            this.doUploadBackupFile(file);
        });
    };

    private onCancel = () => {
        this.closeWindow();
    };

    private onCancelUpload = () => {
        ReactDOM.render(<></>, document.getElementById("DbUploadBackup"));
    };

    private doUploadBackupFile(file: File) {
        let data = new FormData();
        data.append('file_url', file);

        this.uploadClient.postForm(DbBackupUpload, data, (result) => {
            console.log(result)
            if (result.status == ResultStatus.fail) {
                alert(result.message);
                return;
            }

            this.closeUploadThenRequestRestore(result.data.id);
        });
    }

    private closeUploadThenRequestRestore(id: string) {
        this.onCancelUpload();
        this.requestRestore(id);
    }
}
