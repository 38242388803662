import { PagingDisplayUpdaterProtocol, PagingData } from './PagingDisplayUpdaterProtocol';

export interface PagingFeatureControlProtocol{
    setData(pagingData: PagingFeatureControlData): void
    updateNextPage(page: number): void
    updatePrevPage(page: number): void
    updateCurrentPage(page: number): void
    updateTotalPage(total: number): void

    commitUpdate(): void
}

export type PagingFeatureControlData = {
    totalData: number,
    totalDataShowed: number,
    currentPage: number,
    totalPage: number
}

export class PagingFeatureControl implements PagingFeatureControlProtocol, PagingDisplayUpdaterProtocol{

    nextPageEnabled: boolean = false
    prevPageEnabled: boolean = false

    totalPage: number = 1
    prevPage: number = 1
    nextPage: number = 1
    currentPage: number = 1

    updateDisplayList: ((data: PagingData) => void)[] = []

    setData(pagingData: PagingFeatureControlData){
        let currentPage = pagingData.currentPage
        this.updateCurrentPage(currentPage)
        this.updatePrevPage(currentPage - 1)
        this.updateNextPage(currentPage + 1)
        this.updateTotalPage(pagingData.totalPage)
        this.commitUpdate()

        let data: PagingData = {
            totalPage: pagingData.totalPage,

            prevPage: this.prevPage, 
            nextPage: this.nextPage, 
            currentPage: this.currentPage, 

            totalData: pagingData.totalData,
            totalDataShowed: pagingData.totalDataShowed,

            nextPageEnabled: this.nextPageEnabled, 
            prevPageEnabled: this.prevPageEnabled
        }

        this.updateDisplay(data)
    }

    updateTotalPage(total: number){
        this.totalPage = total
    }

    updateNextPage(page: number){
        this.nextPage = page
    }

    updatePrevPage(page: number){
        this.prevPage = (page > 1 ? page : 1)
    }

    updateCurrentPage(page: number){
        this.currentPage = page
    }

    commitUpdate(){
        if(this.nextPage > this.totalPage) this.nextPage = this.totalPage

        if(this.totalPage == 1){
            this.prevPageEnabled = false
            this.nextPageEnabled = false
        }
        else if(this.prevPage == this.currentPage){
            this.nextPageEnabled = true
            this.prevPageEnabled = false
        }
        else if(this.nextPage == this.currentPage){
            this.nextPageEnabled = false
            this.prevPageEnabled = true
        }
        else{
            this.nextPageEnabled = true
            this.prevPageEnabled = true
        }
    }

    register(updateDisplay: (data: PagingData) => void){
        this.updateDisplayList.push(updateDisplay)
    }
    updateDisplay(data: PagingData){
        this.updateDisplayList.forEach( (display) => {
            display(data)
        } )
    }
}