import DocViewer,  { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import { RouteComponentProps } from "react-router"
import { DOCXRenderer } from "./DOCXRenderer"
import { XLSXRenderer } from "./XLSXRenderer"

export const DocumentViewer = (props: RouteComponentProps<{fileUrl: string}>) => {
    return (
        <>
            <DocViewer pluginRenderers={[...DocViewerRenderers, DOCXRenderer, XLSXRenderer]} documents={[{
                uri: window.atob(props.match.params.fileUrl)
            }]} />
        </>
    )
}