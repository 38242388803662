import { OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { FilterClientPICPath } from '../../../database_api/api'
import { useGetData } from '../../Shared/useGetDataWithParam'

export const AsyncClientPICSelectInput = (props: {
    fieldName: string,
    placeholder: string,
    onChange: (client: ClientPICResultBaseType) => void,
    value?: ClientPICResultBaseType
}) => {

    const { fieldName, placeholder, onChange, value } = props
    const { get } = useGetData(FilterClientPICPath, "")

    const loadClientList = (inputValue: string, callback: (options: ClientPICResultBaseType[]) => void) => {
        if(inputValue == ""){
            callback([])
            return
        }

        get(inputValue, {
            onSuccess: (result) => {
                if(result.data == undefined){
                    callback([])
                    return
                }

                const returnedResult: {id: string, name: string, clientName: string}[] = result.data
                if(returnedResult.length == 0){
                    callback([])
                    return
                }

                callback(returnedResult.map( client => {
                    return {
                        label: `${client.name} (${client.clientName})`, value: client.id,
                        picName: `${client.name}`, companyName: `${client.clientName}`
                    }
                }))
            }
        })
        
    }

    const handleSelectedChange = (inputValue: any, _: any) => {
        onChange(inputValue)
    }

    const optionComponent = (props: OptionProps<{}, false>) => {
        const {
            children,
            className,
            cx,
            getStyles,
            isDisabled,
            isFocused,
            isSelected,
            innerRef,
            innerProps,
        } = props;

        let data: ClientPICResultTypeforStyle = props.data

        return (
            <div
                style={getStyles('option', props)}
                className={cx(
                    {
                        option: true,
                        'option--is-disabled': isDisabled,
                        'option--is-focused': isFocused,
                        'option--is-selected': isSelected,
                    },
                    className
                )}
                ref={innerRef}
                {...innerProps}
            >
                <div className="option-picName">{data.picName}</div>
                <div className="option-companyName">{data.companyName}</div>

            </div>
        );
    };

    return (
        <AsyncSelect cacheOptions 
            placeholder={placeholder}
            className="selectionStyle full-border clientPIC-customStyle"
            classNamePrefix="si"
            value={value}
            components={{
                Option: optionComponent
            }}
            loadOptions={loadClientList} defaultOptions 
            onChange={handleSelectedChange}
            />
    )
}

interface ClientPICResultTypeforStyle extends ClientPICResultBaseType{
    picName: string,
    companyName: string,
}

export interface ClientPICResultBaseType{
    label: string,
    value: string
}