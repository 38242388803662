import * as React from 'react';
import * as H from "history"
import { MemberAddRouterProtocol } from '../ui/MemberAdd';
import { MessageType } from '../../../MessageType';
import { MemberAddAPI } from '../MemberAddAPI';
import { MemberPath } from '../../../database_api/api';
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { MemberAddWithFormHandler } from '../formHandler/MemberAddWithFormHandler';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';

export class MemberAddRouter implements MemberAddRouterProtocol{
    basePath: string
    history: H.History<unknown>
    constructor(basePath: string, history: H.History<unknown>){
        this.basePath = basePath
        this.history = history
    }

    cancel(){
        this.history.push(this.basePath)
    }

    successAdd(type: MessageType, message: string){
        this.history.push(this.basePath, {message:{type: type, message: message}})
    }
}

export class MemberAddBuilder{
    router: MemberAddRouterProtocol
    memberHandler = new MemberAddAPI(MemberPath, new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())))
    constructor(router: MemberAddRouterProtocol){
        this.router = router
    }

    get(){
        return <MemberAddWithFormHandler router={ this.router } handler={this.memberHandler} />
    }
}