export class LangClientPartner{
    static clientName: string = "Client Partner Name"
    static clientProduct: string = "Product"
    static clientBusinessUnitOfficer: string = "Business Unit Officer"
    static clientAddress: string = "Address"
    static clientPhone: string = "Phone"
    static clientEmail: string = "Email"
    static clientWebsite: string = "Website"
    static clientSocialMedia: string = "Social Media"
    static mClient: string = "Client Partner"
}