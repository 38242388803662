import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import { QueryComponent } from './QueryFilterProtocol';
import { LoadingControlProtocol } from './SearchComponent';
import { BuildableSearchInputComponentProp, SearchQueryComponent } from './BuildableSearchComponent';

interface FlexibleSearchComponentProp extends BuildableSearchInputComponentProp {
    loadingController: LoadingControlProtocol
    placeholder: string
    queryOptions: QueryComponent[]
}

export class FlexibleSearchComponent extends React.Component<FlexibleSearchComponentProp, { options: SearchQueryComponent[]; isLoading: boolean; }> {

    searchInputValue: SearchQueryComponent[] = [];
    loadingController: LoadingControlProtocol;

    constructor(props: FlexibleSearchComponentProp) {
        super(props);
        this.loadingController = props.loadingController;

        this.state = {
            options: [],
            isLoading: false
        };

        this.loadingController.register(this.setLoading);
        this.props.submitter.register(this.submit);
    }

    setLoading = (status: boolean) => {
        this.setState({
            isLoading: status
        });
    };

    submit = (): SearchQueryComponent[] | undefined => {
        return this.searchInputValue;
    };

    handleChange = (inputValue: any, _: any) => {
        this.searchInputValue = inputValue;
    };

    optionMapper = (inputValue: string): { label: string; value: string; uniqueValue: string; query: QueryComponent; }[] => {
        let options: { label: string; value: string; uniqueValue: string; query: QueryComponent; }[] = [];
        this.props.queryOptions.forEach(query => {
            options.push(
                {
                    label: query.displayField + ": " + inputValue, value: inputValue, uniqueValue: query.field + ": " + inputValue, query: {
                        compareType: query.compareType,
                        field: query.field,
                        value: inputValue,
                        reference: query.reference
                    }
                }
            );
        });
        return options;
    };

    handleInputChange = (inputValue: any, _: any) => {
        if (inputValue == undefined || inputValue == null || inputValue == "") {
            this.setState({
                options: []
            });
        }
        else {
            this.setState({
                options: this.optionMapper(inputValue)
            });
        }
    };

    render() {
        return (
            <CreatableSelect className="search-input" placeholder={this.props.placeholder} isLoading={this.state.isLoading} classNamePrefix="si" isMulti options={this.state.options} onChange={this.handleChange} onInputChange={this.handleInputChange} getOptionValue={option => option.uniqueValue} />
        );
    }
}
