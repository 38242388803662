import UploadDropZone from "@rpldy/upload-drop-zone"
import Uploady, { useItemErrorListener, useItemFinishListener, useItemProgressListener } from "@rpldy/uploady"
import React from "react"
import { FileUploadProgressUpdater } from "../FieldInformation/uploader/FieldInformationFileUploadHandler"
import { FileUploadFinish } from "./FileUploadInterface"

let UploadStatus: React.FunctionComponent<{ finishUpload: FileUploadFinish, progressUpload: FileUploadProgressUpdater }> = (props) => {
    useItemErrorListener((item) => {
        console.log(item)
    })

    useItemFinishListener((item) => {
        console.log(item)
        props.finishUpload.onFinish(item.uploadResponse.data.file)
    })

    useItemProgressListener((item) => {
        console.log(item.loaded)
    })

    useItemProgressListener((item) => {
        props.progressUpload.onProgress({
            name: item.file.name,
            completed: item.completed
        })
    })

    return <></>
}

interface FileUploadHandlerProps{
    projectFilesAPIUrl: string
    projectName: string
    progressUpload: FileUploadProgressUpdater
    finishUpload: FileUploadFinish
    headers: {[name: string]: string}
}

export let FileUploadHandler: React.FunctionComponent<FileUploadHandlerProps> = (props) => {
    return (
        <div className="dragdropArea">
            <Uploady destination={{
                url: props.projectFilesAPIUrl, 
                headers: props.headers
            }}
                forceJsonResponse
                sendWithFormData
                autoUpload
                params={{
                    "projectName": props.projectName
                }}
                inputFieldName="file_url"
                method="POST"
            >
                <UploadDropZone className="full-upload">
                    <div className="full-upload">{props.children && props.children}</div>
                </UploadDropZone>
                <UploadStatus finishUpload={props.finishUpload} progressUpload={props.progressUpload} />

            </Uploady>
        </div>
    )
}