import React from "react"
import { JoditContentEditorModule } from "../../../../../form-components/JoditContentEditorModule"
import { DateHelper } from "../../../../../helper/DateHelper"
import { BlockContentListResult } from "../../../BlockContentListLoader"
import { useBlockContentDetail } from "../hook/useBlockContentDetail"

export const BlockContentDetailComponent = (props: {
    index: number,
    content: BlockContentListResult
}) => {
    const { content, getContent, updateContent } = useBlockContentDetail(props.content)
    const contentEditorModule = new JoditContentEditorModule()

    React.useEffect(() => {
        getContent()
    }, [])

    const handleChange = (data: string) => {
        updateContent({
            ...props.content,
            data
        })
    }

    const handleOnFocus = () => {
    }

    const handleOnBlur = () => {
    }

    return (
        <div
            className="block-content-container draggable"
        >
            <div className="content-editor jodit-style2">
                {contentEditorModule.get("content", "", content?.data ?? "", handleChange, handleOnFocus, handleOnBlur,
                    undefined, true
                )}
            </div>
            {content && content.updatedBy && content.updatedDate &&
                <div className="content-update-history">
                    Updated at {DateHelper.dateFormat(content.updatedDate, "MMMM DD, YYYY HH:mm")} by {content.updatedBy}
                </div>
            }

        </div>
    )
}