import * as React from "react";
import { Route, Router, Switch } from 'react-router-dom';
import LoginBuilder from "./modules/Login/LoginBuilder";
import RouteManager from "./modules/RouteManager";
import { Dashboard } from './page/dashboardV2';
import { RoutePath } from './page/RoutePath';

export class StartApp extends React.Component {
    render() {
        return (
            <Router history={RouteManager}>
                <Switch>
                    <Route path={RoutePath.login} component={LoginBuilder} exact={true} />
                    <Route path={RoutePath.dashboard} component={Dashboard} />
                </Switch>
                <div id="DbUploadBackup"></div>
                <div id="DbRequestBackupAuth"></div>
            </Router>);
    }
}
