import React from 'react'
import { RouteComponentProps, Route } from 'react-router-dom'

import { DbBackupListBuilder } from '../builder/DbBackupListBuilder';
import { DbBackupListRouter } from "../builder/DbBackupListRouter";
import { DbBackupListRouteComponentProps } from './DbBackupList';

export class DbBackup extends React.Component<RouteComponentProps>{
    basePath: string
    constructor(props: RouteComponentProps){
        super(props)
        this.basePath = this.props.match.url
    }

    render(){
        return (
            <React.Fragment>
                <Route path={this.props.match.url} component={(props: DbBackupListRouteComponentProps) => { 
                    return <DbBackupListBuilder {...props} router={ new DbBackupListRouter() }/> 
                    }} exact={true} />
            </React.Fragment>
        )
    }
}