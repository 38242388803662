import { MemberComponentProp } from './ui/MemberComponent';
import { ResultStatus } from '../../Types/ResultStatus';
import { GenericSingleLoader, SingleLoaderProtocol } from '../GenericSingleLoader';

type MemberSingleDetail = {
    cas: string
    value: MemberComponentProp
}

export type MemberSingleResult = {
    status: ResultStatus,
    data?: MemberSingleDetail,
    message?: string
}

export type MemberSingleLoader = SingleLoaderProtocol<MemberSingleResult>
export class APIMemberSingleLoader extends GenericSingleLoader<MemberSingleResult> implements MemberSingleLoader{}