import * as React from 'react';
import { ActionType, LogsModel } from '../model/Logs';
import { DateHelper } from '../../../helper/DateHelper';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../page/RoutePath';
import { ActionPlanPath, NewsPath } from '../../../database_api/api';

export interface LogsComponentProp extends LogsModel{}

export class LogsComponent extends React.Component<LogsComponentProp>{
    actionTypeMap: { [type: string]: () => JSX.Element }
    actionTypePathMap: { [path: string]: string }

    constructor(props: LogsComponentProp){
        super(props)

        this.actionTypeMap = {
            [ActionType.Login] : this.loginAttempt,
            [ActionType.LoginFailed]: this.loginFailed,
            [ActionType.LoginSuccess]: this.loginSuccess,
            [ActionType.Edit]: this.edit,
            [ActionType.Add]: this.add
        }

        this.actionTypePathMap = {
            "/news/" : RoutePath.newsList,
            "/action_plan/" : RoutePath.actionPlanList,
            "/pob_header/" : RoutePath.pobHeaderList,
            "/pob/" : RoutePath.pobList,
            "/law_regulation/" : RoutePath.lawRegulationList,
            "/client/" : RoutePath.clientList,
            "/customer/" : RoutePath.customerList,
            "/customer_personnel/" : RoutePath.customerPersonnelList,
            "/member/" : RoutePath.memberList,
            [RoutePath.memberList] : RoutePath.memberList
        }
    }

    loginAttempt = () => {
        return this.generateLoginLogDescription("A User attempt to login using username: ")    
    }

    loginFailed = () => {
        return this.generateLoginLogDescription("Failed user login attempt using username: ")
    }

    loginSuccess = () => {
        return this.generateLoginLogDescription("Success user login attempt using username: ")
    }

    edit = () => {
        return this.generateLogDescription("edit")
    }

    add = () => {
        return this.generateLogDescription("add")
    }

    generateLoginLogDescription = (sentence: string) => {
        return <div className="log-description">{sentence} {this.generateEditLink(RoutePath.memberList, this.props.id, this.props.id)}</div>
    }

    generateLogDescription = (action: string) => {
        return <div className="log-description">
            [{this.props.moduleType}]
            {this.generateEditLink(RoutePath.memberList, this.props.user?.username, this.props.user?.name)}
            &nbsp;{action}&nbsp;
            {this.generateEditLink(this.props.path, this.props.id, this.props.displayTitle)}
        </div>
    }

    generateEditLink = (path: string, id?: string, displayTitle?: string) => {
        var pathMapped = this.actionTypePathMap[path]
        if (pathMapped == undefined) return <span>{displayTitle}</span>

        return <Link to={`${pathMapped}/edit/${id}`}>{displayTitle}</Link>
    }

    getLogDescription = () => {
        if(this.props.actionType == undefined) return

        try {
            console.log(this.props.actionType)
            return this.actionTypeMap[this.props.actionType]()
        } catch (error) {
            return `[${this.props.actionType}]Error fetch log: Log type not registered yet.`
        }
    }

    render(){
        return (
            <div className="logs-container full-border">
                <div className="log-time">{DateHelper.dateFormat(this.props.time, "MMMM DD, YYYY HH:mm")}</div>
                {
                    this.getLogDescription()
                }

                <div className="clear"></div>
            </div>
        )
    }
}