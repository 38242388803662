import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { DatabaseTNIALAdd } from './DatabaseTNIALAddV2';
import { DatabaseTNIALEdit } from './DatabaseTNIALEditV2';
import { DatabaseTNIALList } from './DatabaseTNIALListV2';
import { DatabaseTNIALViewer } from './DatabaseTNIALViewer';

export const DatabaseTNIAL = (props: RouteComponentProps) => {
    return (
        <React.Fragment>
            <Route path={props.match.url} component={DatabaseTNIALList} exact={true} />
            <Route path={props.match.url + "/view/:id"} component={DatabaseTNIALViewer} />
            <Route path={props.match.url + "/add"} component={DatabaseTNIALAdd}/>
            <Route path={props.match.url + "/edit/:id"} component={DatabaseTNIALEdit}/>
        </React.Fragment>
    )
}