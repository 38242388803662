import * as React from 'react';
import { ImagePath } from '../../../database_api/api';
import statusMenu from '../../../img/checked.png';
import defaultProfile from '../../../img/default-profile.png';
import optionMenu from '../../../img/option.png';
import { Lang } from '../../../lang/Lang';

export type MemberComponentProp = {
    name: string,
    position: string,
    username: string,
    gender?: string,
    dob?: string,
    age?: string,
    photo_url?: string,
    email?: string,
    phone?: string,
    onEdit(id: string):void
}
export class MemberComponent extends React.Component<MemberComponentProp>{
    
    render(){
        return (
            <div className="member-container full-border">
                <img src={this.props.photo_url ? ImagePath + this.props.photo_url : defaultProfile}></img>
                <div className="member-description">
                    <div className="member-id">ID: {this.props.username}</div>
                    <div className="member-bio member-name">{this.props.name}</div>
                    <div className="member-bio member-position">{this.props.position ?? "No " + Lang.memberPosition}</div>
                    <div className="member-bio">{this.props.gender}{this.props.age && ", " + this.props.age}</div>
                    <div className="member-phone">{this.props.phone ?? '\u00A0'}</div>
                    <div className="member-email">{this.props.email ?? '\u00A0'}</div>

                    <div className="member-options">
                        <input className="option" type="image" src={optionMenu} onClick={ () => { this.props.onEdit(this.props.username) } } />
                        <input className="status" type="image" src={statusMenu} title="Active" />
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        )
    }
}