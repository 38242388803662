import { useEffect } from "react"
import { useParams } from "react-router"
import { NewsPath } from "../../../database_api/api"
import { EditFormTemplate } from "../../Shared/template/EditFormTemplate"
import { useItem } from "../../Shared/useItem"
import { useNewsEditFormHandler } from "../formHandler/useNewsEditFormHandler"
import { NewsModel } from "../model/News"
import { NewsSingleResult } from "../NewsSingleLoader"
import { errorValidationSchema } from "../validation/errorValidationSchema"
import { NewsTagResultType } from "./CreatableAsyncNewsTagSelectInputHandler"
import { NewsForm } from "./NewsForm"

export const NewsEdit = () => {
    const params = useParams<{ id: string }>()
    
    const formik = useNewsEditFormHandler({
        id: params.id, title: "", description: ""
    }, errorValidationSchema)

    const {setFieldValue} = formik
    
    const { get, data, isLoading } = useItem(NewsPath, params.id, "news")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    if (isLoading) return (<div>Loading...</div>)

    const mapData = (result: NewsSingleResult) => {
        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if (data[0] == "tags" && typeof data[1] == "string") {
                try {
                    let value: NewsTagResultType[] = JSON.parse(data[1])
                    setFieldValue("selectedTags", value)
                } catch (error) {
                    console.log(error)
                }
            }

            setFieldValue(data[0], data[1])
        })
    }

    return (
        <EditFormTemplate<NewsModel> {...formik}>
            { NewsForm("Update", formik) }
        </EditFormTemplate>
    )
}