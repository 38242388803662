import { ActionPlanModel } from '../../ActionPlan/model/ActionPlan'
import { FieldInformationComponent } from './FieldInformationComponentV3'

interface FieldInformationAddProps{
    projectId?: string,
    actionPlan?: ActionPlanModel
    [key: string]: any
}

export const FieldInformationAddV3 = (props: FieldInformationAddProps) => {
    return (
    <div className={"container" + (props.aclass ? " " + props.aclass : "") }>
        <div style={{
            position: "fixed",
            background: 'rgba(0,0,0,0.5)',
            width: "100%",
            height: "100%",
            top: 0, left: 0
        }}></div>
            <FieldInformationComponent
                id={props.actionPlan?.fieldInformationId}
                projectId={props.projectId}

                result="" status="" time={props.actionPlan?.date ?? ""}
                actionPlanId={props.actionPlan?.id}
                actionPlanName={props.actionPlan?.name}
                personnelList={props.actionPlan?.personnelList}
                contractors={props.actionPlan?.contractors}
                location={props.actionPlan?.location}
                objective={props.actionPlan?.objective}

                enableBackButton={true}
            />
    </div>
    )
}