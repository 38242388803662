import * as React from 'react'
import { ButtonInput } from './ButtonInput'

export class SimpleMessage extends React.Component<{title: string, body?: string, onClick?: () => void}>{
    handleClick = () =>{
        this.props.onClick && this.props.onClick()
    }
    render(){
        return (
            <div id="simple-message">
                <div className="message-background"></div>
                <div className="message-container">
                    <div className="message-cell">
                        <div className="message-box">
                            <div className="title">{this.props.title}</div>
                            <div className="body">{this.props.body}</div>
                            <ButtonInput caption="OK" title="OK" aclass="ok-button" type="button" onClick={this.handleClick} autoFocus={true} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}