import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, RouteComponentProps } from 'react-router-dom';

import { ClientPICAdd } from './ClientPICAddV2';
import { ClientPICEdit } from './ClientPICEditV2';
import { ClientPICList } from './ClientPICListV2';

const queryClient = new QueryClient();

export const ClientPIC = (props: RouteComponentProps) => {
    return (
        <QueryClientProvider client={queryClient}>
        <React.Fragment>
            <Route path={props.match.url} component={ClientPICList} exact={true} />

            <Route path={props.match.url + "/client/:clientData"} 
                component={ClientPICList} 
                exact={true} 
            />
            <Route path={props.match.url + "/add"} 
                component={ClientPICAdd} 
            />
            <Route path={props.match.url + "/edit/:id"} 
                component={ClientPICEdit}
            />
        </React.Fragment>
        </QueryClientProvider>
    )
}