import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ResultStatus } from '../../../Types/ResultStatus';
import { EditButton } from '../../../components/EditButton';
import { NewsPath } from '../../../database_api/api';
import { DateHelper } from '../../../helper/DateHelper';
import optionDelete from '../../../img/close.png';
import { useDeleteData } from '../../Shared/useDeleteData';
import { NewsModel } from '../model/News';
import { NewsTagResultType } from './CreatableAsyncNewsTagSelectInputHandler';

export interface NewsComponentProp extends NewsModel{
    disableEdit?: Boolean
}

export const NewsComponent = (props: NewsComponentProp) => {
    const history = useHistory()
    const location = useLocation()
    const [tags, setTags] = useState<JSX.Element[] | undefined>(undefined)
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const { remove } = useDeleteData(NewsPath + props.id, "Success delete " + props.title)

    useEffect(() => {
        if(props.tags == undefined || props.tags == null || props.tags == ""){
            setTags(undefined)
            return
        }

        let tagsList: NewsTagResultType[] = JSON.parse(props.tags)

        var newTags: JSX.Element[] = []
        tagsList.forEach( tag => {
            newTags.push(<div className="tag-item" key={tag.value}>{tag.label}</div>) 
        })

        setTags(newTags)
    }, [props.tags])

    const deleteNews = (id?: string) => {
        if(!id) return
        if(!window.confirm("Are you sure you want to delete this news?")){
            return
        }

        remove(undefined, {
            onSuccess: (result) => {
                if(result.status === ResultStatus.ok)
                    setIsDeleted(true)
            }
        })
    }

    if(isDeleted) return (
        <div className="news-container full-border">
            <div className="news-id">
                <a target="_blank" href={props.link ?? ""}><div className="name">{props.title}</div></a>
                <div className="news-time">{props.time ? DateHelper.dateFormat(props.time, "MMMM DD, YYYY HH:mm") : "-"}</div>
            </div>
            <div className="news-description sun-editor-content">Deleted</div>
        </div>
    )

    return (
        <div className="news-container full-border">
            <div className="news-id">
                <a target="_blank" href={props.link ?? ""}><div className="name">{props.title}</div></a>
                <div className="news-time">{props.time ? DateHelper.dateFormat(props.time, "MMMM DD, YYYY HH:mm") : "-"}</div>
            </div>

            <div className="news-tags">
                { tags && tags }
            </div>
            
            <div className="news-description sun-editor-content" suppressContentEditableWarning>{(props.description != "" && props.description != undefined) ? parse(props.description.split("href").join("target=\"_blank\" href")) : "-" }</div>

            {!props.disableEdit && 
            <div className="news-options">
                 <EditButton basePath={location.pathname} code={props.id} />
                 <input className="status" type="image" src={optionDelete} title="Delete" onClick={ () => {
                    deleteNews(props.id)
                 } }/>
            </div>
            }

            <div className="clear"></div>
        </div>
    )
}