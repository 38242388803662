import * as React from 'react';
import closeIcon from '../../../img/close.png';

import { useEffect, useState } from 'react';
import { HistoryPath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { JoditContentEditorModule } from '../../../form-components/JoditContentEditorModuleV3';
import { SelectInput } from '../../../form-components/SelectInput';
import { TextInput } from '../../../form-components/TextInput';
import { usePrevious } from '../../LatestStatus/ui/LatestStatusComponentV2';
import { useURL } from '../../Search/useURL';
import { useAddData } from '../../Shared/useAddData';
import { useDeleteData } from '../../Shared/useDeleteData';
import { useUpdateData } from '../../Shared/useUpdateData';
import { HistoryModel } from '../model/HistoryV2';

export const HistoryComponent = (props: HistoryModel) => {
    const { history, params } = useURL()
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const [historyData, setHistoryData] = useState<HistoryModel>(props)

    const { add } = useAddData(HistoryPath, false)
    const { update } = useUpdateData(HistoryPath)
    const { remove } = useDeleteData(HistoryPath + historyData.id)    

    const [isInit, setIsInit] = useState<boolean>(false)

    const prevProps = usePrevious(props)

    useEffect(() => {
        if (!prevProps) return

        if(JSON.stringify(prevProps) === JSON.stringify(props)) return

        setHistoryData(props)
    }, [props])

    useEffect(() => {
        if (!isInit){
            setIsInit(true)
            return
        }
        if (!historyData.quarter || historyData.quarter === "") return
        if (!historyData.year) return
        if (!historyData.information) return

        doSilentUpdate()
    }, [historyData])

    const doSilentUpdate = () => {
        if (!historyData || historyData.quarter === "") return
        if (!historyData.year) return
        if (!historyData.information) return

        if (!historyData.id || historyData.id === "") { 
            add({...historyData}, {
                onSuccess: (data) => {
                    setHistoryData({
                        ...historyData,
                        id: data.data.id
                    })
                }
            }) 
            return 
        }

        update({
            id: historyData.id,
            projectId: historyData.projectId,
            quarter: historyData.quarter,
            year: historyData.year,
            information: historyData.information,
        })
    }

    const handleQuarterChange = (quarter: string) => {
        setHistoryData({
            ...historyData,
            quarter: quarter
        })
    }

    const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHistoryData({
            ...historyData,
            year: parseInt(event.currentTarget.value)
        })
    }

    const confirmDeleteHistory = () => {
        if (!historyData.id) return
        if (!window.confirm("Yakin hapus History ini?")) return

       remove(undefined, {
        onSuccess: () => {
            setIsDeleted(true)
        }
       })
    }

    if(isDeleted) return (<div>History deleted.</div>)

    return (
        <div id="form-container" className="history-form">
        {params.mode ==="add" &&
            <div className="title">
                <h1>Add History</h1>
            </div>
        }
        
        <div className="history-container full-border">
            <div className="row history-header"></div>
            
            <div className="row">
                <div className="col">
                    <div className="col history-when rowflex">
                        <SelectInput onChange={handleQuarterChange}
                            value={historyData.quarter}
                            placeholder="Select Quarter"
                            options={[
                                { text: "Q4", value: "Q4" },
                                { text: "Q3", value: "Q3" },
                                { text: "Q2", value: "Q2" },
                                { text: "Q1", value: "Q1" }
                            ]}
                        />
                        <TextInput type="number" name="year" value={historyData.year} placeholder="Year" onChange={handleYearChange} />

                        {params.mode !== "add" &&
                            <div className="history-delete" title="Delete" onClick={() => {
                                confirmDeleteHistory()
                            }}>
                                <img src={closeIcon} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="row history-description">
                <h2>Information</h2>
                    <JoditContentEditorModule key={`historyComponentInformation${historyData.id ?? "Add"}`}
                        fieldName='information'
                        placehoder='Information'
                        value={historyData.information}
                        handleChange={(content: string) => {
                            setHistoryData(prevState => ({
                                ...prevState,
                                information: content
                            }))
                        }}
                    />
            </div>
            <div className="clear"></div>
        </div>

        { params.mode === "add" &&
            <div className="row default-padding-top">
                <div className="col">
                    <ButtonInput type="button" caption="Back" title="Back" id="cancel" onClick={() => 
                        setTimeout(() => { history.goBack() }, 300)
                    } />
                </div>
            </div>
        }
        </div>
    )
}