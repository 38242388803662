import { FormikTouched } from 'formik';
import React from 'react';
import { SetFieldValueFunctionType } from '../../../FormikHelper/FormikFileUploadFactory';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { TextInput } from '../../../form-components/TextInput';
import { ContactNumberComponent } from './ContactNumberItemComponent';

type HandleChange = (e: React.ChangeEvent<any>) => void;

export interface FormikContactNumberProps {
    setFieldValue: SetFieldValueFunctionType;
    values: IContactNumberFormValues;
    handleChange: HandleChange;
    touched: FormikTouched<IContactNumberFormValues>;
}

export interface IContactNumberFormValues {
    contactNumberAddField?: string;
    contactNumber?: string[]; // Add contactNumbers field
}

export const FormikContactNumberFormComponent: React.FC<FormikContactNumberProps> = ({ setFieldValue, values, handleChange, touched }) => {
    const contactNumberRef = React.createRef<HTMLInputElement>();

    const handleAddContactNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addContactNumber();
            contactNumberRef.current?.focus();
        }
    };

    const addContactNumber = () => {
        if (!values.contactNumberAddField) return;
        let newContactNumberList = values.contactNumber ?? [];
        newContactNumberList.push(values.contactNumberAddField);

        setFieldValue("contactNumber", newContactNumberList);
        setFieldValue("contactNumberAddField", "");

        contactNumberRef.current?.focus();
    };

    const removeContactNumber = (contactNumber: string) => {
        const newContactNumberList = values.contactNumber?.filter(number => number !== contactNumber);

        setFieldValue("contactNumber", newContactNumberList);
    };

    return (
        <>
            <div className="rowflex contactNumberType default-padding-top" style={{ gap: "10px" }}>
                <div className="col">
                    <TextInput placeholder="Contact Number" name="contactNumberAddField" value={values.contactNumberAddField} onChange={handleChange} touched={touched.contactNumberAddField} onKeyPress={handleAddContactNumber} inputRef={contactNumberRef} />
                </div>
                <div className="col right-align">
                    <ButtonInput type="button" caption="Add Contact Number" title="Add Contact Number" id="contactNumberAddSubmit" onClick={addContactNumber} />
                </div>
            </div>

            <div className="contactNumberList">
                {
                    values.contactNumber && values.contactNumber.map((number, index) => {
                        return <ContactNumberComponent contactNumberItem={number} key={index} onDelete={(contactNumber: string) => {
                            const newContactNumbers = values.contactNumber ? values.contactNumber.filter(number => number !== contactNumber) : [];
                            setFieldValue("contactNumber", newContactNumbers);
                        }} />;
                    })
                }
            </div>
        </>
    );
};
