import * as React from 'react';
import closeIcon from '../../../img/close.png';
import { IPosition } from '../model/DatabaseTNIAL';

interface PositionComponentProp{
    data: IPosition
    onDelete: (position: string) => void
    [param: string]: any
}

export class PositionComponent extends React.Component<PositionComponentProp>{
    data: IPosition

    constructor(props: PositionComponentProp){
        super(props)
        this.data = props.data
    }

    handleDelete = () => {
        this.props.onDelete(this.data.position)
    }

    render(){
        const { aclass, type, onDelete, ...props } = this.props
        return <div className="input-container budgetType">
                    <input type={type || "text"} className={"text-input " + (aclass ?? '') + ' no-icon' } value={this.data.position + " ( " + this.data.time + " )" } {...props} disabled></input>
                    <img src={closeIcon} onClick={ () => this.handleDelete() }/>
                </div>
    }
}