import { FileData } from './FileUploadInterface';

interface ProjectFileDeleteResult{
    status: string,
    data?: string,
    message?: string
}

export interface ManageProjectFiles {
    init(): void
    onGetFileList(files: FileData[]): void
    delete(fileName: string, result: (deleteResult: ProjectFileDeleteResult) => void): void
}

interface StandardManageProjectFilesProps {
    projectName: string
    projectFilesAPIURL: string
    headers: { [name: string]: string }
}

export class StandarManageProjectFiles implements ManageProjectFiles {
    private props: StandardManageProjectFilesProps;
    constructor(props: StandardManageProjectFilesProps) {
        this.props = props;
    }

    init = () => {
        fetch(`${this.props.projectFilesAPIURL}filelist/${this.props.projectName}`, { headers: this.props.headers }).then(data => data.json())
            .then(data => {
                try {
                    let files: FileData[] = data.data;
                    this.onGetFileList(files);
                }
                catch {
                    this.onGetFileList([]);
                }
            });
    };

    onGetFileList(files: FileData[]) { }

    delete = (fileName: string, result: (deleteResult: ProjectFileDeleteResult) => void) => {
        fetch(`${this.props.projectFilesAPIURL}delete/${this.props.projectName}/${fileName}`, 
            { headers: this.props.headers }).then(data => data.json())
            .then(data => {
                try {
                    let returnedResult: ProjectFileDeleteResult = data
                    result(returnedResult)
                }
                catch {
                    result({
                        status: "fail",
                        message: "Fail with no reason?"
                    })
                }
            });
    }

}
