import { FormikTouched } from 'formik';
import React from 'react';
import { SetFieldValueFunctionType } from '../../../FormikHelper/FormikFileUploadFactory';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { TextInput } from '../../../form-components/TextInput';
import { ChildComponent } from './ChildItemComponent';

type HandleChange = (e: React.ChangeEvent<any>) => void;

export interface FormikChildrenFormComponentProps {
    setFieldValue: SetFieldValueFunctionType;
    values: IChildrenFormValues;
    handleChange: HandleChange;
    touched: FormikTouched<IChildrenFormValues>;
}

export interface IChildrenFormValues {
    childAddField?: string;
    children?: string[];
}

export const FormikChildrenFormComponent: React.FC<FormikChildrenFormComponentProps> = ({ setFieldValue, values, handleChange, touched }) => {
    const childrenRef = React.createRef<HTMLInputElement>();

    const handleAddChild = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addChild();
            childrenRef.current?.focus();
        }
    };

    const addChild = () => {
        if (!values.childAddField) return;
        let newChildren = values.children ?? [];
        newChildren.push(values.childAddField);

        setFieldValue("children", newChildren);
        setFieldValue("childAddField", "");

        childrenRef.current?.focus();
    };

    return (
        <>
            <div className="rowflex contactNumberType default-padding-top" style={{ gap: "10px" }}>
                <div className="col">
                    <TextInput placeholder="Child" name="childAddField" value={values.childAddField} onChange={handleChange} touched={touched.childAddField} onKeyPress={handleAddChild} inputRef={childrenRef} />
                </div>
                <div className="col right-align">
                    <ButtonInput type="button" caption="Add Child" title="Add Child" id="childrenAddSubmit" onClick={addChild} />
                </div>
            </div>

            <div className="childrenList">
                {
                    values.children && values.children.map((child, index) => {
                        return <ChildComponent childItem={child} key={index} onDelete={(child: string) => {
                            const newChildren = values.children ? values.children.filter(number => number !== child) : [];
                            setFieldValue("children", newChildren);
                        }} />;
                    })
                }
            </div>
        </>
    );
};
