

import { ButtonInput } from '../../../form-components/ButtonInput';
import { LawRegulationComponent } from './LawRegulationComponentV2';


import { useHistory } from 'react-router-dom';
import { LawRegulationPagePath } from '../../../database_api/api';
import { Lang } from '../../../lang/Lang';
import { ListLayoutFacade } from '../../../layout/ListLayoutFacade';
import { RoutePath } from '../../../page/RoutePath';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from "../../Search/FlexibleSearchComponentV2";
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { LawRegulationModel } from '../model/LawRegulationV2';
import { YearTagsComponent } from './YearTags/YearTagsComponentV2';

export const LawRegulationList = () => { 
    const history = useHistory()
    const itemSize = 8
    const { data, totalData, totalPage, isLoading } = useDataList<LawRegulationModel>({
        url: LawRegulationPagePath,
        size: itemSize,
        identifier: "laws"
    })

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: Lang.lawRegulationName
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "description",
            displayField: Lang.lawRegulationDescription
        }
    ]

    const topSearchComponent = (
        <div id="search-container">
            <FlexibleSearchComponent isLoading={isLoading} placeholder={Lang.lawRegulationName + ", " + Lang.lawRegulationDescription} queryOptions={queryOptions} />
        </div>
    )

    const filterComponents = [
        <YearTagsComponent />
    ]

    const contentComponent = (
        <div id="content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="lawRegulation-list">
                {
                    (data && data.length > 0 && data.map((lawRegulation, index) => {
                        return <LawRegulationComponent name={lawRegulation.name}
                            code={lawRegulation.code} ruleDate={lawRegulation.ruleDate}
                            description={lawRegulation.description} file_url={lawRegulation.file_url} tags={lawRegulation.tags}
                            status={lawRegulation.status}
                            key={`lawRegulation${lawRegulation.code}`} />
                    }))
                    ||
                    <div className="message">No lawRegulation registered</div>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    )

    const addComponent = <ButtonInput id="add" caption="+"
        title={"Add New"}
        onClick={() => {
            history.push(RoutePath.lawRegulationList + "/add")
        }} 
    />
    
    return (
        <ListLayoutFacade
            topSearchComponent={topSearchComponent}
            filterComponents={filterComponents}
            contentComponents={contentComponent}
            addComponent={addComponent}
        />
    )
}