import * as React from 'react';

export type ButtonInputProp = {
    caption: string
    title?: string
    type?: 'submit' | 'reset' | 'button'
    [param: string]: any
}

export class ButtonInput extends React.Component<ButtonInputProp>{
    constructor(props: ButtonInputProp){
        super(props)
    }

    render(){
        const { aclass, caption, title, type, ...props } = this.props
        return <button className={aclass ?? ''} type={type || "submit"} {...props} title={title ?? ""}>
                {this.props.children}
                {caption}
                </button>
    }
}