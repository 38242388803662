export enum RoutePath{
    dashboard = "/",
    login = "/login",
    profile = "/profile",
    logout = "/logout",

    memberList = "/member",
    clientList = "/client",
    prospectiveClientList = "/prospective-client",
    clientPartnerList = "/client-partner",
    clientPICList = "/client-pic",
    customerList = "/customer",
    customerPersonnelList = "/customer-personnel",
    databaseTNIALList = "/database-tni-al",
    lawRegulationList = "/law-regulation",

    pobHeaderList = "/pob-header",
    pobList = "/pob",
    pobContent = "/pob-content/:type/:id",
    pobContentWithHeader = "/pob-content/:type/:id/:pobHeaderId",

    actionPlanList = "/action-plan",

    newsList = "/news",
    log = "/logs",

    backup = "/backup",

    viewDocument = "/viewdoc",
    viewDocumentParam = "/viewdoc/:fileUrl",

    googleCalendarAuth = "/google-calendar-auth",
}