import 'jodit/build/jodit.min.css';
import { ContentEditorModuleInterface } from './ContentEditorModuleInterface';
import { FieldMessage } from './FieldMessage';
import JoditReact from './JoditReact';

const config = {
    inline: true,
    preset: "inline",
    toolbar: false,
    theme: 'default',
    popup: {
        selection: ["bold", "underline", "italic", "brush", "\n", "link", "image", "paragraph", "align","\n", "ul", "ol", "indent", "outdent", "\n", "table"]
    },
    useInputsPlaceholder: true,
    showPlaceholder: true,
    askBeforePasteHTML: false,
    tabIndex: 0
}

const config2 = {
    inline: false,
    toolbarSticky: true,
    toolbarStickyOffset: 107,
    theme: 'default',
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: [
        "undo", "redo", "bold", "underline", "italic", "brush", "link", "image", "paragraph", "align", "ul", "ol", "indent", "outdent", "table"],
    useInputsPlaceholder: true,
    showPlaceholder: true,
    askBeforePasteHTML: false,
    tabIndex: 0
}

export class JoditContentEditorModule implements ContentEditorModuleInterface {

    get = (fieldName: string, placehoder: string, value: string | undefined, handleChange: (content: string) => void, handleOnFocus?: () => void, handleOnBlur?: () => void, message?: string,
        useConfig2?: boolean
    ) => {
        return <div className="input-container custom-0-z-index">
            {message && <FieldMessage message={message} />}
            <JoditReact onBlur={(content) => { handleOnBlur && handleOnBlur(); handleChange(content) } }
                onFocus={handleOnFocus}
                placeHolder={placehoder}
                defaultValue={value} 
                config={useConfig2 ? config2 : config} />
            
        </div>;
    };
}
