import * as React from 'react';
import closeIcon from '../../../img/close.png'
import { BudgetModel } from '../model/POBHeader';

interface BudgetTypeProp{
    data: BudgetModel
    onDelete: (budgetType: string) => void
    [param: string]: any
}

export class BudgetTypeComponent extends React.Component<BudgetTypeProp>{
    data: BudgetModel

    constructor(props: BudgetTypeProp){
        super(props)
        this.data = props.data
    }

    handleDelete = () => {
        this.props.onDelete(this.data.budgetType)
    }

    render(){
        const { aclass, type, onDelete, ...props } = this.props
        return <div className="input-container budgetType">
                    <input type={type || "text"} className={"text-input " + (aclass ?? '') + ' no-icon' } value={this.data.budgetType + " : " + this.data.value} {...props} disabled></input>
                    <img src={closeIcon} onClick={ () => this.handleDelete() }/>
                </div>
    }
}