import { AuthHTTPClient } from './interfaces/HTTPHeader';
import { LoginResult } from './Types/LoginResult';
import { Session } from './interfaces/Session';
import { ResultStatus } from './Types/ResultStatus';

export enum LoginError{
    emptyParameter = "Empty Parameter",
    wrongCredential = "Wrong Credential",
    timeout = "Timeout"
}

export interface LoginInterface{
    doLogin(username: string, password: string, loginCallback: (result: LoginResult) => void): void
}

export class StandardLogin implements LoginInterface{
    client: AuthHTTPClient
    session: Session

    constructor(client: AuthHTTPClient, session: Session){
        this.client = client
        this.session = session
    }

    public doLogin(username: string, password: string, loginCallback: (result: LoginResult) => void){
        if(username == "" && password == ""){
            let emptyLoginResult: LoginResult = {
                status: ResultStatus.fail,
                message: LoginError.emptyParameter
            }
            loginCallback(emptyLoginResult)
            return
        }
        
        this.client.auth(username.replace(/[^a-zA-Z0-9 ]/g, ""), password.replace(/[^a-zA-Z0-9 ]/g, ""), (result) => {
            if(result.status == ResultStatus.ok){
                this.session.saveAccount(result.data)
            }
            
            loginCallback(result)
        })
    }

}