import { useUploady } from '@rpldy/uploady';
import { ChonkyActions, FileArray, FileBrowser, FileContextMenu, FileList, FileToolbar } from 'chonky';
import React from 'react';
import { RoutePath } from '../../page/RoutePath';
import { FileUploadProgressUI } from '../FieldInformation/ui/FileUploadProgressUI';
import { FileUploadProgress } from '../FieldInformation/uploader/FieldInformationFileUploadHandler';
import { ActionHandler, copyUrlActionButton, deleteFileActionButton } from './CustomAction';
import { FileUploadHandler } from './FileUploadHandler';
import { FileData } from './FileUploadInterface';
import { ManageProjectFiles } from './StandarManageProjectFiles';

interface FileUploadDndProps {
    projectFilesAPIURL: string
    projectName: string
    manageProjectFiles: ManageProjectFiles
    headers: { [name: string]: string }
}

interface FileUploadDndState{
    files: FileArray
    fileUploadList: FileUploadProgress[]
    fileUploadOnProgress: boolean,
    manualFileUpload?: () => void
}

export class FileUploadDnD extends React.Component<FileUploadDndProps, FileUploadDndState> {
  constructor(props: FileUploadDndProps) {
    super(props);

    this.state = {
      files: [],
      fileUploadList: [],
      fileUploadOnProgress: false,
    };

    this.props.manageProjectFiles.onGetFileList = this.onGetFilelist
    this.props.manageProjectFiles.init()
  }

  onGetFilelist = (files: FileData[]) => {
      this.setState({
          files: files.map(file => ({
              id: file.name,
              name: file.name,
              url: this.props.projectFilesAPIURL + file.url,
              thumbnailUrl: this.props.projectFilesAPIURL + file.thumbUrl
          }))
      })
  }

  actionHandler: ActionHandler = (data) => {
    if (data.id === copyUrlActionButton.id) {
      let textField: HTMLInputElement | null = document.getElementById("urlToCopy") as HTMLInputElement
      if(textField){
          textField.classList.remove("hide")
          textField.value = window.location.origin + RoutePath.viewDocument + "/" + window.btoa(data.state.selectedFiles[0].url)
          textField.focus()
          textField.select()
          document.execCommand("copy")
          textField.classList.add("hide")
          textField.value = ""
      }
        
    }

    if (data.id === deleteFileActionButton.id) {
      this.props.manageProjectFiles.delete(data.state.selectedFiles[0].name, result => {
          if(result.status == "fail"){
            alert("Failed with message: " + result.message)  
            return
          }

          alert("Success delete " + result.data)
          result.data && this.onRemoveFile(result.data)

      })
    }

    if (data.id === ChonkyActions.OpenFiles.id) {
      window.open(RoutePath.viewDocument + "/" + window.btoa(data.state.selectedFiles[0].url));
    }

    if(data.id === ChonkyActions.UploadFiles.id){
      this.state.manualFileUpload && this.state.manualFileUpload()
    }
  };

  uploadDisplay = new FileUploadProgressUI()
    onProgress = (file: FileUploadProgress) => {
      var fileUploadList = [...this.state.fileUploadList]

      if (!this.state.fileUploadOnProgress) fileUploadList = []

      var found = false
      fileUploadList.forEach(fileUpload => {
        if (fileUpload.name === file.name) {
          fileUpload.completed = file.completed
          found = true
        }
      })

      if (!found) fileUploadList.push(file)

      this.setState({
        fileUploadList: fileUploadList
      }, () => {
        var uploadOnProgress = false

        this.state.fileUploadList.forEach(file => {
          if (file.completed != 100) uploadOnProgress = true
        })

        if (uploadOnProgress != this.state.fileUploadOnProgress) this.setState({ fileUploadOnProgress: uploadOnProgress })
      })
    }
  
  onAddNewFile = (file: FileData) => {
    this.setState({
      files: [...this.state.files, {
        id: file.name,
        name: file.name,
        url: this.props.projectFilesAPIURL + file.url,
        thumbnailUrl: this.props.projectFilesAPIURL + file.thumbUrl
      }]
    });
  };

  onRemoveFile = (fileName: string) => {
      this.setState({
          files: [...this.state.files.filter( file => (file && file.id != fileName))]
      })
  }

  registerManualUpload = (uploadFn: () => void) => {
    if(this.state.manualFileUpload) return
    this.setState({
      manualFileUpload: uploadFn
    })
  }

  render = () => {
    return (
      <>
        
      <FileUploadHandler 
        projectName={this.props.projectName}
        projectFilesAPIUrl={this.props.projectFilesAPIURL}
        headers={this.props.headers}
        progressUpload={{ onProgress: this.onProgress}}
        finishUpload={{ onFinish: this.onAddNewFile }}>

        <input type="text" id="urlToCopy" className="hide" />

          <div style={{height:"100%"}} className={(this.state.fileUploadOnProgress ? "hidden" : "")}>
            <NormalFileUpload manualUpload={this.registerManualUpload}/>
            <FileBrowser files={this.state.files}
              onFileAction={this.actionHandler}
              fileActions={[copyUrlActionButton, deleteFileActionButton, ChonkyActions.UploadFiles]} darkMode>
              <FileToolbar />
              <FileList />
              <FileContextMenu />
            </FileBrowser>
        </div>

        <div className={("rowflex file-container title " + (this.state.fileUploadOnProgress ? "" : "hidden"))}>
          <div className="col name flex5">File</div><div className="col progress">Upload Progress</div>
        </div>
        {this.state.fileUploadOnProgress && this.uploadDisplay.displayFileUpload(this.state.fileUploadList)}
        
      </FileUploadHandler>
      </>
    );
  };
}


interface NormalFileUploadProps{
  manualUpload: (uploadFn: () => void) => void
}

let NormalFileUpload = ({manualUpload}: NormalFileUploadProps) => {
  let uploady = useUploady()
  let onClick = () => {
    uploady.showFileUpload()
  }
  manualUpload(onClick)
  return <></>
}