import JoditEditor from 'jodit-react';
import 'jodit/build/jodit.min.css';
import { useEffect, useMemo, useRef, useState } from 'react';

export const JoditContentEditorModule = (props: {
    fieldName: string, placehoder: string,
    value: string | undefined,
    handleChange: (content: string) => void,
    handleOnFocus?: () => void,
    handleOnBlur?: () => void, message?: string
    aclass?: string
}) => {

    const { aclass, fieldName, placehoder, value, handleChange, handleOnFocus, handleOnBlur, message } = props;
    const editor = useRef(null);
    const [content, setContent] = useState(value ?? '');

    useEffect(() => {
        if (content === value) return;
        setContent(value ?? '')
    }, [value])

    const config = useMemo(() => ({
        inline: true,
        preset: "inline",
        toolbar: false,
        theme: 'default',
        popup: {
            selection: ["bold", "underline", "italic", "brush", "\n", "link", "image", "paragraph", "align", "\n", "ul", "ol", "indent", "outdent", "\n", "table"]
        },
        useInputsPlaceholder: true,
        showPlaceholder: true,
        askBeforePasteHTML: false,
        placeholder: placehoder,
        tabIndex: 1
    }), [])

    return (
        <JoditEditor
            className={aclass ?? ""}
            ref={editor}
            value={content}
            config={config}
            onBlur={(content) => {
                handleOnBlur && handleOnBlur();
            }}
            onChange={newContent => {
                handleChange(newContent)
            }}
        />
    );
}