import { FormikProps } from "formik"
import { StandardAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient"
import { JoditContentEditorModule } from "../../../form-components/JoditContentEditorModule"
import { TextInput } from "../../../form-components/TextInput"
import emailIcon from '../../../img/icon-email.png'
import nameIcon from '../../../img/icon-name.png'
import { HTTPHeader } from "../../../interfaces/HTTPHeader"
import { AuthTokenSessionStorage } from "../../Login/AuthPermission"
import { NewsModel } from "../model/News"
import { CreatableAsyncNewsTagSelectInputHandler, NewsTagResultType } from "./CreatableAsyncNewsTagSelectInputHandler"

export const NewsForm: <T extends NewsModel>(pageTitle: string, props: FormikProps<T>) => JSX.Element = 
    (pageTitle, props) => {
    const { values, errors, touched, handleChange, setFieldValue } = props

    let handleDescriptionChange = (content: string) => {
        setFieldValue("description", content)
    }

    let handleTagChange = (tags?: NewsTagResultType[]) => {
        setFieldValue("tags", JSON.stringify(tags))
        setFieldValue("selectedTags", tags)
    }

    let handleNewTagChange = (tag: NewsTagResultType) => {
        if (!values.tags) {
            setFieldValue("tags", JSON.stringify([tag]))
            handleTagChange([tag])
            return
        }

        var tags: NewsTagResultType[] = [...JSON.parse(values.tags), tag]
        handleTagChange(tags)
    }

    let tagSelector = new CreatableAsyncNewsTagSelectInputHandler("tags", "Tags", new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())), handleTagChange, handleNewTagChange)
    let contentEditorModule = new JoditContentEditorModule()
    
    return (
        <>
            <div className="page-title">{pageTitle} {values.title}</div>
            <div className="rowflex">
                <div className="col">
                    <TextInput icon={nameIcon} placeholder="Title" name="title" value={values.title} onChange={handleChange} message={errors.title} touched={touched.title} />
                    <TextInput icon={emailIcon} placeholder="Link" name="link" value={values.link} onChange={handleChange} message={errors.link} touched={touched.link} />
                    <div className="input-container">
                        {tagSelector.getComponent(values.selectedTags)}
                    </div>
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col">
                    <div className="field-description space">Description</div>
                    {contentEditorModule.get("description", "Content", values.description, handleDescriptionChange)}
                </div>
            </div>
        </>
    )
}