import * as React from 'react';
import { TextInput } from '../../../form-components/TextInput';
import defaultProfile from '../../../img/default-profile.png';
import dobIcon from '../../../img/icon-date.png';
import emailIcon from '../../../img/icon-email.png';
import nameIcon from '../../../img/icon-name.png';
import passwordIcon from '../../../img/icon-password.png';
import phoneIcon from '../../../img/icon-phone.png';
import usernameIcon from '../../../img/icon-user.png';

import { FormikProps } from 'formik';
import { ResultStatus } from '../../../Types/ResultStatus';
import { ImagePath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { OptionInput, OptionValue } from '../../../form-components/OptionInput';
import { SimpleMessage } from '../../../form-components/SimpleMessage';
import bottomOrnament from '../../../img/form-ornament-bottom.png';
import topOrnament from '../../../img/form-ornament-top.png';
import { MemberSingleLoader, MemberSingleResult } from '../MemberSingleLoader';
import { MemberModel } from '../model/Member';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FormikImageUploadFactory } from '../../../FormikHelper/FormikImageUploadFactory';
import { Lang } from '../../../lang/Lang';

export interface MemberEditRouterProtocol{
    back(): void
}

export interface MemberEditProp extends MemberModel{
    dob_date?: Date
    retype_password: string
    photo_base64?: string
    isPhotoChanged: boolean

    global: string
    message?: string
    
    loader: MemberSingleLoader
    router: MemberEditRouterProtocol
    isRequestData: boolean
}

export const MemberEdit: React.FC<FormikProps<MemberEditProp>> = (props: FormikProps<MemberEditProp>) => {
    const {values, errors, touched, handleSubmit, handleChange, setFieldValue, setSubmitting, isSubmitting} = props

    let genderOption: OptionValue[] = [
        {key: "male", value: "Male"},
        {key: "female", value: "Female"}
    ]

    let router: MemberEditRouterProtocol = values.router

    let onBack = () => {
        router.back()
    }

    let photoHandler = new FormikImageUploadFactory(setFieldValue, "photo_url", "photo_base64", "isPhotoChanged", "member-profile-photo", defaultProfile, values.photo_url, values.photo_base64)

    React.useEffect(() => {
        values.loader.load(values.username, (result) => {
            mapData(result)
        })
    }, [])

    let mapData = (result: MemberSingleResult) => {
        setFieldValue("isRequestData", false)

        if (result.status == ResultStatus.fail){
            router.back()
        }

        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if(data[0] == "password" || data[0] == "retype_password") return
            if(data[0] == "photo_url"){
                let photo_url = data[1]
                if(photo_url == "") return
                setFieldValue("photo_base64", ImagePath + photo_url)
                return
            }

            if(data[0] == "dob"){
                if( typeof data[1] == "string"){
                    let d = moment(data[1])
                    if(d.isValid()) setFieldValue("dob_date", d.toDate())
                }
            }
            
            setFieldValue(data[0], data[1])
        })
    }

    let handleDateChange = (date: Date | [Date, Date] | null) => {
        setFieldValue('dob_date', date)
        if(date && date instanceof Date){
            setFieldValue('dob', date.toLocaleDateString())
        }
        else{
            setFieldValue('dob', undefined)
        }
    }

    return (
        <div id="form-container">
            {errors.global && <SimpleMessage title={Lang.mMembers + "MEMBER UPDATE FAILED"} body={errors.global} onClick={() => { setFieldValue("global", null) }} /> }

            { values.message && <div className="message update">{values.message}</div> }

            <div className="top-ornament"><img src={topOrnament}/></div>
            <div className="bottom-ornament"><img src={bottomOrnament}/></div>

            <form onSubmit={handleSubmit}>
            <div className="page-title">Update {Lang.mMembers} {values.username}</div>
            <div className="rowflex">
                <div className="col">
                    {photoHandler.getEditComponent()}
                    <OptionInput name="gender" options={genderOption} value={values.gender} onChange={handleChange} />
                </div>
                <div className="col default-padding-left">
                    <TextInput icon={usernameIcon} placeholder={Lang.memberUsername} name="username" value={values.username} onChange={handleChange} disabled/>
                    <TextInput icon={nameIcon} placeholder={Lang.memberName} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                    <TextInput icon={usernameIcon} placeholder={Lang.memberPosition} name="position" value={values.position} onChange={handleChange} message={errors.position} touched={touched.position} />
                    <TextInput icon={phoneIcon} placeholder={Lang.memberContactNumber} name="phone" value={values.phone} onChange={handleChange} message={errors.phone} touched={touched.phone} />
                    <TextInput icon={emailIcon} placeholder={Lang.memberEmail} name="email" value={values.email} onChange={handleChange} message={errors.email} touched={touched.email} />
                    <div className="input-container">
                        <DatePicker className="text-input input-container default" placeholderText="Date of Birth" selected={values.dob_date} onChange={ (date) => { handleDateChange(date) }} 
                        dateFormat="MMMM d, yyyy"
                        customInput={<TextInput icon={dobIcon} placeholder="Date of Birth" name="dob" value={values.dob} />} />
                    </div>
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col">
                    <TextInput icon={passwordIcon} type="password" placeholder="Password" aclass="default full-width" name="password" value={values.password} onChange={handleChange} message={errors.password} touched={touched.password}/>
                    <TextInput icon={passwordIcon} type="password" placeholder="Retype Password" aclass="default full-width" name="retype_password" value={values.retype_password} onChange={handleChange} message={errors.retype_password} touched={touched.retype_password}/>
                </div>
            </div>
            
            <div className="row default-padding-top">
                <div className="col">
                    <ButtonInput type="button" caption="Back" title="Back" id="cancel" onClick={() => { onBack() }} {...(isSubmitting ? {disabled:"disabled"} : {}) }/>
                    <ButtonInput id="submit"  {...(isSubmitting || values.isRequestData ? {disabled:"disabled", caption: "Updating"} : {caption: "Update"}) } />
                </div>
            </div>
            </form>
        </div>
    )
}