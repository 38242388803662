import React from "react"
import { POBBlockContentDetail, POBBlockContentUpdate } from "../../../../../database_api/api"
import { useGetData } from "../../../../Shared/useGetData"
import { useUpdateData } from "../../../../Shared/useUpdateData"
import { BlockContentDetailResultData } from "../../../BlockContentDetailLoader"
import { BlockContentListResult } from "../../../BlockContentListLoader"
import { BlockContentWithData } from "../../POBBlockContentListComponent"

export const useBlockContentDetail = (props: BlockContentListResult) => {
    const [content, setContent] = React.useState<BlockContentDetailResultData | undefined>(undefined)
    const { get } = useGetData(POBBlockContentDetail + props.type + "/" + props.id, "")
    const { update } = useUpdateData<BlockContentWithData>(POBBlockContentUpdate + props.id)

    const getContent = () => {
        get(undefined, {
            onSuccess(data) {
                setContent(data.data)
            }
        })
    }

    const updateContent = (data: BlockContentWithData) => {
        update(data)
    }

    return { getContent, content, setContent, updateContent }
}