import { ListLoaderControllerProtocol } from '../ListLoaderControllerProtocol';
import { PagingFeatureControlProtocol, PagingFeatureControlData } from './PagingFeatureControlProtocol';
import { LoadingControlProtocol } from '../Search/SearchComponent';
import { ListPagingResult } from './GenericAPIListPagingLoader';

export interface GenericListPagingLoader<DataType>{
    load(page: number, completion: (result: DataType) => void):void
}

export class GenericListController<ListDisplayUpdater, DataType, PagingResult extends ListPagingResult<DataType>, ListPagingLoader extends GenericListPagingLoader<PagingResult>> implements ListLoaderControllerProtocol, LoadingControlProtocol{
    
    private loader: ListPagingLoader
    pagingFeatureControl: PagingFeatureControlProtocol
    listDelegate?: ListDisplayUpdater
    loadingStatusSubscriber: ((status: boolean) => void)[] = []

    onLoad: (clients: DataType[]) => void = (_) => {}

    constructor(loader: ListPagingLoader, pagingFeatureControl: PagingFeatureControlProtocol){
        this.loader = loader
        this.pagingFeatureControl = pagingFeatureControl
    }

    load(page: number){
        this.setLoading(true)
        this.loader.load(page, (result) => {
            this.setLoading(false)
            let pagingData: PagingFeatureControlData = {
                currentPage: result.currentPage,
                totalData: result.totalData,
                totalDataShowed: result.totalDataShowed,
                totalPage: result.totalPage
            }
            
            this.pagingFeatureControl.setData(pagingData)

            if(result.data){
                this.onLoad(result.data)
            }
        })
    }

    register(method: (status: boolean) => void){
        this.loadingStatusSubscriber.push(method)
    }
    setLoading(status: boolean){
        this.loadingStatusSubscriber.forEach( subscriber => subscriber(status) )
    }
}