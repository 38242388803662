import * as React from 'react'
import closeIcon from '../img/close.png'
import { SetFieldValueFunctionType } from './FormikFileUploadFactory'

export class FormikImageUploadFactory{
    private setFieldValue: SetFieldValueFunctionType
    private file_fieldName: string
    private base64_fieldName: string
    private fileChange_fieldName: string
    private file?: File
    private base64?: string

    private className: string
    private defaultImage: any

    constructor(setFieldValue: SetFieldValueFunctionType, file_fieldName: string, base64_fieldName: string, fileChange_fieldName: string, className: string = "", defaultImage: any, file?: File, base64?: string){
        this.setFieldValue = setFieldValue
        this.file_fieldName = file_fieldName
        this.base64_fieldName = base64_fieldName
        this.fileChange_fieldName = fileChange_fieldName

        this.file = file
        this.base64 = base64

        this.className = className
        this.defaultImage = defaultImage
    }

    private fileToBase64 = (file: File, completion: (result: string | undefined) => void) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if(reader.result) completion(reader.result.toString())
            else completion(undefined)
        }
        reader.onerror = (error) => {
            completion(undefined)
        }
    }

    private photoRef = React.createRef<HTMLInputElement>()

    private onPhotoClick = () => {
        this.photoRef.current?.click()
    }

    private onPhotoChangeAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }

        let file = e.target.files[0]

        this.fileToBase64(file, (result) => {
            if(result){
                this.setFieldValue(this.file_fieldName, file)
                this.setFieldValue(this.base64_fieldName, result)
            }
        })
    }

    private onRemovePhotoAdd = () => {
        this.setFieldValue(this.file_fieldName, undefined)
        this.setFieldValue(this.base64_fieldName, undefined)
    }

    private onPhotoChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }
        let file = e.target.files[0]

        this.fileToBase64(file, (result) => {
            if(result){
                this.setFieldValue(this.file_fieldName, file)
                this.setFieldValue(this.base64_fieldName, result)
                this.setFieldValue(this.fileChange_fieldName, true)
            }
        })
    }

    private onRemovePhotoUpdate = () => {
        this.setFieldValue(this.file_fieldName, undefined)
        this.setFieldValue(this.base64_fieldName, undefined)
        this.setFieldValue(this.fileChange_fieldName, true)
    }

    getAddComponent = () => (
        <React.Fragment>
            {this.file && <img className="photo-delete" src={closeIcon} onClick={() => this.onRemovePhotoAdd() } /> }
            <img className={this.className} src={ this.base64 || this.defaultImage} onClick={ () => { this.onPhotoClick() } }/>
            <input type="file" name={this.file_fieldName} className="hidden" accept="image/*" onChange={(e) => { this.onPhotoChangeAdd(e) }} ref={this.photoRef} />
        </React.Fragment>
    )

    getEditComponent = () => (
        <React.Fragment>
            {this.base64 && <img className="photo-delete" src={closeIcon} onClick={() => this.onRemovePhotoUpdate() } /> }
            <img className={this.className} src={ this.base64 || this.defaultImage} onClick={ () => { this.onPhotoClick() } }/>
            <input type="file" name={this.file_fieldName} className="hidden" accept="image/*" onChange={(e) => { this.onPhotoChangeUpdate(e) }} ref={this.photoRef} />
        </React.Fragment>
    )
}
