import { MemberModel } from "../../Member/model/Member"

export enum ActionType {
    List = "list",
    Add = "add",
    Edit = "edit",
    Import = "import",
    Delete = "delete",
    Result = "action_plan_result",
    ResultFile = "action_plan_result_file",
    Login = "login_attempt",
    LoginFailed = "login_failed",
    LoginSuccess = "login_success"
}

export interface LogsModel {
    user?: MemberModel
    moduleType: string
    id?: string
    displayTitle?: string
    actionType?: ActionType
    time: string
    path: string
}