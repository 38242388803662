import { ProjectDownloadPath } from "../../../database_api/api"
import { StandardAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient"
import { HTTPHeader } from "../../../interfaces/HTTPHeader"
import { AuthTokenSessionStorage } from "../../Login/AuthPermission"
import { DownloadActionPlanToDoc } from "../../Project/DownloadActionPlanToDoc"
import { DownloadBackgroundToDoc } from "../../Project/DownloadBackgroundToDoc"
import { DownloadFieldInformationToDoc } from "../../Project/DownloadFieldInformationToDoc"
import { DownloadHistoryToDoc } from "../../Project/DownloadHistoryToDoc"
import { DownloadLatestStatusToDoc } from "../../Project/DownloadLatestStatusToDoc"
import { DownloadProjectToDoc, ProjectType } from "../../Project/DownloadProjectToDoc"

let client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))

let DownloadLatestStatus = (projectName: string, projectId: string) => {
        let downloader = new DownloadLatestStatusToDoc(client, ProjectDownloadPath + "data/")
        let projectDocumentName = projectName + " " + "Latest Status & Analysis"
        downloader.download(projectId, projectDocumentName + " " + downloader.getPostfix(), result => {
            if (result)
                downloader.toWord(projectDocumentName, result, projectDocumentName + " " + downloader.getPostfix() + ".docx")
        })
}

let DownloadActionPlan = (projectName: string, projectId: string) => {
    let downloader = new DownloadActionPlanToDoc(client, ProjectDownloadPath + "data/")
    let projectDocumentName = projectName + " " + "Action Plan"
    downloader.download(projectId, projectDocumentName + " " + downloader.getPostfix(), result => {
        if (result)
            downloader.toWord(projectDocumentName, result, projectDocumentName + " " + downloader.getPostfix() + ".docx")
    })
}

let DownloadFieldInformation = (projectName: string, projectId: string) => {
    let downloader = new DownloadFieldInformationToDoc(client, ProjectDownloadPath + "data/")
    let projectDocumentName = projectName + " " + "Field Information"
    downloader.download(projectId, projectDocumentName + " " + downloader.getPostfix(), result => {
        if (result)
            downloader.toWord(projectDocumentName, result, projectDocumentName + " " + downloader.getPostfix() + ".docx")
    })
}

let DownloadHistory = (projectName: string, projectId: string) => {
    let downloader = new DownloadHistoryToDoc(client, ProjectDownloadPath + "data/")
    let projectDocumentName = projectName + " " + "History"
    downloader.download(projectId, projectDocumentName + " " + downloader.getPostfix(), result => {
        if (result)
            downloader.toWord(projectDocumentName, result, projectDocumentName + " " + downloader.getPostfix() + ".docx")
    })
}

let DownloadProject = (projectName: string, projectId: string, isHeader: boolean) => {
    let downloader = new DownloadProjectToDoc(client, ProjectDownloadPath)
    var projectType = ProjectType.pob
    if (isHeader) {
        projectType = ProjectType.pobHeader
    }
    downloader.download(projectType, projectId, result => {
        if(!result) return

        if (result.data)
            downloader.toWord(projectType, result, projectName + " " + downloader.getPostfix() + ".docx")
    })

    return
}

let DownloadBackground = (projectName: string, projectId: string, isHeader: boolean) => {
    let downloader = new DownloadBackgroundToDoc(client, ProjectDownloadPath)
    var projectType = ProjectType.pob
    if (isHeader) {
        projectType = ProjectType.pobHeader
    }
    downloader.download(projectType, projectId, result => {
        if (result.data)
            downloader.toWord(projectType, result, projectName + " Background.docx")
    })

    return
}

export { DownloadLatestStatus, DownloadActionPlan, DownloadFieldInformation, DownloadProject, DownloadBackground, DownloadHistory }