import * as React from 'react';
import { Route, RouteComponentProps } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from '../components/HeaderV2';
import { Client } from '../modules/Client/ui/Client';
import { ClientPartner } from '../modules/Client/ui/ClientPartner';
import { ProspectiveClient } from '../modules/Client/ui/ProspectiveClient';
import { ClientPIC } from '../modules/ClientPIC/ui/ClientPIC';
import { Customer } from '../modules/Customer/ui/CustomerV2';
import { DatabaseTNIAL } from '../modules/DatabaseTNIAL/ui/DatabaseTNIALV2';
import { DbBackup } from '../modules/DbBackup/ui/DbBackup';
import { DocumentViewer } from '../modules/DocumentViewer';
import { LawRegulation } from '../modules/LawRegulation/ui/LawRegulationV2';
import { Logs } from '../modules/Log/ui/Logs';
import { AuthPermissionSessionStorage } from '../modules/Login/AuthPermission';
import { Logout } from '../modules/Login/Logout';
import { Member } from '../modules/Member/ui/Member';
import { News } from '../modules/News/ui/News';
import { POB } from '../modules/POB/ui/POBV2';
import { POBContent } from '../modules/POBContent/ui/POBContentV2';
import { POBHeader } from '../modules/POBHeader/ui/POBHeaderV2';
import { ProfileBuilder } from '../modules/Profile/builder/ProfileBuilder';
import { DashboardWidget } from './DashboardWidget';
import { RoutePath } from './RoutePath';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalData from '../GlobalData';
import { ActionPlan } from '../modules/ActionPlan/ui/ActionPlan';
import { FieldInformationMoveFiles } from '../modules/FieldInformation/ui/FieldInformationMoveFiles';
import { GoogleCalendarAuth } from '../modules/GoogleCalendarAuth/GoogleCalendarAuth';

const queryClient = new QueryClient();


export const Dashboard = () => {
    const auth = new AuthPermissionSessionStorage()
    const [pageTitle, setPageTitle] = React.useState<string>("Dashboard")

    if (!auth.isLoggedIn()) auth.goToLogin()

    return (
        <GlobalData.Provider value={{ pageTitle, setPageTitle }}>
        <QueryClientProvider client={queryClient}>
        <React.Fragment>
            <Header />

            <ToastContainer position="bottom-center" theme='dark' autoClose={500} hideProgressBar />

            <div className="content">
                <Route path={RoutePath.googleCalendarAuth} component={GoogleCalendarAuth} />
                    
                <Route path={RoutePath.dashboard} component={DashboardWidget} exact={true} />
                <Route path={RoutePath.memberList} component={Member} />
                <Route path={RoutePath.clientList} component={Client} />
                <Route path={RoutePath.prospectiveClientList} component={ProspectiveClient} />
                <Route path={RoutePath.clientPartnerList} component={ClientPartner} />
                <Route path={RoutePath.clientPICList} component={ClientPIC} />
                <Route path={RoutePath.customerList} component={Customer} />
                <Route path={RoutePath.databaseTNIALList} component={DatabaseTNIAL} />
                <Route path={RoutePath.lawRegulationList} component={LawRegulation} />
                <Route path={RoutePath.pobHeaderList} component={POBHeader} />
                <Route path={RoutePath.pobList} component={POB} />
                <Route exact path={RoutePath.pobContentWithHeader} component={POBContent} />
                <Route exact path={RoutePath.pobContent} component={POBContent} />
                <Route path={RoutePath.newsList} component={News} />
                <Route path={RoutePath.log} component={Logs} />
                <Route path={RoutePath.backup} component={DbBackup} />
                <Route path={RoutePath.logout} component={Logout} />

                <Route path={RoutePath.actionPlanList} component={ActionPlan} />

                <Route path={RoutePath.profile} component={(props: RouteComponentProps) => { return new ProfileBuilder(props).get() }} />
                <Route path={RoutePath.viewDocumentParam} component={DocumentViewer} />

                <Route path={'/move-files'} component={FieldInformationMoveFiles} />
            </div>

            <Footer note="Copyright @Bravado Armada 2020" />
        </React.Fragment>
        </QueryClientProvider>
        </GlobalData.Provider>
    )
}

