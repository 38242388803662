import React from 'react'
import { SupportingDocsPath } from '../../database_api/api'
import { HTTPHeader } from '../../interfaces/HTTPHeader'
import { AuthTokenSessionStorage } from '../Login/AuthPermission'
import { FileUploadDnD } from './FileUploadDnD'
import { StandarManageProjectFiles } from './StandarManageProjectFiles'

export interface SupportingDocsProps{
    projectId: string
}

export class SupportingDocsBuilder extends React.Component<SupportingDocsProps>{
    httpHeader = new HTTPHeader(new AuthTokenSessionStorage())

    render = () => (
        <div className="supportingDocs-container">
        <FileUploadDnD
            projectName={this.props.projectId}
            manageProjectFiles={new StandarManageProjectFiles({
                headers: this.httpHeader.formHeader(),
                projectFilesAPIURL: SupportingDocsPath,
                projectName: this.props.projectId
            })}
            projectFilesAPIURL={SupportingDocsPath}
            headers={this.httpHeader.formHeader()}
        />
        </div>)
}