import * as React from 'react';

import closeIcon from '../../../img/close.png';
import dateIcon from '../../../img/icon-date.png';

import { useUploady } from '@rpldy/uploady';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ResultStatus } from '../../../Types/ResultStatus';
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { FieldInformationPath, FieldInformationUpdateFileResultPath, SupportingDocsPath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { JoditContentEditorModule } from '../../../form-components/JoditContentEditorModule';
import { TextInput } from '../../../form-components/TextInput';
import { DateHelper } from '../../../helper/DateHelper';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { RoutePath } from '../../../page/RoutePath';
import { AddActionPlanModuleInterface } from '../../ActionPlan/ActionPlanAddAPI';
import { EditActionPlanModuleInterface } from '../../ActionPlan/ActionPlanEditAPI';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';
import { FileData } from '../../ProjectFiles/FileUploadInterface';
import { APIFieldInformationSingleLoader } from '../FieldInformationSingleLoader';
import { FieldInformationAddAPI } from '../api/FieldInformationAddAPI';
import { FieldInformationEditAPI } from '../api/FieldInformationEditAPI';
import { FieldInformationModel } from '../model/FieldInformation';
import { FieldInformationFileUploadHandler, FileUploadProgress } from '../uploader/FieldInformationFileUploadHandler';
import { ActionPlanFields } from './ActionPlanFields';
import { FileUploadProgressUI } from './FileUploadProgressUI';

export class FieldInformationComponent extends React.Component<FieldInformationComponentProp, FieldInformationComponentState>{
    
    header = new HTTPHeader(new AuthTokenSessionStorage())

    onFileClick = (fileName:String) => {
        window.open(RoutePath.viewDocument + "/" + window.btoa(SupportingDocsPath + fileName))
    }
    
    constructor(props: FieldInformationComponentProp){
        super(props)

        this.state = {
            date_date: props.time ? moment(props.time).toDate() : undefined,
            location: props.location ?? "",
            objective: props.objective ?? "",
            contractors: props.contractors ?? "",
            files: props.files ?? [],
            fileUploadList: [],
            fileUploadOnProgress: false,
            onSavingProgress: false,
            onFocusResult: false,
            ...props
        }

        if (props.id) {
            let api = new APIFieldInformationSingleLoader(FieldInformationPath, new StandardAPIHTTPClient(this.header))
            api.load(props.id, result => {
                if(result.data && result.data.value){
                    this.setState({
                        result: result.data.value.result,
                        files: result.data.value.files
                    })
                }
            })
        }
    }

    doSilentUpdate = (actionPlanId?: string, clearActionPlan?: boolean, done?: () => void) => {
        console.log("DO SILENT UPDATE CALLED")
        console.log("SAVING PROGRESS: " + this.state.onSavingProgress)
        if(this.state.onSavingProgress) return
        if(!this.state.date_date) return

        this.setState({
            onSavingProgress: true
        }, () => {
            console.log("SAVING PROGRESS 2: " + this.state.onSavingProgress)
            this.doSilentUpdateProgress(actionPlanId, clearActionPlan, done)
        })
        
    }

    doSilentUpdateProgress = (actionPlanId?: string, clearActionPlan?: boolean, done?: () => void) => {
        if (!this.state.date_date) return

        if (!this.state.id && this.props.addHandler) {
            this.setState({onSavingProgress: false})
            this.props.addHandler.add({
                id: this.state.id,
                projectId: this.state.projectId,
                time: DateHelper.dateISO8601(this.state.date_date.toLocaleString("en-US")),
                result: this.state.result
            }, result => {
                if (result.status == ResultStatus.fail) {
                    alert(result.message)
                    done && done()
                    return
                }

                this.setState({
                    id: result.data.id
                }, () => { console.log(this.state.id); this.fullSilentUpdate(clearActionPlan ? undefined : this.state.actionPlanId, done) })
            })

            return
        }

        if (actionPlanId && !this.state.actionPlanId) {
            this.setState({
                actionPlanId: actionPlanId
            }, () => {
                this.fullSilentUpdate(clearActionPlan ? undefined : this.state.actionPlanId, done)
            })
            return
        }

        this.fullSilentUpdate(clearActionPlan ? undefined : this.state.actionPlanId, done)
    }

    fullSilentUpdate = (actionPlanId?: string, done?: () => void) => {
        if(!this.state.date_date) return

        this.props.editHandler.update({
            id: this.state.id,
            projectId: this.state.projectId,
            result: this.state.result,
            time: DateHelper.dateISO8601(this.state.date_date.toLocaleString("en-US")),
            actionPlanId: actionPlanId,
        }, (_) => { this.setState({onSavingProgress: false}); done && done() })
    }

    handleDateChange = (date: Date | [Date, Date] | null) => {
        let pDate = date as Date
        if(pDate){
            this.setState({
                date_date: pDate,
                time: pDate.toLocaleString("en-US")
            }, () => {
                this.doSilentUpdate(this.state.actionPlanId)
            })
        }
    }

    resultField = new JoditContentEditorModule()
    handleResultChange = (content: string) => {
        this.setState({
            result: content
        }, () => {
            this.doSilentUpdate(this.state.actionPlanId)
        })
    }

    onProgress = (file: FileUploadProgress) => {
        var fileUploadList = [...this.state.fileUploadList]
        
        if (!this.state.fileUploadOnProgress) fileUploadList = []

        var found = false
        fileUploadList.forEach(fileUpload => {
            if(fileUpload.name === file.name){
                fileUpload.completed = file.completed
                found = true
            }
        })

        if(!found) fileUploadList.push(file)

        this.setState({
            fileUploadList: fileUploadList
        }, () => {
            var uploadOnProgress = false

            this.state.fileUploadList.forEach( file => {
                if(file.completed != 100) uploadOnProgress = true
            })

            if(uploadOnProgress != this.state.fileUploadOnProgress) this.setState({fileUploadOnProgress: uploadOnProgress})
        })
    }

    onFinishUpload = (file: FileData) => {
        this.setState({
            files: [...this.state.files ?? [], {
                filename: file.name,
                date: "",
                fileExt: ""
            }]
        })
    }

    deleteFile = (fileName: string) => {
        if(!window.confirm("Are you sure want to delete " + fileName + "?")) return

        fetch(`${FieldInformationPath}/${this.props.id}/${fileName}`,
            { headers: this.header.header(), method: "delete" }).then(data => data.json())
            .then(data => {
                this.setState({
                    files: this.state.files?.filter( file => file.filename != data.data.file)
                })
            });
    }

    uploadDisplay = new FileUploadProgressUI()

    confirmDeleteFieldInformation = () => {
        if(!this.state.id) return
        if(!window.confirm("Yakin hapus field information ini?")) return
        
        this.props.deleteHandler && this.props.deleteHandler(this.state.id)
    }

    saveAndBack = () => {
        if(this.state.result && this.state.result != ""){
            this.doSilentUpdate(this.state.actionPlanId, undefined, () => {
                this.props.saveAndBackFn && this.props.saveAndBackFn()
            })
            return
        }

        this.props.saveAndBackFn && this.props.saveAndBackFn()
    }

    back = () => {
        this.props.backFn && this.props.backFn()
    }

    render(){
        return (
            <div id="form-container">
            <div className="fieldInformation-container full-border">
                <div className="row fieldInformation-header">
                    <div className="col">
                        <div className="input-container">
                        <DatePicker className="text-input default time" placeholderText="Time" selected={this.state.date_date} onChange={(date: Date) => { this.handleDateChange(date) }}
                            showTimeSelect dateFormat="MMMM d, yyyy H:mm" timeFormat='HH:mm'
                            customInput={<TextInput icon={dateIcon} placeholder="" name="time" value={this.state.time} />} />
                        </div>
                        <div className="fieldInformation-delete" onClick={() => {this.confirmDeleteFieldInformation()}}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                </div>

                <div className="row fieldInformation-description">
                    <ActionPlanFields actionPlanAddHandler={this.props.actionPlanAddHandler} actionPlanEditHandler={this.props.actionPlanEditHandler}
                        doSilentUpdate={this.doSilentUpdate} projectId={this.state.projectId} time={this.state.time} actionPlanId={this.state.actionPlanId}
                        actionPlanName={this.props.actionPlanName} personnelList={this.props.personnelList} 
                        contractors={this.props.contractors} location={this.props.location} objective={this.props.objective}
                    />
                </div>

                { this.state.date_date &&

                <div className="row fieldInformation-description">
                    <div className="fieldInformation-label result">
                        Result
                        {
                            this.props.resultCreatedDate && this.props.resultUpdatedDate &&
                            <div className="fieldInformation-dateResult">
                                <div className="status-text">{ (this.props.resultCreatedDate == this.props.resultUpdatedDate ? "created at" : "updated at") }</div>
                                <div className="value">{DateHelper.dateFormat(this.props.resultUpdatedDate,"MMMM DD, YYYY HH:mm")}</div>
                            </div>
                        }
                    </div>
                        <div className="fieldInformation-objective">
                            { this.resultField.get("result", "Result", this.state.result, (content) => {
                                this.handleResultChange(content)
                                this.setState({ onFocusResult: false })
                            } , () => {
                                this.setState({ onFocusResult: true })
                            }, undefined) }
                        </div>

                    <br />
                    { !this.state.id && 
                        <div>
                            Must fill the Result to make File Attachment available.
                        </div>
                    }
                    { this.state.id &&
                        <div className="fieldInformation-fileResult">
                                <FieldInformationFileUploadHandler id={this.state.id} apiURL={FieldInformationUpdateFileResultPath} 
                                    projectName={this.props.projectId ?? "NOPROJECTID"}
                                    headers={this.header.formHeader()} 
                                    progressUpload={{onProgress: this.onProgress}}
                                    finishUpload={{onFinish: this.onFinishUpload}} >
                                
                                    <div className={("rowflex file-container title " + (this.state.files ? "" : " hidden") )}>
                                        <div className="col name flex5">File</div><div className="col date">Date Upload</div><div className="col delete flex1">Delete</div>
                                    </div>
                                    {
                                        this.state.files && this.state.files.map((val, index) => {
                                            return (
                                            <div className="rowflex file-container center" key={index}>
                                                <div className="col name flex5">
                                                    <a className='clickable' onClick={() => this.onFileClick(val.filename)}>{val.filename}</a>
                                                </div>
                                                <div className="col date">{DateHelper.dateFormat(val.date, "MMMM DD, YYYY")}</div>
                                                    <div className="col delete flex1"><ButtonInput type="button" caption="Delete" title="Delete" onClick={() => this.deleteFile(val.filename) }/></div>
                                            </div>)
                                        })

                                    }

                                    <div className={("rowflex file-container title " + (this.state.fileUploadOnProgress ? "" : "hidden"))}>
                                        <div className="col name flex5">File</div><div className="col progress">Upload Progress</div>
                                    </div>

                                    {this.state.fileUploadOnProgress && this.uploadDisplay.displayFileUpload(this.state.fileUploadList)}

                                    <div className="row dragHere">
                                        <div className="col">
                                            Drag here to upload
                                            <br/>
                                            <NormalFileUpload />
                                        </div>
                                    </div>
                                    
                                </FieldInformationFileUploadHandler>
                                
                        </div>
                    }
                </div>

                }
            
                <div className="clear"></div>
            </div>

            <div className="container-separator-block">
                <div className="separator-block"/>
                <div className="separator-block"/>
                <div className="separator-block"/>
            </div>

            {this.props.enableBackButton && this.state.onFocusResult &&
                <div style={{color: "red", fontWeight: "bold"}}>
                    Tidak dapat "Save & Back" sewaktu fokus pada field result agar tidak mengganggu proses save result.<br/>
                    Tekan mouse pada bagian kosong manapun di halaman ini untuk menyudahi update field result.
                </div>
            }

            {this.props.enableBackButton &&
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <ButtonInput caption="Back" title="Back" onClick={() => this.back() } />
                    <ButtonInput caption="Save & Back"
                        {...this.state.onFocusResult && { disabled: true }}
                        onClick={() => {
                            this.saveAndBack()
                        }} />
                </div>
            }
            </div>
        )
    }
}

export interface ActionPlanFile {
    filename: string
    fileExt: string
    date: string
}

export interface FieldInformationComponentProp extends FieldInformationModel {
    personnelList?: string
    location?: string
    objective?: string
    contractors?: string
    status: string
    actionPlanName?: string

    addHandler?: FieldInformationAddAPI
    editHandler: FieldInformationEditAPI
    actionPlanAddHandler: AddActionPlanModuleInterface
    actionPlanEditHandler: EditActionPlanModuleInterface
    deleteHandler?: (id: string) => void

    enableBackButton?: boolean
    saveAndBackFn?: () => void
    backFn?: () => void
}

export interface FieldInformationComponentState extends FieldInformationModel {
    date_date?: Date

    fileUploadList: FileUploadProgress[]
    fileUploadOnProgress: boolean

    onSavingProgress: boolean
    onFocusResult: boolean
}

let NormalFileUpload = () => {
    let uploady = useUploady()
    let onClick = () => {
        uploady.showFileUpload()
    }
    return <ButtonInput caption="Select File to Upload" title="Select File to Upload" onClick={() => {onClick()}} />
}