import UploadDropZone from "@rpldy/upload-drop-zone"
import Uploady, { useItemErrorListener, useItemFinishListener, useItemProgressListener } from "@rpldy/uploady"
import React from "react"
import { FileUploadFinish } from "../../ProjectFiles/FileUploadInterface"

let UploadStatus: React.FunctionComponent<{ finishUpload: FileUploadFinish, progressUpload: FileUploadProgressUpdater }> = (props) => {
    useItemErrorListener((item) => {
        console.log(item)
    })

    useItemFinishListener((item) => {
        console.log(item)
        if (item.uploadResponse.data.data.file)
            props.finishUpload.onFinish(item.uploadResponse.data.data.file)
    })

    useItemProgressListener((item) => {
        props.progressUpload.onProgress({
            name: item.file.name,
            completed: item.completed
        })
    })

    return <></>
}

export interface FileUploadProgress{
    name: string
    completed: number
}

export interface FileUploadProgressUpdater{
    onProgress(file: FileUploadProgress):void
}

interface FileUploadHandlerProps{
    id?: string
    projectName: string
    apiURL: string
    progressUpload: FileUploadProgressUpdater
    finishUpload: FileUploadFinish
    headers: {[name: string]: string}
}

export let FieldInformationFileUploadHandler: React.FunctionComponent<FileUploadHandlerProps> = (props) => {
    return (
        <div className="dragdropArea">
            <Uploady key={props.id} destination={{
                url: props.apiURL, 
                headers: props.headers
            }}
                multiple
                forceJsonResponse
                sendWithFormData
                autoUpload
                concurrent
                maxConcurrent={20}
                
                params={{
                    "id": props.id,
                    "projectName": props.projectName
                }}
                inputFieldName="file_url"
                method="POST"
            >
                <UploadDropZone className="full-upload">
                    <div className="full-upload">{props.children && props.children}</div>
                </UploadDropZone>
                <UploadStatus key={props.id} finishUpload={props.finishUpload} progressUpload={props.progressUpload} />

            </Uploady>
        </div>
    )
}