import { ResultStatus } from '../../../Types/ResultStatus';
import { APIActionPlanResultSingleLoader } from '../ActionPlanSingleLoader';
import { ReactActionPlanPostResultControllerProtocol } from './ActionPlanPostResultController';


export class ActionPlanPostResultComponentPresenter {
    private setState: (data: any) => void = () => {}
    private controller: ReactActionPlanPostResultControllerProtocol;
    private loader: APIActionPlanResultSingleLoader;

    constructor(loader: APIActionPlanResultSingleLoader, controller: ReactActionPlanPostResultControllerProtocol) {
        this.loader = loader;
        this.controller = controller;
    }

    setUpdateState = (setState: (data: any) => void) => {
        this.setState = setState
    }

    onLoad = (id: string) => {
        this.loader.load(id, result => {
            if (result.status == ResultStatus.fail) {
                if (result.message) {
                    this.setState({
                        message: result.message
                    });
                }
                return;
            }

            if (result.data == undefined)
                return;

            this.setState({
                name: result.data.name,
                textResult: result.data.result,
                resultDate: result.data.resultUpdatedDate
            });
        });
    };

    cancel = () => {
        this.controller.cancel()
    }

    updateResult = (resultContent?: string) => {
        this.controller.update(resultContent ?? "", (result, error) => {
            this.setState({
                message: (!result && error ? error : "Success Update")
            })

            setTimeout(() => {
                this.setState({
                    message: undefined
                })
            }, 5000);
        })
    }

    updateFileResult = (resultContent?: FileList | null) => {
        if(resultContent == undefined || resultContent == null){
            this.setState({
                message: "You must put files to upload."
            })

            setTimeout(() => {
                this.setState({
                    message: undefined
                })
            }, 5000);
            return
        }
        this.controller.updateFile(resultContent, (result, error) => {
            this.setState({
                message: (!result && error ? error : "Success Update"),
                files: undefined
            })

            setTimeout(() => {
                this.setState({
                    message: undefined
                })
            }, 5000);
        })
    }

    handleTextChange = (content: string) => {
        this.setState({
            textResult: content
        });
    };
}
