import { PostAPIHTTPClient, GetAPIHTTPClient, FormPostAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient"
import { ResultStatus } from '../../../Types/ResultStatus';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { APIActionPlanResultSingleLoader } from '../ActionPlanSingleLoader';
import { ActionPlanUpdateResultPath, ActionPlanGetResultPath, FieldInformationUpdateFileResultPath } from '../../../database_api/api';
import { ActionPlanPostResultComponent, ActionPlanResultEnum } from "./ActionPlanPostResultComponent";
import { ActionPlanPostResultComponentPresenter } from "./ActionPlanPostResultComponentPresenter";
import { UploadProgressProtocol } from "../../../database_api/AxiosAPIHTTPClient";

export interface ReactActionPlanPostResultControllerProtocol{
    cancel(): void
    setResultType(type: ActionPlanResultEnum): void
    openResult(id: string): void
    update(data: string, callback: (result: boolean, error?: string) => void): void
    updateFile(files: FileList, callback: (result: boolean, error?: string) => void): void
}

export class ReactActionPlanPostResultController implements ReactActionPlanPostResultControllerProtocol{
    private id: string = ""
    private client: PostAPIHTTPClient & GetAPIHTTPClient
    private fileClient: FormPostAPIHTTPClient<FormData> & UploadProgressProtocol
    private loader: APIActionPlanResultSingleLoader
    private presenter: ActionPlanPostResultComponentPresenter

    constructor(client: PostAPIHTTPClient & GetAPIHTTPClient, fileClient: FormPostAPIHTTPClient<FormData> & UploadProgressProtocol){
        this.client = client
        this.fileClient = fileClient
        this.loader = new APIActionPlanResultSingleLoader(ActionPlanGetResultPath, this.client)
        this.presenter = new ActionPlanPostResultComponentPresenter(this.loader, this)
    }

    openResult = (id: string) => {
        this.id = id
        ReactDOM.render(<ActionPlanPostResultComponent id={id} presenter={this.presenter} uploadProgress={this.fileClient} />, document.getElementById("actionPlan-result"))
    }

    cancel = () => {
        ReactDOM.render(<></>, document.getElementById("actionPlan-result"))
    }

    setResultType(type: ActionPlanResultEnum){}

    update = (data: string, callback: (result: boolean, error?: string) => void) => {
        let post = {
            id: this.id,
            result: data,
            resultCreatedDate: new Date().toLocaleString("en-US")
        }
        this.client.post(ActionPlanUpdateResultPath, JSON.stringify(post), result => {
            if(result.status == ResultStatus.fail && result.message){
                callback(false, result.message)
                return
            }

            callback(true)            
        })
    }

    updateFile(files: FileList, callback: (result: boolean, error?: string) => void): void {
        let data = new FormData()

        data.append("id", this.id)
        for(var i:number = 0; i < files.length; i++){
            let item = files.item(i)
            if(item == null) continue
            data.append("file_url", item)
        }
        
        this.fileClient.postForm(FieldInformationUpdateFileResultPath, data, result => {
            if (result.status == ResultStatus.fail && result.message) {
                callback(false, result.message)
                return
            }

            callback(true)     
        })
    }
}