import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';
import { POBHeaderPath } from '../../../database_api/api';
import { useAddFormData } from '../../Shared/useAddFormData';
import { POBHeaderModel } from '../model/POBHeader';

export const usePOBHeaderAddFormHandler = (initialValues: POBHeaderModel, validationSchema?: ObjectSchema) => { 
    const { add } = useAddFormData(POBHeaderPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let pobHeaderData: POBHeaderModel = {
                id: values.id,
                code: values.code,
                name: values.name,
                fiscalYearFrom: values.fiscalYearFrom,
                fiscalYearTo: values.fiscalYearTo,
                amount: values.amount,
                active: values.active
            }

            let pobHeaderDataForm = new FormData()
            Object.entries(pobHeaderData).forEach( field => {
                if(field[1] == undefined) return
                pobHeaderDataForm.append(field[0], field[1])
            })

            pobHeaderDataForm.append("budgetList", JSON.stringify(values.budgetList))
            if (values.projectManagerId && values.projectManagerId != "0") {
                pobHeaderDataForm.append("projectManagerId", values.projectManagerId)
            }

            if (values.classificationBudgetSelected) {
                pobHeaderDataForm.append("classificationBudget", values.classificationBudgetSelected.value);
            }

            if (values.photo_url) {
                pobHeaderDataForm.append('photo_url', values.photo_url)
            }

            setSubmitting(false)
            add(pobHeaderDataForm)
        }
    })

    return formik
}