import { useEffect, useState } from 'react'
import { LawRegulationYearTagsPath } from '../../../../database_api/api'
import { useURL } from '../../../Search/useURL'
import { useGetData } from '../../../Shared/useGetData'
import { useGetData as useGetDataWithParam } from '../../../Shared/useGetDataWithParam'
import { MonthTagComponent } from './MonthTagComponent'
import { MonthTagProps } from './MonthTagProps'
import { TagProps } from './TagProps'
import { YearTagComponent } from './YearTagComponentV2'

interface RawMonthTagsData {
    ruleDate: string
    tags: string
}

interface tagParamData {
    years?: string[]
    selectedYear?: string
    monthsTagsList?: MonthTagProps[]
    selectedMonth?: string
    selectedTags?: string[]
}

export const YearTagsComponent = () => {
    const { location, params, goTo, replaceTo } = useURL()

    const decryptedParamData: tagParamData | undefined = params.tagParamData ? JSON.parse(atob(params.tagParamData as string)) : undefined

    const [years, setYears] = useState<string[]>(decryptedParamData?.years ?? [])
    const [monthsTagsList, setMonthsTagsList] = useState<MonthTagProps[]>(decryptedParamData?.monthsTagsList ?? [])
    const [selectedMonth, setSelectedMonth] = useState(decryptedParamData?.selectedMonth ?? "")
    const [selectedYear, setSelectedYear] = useState(decryptedParamData?.selectedYear ?? "")
    const [selectedTags, setSelectedTags] = useState<string[]>(decryptedParamData?.selectedTags ?? [])

    const { get: getYear } = useGetData(LawRegulationYearTagsPath + "getYear", "")
    const { get: getMonth } = useGetDataWithParam(LawRegulationYearTagsPath, "")

    useEffect(() => {
        getYear(undefined, {
            onSuccess: (result) => {
                setYears(result.data)
            }
        })
    }, [])

    useEffect(() => {
        if (!selectedYear) { generateAndGoToEncodedParamData(); return }
        if(selectedYear === ""){
            setMonthsTagsList([])
            setSelectedTags([])
            return
        }
        
        if(monthsTagsList.length === 0){
            requestMonthNewsList()
            return
        }

        generateAndGoToEncodedParamData()
    }, [selectedYear])

    useEffect(() => {
        if (!selectedMonth) return
        if(selectedMonth === ""){
            setSelectedTags([])
            return
        }

        generateAndGoToEncodedParamData()
    }, [selectedMonth])

    useEffect(() => {
        generateAndGoToEncodedParamData()
    }, [selectedTags])

    const generateAndGoToEncodedParamData = () => {
        const paramData: tagParamData = {
            ...(years && { years }),
            ...(selectedYear && { selectedYear }),
            ...(monthsTagsList && { monthsTagsList }),
            ...(selectedMonth && { selectedMonth }),
            ...(selectedTags && { selectedTags })
        }

        const encryptedParamData = btoa(JSON.stringify(paramData))

        delete params.page
        delete params.tagParamData
        goTo(location.pathname, { ...params, tagParamData: encryptedParamData })
    }

    const onYearChoosen = (year: string) => {
        if(selectedYear === year) setSelectedYear("")
        else setSelectedYear(year)
    }

    const requestMonthNewsList = () => {
        let from = selectedYear + "-01-01"
        let to = (parseInt(selectedYear) + 1).toString() + "-01-01"

        getMonth(from + "/" + to, {
            onSuccess: (result) => {
                updateMonths(result.data)
            }
        })
    }

    const clearMonths = (callback: () => void) => {
        setMonthsTagsList([])
        callback()
    }

    const updateMonths = (datas: RawMonthTagsData[]) => {
        clearMonths(() => {
            let months: MonthTagProps[] = organizeMonthsetMonthsTagsList(datas)
            setMonthsTagsList(months)
        })
    }

    const organizeMonthsetMonthsTagsList = (datas: RawMonthTagsData[]) => {
        let months: MonthTagProps[] = []
        if (!Array.isArray(datas)) return []
        if (datas.length == 0) return []

        datas.forEach((data: RawMonthTagsData) => {
            let time = new Date(data.ruleDate)
            let month = (time.getMonth() + 1).toString()
            let tagsJSON: TagProps[] = data.tags.length == 0 ? [] : JSON.parse(data.tags)

            let existsMonth = months.filter(cMonth => cMonth.month === month)
            if (existsMonth.length == 0) {
                months.push({
                    month: month,
                    tags: tagsJSON
                })

                return
            }

            tagsJSON.forEach(newTag => {
                let existsTags = existsMonth[0].tags.filter(tag => JSON.stringify(newTag) === JSON.stringify(tag))
                if (existsTags.length == 0) existsMonth[0].tags.push(newTag)
            })
        })

        return months
    }

    const onMonthChoosen = (month: string) => {
        setSelectedMonth((month === selectedMonth) ? "" : month)
    }

    const onTagChoosen = (tagValue: string) => {
        if(!selectedYear) return
        let newSelectedTags = selectedTags.filter( tag => tag !== tagValue )

        if(newSelectedTags.length === selectedTags.length){
            newSelectedTags.push(tagValue)
        }

        setSelectedTags(newSelectedTags)
    }
    
    return (
        <>
            <h2>Tags</h2>
            <ul>
                { years.map( year => (
                    <YearTagComponent key={year} year={year} selectedYear={ selectedYear } onYearChoosen={onYearChoosen}>
                        <ul>
                            {monthsTagsList.map(monthTags => (
                                <MonthTagComponent key={monthTags.month} monthTag={monthTags} selectedMonth={selectedMonth} onMonthChoosen={onMonthChoosen}>
                                    <ul className="tag-list">
                                        {monthTags.tags.map( tag => (
                                            <li className={selectedTags.includes(tag.value) ? "tag-button selected" : "tag-button"} 
                                            onClick={() => { onTagChoosen(tag.value) }}>
                                                <a className={ selectedTags.includes(tag.value) ? "selected" : ""} >
                                                    {tag.label}
                                                </a>
                                            </li>    
                                        ))}
                                    </ul>
                                </MonthTagComponent>
                            ))}
                        </ul>
                    </YearTagComponent>
                )) }
            </ul>
        </>
    )
}