import React from 'react'
import { MonthNewsProps } from './MonthNewsProps'

interface MonthNewsComponentProps {
    monthNews: MonthNewsProps
    selectedMonth: string
    onMonthChoosen: (month: string) => void
}

export let MonthNewsComponent: React.FunctionComponent<MonthNewsComponentProps> = ({ monthNews, selectedMonth, onMonthChoosen, ...props }) => {
    let monthList: { [name: string]: string } = {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December"
    }
    return (
        <li>
            <a onClick={() => { onMonthChoosen(monthNews.month) }}>{monthList[monthNews.month]}</a><br />
            {(monthNews.month == selectedMonth) &&
                props.children && props.children
            }
        </li>
    )
}