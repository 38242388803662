import React from 'react';

import { FieldInformationComponent, FieldInformationComponentProp } from './FieldInformationComponentV3';

import { useParams } from 'react-router';
import { v4 as uuid } from 'uuid';
import { FieldInformationPagePath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from '../../Search/FlexibleSearchComponentV2';
import { QueryCompareTypeEnum, QueryComponent } from '../../Search/QueryFilterProtocol';
import { useURL } from '../../Search/useURL';
import { useDataList } from '../../Shared/useDataList';
import { ResultType, useSelectMemberHelper } from '../../useSelectMemberHelperV2';
import { FieldInformationAddV3 } from './FieldInformationAddV3';

export const FieldInformationList = () => { 
    const { history, location, params } = useURL()

    const itemSize = 8
    const { id: projectId } = useParams<{ id: string }>()

    const { data, totalData, totalPage, isLoading } = useDataList<FieldInformationComponentProp>({
        url: FieldInformationPagePath,
        size: itemSize,
        identifier: "fieldInformations", 
        ignoreParamsAsURL: ["contentId", "contentName", "contentType", "addForm"],
        defaultParamsNotURL: { projectId: projectId }
    })
    const [memberList, setMemberList] = React.useState<ResultType[]>([])

    const queryOptions: QueryComponent[] = [
        {
            compareType: QueryCompareTypeEnum.equal,
            field: "actionPlanName",
            displayField: "Action Item"
        }
    ]

    useSelectMemberHelper((datas) => {
        setMemberList(datas)
    })

    if(memberList.length == 0) return <></>

    return (
    <React.Fragment>
        <div id="top-container">
            <div id="search-container">
                <FlexibleSearchComponent isLoading={isLoading} placeholder="Agenda/Event" queryOptions={queryOptions} />
            </div>   
            <div className="clear"></div>
        </div>

        <div id="add-menu-container">
            <ButtonInput id="add" caption="+" title="Add New" onClick={() => { 
                history.push({
                    pathname: location.pathname,
                    search: location.search + "&addForm=true"
                })
            }} />
        </div>
        
        <div id="fieldInformation-content-container">
        <PaginationComponent
            totalData={totalData}
            totalPage={totalPage}
            isLoading={isLoading}
            itemPerPage={itemSize}
        />

        <div id="fieldInformation-list">
            {
                (data && data.length > 0 && data.map((fieldInformation, index) => {
                    return <FieldInformationComponent id={fieldInformation.id} 
                        time={fieldInformation.time}
                        projectId={fieldInformation.projectId}
                        location={fieldInformation.location}
                        objective={fieldInformation.objective}
                        personnelList={fieldInformation.personnelList}
                        contractors={fieldInformation.contractors}
                        picIds={fieldInformation.picIds}
                        result={fieldInformation.result}
                        status={fieldInformation.status}
                        actionPlanId={fieldInformation.actionPlanId}
                        actionPlanName={fieldInformation.actionPlanName}
                        actionPlanTime={fieldInformation.actionPlanTime}
                        resultCreatedDate={fieldInformation.resultCreatedDate}
                        resultUpdatedDate={fieldInformation.resultUpdatedDate}
                        files={fieldInformation.files}
                        memberList={memberList}
                        key={uuid()} />
                }))
                || 
                <div className="message">No fieldInformation registered</div>
            }
        </div>

        <PaginationComponent
            totalData={totalData}
            totalPage={totalPage}
            isLoading={isLoading}
            itemPerPage={itemSize}
        />
        </div>
        <div id="fieldInformation-result"></div>
        { params.addForm && 
            <div id="field-information-add">
                <FieldInformationAddV3 projectId={projectId} />
            </div>
        }
        
    </React.Fragment>
    )
}