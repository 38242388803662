import { useFormik } from "formik";
import { ObjectSchema } from "yup";
import { ClientPICPath } from "../../../database_api/api";
import { useUpdateFormData } from "../../Shared/useUpdateFormData";
import { ClientPICModel } from "../model/ClientPIC";

export const useClientPICEditFormHandler = (initialValues: ClientPICModel, validationSchema: ObjectSchema) => {
    const { update } = useUpdateFormData(ClientPICPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let clientPICData: ClientPICModel = {
                clientId: values.clientId,
                id: values.id,
                name: values.name,
                phone: values.phone,
                email: values.email,
                position: values.position,
                active: values.active,
                noted: values.noted
            }

            let clientPICDataForm = new FormData()
            Object.entries(clientPICData).forEach(field => {
                if (field[1]) clientPICDataForm.append(field[0], field[1])
            })

            if (values.photo_url) clientPICDataForm.append('photo_url', values.photo_url)

            setSubmitting(false)
            update(clientPICDataForm)
        }
    })

    return formik
}