import * as React from 'react';

interface DragIdProtocol{
    id: string
}

export class ReactDragAndDrop<ContentType extends DragIdProtocol> {
    draggableElementClass = "draggable";
    draggingElementClass = "dragging";
    idAttribute = "data-id";

    selectedIndex?: number = undefined;
    targetIndex?: number = undefined;

    contentList: ContentType[];

    constructor(contentList: ContentType[]) {
        this.contentList = contentList;
    }

    onStartDrag = (event: React.DragEvent<HTMLDivElement>) => {
        this.setElementDraggingStatus(event.currentTarget, true);
        this.setAndGetDragSelectedIndex(event.currentTarget);
    };

    onDragged = (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (event.clientY < 90) {
            window.scrollBy(0, -10);
        }
        this.updateElementDraggingLocation(event);
        this.getTargetDroppedIndex();
    };

    onEndDrag = (event: React.DragEvent<HTMLDivElement>, callback: (newContents?: ContentType[]) => void) => {
        this.setElementDraggingStatus(event.currentTarget, false);
        event.preventDefault();
        callback(this.getUpdatedContents());
    };

    onEndDragContentToPosition = (event: React.DragEvent<HTMLDivElement>, callback: (selectedBlockId?: string | null, targetIndex?: number) => void) => {
        let selectedBlockId = event.currentTarget.getAttribute(this.idAttribute);

        this.setElementDraggingStatus(event.currentTarget, false);
        event.preventDefault();
        callback(selectedBlockId, this.targetIndex);
    };

    private getTargetDroppedIndex = () => {
        let allBlockComponents = Array.from(document.querySelectorAll("." + this.draggableElementClass));

        allBlockComponents.forEach((block, index) => {
            if (block.classList.contains(this.draggingElementClass)) {
                this.targetIndex = index;
            }
        });
    };


    private updateElementDraggingLocation = (event: React.DragEvent<HTMLDivElement>) => {
        let currentElement = document.querySelector("." + this.draggingElementClass);
        if (!currentElement)
            return;

        let droppedElement = this.getDraggingElementLocation(event.clientY);

        if (droppedElement) {
            event.currentTarget.insertBefore(currentElement, droppedElement);
        }
        else {
            event.currentTarget.appendChild(currentElement);
        }
    };


    private getDraggingElementLocation = (y: number): Element | undefined => {

        let availableBlockDropComponents = Array.from(document.querySelectorAll("." + this.draggableElementClass + ":not(." + this.draggingElementClass + ")"));
        let element = availableBlockDropComponents.reduce<{ element: Element | undefined; offset: number; }>((closestBlock, block) => {
            let blockArea = block.getBoundingClientRect();
            let offset = y - blockArea.top - blockArea.height / 2;

            if (offset < 0 && offset > closestBlock.offset) {
                return { element: block, offset: offset };
            }
            else {
                return closestBlock;
            }
        }, { element: undefined, offset: Number.NEGATIVE_INFINITY });

        return element.element;
    };


    private setElementDraggingStatus(element: EventTarget & HTMLDivElement, status: boolean) {
        if (status) {
            element.classList.add(this.draggingElementClass);
        }
        else {
            element.classList.remove(this.draggingElementClass);
        }
    }


    private setAndGetDragSelectedIndex = (element: EventTarget & HTMLDivElement) => {
        let selectedBlockId = element.getAttribute(this.idAttribute);

        this.contentList.forEach((content, index) => {
            if (content.id == selectedBlockId)
                this.selectedIndex = index;
        });
    };


    private getUpdatedContents: () => ContentType[] | undefined = () => {
        if (this.selectedIndex == undefined || this.targetIndex == undefined)
            return;

        let newContents = [...this.contentList];
        let tempBlock = this.contentList[this.selectedIndex];

        newContents.splice(this.selectedIndex, 1);
        newContents.splice(this.targetIndex, 0, tempBlock);

        this.selectedIndex = undefined;
        this.targetIndex = undefined;

        return newContents;
    };
}
