
import { Lang } from '../../../lang/Lang';
import { LangClientPartner } from '../../../lang/LangClientPartner';
import { AddFormTemplate } from '../../Shared/template/AddFormTemplate';
import { useClientAddFormHandler } from '../formHandler/useClientAddFormHandler';
import { ClientModel, ClientVariant } from '../model/Client';
import { errorValidationSchema } from '../validation/errorValidationSchema';
import { ClientForm, FormMode } from './ClientFormV3';

interface ClientAddProps{
    variant: ClientVariant
    prospective?: boolean
}

export const ClientAdd = ({variant, prospective}: ClientAddProps) => {
    const formik = useClientAddFormHandler({
        name: "", active: "1", prospectiveStatus: "0", variant,
        prospective
    }, errorValidationSchema)
    const { values } = formik

    return (
        <AddFormTemplate<ClientModel> {...formik}>
            { ClientForm(FormMode.ADD, "Add New", values.isClientPartner ? LangClientPartner : Lang, formik, values.prospective) }
        </AddFormTemplate>            
    )
}