import * as React from 'react';
import { UploadProgressProtocol } from '../../../database_api/AxiosAPIHTTPClient';
import { ButtonInput } from "../../../form-components/ButtonInput";
import { JoditContentEditorModule } from '../../../form-components/JoditContentEditorModule';
import { UploadProgressBarComponent } from '../../../form-components/UploadProgressBarComponent';
import { DateHelper } from '../../../helper/DateHelper';
import { ActionPlanPostResultComponentPresenter } from './ActionPlanPostResultComponentPresenter';

export interface ActionPlanPostResultComponentProp{
    id: string
    presenter: ActionPlanPostResultComponentPresenter
    uploadProgress?: UploadProgressProtocol
}

export interface ActionPlanPostResultComponentState{
    resultType: ActionPlanResultEnum
    name?: string
    textResult?: string
    resultDate?: string
    message?: string
    files?: FileList | null
}

export enum ActionPlanResultEnum{
    text,
    file
}

export class ActionPlanPostResultComponent extends React.Component<ActionPlanPostResultComponentProp, ActionPlanPostResultComponentState> {
    constructor(props: ActionPlanPostResultComponentProp) {
        super(props);

        this.state = {
            resultType: ActionPlanResultEnum.text
        };
    }

    componentDidMount = () => {
        this.props.presenter.setUpdateState(this.updateState)
        this.props.presenter.onLoad(this.props.id)
    };

    updateState = (data: any) => {
        this.setState(data)
    }

    textResultEditor = new JoditContentEditorModule();

    changeResultTab = (type: ActionPlanResultEnum) => {
        this.setState({resultType: type})
    }

    fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({files: event.target.files})
    }
    
    getFileToUploadList = (filelist?: FileList | null) => {
        if(filelist == null) return ""

        let fileListHtml: JSX.Element[] = []
        for(var i: number = 0; i < filelist.length; i++){
            fileListHtml.push(<div className="file-to-upload" key={i}>{filelist.item(i)?.name}</div>)
        }

        return fileListHtml
    }

    render() {
        return (
            <div className="actionPlan-result">
                <div className="background" />
                <div id="form-container">
                    <div id="header">
                        <div id="title">
                            {this.state.name ?? "-"}
                        </div>
                        <div id="date">
                            {this.state.resultDate ? DateHelper.dateFormat(this.state.resultDate, "MMMM DD, YYYY HH:mm") : "-"}
                        </div>
                    </div>

                    <div id="actionPlan-resultType">
                        <div className="text" onClick={() => { this.changeResultTab(ActionPlanResultEnum.text) }}>Text</div>
                        <div className="file" onClick={() => { this.changeResultTab(ActionPlanResultEnum.file) }}>Files</div>
                    </div>

                    {this.state.message && <div className="message">{this.state.message}</div>}

                    <div id="actionPlan-formContent">
                        <div className={"text" + (this.state.resultType == ActionPlanResultEnum.text ? "" : " hidden")}>
                            {this.textResultEditor.get("textResult", "Type result...", this.state.textResult ?? "", this.props.presenter.handleTextChange)}

                            <div id="actionPlan-button" className="default-margin-top">
                                <ButtonInput type="button" caption="Cancel" title="Cancel" id="cancel_actionplanResult_update" onClick={() => this.props.presenter.cancel()} />
                                <ButtonInput type="button" caption="Update Result" title="Update Result" onClick={() => { this.props.presenter.updateResult(this.state.textResult) }} />
                            </div>
                        </div>

                        <div className={"file" + (this.state.resultType == ActionPlanResultEnum.file ? "" : " hidden")}>
                            <input type="file" name="file_url" className="default-margin-top" multiple onChange={this.fileChange}/>

                            {this.props.uploadProgress && <UploadProgressBarComponent uploadProgress={this.props.uploadProgress}/>}

                            <div className="default-margin-top" />
                            { this.getFileToUploadList(this.state.files) }

                            <div id="actionPlan-file-button" className="default-margin-top">
                                <ButtonInput type="button" caption="Update Result" title="Update Result" onClick={() => { this.props.presenter.updateFileResult(this.state.files) }} />
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        );
    }
}