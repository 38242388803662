import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router';
import { ClientPICPath, ImagePath } from '../../../database_api/api';
import { Lang } from '../../../lang/Lang';
import { ResultStatus } from '../../../Types/ResultStatus';
import { FormMode } from '../../Client/ui/ClientFormV3';
import { EditFormTemplate } from '../../Shared/template/EditFormTemplate';
import { useItem } from '../../Shared/useItem';
import { ClientPICSingleResult } from '../ClientPICSingleResult';
import { useClientPICEditFormHandler } from '../formHandler/useClientPICEditFormHandler';
import { ClientPICModel } from '../model/ClientPIC';
import { errorValidationSchema } from '../validation/errorValidationSchema';
import { ClientPICForm } from './ClientPICForm';

export const ClientPICEdit = () => {
    const params = useParams<{ id: string }>()
    const formik = useClientPICEditFormHandler({
        id: params.id
    }, errorValidationSchema)

    const {setFieldValue} = formik
    const history = useHistory()
    const { get, data, isLoading } = useItem(ClientPICPath, params.id, "clientPIC")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    let mapData = (result: ClientPICSingleResult) => {
        setFieldValue("isRequestData", false)

        if (result.status == ResultStatus.fail){
            history.goBack()
        }
        if (result.data == undefined) return

        var clientName = ""
        var clientId = ""
        Object.entries(result.data.value).forEach(data => {
            if(data[0] == "photo_url"){
                let photo_url = data[1]
                if(photo_url == "") return
                setFieldValue("photo_base64", ImagePath + photo_url)
                return
            }

            if (data[0] == "clientName" && typeof data[1] == "string") {
                clientName = data[1]
            }
            if (data[0] == "clientId" && typeof data[1] == "string") {
                clientId = data[1]
            }

            setFieldValue(data[0], data[1])
        })

        if(clientId != ""){
            setFieldValue("clientSelected", {
                label: clientName,
                value: clientId
            })
        }
    }

    if(isLoading) return (<div>Loading...</div>)

    return (
        <EditFormTemplate<ClientPICModel> {...formik}>
            { ClientPICForm(FormMode.EDIT, "Update", Lang, formik )}
        </EditFormTemplate>
    )
}