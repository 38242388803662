import { GetAPIHTTPClient } from "../../database_api/StandardAPIHTTPClient";
import { BudgetModel, POBHeaderModel } from "../POBHeader/model/POBHeader";
import { POBModel } from "../POB/model/POB";
import { ResultStatus } from "../../Types/ResultStatus"
import { DateHelper } from "../../helper/DateHelper";
import { asBlob } from "html-docx-js-typescript"
import { DownloadStatus } from "./DownloadStatus";
import { LatestStatusDownloadData } from "./LatestStatusDownloadData";
import { saveAs } from "file-saver";

export enum ProjectType {
    pob = "pob",
    pobHeader = "pobHeader"
}

interface ProjectHTML {
    contentHtml: {
        contentId: string,
        contentName: string,
        html: string
    }[],
    latestStatuses: LatestStatusDownloadData[]
}

interface ProjectManagerExtra{
    projectManagerName?: string
}

interface POBExtra {
    projectHeaderName?: string
    customerName?: string
}

interface ProjectDownloadPOBHeader extends POBHeaderModel, ProjectManagerExtra, ProjectHTML{}

interface ProjectDownloadPOB extends POBModel, ProjectManagerExtra, POBExtra, ProjectHTML{}

export interface DownloadProjectResult {
    status: ResultStatus
    message?: string
    data?: {
        value: ProjectDownloadPOBHeader | ProjectDownloadPOB
    }
}

export interface DownloadProjectToDocProtocol{
    download(type: ProjectType, projectId: string, result: (result: DownloadProjectResult) => void): void
    toWord(type: ProjectType, project: DownloadProjectResult, fileName: string): void
}

export class DownloadBackgroundToDoc implements DownloadProjectToDocProtocol{
    status = DownloadStatus.idle;
    client: GetAPIHTTPClient;
    basePath: string;

    constructor(client: GetAPIHTTPClient, basePath: string) {
        this.client = client;
        this.basePath = basePath;
    }

    download(type: ProjectType, projectId: string, result: (result: DownloadProjectResult) => void) {
        this.status = DownloadStatus.request;
        this.client.get(this.basePath + "data/background/" + type + "/" + projectId, clientResult => {
            this.status = DownloadStatus.idle;

            if (clientResult.message && clientResult.message == "13") {
                clientResult.message = `Project ${projectId} is not registered`;
            }
            result(clientResult);
        });
    }

    toWord(type: ProjectType, project: DownloadProjectResult, fileName: string) {
        if (!project.data || !project.data.value.id || !project.data.value.name)
            return "";
        
        
        let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            "<head><meta charset='utf-8'><title>" + project.data.value.name + "</title>" +
            "</head>" +
            "<body>";

        let htmlToPrint = this.useTemplate(type, project);
        let footer = "</body></html>";
        let htmlSource = header + htmlToPrint + footer;
        let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlSource);
        
        asBlob(htmlToPrint).then( data => {
            saveAs(data as Blob, fileName)
        })

        return source;
    }

    private useTemplate(type: ProjectType, project: DownloadProjectResult) {
        if (!project.data)
            return "";
        let temp: any = project.data.value;

        var html = `<h1>Project ${project.data.value.name}</h1>`;

        project.data.value.contentHtml.forEach(contentHtmlData => {
            if(contentHtmlData.contentName != "Background") return
            if(contentHtmlData.html){
                html += `<h2>${contentHtmlData.contentName}</h2>${contentHtmlData.html}`;
            }
        });

        return html;
    }
}
