import moment from "moment"
import { useEffect, useState } from "react"
import { ResultStatus } from "../../../Types/ResultStatus"
import { FieldInformationPath, SupportingDocsPath } from "../../../database_api/api"
import { JoditContentEditorModule } from "../../../form-components/JoditContentEditorModule"
import { DateHelper } from "../../../helper/DateHelper"
import { HTTPHeader } from "../../../interfaces/HTTPHeader"
import { RoutePath } from "../../../page/RoutePath"
import { ActionPlanModel } from "../../ActionPlan/model/ActionPlan"
import { AuthTokenSessionStorage } from "../../Login/AuthPermission"
import { FileData } from "../../ProjectFiles/FileUploadInterface"
import { useURL } from "../../Search/useURL"
import { useAddData } from "../../Shared/useAddData"
import { useDeleteData } from "../../Shared/useDeleteData"
import { useItem } from "../../Shared/useItem"
import { useUpdateData } from "../../Shared/useUpdateData"
import { FieldInformationModel } from "../model/FieldInformation"
import { FileUploadProgress } from "../uploader/FieldInformationFileUploadHandler"
import { FieldInformationComponentProp } from "./FieldInformationComponentV3"
import { FileUploadProgressUI } from "./FileUploadProgressUI"

export const useFieldInformationComponent = (props: FieldInformationComponentProp) => {
    const { get, data } = useItem(FieldInformationPath, props.id, "result")
    const { add } = useAddData(FieldInformationPath, false)
    const { update } = useUpdateData(FieldInformationPath)
    const { remove } = useDeleteData(FieldInformationPath + props.id)

    const { history, params } = useURL()

    const header = new HTTPHeader(new AuthTokenSessionStorage())
    console.log("RAW TIME: ", props.time)
    const [date_date, setDate_Date] = useState(props.time && !params.addForm ? moment(props.time).toDate() : undefined)
    const [files, setFiles] = useState(props.files ?? [])
    const [fileUploadList, setFileUploadList] = useState<FileUploadProgress[]>([])
    const [fileUploadOnProgress, setFileUploadOnProgress] = useState(false)
    const [onFocusResult, setOnFocusResult] = useState(false)
    const [fieldInformationData, setFieldInformationData] = useState<FieldInformationModel>({...props})
    const [isDeleted, setIsDeleted] = useState(false)

    const [actionPlanData, setActionPlanData] = useState<ActionPlanModel>({
        id: props.actionPlanId,
        name: props.actionPlanName,
        personnelList: props.personnelList,
        contractors: props.contractors,
        location: props.location,
        picIds: props.picIds,
        objective: props.objective,
        projectId: props.projectId,
        date: props.actionPlanTime
    })

    const onFileClick = (fileName: String) => {
        window.open(RoutePath.viewDocument + "/" + window.btoa(SupportingDocsPath + "getfile/" + props.projectId + "/" + fileName))
    }

    useEffect(() => {
        if(!props.id) return
        get()
    }, [props.id])

    useEffect(() => {
        if (!data) return
        if (!data.data) return
        if (!data.data.value) return

        console.log("Field Information Data: ", data.data.value)
        
        setFieldInformationData({
            ...fieldInformationData, 
            ...data.data.value
        })

        if (data.data.value.time)
            setDate_Date(moment(data.data.value.time).toDate())

        if (data.data.value.files)
            setFiles([...data.data.value.files])
    }, [data])

    useEffect(() => {
        console.log("action plan data", actionPlanData)
    }, [actionPlanData])

    const doManualUpdateV2 = (data: { content?: string, actionPlanId?: string, noRefresh?: boolean }) => {
        if (!date_date) return
        console.log("manual update run")
        console.log("content", data.content)
        
        const { noRefresh, content, actionPlanId } = data
        const resultActionPlanId = actionPlanId ?? fieldInformationData.actionPlanId
        const resultContent = content ?? fieldInformationData.result
        let id = fieldInformationData.id

        if (!fieldInformationData.id) {
            const newFieldInformation = {
                projectId: props.projectId,
                time: DateHelper.dateISO8601(date_date.toLocaleString("en-US")),
                result: resultContent,
                actionPlanId: resultActionPlanId,
            }

            add(newFieldInformation,
                {
                    onSuccess(result) {
                        if (result.status == ResultStatus.fail) {
                            alert(result.message)
                            return
                        }
                    
                        id = result.data.id

                        if(!noRefresh)
                            setFieldInformationData(prevData2 => {
                                return {
                                    ...prevData2,
                                    id,
                                    result: resultContent,
                                    actionPlanId: resultActionPlanId
                                }
                                
                            })
                    }
                }
            )
        }
        else{
            console.log("Update")
            console.log("Previous result: ", fieldInformationData.result)
            console.log("New result: ", resultContent)
            if(fieldInformationData.result === resultContent &&
                fieldInformationData.actionPlanId === resultActionPlanId &&
                fieldInformationData.time === DateHelper.dateISO8601(date_date.toLocaleString("en-US"))
                ) return

                
            update({
                id,
                projectId: props.projectId,
                result: resultContent,
                time: DateHelper.dateISO8601(date_date.toLocaleString("en-US")),
                actionPlanId: resultActionPlanId,
            }, {
                onSuccess(result) {
                    if (result.status == ResultStatus.fail) {
                        alert(result.message)
                        return
                    }

                    console.log(result)

                    if(!noRefresh)
                        setFieldInformationData(prevData => {
                            return {
                                ...prevData,
                                id,
                                result: resultContent,
                                actionPlanId: resultActionPlanId,
                                time: DateHelper.dateISO8601(date_date.toLocaleString("en-US"))
                            }
                        })
                }
            })
        }
    }

    const handleDateChange = (date: Date | [Date, Date] | null) => {
        console.log("date change", date)
        let pDate = date as Date
        if (pDate) {
            setDate_Date(pDate)
            // setFieldInformationData({
            //     ...fieldInformationData,
            //     time: pDate.toLocaleString("en-US")
            // })
        }
    }

    useEffect(() => {
        // if(!fieldInformationData.id) return
        console.log("date date", date_date)
        if(date_date == undefined){
            console.log("date date IS undefined")
            return
        }
        if(params.addForm && date_date){
            console.log("triggered")
            doManualUpdateV2({content: ""})
        }
    }, [date_date])

    const resultField = new JoditContentEditorModule()
    const handleResultChange = (content: string) => {
        doManualUpdateV2({ content })
    }

    const onProgress = (file: FileUploadProgress) => {
        var fileUploadListProgress = [...fileUploadList]

        if (!fileUploadOnProgress) fileUploadListProgress = []

        var found = false
        fileUploadListProgress.forEach(fileUpload => {
            if (fileUpload.name === file.name) {
                fileUpload.completed = file.completed
                found = true
            }
        })

        if (!found) fileUploadListProgress.push(file)


        setFileUploadList(fileUploadListProgress)
        var uploadOnProgress = false

        fileUploadListProgress.forEach(file => {
            if (file.completed != 100) uploadOnProgress = true
        })

        if (uploadOnProgress != fileUploadOnProgress) setFileUploadOnProgress(uploadOnProgress)
    }

    const onFinishUpload = (file: FileData) => {
        setFiles([
            ...files ?? [],
            {
                filename: file.name,
                date: "",
                fileExt: ""
            }
        ])
    }

    const deleteFile = (fileName: string) => {
        if (!window.confirm("Are you sure want to delete " + fileName + "?")) return

        fetch(`${FieldInformationPath}/${props.id}/${fileName}`,
            { headers: header.header(), method: "delete" }).then(data => data.json())
            .then(data => {
                setFiles(files?.filter(file => file.filename != data.data.file))
            });
    }

    const uploadDisplay = new FileUploadProgressUI()

    const confirmDeleteFieldInformation = () => {
        let id = fieldInformationData.id
        if (!id) return
        if (!window.confirm("Yakin hapus field information ini?")) return

        remove(undefined, {
            onSuccess(result) {
                if (result.status == ResultStatus.fail) {
                    alert(result.message)
                    return
                }

                setIsDeleted(true)
            }
        })
    }

    const saveAndBack = () => {
        history.goBack()
    }

    const back = () => {
        history.goBack()
    }

    const setActionPlanId = (actionPlanId?: string, actionPlanData?: ActionPlanModel) => {
        console.log("update action plan id", actionPlanId)
        console.log("update action plan data", actionPlanData)
        if(actionPlanData && !actionPlanData.id) return

        setActionPlanData(actionPlanData ?? {})

        console.log(props.id)
        if(fieldInformationData.id)
            doManualUpdateV2({ actionPlanId })
    }

    return {
        fieldInformationData,
        isDeleted,
        date_date,
        handleDateChange,
        resultField,
        handleResultChange,
        onProgress,
        onFinishUpload,
        deleteFile,
        uploadDisplay,
        fileUploadList,
        fileUploadOnProgress,
        confirmDeleteFieldInformation,
        saveAndBack,
        back,
        setOnFocusResult,
        header,
        files,
        onFileClick,
        onFocusResult,
        setActionPlanId,
        actionPlanData
    }

}