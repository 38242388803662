import { defineFileAction, GenericFileActionHandler, ChonkyActionUnion } from "chonky"

export let copyUrlActionButton = defineFileAction({
    id: 'copy_url',
    requiresSelection: true,
    button: {
        name: "Copy URL",
        contextMenu: true,
        toolbar: true
    }
} as const)

export let deleteFileActionButton = defineFileAction({
    id: 'do_delete_file',
    requiresSelection: true,
    button: {
        name: "Delete File",
        contextMenu: true
    }
} as const)

export type AllAction = typeof copyUrlActionButton | typeof deleteFileActionButton
export type ActionHandler = GenericFileActionHandler<ChonkyActionUnion | AllAction>