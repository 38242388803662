
import nameIcon from '../../../img/icon-name.png';

import parse from 'html-react-parser';
import { useHistory } from 'react-router';
import { EditButton } from '../../../components/EditButton';
import { FilePath } from '../../../database_api/api';
import { TextInput } from '../../../form-components/TextInput';
import { DateHelper } from '../../../helper/DateHelper';
import { RoutePath } from '../../../page/RoutePath';
import { LawRegulationModel } from '../model/LawRegulationV2';
import { TagResultType } from './CreatableAsyncTagSelectInputHandler';

export const LawRegulationComponent = (props: LawRegulationModel) => {
    const history = useHistory()
    
    const onFileClick = () => {
        window.open(RoutePath.viewDocument + "/" + window.btoa(FilePath + props.file_url))
    }

    let tags: JSX.Element[] = []

    if(props.tags){
        try {
            let tagList: TagResultType[] = JSON.parse(props.tags)
            tagList.forEach( tag => {
                tags.push(<div className="tagItem">{tag.label}</div>)
            })
        } catch (error) {
            console.log("Tag List has wrong format")
        }
    }

    return (
        <div className="lawRegulation-container full-border">
            <div className="row lawRegulation-header">
                <div className="ruleType">{props.name}</div>
                <div className="ruleDate">{ !props.ruleDate ? "-" : DateHelper.dateFormat(props.ruleDate, "MMMM DD, YYYY")}</div>
            </div>
            
            <div className="row lawRegulation-description">
                <div className="sun-editor-content" suppressContentEditableWarning>{(props.description != "" && props.description != undefined) ? parse(props.description) : "-" }</div>
                
                {props.status != "" && props.status != undefined &&
                <div className="default-margin-top">
                    <div className="field-description">Status</div>
                    <div className="sun-editor-content" suppressContentEditableWarning>{(props.status != "" && props.status != undefined) ? parse(props.status) : "-"}</div>
                </div>
                }

                {props.file_url && <TextInput icon={nameIcon} placeholder="" name="file_description" value={props.file_url || ""} readOnly onClick={ () => { onFileClick() } } />}

                <div className="lawRegulation-tag-list">
                    {tags}
                </div>
                <div className="lawRegulation-options">
                    <EditButton basePath={RoutePath.lawRegulationList} code={props.code} />
                </div>
                <div className="clear"></div>
            </div>
        </div>
    )
}