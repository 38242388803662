import * as React from 'react';
import * as H from "history"
import { MemberEditWithFormHandler } from '../formHandler/MemberEditWithFormHandler';
import { RouteComponentProps } from 'react-router-dom';
import { MemberEditRouterProtocol } from '../ui/MemberEdit';
import { MemberSingleLoader, APIMemberSingleLoader } from '../MemberSingleLoader';
import { MemberPath } from '../../../database_api/api';
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { MemberEditAPI } from '../MemberEditAPI';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';

export type MemberEditParameterRoute = RouteComponentProps<{username: string}>

export class MemberEditRouter implements MemberEditRouterProtocol{
    basePath: string
    history: H.History<unknown>
    constructor(basePath: string, history: H.History<unknown>){
        this.basePath = basePath
        this.history = history
    }

    back(){
        this.history.push(this.basePath)
    }
}

export class MemberEditBuilder{
    router: MemberEditRouterProtocol
    parameterRoute: MemberEditParameterRoute
    client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))
    loader: MemberSingleLoader = new APIMemberSingleLoader(MemberPath, this.client)
    memberHandler = new MemberEditAPI(MemberPath, this.client)

    constructor(router: MemberEditRouterProtocol, parameterRoute: MemberEditParameterRoute){
        this.router = router
        this.parameterRoute = parameterRoute
    }

    get(){
        return <MemberEditWithFormHandler router={ this.router } loader={this.loader} handler={this.memberHandler} {...this.parameterRoute} />
    }
}