import queryString from "query-string"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ActionPlanGoogleCalendarSetTokenPath } from "../../database_api/api"
import { AddResult } from "../GenericAPI/GenericAddAPI"
import { usePostData } from "../Shared/usePostData"

export const GoogleCalendarAuth = () => {
    const location = useLocation()
    const params = queryString.parse(location.search)

    const [result, setResult] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    const { post, isLoading } = usePostData(ActionPlanGoogleCalendarSetTokenPath)
    
    useEffect(() => {
        post({code: params.code}, {
            onSuccess: (data: AddResult) => {
                console.log(data)
                setResult(data.status === "ok")
                setError(data.message ?? "")
                
            }
        })
    }, [])
    
    if (isLoading) return <div>Please wait while set the new token...</div>

    return <div>
        Result: {result ? "Success" : "Failed"}
        <br />
        {error !== "" && `Error: ${error}`}
    </div>
}