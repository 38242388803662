import { GetAPIHTTPClient } from '../database_api/StandardAPIHTTPClient';

export interface SingleLoaderProtocol<Result>{
    load(id: string, completion: (result: Result) => void):void
}

export class GenericSingleLoader<Result> implements SingleLoaderProtocol<Result>{
    private client: GetAPIHTTPClient
    private path: string

    constructor(path: string, client: GetAPIHTTPClient){
        this.client = client
        this.path = path
    }

    load(id: string, completion: (result: Result) => void){
        this.client.get(this.path + id, (result) => {
            completion(result)
        })
    }
}