import React from 'react'
import { FileUploadProgress } from '../uploader/FieldInformationFileUploadHandler'

export class FileUploadProgressUI{
    displayFileUpload = (fileUploadList: FileUploadProgress[]) => (
        <div className="row fileUploadList">
            { fileUploadList.map(file => (
                <div className="rowflex file-container center" key={file.name}>
                    <div className="col name flex5">
                        {file.name}
                    </div>
                    <div className="col progress">
                        {file.completed.toFixed(1)} %
                    </div>
                </div>
            ))}
        </div>
    )
}