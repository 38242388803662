import moment from 'moment'
import React, { PropsWithChildren, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { ResultStatus } from '../../../Types/ResultStatus'
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient'
import { ActionPlanPath } from '../../../database_api/api'
import { SelectOptionObject } from '../../../form-components/FormikSelectInputHandler'
import { JoditContentEditorModule } from '../../../form-components/JoditContentEditorModuleV3Alt'
import { TextInput } from '../../../form-components/TextInput'
import { DateHelper } from '../../../helper/DateHelper'
import closeIcon from '../../../img/close.png'
import nameIcon from '../../../img/icon-actionPlanTimer.png'
import dateIcon from '../../../img/icon-date.png'
import { HTTPHeader } from '../../../interfaces/HTTPHeader'
import { Lang } from '../../../lang/Lang'
import { ActionPlanModel } from '../../ActionPlan/model/ActionPlan'
import { ActionPlanType } from '../../LatestStatus/ui/AsyncCreatableActionPlanSelectInputHandler'
import { usePrevious } from '../../LatestStatus/ui/LatestStatusComponentV2'
import { AuthTokenSessionStorage } from '../../Login/AuthPermission'
import { useAddData } from '../../Shared/useAddData'
import { useDeleteData } from '../../Shared/useDeleteData'
import { useUpdateData } from '../../Shared/useUpdateData'
import { ResultType } from '../../useSelectMemberHelperV2'

interface ActionPlanFieldsProps extends ActionPlanModel {
    clearable?: boolean
    deleteable?: boolean
    onActionPlanIdUpdate?: (actionPlanId: string | undefined, actionPlanData?: ActionPlanModel) => void
    onDeleteActionPlan?: () => void
    time: string
    showTimeField?: boolean
}

export const ActionPlanFields = (props: PropsWithChildren<ActionPlanFieldsProps>) => {
    const { add } = useAddData(ActionPlanPath, false)
    const { update } = useUpdateData(ActionPlanPath)
    const { remove } = useDeleteData(ActionPlanPath + props.id + "/latestStatus")
    const [actionPlanSelected, setActionPlanSelected] = React.useState<SelectOptionObject | undefined>(
        (props.name && props.id) ? {
            label: props.name,
            value: props.id
        } : undefined
    )
    const [location, setLocation] = React.useState<string | undefined>(props.location)
    const [objective, setObjective] = React.useState<string | undefined >(props.objective)
    const [contractors, setContractors] = React.useState<string | undefined>(props.contractors)
    const [personnelList, setPersonnelList] = React.useState<string | undefined>(props.personnelList)
    
    const [time, setTime] = React.useState<string>(props.time && props.time !== "" ? props.time : new Date().toLocaleString("en-US"))
    const [date_date, setDate_date] = React.useState<Date | undefined>(props.time && props.time !== "" ? moment(props.time).toDate() : new Date())
    const [actionPlanId, setActionPlanId] = React.useState<string | undefined>(props.id)
    const [actionPlanName, setActionPlanName] = React.useState<string | undefined>(props.name)

    const [picIds, setPicIds] = React.useState<string[]>(props.picIds ?? [])
    const [membersSelected, setMembersSelected] = React.useState<ResultType[]>(props.picIds ? props.picIds.map((picId: string) => {
        if(!props.memberList) return {label: "", value: ""}

        const member = props.memberList?.find(data => data.value == picId)
        if(!member) return {label: "", value: ""}
        
        return member
    }) : [])

    const prevProps = usePrevious(props)

    useEffect(() => {
        if (!prevProps) return

        const prevActionPlanProps = {
            ...prevProps,
            id: prevProps.id ?? "",
            name: prevProps.name ?? ""
        }

        delete prevActionPlanProps.clearable
        delete prevActionPlanProps.deleteable
        delete prevActionPlanProps.onActionPlanIdUpdate
        delete prevActionPlanProps.onDeleteActionPlan
        delete prevActionPlanProps.showTimeField

        const currentActionPlanProps = {
            ...props,
            id: props.id ?? "",
            name: props.name ?? ""
        }

        delete currentActionPlanProps.clearable
        delete currentActionPlanProps.deleteable
        delete currentActionPlanProps.onActionPlanIdUpdate
        delete currentActionPlanProps.onDeleteActionPlan
        delete currentActionPlanProps.showTimeField

        if (JSON.stringify(prevActionPlanProps) != JSON.stringify(currentActionPlanProps)) {
            handleActionPlanChange(currentActionPlanProps, true)
            if(currentActionPlanProps.time !== prevActionPlanProps.time) {
                setTime(currentActionPlanProps.time)
            }
        }
    }, [props])

    const handleActionPlanChange = (actionPlan?: ActionPlanType, firstLoad?: boolean) => {
        if (actionPlan == undefined) {
            setActionPlanId(undefined)
            setActionPlanName(undefined)
            setActionPlanSelected(undefined)
            setLocation("")
            setPersonnelList("")
            setContractors("")
            setObjective("")
            setPicIds([])
            setMembersSelected([])
            
            props.onActionPlanIdUpdate && props.onActionPlanIdUpdate(undefined)
            return
        }

        setActionPlanId(actionPlan.id)
        setActionPlanName(actionPlan.name)
        setActionPlanSelected({
            label: actionPlan.name,
            value: actionPlan.id
        })
        setLocation(actionPlan.location ?? "")
        setPersonnelList(actionPlan.personnelList ?? "")
        setContractors(actionPlan.contractors ?? "")
        setObjective(actionPlan.objective ?? "")
        setPicIds(actionPlan.picIds ?? [])
        setMembersSelected(actionPlan.picIds ? actionPlan.picIds.map((picId: string) => {
            if(!props.memberList) return {label: "", value: ""}

            const member = props.memberList?.find(data => data.value == picId)
            if(!member) return {label: "", value: ""}
            
            return member
        }) : [])
        
        if(actionPlan.date) {
            let d = moment(actionPlan.date)
            if (d.isValid()) {
                setDate_date(d.toDate())
                setTime(d.toDate().toLocaleString("en-US"))
            }
        }

        if(firstLoad) return
        
        props.onActionPlanIdUpdate && props.onActionPlanIdUpdate(actionPlan.id, {
            id: actionPlan.id,
            name: actionPlan.name,
            location: actionPlan.location,
            objective: actionPlan.objective,
            personnelList: actionPlan.personnelList,
            contractors: actionPlan.contractors,
            picIds: actionPlan.picIds,
            date: actionPlan.date
        })
    }

    const createActionPlan = (name: string) => {
        if(!date_date) return

        add({
            projectId: props.projectId,
            date: DateHelper.dateISO8601(date_date.toLocaleString("en-US")),
            name: name,
            location: location,
            objective: objective,
            personnelList: personnelList,
            contractors: contractors,
            picIds: picIds
        },
        {
            onSuccess(result) {
                handleActionPlanChange({
                    id: result.data.id,
                    name: result.data.name
                })
            }
        })
    }

    const updateActionPlan = (name: string, date_date?: Date, refresh: Boolean = true) => {
        if(!date_date) return
        if(!actionPlanId) return

        console.log("updateActionPlan", DateHelper.dateISO8601(date_date.toLocaleString("en-US")))

        update({
            id: actionPlanId,
            projectId: props.projectId,
            date: DateHelper.dateISO8601(date_date.toLocaleString("en-US")),
            name: name,
            location: location,
            objective: objective,
            personnelList: personnelList,
            contractors: contractors,
            picIds: picIds
        },
        {
            onSuccess(result) {
                if (result.status == ResultStatus.ok && refresh)
                    handleActionPlanChange({
                        id: actionPlanId,
                        name: name,
                        location: location,
                        objective: objective,
                        personnelList: personnelList,
                        contractors: contractors,
                        picIds: picIds,
                        date: DateHelper.dateISO8601(date_date.toLocaleString("en-US"))
                    })
            }
        })
    }

    const handleActionPlanCreate = (name: string) => {
        console.log("Action plan create called")
        console.log("actionPlanSelected", actionPlanSelected)

        const emptyActionPlan = {
            label: '', value: ''
        }

        if(!actionPlanSelected || JSON.stringify(actionPlanSelected) == JSON.stringify(emptyActionPlan)){
            createActionPlan(name)
        }
        else{
            updateActionPlan(name, date_date, false)
        }
    }

    const callUpdateActionPlan = () => {
        console.log("callUpdateActionPlan")
        if(!actionPlanName) return
        updateActionPlan(actionPlanName, date_date, true)
    }

    const client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))

    const handleChangeForActionPlanFields = (e: React.FormEvent<HTMLInputElement>) => {
        console.log("NOT WORKING YET")
        // setState<never>({
        //     [`${e.currentTarget.name}`]: e.currentTarget.value
        // })
    }

    const handleBlurForActionPlanFields = (e: React.FormEvent<HTMLInputElement>) => {
        if(!actionPlanSelected) return
        callUpdateActionPlan()
    }

    const handleDateChange = (date: Date | [Date, Date] | null) => {
        let pDate = date as Date
        if (pDate) {
            if(date_date && date_date.toLocaleString("en-US") !== pDate.toLocaleString("en-US")){
                console.log(date_date.toLocaleString("en-US"))
                console.log(pDate.toLocaleString("en-US"))
                setDate_date(pDate)
                setTime(pDate.toLocaleString("en-US"))
                if(actionPlanName) updateActionPlan(actionPlanName, pDate)
            }
        }
    }
    
    const deleteActionPlan = () => {
        if(!actionPlanId) return
        
        const confirmDelete = window.confirm("Are you sure want to delete " + actionPlanName + "? The action plan will be deleted permanently.")
        if(!confirmDelete) return

        remove(undefined, {
            onSuccess(result) {
                if (result.status == ResultStatus.ok) {
                    props.onDeleteActionPlan && props.onDeleteActionPlan()
                }
            }
        })
    }

    const handlePICSelectedChange = (inputValue: any, _: any) => {
        console.log(inputValue)
        if(!inputValue) {
            setPicIds([])
            setMembersSelected([])
            return
        }

        setPicIds(inputValue.map((data: ResultType) => data.value))
        setMembersSelected(inputValue)
    }

    useEffect(() => {
        if(!actionPlanSelected) return
        if(props.picIds === picIds) return
        
        callUpdateActionPlan()
    }, [picIds])
    
    return (
        <div className="col right default-margin-left actionPlanSection">
            <div id="actionPlanSectionContainer">
                { props.showTimeField && time && 
                <div className="rowflex center" style={{
                    marginTop: "10px"
                }}>
                    <div className="col left">
                        <div className="fieldInformation-label">{Lang.actionPlanTime}</div>
                    </div>
                    <div className="col right">
                        <DatePicker className="text-input default" placeholderText="" selected={date_date} onChange={(date: Date) => { handleDateChange(date) }}
                            showTimeSelect dateFormat="MMMM d, yyyy H:mm" timeFormat='HH:mm'
                            customInput={<TextInput icon={dateIcon} placeholder="" name="time" value={time} />} />
                    </div>
                </div>
                }

                { time &&
                <div className="rowflex center" style={{
                    marginTop: "10px"
                }}>
                    <div className="col left">
                        <div className="fieldInformation-label">{Lang.actionPlanItem}</div>
                    </div>
                    <div className="col right">
                        <TextInput aclass="full-width default fieldInformation-actionPlanName" icon={nameIcon} placeholder={Lang.actionPlanItem} name="name"
                            value={actionPlanName} 
                            onChange={(e: React.FormEvent<HTMLInputElement>) => setActionPlanName(e.currentTarget.value)}
                            onBlur={(e: React.FormEvent<HTMLInputElement>) => handleActionPlanCreate(e.currentTarget.value)}
                        />
                
                    </div>
                </div>
                }

                { time && actionPlanId &&
                <div className={"actionPlanFields"}>
                    <div className="row">
                        <div className="fieldInformation-location">
                            <TextInput name="location" value={location} placeholder={Lang.actionPlanLocation}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setLocation(e.currentTarget.value)}
                                onBlur={handleBlurForActionPlanFields} /></div>
                    </div>

                    <div className="row">
                        <div className="fieldInformation-personnelList">
                                <TextInput name="personnelList" value={personnelList} placeholder={Lang.actionPlanCustomerPersonnel}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setPersonnelList(e.currentTarget.value)}
                                onBlur={handleBlurForActionPlanFields} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="fieldInformation-personnelList">
                            <TextInput name="contractors" value={contractors} placeholder={Lang.actionPlanThirdParty}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => setContractors(e.currentTarget.value)}
                                onBlur={handleBlurForActionPlanFields} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="fieldInformation-picIds">
                        <div className="fieldInformation-label">{Lang.actionPlanPicIds}</div>
                        <Select placeholder={Lang.actionPlanPicIds} className="selectionStyle full-border" 
                            isSearchable={true} classNamePrefix="si" options={props.memberList ?? []} 
                            value={membersSelected ?? []} 
                            isMulti={true}
                            onChange={handlePICSelectedChange}
                        />
                        </div>
                    </div>

                    <div className="row">
                        <div className="fieldInformation-objective">
                            <div className="fieldInformation-label">{Lang.actionPlanObjective}</div>
                            <JoditContentEditorModule
                                aclass="textarea"
                                placehoder={Lang.actionPlanObjective}
                                fieldName="objective" value={objective}
                                handleChange={(value) => { setObjective(value) }}
                                handleOnBlur={() => callUpdateActionPlan()}
                            />
                        </div>
                    </div>

                    {props.deleteable &&
                            <div className="actionPlan-delete" title="Delete Action Plan" onClick={() => { deleteActionPlan() }}><img src={closeIcon} /></div>
                    }
                </div>
                }
            </div>
        </div>

    )
}