import { FormPostAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient';
import { AddModuleInterface } from './GenericAddAPI';

interface ErrorMapping{
    [index: string]: string
}

export class GenericFormAddAPI<Model, Result> implements AddModuleInterface<Model, Result>{
    private client: FormPostAPIHTTPClient<Model>
    private path: string

    errorMapping: () => (ErrorMapping) = () => ({})

    constructor(path: string, client: FormPostAPIHTTPClient<Model>){
        this.path = path
        this.client = client
    }

    add(data: Model, addCallback: (result: Result) => void){
        this.client.postForm(this.path, data, (result) => {
            if (result.message){
                if(this.errorMapping()[result.message]){
                    result.message = this.errorMapping()[result.message]
                }
                else{
                    result.message = `Error code: ${result.message}. Please contact your developer.`
                }
                
            }
            setTimeout(() => {
                addCallback(result)
            }, 300)
        })
    }
}