import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MemberPath } from '../../../database_api/api';
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { LoginSessionStorage } from '../../Login/LoginSessionStorage';
import { MemberSingleLoader, APIMemberSingleLoader } from '../../Member/MemberSingleLoader';
import { MemberEditAPI } from '../../Member/MemberEditAPI';
import { ProfileWithFormHandler } from '../ui/ProfileWithFormHandler';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';

export class ProfileBuilder{
    client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))
    parameterRoute: RouteComponentProps
    loader: MemberSingleLoader = new APIMemberSingleLoader(MemberPath, this.client)
    session = new LoginSessionStorage()
    memberHandler = new MemberEditAPI(MemberPath, this.client)

    constructor(parameterRoute: RouteComponentProps){
        this.parameterRoute = parameterRoute
    }

    get(){
        return <ProfileWithFormHandler session={this.session} loader={this.loader} handler={this.memberHandler} {...this.parameterRoute} />
    }
}