import { useHistory } from 'react-router';
import optionMenu from '../img/option.png';

interface IEditButton {
    basePath: string
    code?: string
}

export const EditButton = (props: IEditButton) => {
    const history = useHistory()

    return (
        <input className="option" type="image" src={optionMenu}
            title='Edit'
            onClick={ () => { 
                history.push(props.basePath + "/edit/" + props.code)
            } } 
        />
    )
}