import { ActionPlanModel } from './model/ActionPlan'
import { GenericAddAPI, AddResult, AddModuleInterface } from '../GenericAPI/GenericAddAPI';

export type ActionPlanAddResult = AddResult
export interface AddActionPlanModuleInterface extends AddModuleInterface<ActionPlanModel, ActionPlanAddResult>{}

export class ActionPlanAddAPI extends GenericAddAPI<ActionPlanModel, ActionPlanAddResult> implements AddActionPlanModuleInterface{
    errorMapping = () => ({
        "12": "ID already exists, please try submit again."
    })
}