import closeIcon from '../../../img/close.png';

interface EducationComponentProps {
    educationItem: string;
    onDelete: (educationItem: string) => void;
    [param: string]: any;
}

export const EducationComponent = (props: EducationComponentProps) => {

    const { aclass, type, onDelete, educationItem: childItem } = props;
        
    const handleDelete = () => {
        onDelete(props.educationItem);
    }

    return (
        <div className="input-container budgetType">
            <input
                type={type || "text"}
                className={"text-input " + (aclass ?? '') + ' no-icon'}
                value={childItem}
                {...props}
                disabled
            />
            <img src={closeIcon} onClick={() => handleDelete()} />
        </div>
    );
}
