import { DownloadDataToDoc } from "./DownloadDataToDocProtocol";
import { ContentListType } from "../POBContent/ContentListLoader";
import { QuarterYearFilter } from "./filter/QuarterYearFilter";
import { HistoryModel } from "../History/model/History";

export class DownloadHistoryToDoc extends DownloadDataToDoc<HistoryModel[]>{
    type = ContentListType.history
    quarter: string = "Q1"
    year: string = new Date().getFullYear().toString()

    apiPath = (projectId: string) => {
        return (this.basePath + this.type + "/" + projectId + "/" + this.year + this.quarter)
    }

    filterElement = () => <QuarterYearFilter downloadDecisionFn={this.downloadDecisionFn} 
        setQuarterFilter={(quarter) => {this.quarter = quarter}}
        setYearFilter={(year) => { this.year = year }}
    />

    getPostfix = () => "( " + this.quarter + " " + this.year + " )"
    
    protected useTemplate(projectName: string, data: HistoryModel[]){
        let html = super.useTemplate(projectName, data)

        if (!data) return html
        if (data.length == 0) return html

        data.forEach(dt => {
            html += "<h3><u>" + dt.quarter + " " + dt.year.toString() + "</u></h3>"
            html += dt.information ?? "-"
            html += "<br><br>"
        })

        return html;
    }
}