import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';
import { POBPath } from '../../../database_api/api';
import { useSetProjectName } from '../../POBContent/useSetProjectName';
import { useUpdateFormData } from '../../Shared/useUpdateFormData';
import { POBModel } from '../model/POBV2';

export const usePOBEditFormHandler = (initialValues: POBModel, validationSchema?: ObjectSchema) => {
    const { updateProjectName } = useSetProjectName()
    const { update } = useUpdateFormData(POBPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            const pobDataForm = getPOBEditDataToSubmit(values);

            setSubmitting(false)
            update(pobDataForm, {
                onSuccess: () => {
                    updateProjectName(values.name ?? "", "Child Project")
                }
            })
        }
    })

    return formik
}

export function getPOBEditDataToSubmit(values: POBModel) {
    let pobData = {
        id: values.id,
        code: values.code,
        name: values.name,
        customerTemporary: values.customerTemporary,
        projectValue: values.projectValue,
        active: values.active
    };

    let pobDataForm = new FormData();
    Object.entries(pobData).forEach(field => {
        if (field[1])
            pobDataForm.append(field[0], field[1]);
    });

    if (values.projectManagerId && values.projectManagerId != "0") {
        pobDataForm.append("projectManagerId", values.projectManagerId);
    }

    if (values.projectHeaderId && values.projectHeaderId != "0") {
        pobDataForm.append("projectHeaderId", values.projectHeaderId);
    }

    if (values.customerId && values.customerId != "0") {
        pobDataForm.append("customerId", values.customerId);

    }

    if (values.clientId && values.clientId != "0") {
        pobDataForm.append("clientId", values.clientId);
    }

    if (values.clientName && values.clientName != "0") {
        pobDataForm.append("clientName", values.clientName);

    }

    pobDataForm.append('isPhotoChanged', (values.isPhotoChanged ? "true" : "false"));

    if (values.photo_url)
        pobDataForm.append('photo_url', values.photo_url);
    return pobDataForm;
}