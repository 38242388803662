import { useState } from "react"

export let useDateRangeFilter = (setFromFilter: ((from: Date) => void), setToFilter: ((from: Date) => void)) => {
    let [from, setFrom] = useState<string>("")
    let [fromDate, setFromDate] = useState<Date>(new Date())

    let [to, setTo] = useState<string>("")
    let [toDate, setToDate] = useState<Date>(new Date())

    let handleDateChange = (type: "from" | "to", date: Date | [Date, Date] | null) => {
        if (!(date instanceof Date)) return
        if (type === "from") {
            setFromDate(date)
            setFromFilter(date)
            setFrom(date.toLocaleDateString())
        }
        else {
            setToDate(date)
            setToFilter(date)
            setTo(date.toLocaleDateString())
        }
    }

    return {
        from, to,
        fromDate, toDate,
        handleDateChange
    }
}