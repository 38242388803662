import React from 'react'
import { ActionPlanPath, FieldInformationPath } from '../../../database_api/api'
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient'
import { HTTPHeader } from '../../../interfaces/HTTPHeader'
import { ActionPlanAddAPI } from '../../ActionPlan/ActionPlanAddAPI'
import { ActionPlanEditAPI } from '../../ActionPlan/ActionPlanEditAPI'
import { ActionPlanModel } from '../../ActionPlan/model/ActionPlan'
import { AuthTokenSessionStorage } from '../../Login/AuthPermission'
import { FieldInformationAddAPI } from '../api/FieldInformationAddAPI'
import { FieldInformationEditAPI } from '../api/FieldInformationEditAPI'
import { FieldInformationRouter } from '../FieldInformationRouter'
import { FieldInformationComponent } from './FieldInformationComponent'

interface FieldInformationAddProps{
    projectId: string, 
    router: FieldInformationRouter,
    actionPlan?: ActionPlanModel
    [key: string]: any
}

export class FieldInformationAddV2 extends React.Component<FieldInformationAddProps>{
    client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))
    editHandler = new FieldInformationEditAPI(FieldInformationPath, this.client)
    addHandler = new FieldInformationAddAPI(FieldInformationPath, this.client)
    actionPlanAddHandler = new ActionPlanAddAPI(ActionPlanPath, this.client)
    actionPlanEditHandler = new ActionPlanEditAPI(ActionPlanPath, this.client)

    backFn = () => {
        this.props.router.cancel()
    }

    render = () => {
        return (
        <div className={"container" + (this.props.aclass ? " " + this.props.aclass : "") }>
            <div style={{
                position: "fixed",
                background: 'rgba(0,0,0,0.5)',
                width: "100%",
                height: "100%",
                top: 0, left: 0
            }}></div>
                <FieldInformationComponent
                    id={this.props.actionPlan?.fieldInformationId}
                    actionPlanAddHandler={this.actionPlanAddHandler}
                    actionPlanEditHandler={this.actionPlanEditHandler}
                    addHandler={this.addHandler}
                    editHandler={this.editHandler}
                    projectId={this.props.projectId}

                    result="" status="" time={this.props.actionPlan?.date ?? ""}
                    actionPlanId={this.props.actionPlan?.id}
                    actionPlanName={this.props.actionPlan?.name}
                    personnelList={this.props.actionPlan?.personnelList}
                    contractors={this.props.actionPlan?.contractors}
                    location={this.props.actionPlan?.location}
                    objective={this.props.actionPlan?.objective}

                    enableBackButton={true}
                    backFn={this.props.router.back}
                    saveAndBackFn={ this.backFn }
                />
        </div>
        )
    }
}