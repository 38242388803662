import * as React from 'react'
import { TextInput } from '../../../form-components/TextInput'
import closeIcon from '../../../img/close.png'

export class BackupFileUpload{
    private setFile: (value: File | undefined) => void
    private setFileName: (value: string | undefined) => void
    private file?: File
    private defaultFile: any

    constructor(setFile: (value: File | undefined) => void, setFileName: (value: string | undefined) => void, defaultFile: any){
        this.setFile = setFile
        this.setFileName = setFileName

        this.defaultFile = defaultFile
    }

    private fileRef = React.createRef<HTMLInputElement>()

    private onFileClick = () => {
        this.fileRef.current?.click()
    }

    private onFileChangeAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }

        this.file = e.target.files[0]
        this.setFile(this.file)
        this.setFileName(this.file.name)
    }

    private onRemoveFileAdd = () => {
        this.setFile(undefined)
        this.setFileName(undefined)
        this.file = undefined
    }

    getComponent = (fileName?: string) => (
        <React.Fragment>
            <TextInput icon={this.defaultFile} placeholder="Select File" 
            name="file_description" value={fileName || ""} readOnly onClick={ () => { this.onFileClick() } } />

            {this.file && <img className="file-delete" src={closeIcon} onClick={() => this.onRemoveFileAdd() } /> }
            <input type="file" className="hidden" onChange={(e) => { this.onFileChangeAdd(e) }} ref={this.fileRef} />
        </React.Fragment>
    )
}