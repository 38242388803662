import { FormikProps } from "formik";
import DatePicker from 'react-datepicker';
import { FormikImageUploadFactory } from "../../../FormikHelper/FormikImageUploadFactory";
import { FormikSelectInputHandler, SelectOptionObject } from "../../../form-components/FormikSelectInputHandler";
import { JoditContentEditorModule } from "../../../form-components/JoditContentEditorModule";
import { TextInput } from "../../../form-components/TextInput";
import defaultProfile from '../../../img/default-profile.png';
import dateIcon from '../../../img/icon-date.png';
import nameIcon from '../../../img/icon-name.png';
import { Lang } from "../../../lang/Lang";
import { FormMode } from "../../Client/ui/ClientFormV3";
import { DatabaseTNIALModel } from "../model/DatabaseTNIAL";
import { FormikChildrenFormComponent } from "./FormikChildrenFormComponent";
import { FormikContactNumberFormComponent } from "./FormikContactNumbersFormComponent";
import { FormikEducationFormComponent } from "./FormikEducationFormComponent";
import { FormikPositionsFormComponent } from "./FormikPositionsFormComponent";
import { FormikAcknowledgementFormComponent } from "./FormikAcknowledgmentFormComponent";

export interface DatabaseTNIALAddModel extends DatabaseTNIALModel{
    photo_base64?: string
    isPhotoChanged?: boolean
    corpsSelected?: SelectOptionObject
}


export const DatabaseTNIALForm: <T extends DatabaseTNIALAddModel>(mode:FormMode, pageTitle: string, props: FormikProps<T>) => JSX.Element =
(mode, pageTitle, props) => {
    const { values, errors, touched, handleChange, setFieldValue } = props
    
    const photoHandler = new FormikImageUploadFactory(setFieldValue, "photo_url", "photo_base64", "isPhotoChanged", "customerPersonnel-profile-photo", defaultProfile, values.photo_url, values.photo_base64)
    const corpsSelector = new FormikSelectInputHandler("corps", Lang.customerProfileCorps, [
        {label: "P", value: "P"},
        {label: "T", value: "T"},
        {label: "E", value: "E"},
        {label: "S", value: "S"},
        {label: "MAR", value: "MAR"},
    ], "corpsSelected", setFieldValue, values.corpsSelected)

    let handleDateChange = (date: Date | [Date, Date] | null) => {
        setFieldValue('birthOfDate_date', date)
        if (date && date instanceof Date) {
            setFieldValue('birthOfDate', date.toLocaleDateString())
        }
    }

    const handleDescriptionChange = (content: string) => {
        setFieldValue("description", content)
    }

    let contentEditor = new JoditContentEditorModule().get("description", Lang.customerProfileDescription, values.description, handleDescriptionChange)
    
    return (
        <>
            <div className="page-title">{pageTitle} {values.name}</div>
            <div className="rowflex">
                <div className="col profile">
                    { !values.id ? photoHandler.getAddComponent() : photoHandler.getEditComponent() }
                </div>
            </div>

            <div className="title">Basic Information</div>
            <div className="rowflex two-field">
                <TextInput icon={nameIcon} placeholder={Lang.customerProfileName} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                <TextInput placeholder={Lang.customerProfileRank} name="rank" value={values.rank} onChange={handleChange} message={errors.rank} touched={touched.rank} />
            </div>

            <div className="row">
                <div className="input-container">
                    <DatePicker className="text-input default" placeholderText={"Date of Birth"} selected={values.birthOfDate_date} onChange={(date) => { handleDateChange(date) }}
                        dateFormat="MMMM d, yyyy" showYearDropdown
                        customInput={<TextInput icon={dateIcon} placeholder="Date of Birth" name="birthOfDate" value={values.birthOfDate} />} />
                </div>
            </div>

            <div className="rowflex two-field">
                <TextInput placeholder={Lang.customerProfilePosition} name="position" value={values.position} onChange={handleChange} message={errors.position} touched={touched.position} />
                <div className="input-container">
                    {corpsSelector.getComponent()}
                </div>
            </div>

            <div className="rowflex two-field">
                <TextInput placeholder={Lang.customerProfilePeriod} name="period" value={values.period} onChange={handleChange} message={errors.period} touched={touched.period} />
                <TextInput placeholder="NRP" name="nrp" value={values.nrp} onChange={handleChange} message={errors.nrp} touched={touched.nrp} />
            </div>

            <div className="rowflex two-field">
                <TextInput placeholder={Lang.customerProfileSequenceNumber} name="serial_number" value={values.serial_number} onChange={handleChange} message={errors.serial_number} touched={touched.serial_number} />
                <TextInput placeholder={Lang.customerProfileGeneration} name="generation" value={values.generation} onChange={handleChange} message={errors.generation} touched={touched.generation} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">{Lang.customerProfilePositionHistoryLabel}</div>
                <p style={{textAlign:"left"}}><b>Note</b>: Untuk menambah jabatan ( Position ) & masa jabatan ( Time ).<br/>
Gunakan "Tab" untuk pindah dari "Position" ke "Time", dan "Enter" untuk menambahkan jabatan & masa jabatan baru.
</p>
                <FormikPositionsFormComponent {...props} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">{Lang.customerProfileContactNumber}</div>
                <p style={{textAlign:"left"}}><b>Note</b>: Untuk menambah nomor kontak.<br/>
Tekan "Enter" untuk menambahkan nomor kontak yang baru.
</p>
                <FormikContactNumberFormComponent {...props} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">Family</div>
            </div>

            <div className="row">
                <TextInput placeholder={Lang.customerProfileSpouse} name="spouse" value={values.spouse} onChange={handleChange} message={errors.spouse} touched={touched.spouse} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">{Lang.customerProfileChildren}</div>
                <p style={{textAlign:"left"}}><b>Note</b>: Untuk menambah anak.<br/>
Tekan "Enter" untuk menambahkan anak.
</p>
                <FormikChildrenFormComponent {...props} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">{Lang.customerProfileEducation}</div>
                <p style={{textAlign:"left"}}><b>Note</b>: Untuk menambah Pendidikan.<br/>
Tekan "Enter" untuk menambahkan pendidikan.
</p>
                <FormikEducationFormComponent {...props} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">{Lang.customerProfileAcknowledgement}</div>
                <p style={{textAlign:"left"}}><b>Note</b>: Untuk menambah penghargaan.<br/>
Tekan "Enter" untuk menambahkan penghargaan.
</p>
                <FormikAcknowledgementFormComponent {...props} />
            </div>

            <div className="row">
                <div className="line-decoration default-margin-top"/>
                <div className="title">{Lang.customerProfileDescription}</div>
                { contentEditor }
            </div>
        </>
    )
}