import { PostAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient"
import { ResultStatus } from "../../../Types/ResultStatus"
import { BlockContentModuleEnum } from '../BlockContentListLoader';

export interface BlockContentModel{
    id: string
    type: BlockContentModuleEnum
    data?: string
}

export interface BlockContentAddFeatureRequestResult{
    status: ResultStatus
    data?: BlockContentModel
    message?: string
}

export enum BlockContentAddIndex{
    first = "first",
    last = "last"
}

export interface BlockContentAddFeatureProtocol{
    add(contentId: string, type: BlockContentModuleEnum, index: BlockContentAddIndex, data: string, callback: (data?: BlockContentModel, error?: string) => void): void
}

export class BlockContentAddFeature implements BlockContentAddFeatureProtocol{
    private client: PostAPIHTTPClient
    private url: string

    constructor(client: PostAPIHTTPClient, url: string) {
        this.client = client
        this.url = url
    }

    add(contentId: string, type: BlockContentModuleEnum = BlockContentModuleEnum.blockContent, index: BlockContentAddIndex, data: string, callback: (data?: BlockContentModel, error?: string) => void) {
        this.client.post(this.url + contentId + "/" + type + "/" + index, data, (result) => {
            let returnedResult: BlockContentAddFeatureRequestResult = result
            if(returnedResult.status == ResultStatus.fail){
                let error = returnedResult.message && returnedResult.message != "" ? returnedResult.message : "Unknown Error on Add Block Content"
                callback(undefined, error)
                return
            }

            callback(returnedResult.data, undefined)
            
        })
    }
}
