import React from 'react';

import { SelectOptionProp } from '../../../form-components/SelectInput';

import { ButtonInput } from '../../../form-components/ButtonInput';
import { MemberComponent, MemberComponentProp } from './MemberComponent';

import { ListLoaderControllerProtocol } from '../../ListLoaderControllerProtocol';
import { SearchFeatureProtocol } from '../../Search/SearchFeatureProtocol';
import { MemberListDisplayUpdaterProtocol } from '../MemberListDisplayUpdaterProtocol';

import { StaticContext } from "react-router";
import { RouteComponentProps } from 'react-router-dom';
import { MessageType } from '../../../MessageType';
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { LoadingControlProtocol, SearchComponent } from '../../Search/SearchComponent';

import moment from 'moment';

export interface MemberListRouteProtocol{
    goToAdd(): void
    goToEdit(id: string): void
}

export type MemberListMessage = {
    message?:{type: MessageType, message: string}
}

export type MemberListRouteComponentProps = RouteComponentProps<{}, StaticContext, MemberListMessage>

interface MemberListProp extends MemberListRouteComponentProps{
    router: MemberListRouteProtocol
    selectOption?: SelectOptionProp
    paginationComponent?: JSX.Element
    controller: ListLoaderControllerProtocol & MemberListDisplayUpdaterProtocol & LoadingControlProtocol
    search: SearchFeatureProtocol
}

export class MemberList extends React.Component<MemberListProp, {members: MemberComponentProp[], message?:{type: MessageType, message: string}}> { 
    queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "username",
            displayField: "Username"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Name"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "phone",
            displayField: "Phone"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "email",
            displayField: "Email"
        }
    ]

    constructor(props: MemberListProp){
        super(props)

        let messageState = this.props.location.state ? this.props.location.state.message : undefined

        this.state = {
            members: [],
            message: messageState
        }

        this.props.controller.onLoad = this.onLoadMembers

        this.props.controller.load(1)
    }

    onLoadMembers = (members: MemberComponentProp[]) => {
        this.setState({
            members: members
        })
    }

    onAdd = () => {
        this.props.router.goToAdd()
    }

    onEdit = (id: string) => {
        this.props.router.goToEdit(id)
    }

    render(){

        return (
        <React.Fragment>
            <div id="top-container">
                <SearchComponent searchModule={this.props.search} loadingController={this.props.controller} placeholder="Username, Name, Phone, Email" queryOptions={this.queryOptions}/>
                <div className="clear"></div>
            </div>

            <div id="add-menu-container">
                <ButtonInput id="add" caption="+" title="Add New" onClick={this.onAdd} />
            </div>
            
            <div id="content-container">
            {this.props.paginationComponent}

            {this.state.message && <div className={"message " + this.state.message.type}>{this.state.message.message}</div> }
            { this.state.message && setTimeout(() => { this.setState({message: undefined}) }, 3000) }

            <div id="member-list">
                {
                    (this.state.members && this.state.members.length > 0 && this.state.members.map((member, index) => {
                        let age: string | undefined = undefined
                        if(member.dob != undefined){
                            age = moment(member.dob).toNow(true) + " old"
                        }
                        return <MemberComponent username={member.username} name={member.name} 
                            position={member.position}
                            gender={member.gender} photo_url={member.photo_url}
                            age={age} email={member.email} phone={member.phone} key={index} onEdit={ this.onEdit } />
                    }))
                    || 
                    <div className="message">No member registered</div>
                }
            </div>

            {this.props.paginationComponent}
            </div>
        </React.Fragment>
        )
    }
}