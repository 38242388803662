import * as React from 'react';

export type SearchButtonInputProp = {
    id?: string
    title: string
    icon?: string
    [param: string]: any
}

export class SearchButtonInput extends React.Component<SearchButtonInputProp>{
    constructor(props: SearchButtonInputProp){
        super(props)
    }

    render(){
        const {id, title, icon, ...props} = this.props
        return (
            <button id={id} button-type="search" {...props}>
                <div className="component-container">
                    {icon ? <img src={icon} /> : ""}
                    <span>{title}</span>
                </div>
            </button>
        )
    }
}