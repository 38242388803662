import { MemberModel } from '../Member/model/Member'
import { goToLogin } from '../RouteManager'

export interface AuthPermissionProtocol {
    isLoggedIn(): boolean
    isSuperAdmin(): boolean
    goToLogin(): void
}

export interface MemberLoginModel extends MemberModel {
    superAdmin: boolean
    token: string
}

export class AuthPermissionSessionStorage implements AuthPermissionProtocol {

    isLoggedIn(): boolean {
        if (!sessionStorage.getItem("userData")) return false

        return true
    }

    isSuperAdmin(): boolean {
        if (!sessionStorage.getItem("userData")) return false

        let data: string = sessionStorage.getItem("userData") ?? ""
        if (data == "") return false

        let userData: MemberLoginModel = JSON.parse(data)
        if (!userData.superAdmin) return false

        return true
    }

    goToLogin() {
        goToLogin()
    }
}

export interface Token{
    getToken(): string
}

export class AuthTokenSessionStorage implements Token {
    getToken(): string {
        if (!sessionStorage.getItem("userData")) return ""

        let data: string = sessionStorage.getItem("userData") ?? ""
        if (data == "") return ""

        let userData: MemberLoginModel = JSON.parse(data)

        return userData.token
    }
}