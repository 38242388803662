import { Session } from '../../interfaces/Session';
import { MemberLoginModel } from './AuthPermission';

export class LoginSessionStorage implements Session{
    saveAccount(userData: MemberLoginModel){
        sessionStorage.setItem("userData", JSON.stringify(userData))
    }

    getUsername(){
        if(!sessionStorage.getItem("userData")) return null
        
        let data: string = sessionStorage.getItem("userData") ?? ""
        let userData: MemberLoginModel = JSON.parse(data)
        return userData.username
    }

    logOut(){
        sessionStorage.removeItem("userData")
    }
}