import { FormikTouched } from 'formik'
import React from 'react'
import { SetFieldValueFunctionType } from '../../../FormikHelper/FormikFileUploadFactory'
import { ButtonInput } from '../../../form-components/ButtonInput'
import { TextInput } from '../../../form-components/TextInput'
import { BudgetModel } from '../model/POBHeader'
import { BudgetTypeComponent } from './BudgetTypeComponent'
import { POBBudget } from './POBHeaderAdd'

type HandleChange = (e: React.ChangeEvent<any>) => void

export const FormikBudgetFormComponent = (props: {
    setFieldValue: SetFieldValueFunctionType,
    values: POBBudget,
    handleChange: HandleChange,
    touched: FormikTouched<POBBudget>
}) => {

    const { setFieldValue, values, handleChange, touched } = props
    const budgetTypeRef = React.createRef<HTMLInputElement>()

    const handleAddBudget = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key == "Enter"){
            e.preventDefault()
            addBudget()
            budgetTypeRef.current?.focus()
        }
    }

    const addBudget = () => {
        if (!(values.budgetType && values.budgetValue)) return
        let newBudgetList = values.budgetList ?? []
        newBudgetList.push({
            budgetType: values.budgetType,
            value: values.budgetValue
        })

        setFieldValue("budgetList", newBudgetList)
        setFieldValue("budgetType", "")
        setFieldValue("budgetValue", "")

        budgetTypeRef.current?.focus()
    }

    const removeBudget = (budgetType: string) => {
        let newBudgetList: BudgetModel[] = []

        values.budgetList && values.budgetList.forEach( budget => {
            if(budget.budgetType != budgetType) newBudgetList.push(budget)
        })

        setFieldValue("budgetList", newBudgetList)
    }

    return (
        <>
        <div className="rowflex budgetType default-padding-top">
            <div className="col">
                <TextInput placeholder="Budget Type" name="budgetType" value={values.budgetType} onChange={handleChange} touched={touched.budgetType} onKeyPress={handleAddBudget} inputRef={budgetTypeRef} />
            </div>
            <div className="col spacing"></div>
            <div className="col">
                <TextInput placeholder="Total" name="budgetValue" value={values.budgetValue} onChange={handleChange} touched={touched.budgetValue} onKeyPress={handleAddBudget} />
            </div>
            <div className="col spacing"></div>
            <div className="col right-align">
                <ButtonInput type="button" caption="Add Budget" title="Add Budget" id="budgetAddSubmit" onClick={ () => addBudget() } />
            </div>
        </div>

        <div className="budgetList">
            {
                values.budgetList && values.budgetList.map( budget => {
                    return <BudgetTypeComponent data={budget} key={budget.budgetType} onDelete={removeBudget} />
                })
            }
        </div>
        </>
    )
}