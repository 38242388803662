import { ActionPlanModel } from './model/ActionPlan';
import { GenericEditAPI, EditResult, EditModuleInterface } from '../GenericAPI/GenericEditAPI';

export type ActionPlanEditResult = EditResult

export interface ActionPlanSingleModel extends ActionPlanModel {
    id: string
}

export interface EditActionPlanModuleInterface extends EditModuleInterface<ActionPlanSingleModel, ActionPlanEditResult> {}

export class ActionPlanEditAPI extends GenericEditAPI<ActionPlanSingleModel, ActionPlanEditResult> implements EditActionPlanModuleInterface{}