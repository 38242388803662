import { AuthTokenSessionStorage, Token } from '../modules/Login/AuthPermission';
import { LoginResult } from '../Types/LoginResult';

export enum HTTPMethod {
    post = "POST",
    get = "GET",
    patch = "PATCH",
    delete = "DELETE"
}

export class HTTPHeader {
    token: Token

    constructor(token: Token){
        this.token = token
    }

    header = () => {
        return {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.token.getToken()
        }
    }

    formHeader = () => {
        return {
            "Authorization": "Bearer " + this.token.getToken()
        }   
    }
}

export interface AuthHTTPClient {
    auth(username: String, password: String, authCallback: (result: LoginResult) => void): void
}