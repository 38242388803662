import * as React from 'react'

type BoxComponentProp = {
    text?: string,
    border?: string,
    aclass?: string
}

export class BoxComponent extends React.Component<BoxComponentProp>{
    aclass: string = ""
    defaultBorder: string = "no-border"

    constructor(props: BoxComponentProp){
        super(props)

        this.aclass = (this.props.aclass ?? "") + " " + (this.props.border ?? this.defaultBorder)
    }

    render(){
        return <div className={this.aclass}>
            {this.props.text ? <span>{this.props.text}</span> : ""}
        </div>
    }
}