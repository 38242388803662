import { FormikTouched } from 'formik';
import React from 'react';
import { SetFieldValueFunctionType } from '../../../FormikHelper/FormikFileUploadFactory';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { TextInput } from '../../../form-components/TextInput';
import { EducationComponent } from './EducationItemComponent';

type HandleChange = (e: React.ChangeEvent<any>) => void;

export interface FormikEducationFormComponentProps {
    setFieldValue: SetFieldValueFunctionType;
    values: IEducationFormValues;
    handleChange: HandleChange;
    touched: FormikTouched<IEducationFormValues>;
}

export interface IEducationFormValues {
    educationAddField?: string;
    education?: string[];
}

export const FormikEducationFormComponent: React.FC<FormikEducationFormComponentProps> = ({ setFieldValue, values, handleChange, touched }) => {
    const educationRef = React.createRef<HTMLInputElement>();

    const handleAddEducation = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addEducation();
            educationRef.current?.focus();
        }
    };

    const addEducation = () => {
        if (!values.educationAddField) return;
        let newEducation = values.education ?? [];
        newEducation.push(values.educationAddField);

        setFieldValue("education", newEducation);
        setFieldValue("educationAddField", "");

        educationRef.current?.focus();
    };

    return (
        <>
            <div className="rowflex educationType default-padding-top" style={{ gap: "10px" }}>
                <div className="col">
                    <TextInput placeholder="Education" name="educationAddField" value={values.educationAddField} onChange={handleChange} touched={touched.educationAddField} onKeyPress={handleAddEducation} inputRef={educationRef} />
                </div>
                <div className="col right-align">
                    <ButtonInput type="button" caption="Add Education" title="Add Education" id="educationAddSubmit" onClick={addEducation} />
                </div>
            </div>

            <div className="educationList">
                {
                    values.education && values.education.map((education, index) => {
                        return <EducationComponent educationItem={education} key={index} onDelete={(education: string) => {
                            const newEducation = values.education ? values.education.filter(edu => edu !== education) : [];
                            setFieldValue("education", newEducation);
                        }} />;
                    })
                }
            </div>
        </>
    );
};
