import { FormikProps } from 'formik';
import React from 'react'
import { OptionInput } from '../../../form-components/OptionInput';

export let OptionInputProspectiveStatusSelection: <T extends {prospectiveStatus: string}>(props: FormikProps<T>) => JSX.Element = 
    ({values, handleChange}) => (
    <OptionInput name='prospectiveStatus'
        value={values.prospectiveStatus} onChange={handleChange}
        options={[
            {
                key: "0",
                value: "Neutral"
            },
            {
                key: "1",
                value: "Client"
            },
            {
                key: "2",
                value: "Competitor"
            }
        ]} 
    />
)