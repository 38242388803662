import { FormikTouched } from 'formik';
import React from 'react';
import { SetFieldValueFunctionType } from '../../../FormikHelper/FormikFileUploadFactory';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { TextInput } from '../../../form-components/TextInput';
import { AcknowledgementComponent } from './AcknowledgmentItemComponent';

type HandleChange = (e: React.ChangeEvent<any>) => void;

export interface FormikAcknowledgementFormComponentProps {
    setFieldValue: SetFieldValueFunctionType;
    values: IAckFormValues;
    handleChange: HandleChange;
    touched: FormikTouched<IAckFormValues>;
}

export interface IAckFormValues {
    acknowledgementAddField?: string;
    acknowledgement?: string[];
}

export const FormikAcknowledgementFormComponent: React.FC<FormikAcknowledgementFormComponentProps> = ({ setFieldValue, values, handleChange, touched }) => {
    const ackRef = React.createRef<HTMLInputElement>();

    const handleAddAck = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addAck();
            ackRef.current?.focus();
        }
    };

    const addAck = () => {
        if (!values.acknowledgementAddField) return;
        let newAck = values.acknowledgement ?? [];
        newAck.push(values.acknowledgementAddField);

        setFieldValue("acknowledgement", newAck);
        setFieldValue("acknowledgementAddField", "");

        ackRef.current?.focus();
    };

    return (
        <>
            <div className="rowflex ackType default-padding-top" style={{ gap: "10px" }}>
                <div className="col">
                    <TextInput placeholder="Acknowledgement" name="acknowledgementAddField" value={values.acknowledgementAddField} onChange={handleChange} touched={touched.acknowledgementAddField} onKeyPress={handleAddAck} inputRef={ackRef} />
                </div>
                <div className="col right-align">
                    <ButtonInput type="button" caption="Add Acknowledgement" title="Add Acknowledgement" id="ackAddSubmit" onClick={addAck} />
                </div>
            </div>

            <div className="ackList">
                {
                    values.acknowledgement && values.acknowledgement.map((ack, index) => {
                        return <AcknowledgementComponent ackItem={ack} key={index} onDelete={(ack: string) => {
                            const newAck = values.acknowledgement ? values.acknowledgement.filter(ackd => ackd !== ack) : [];
                            setFieldValue("acknowledgement", newAck);
                        }} />;
                    })
                }
            </div>
        </>
    );
};
