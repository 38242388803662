import * as React from 'react';

import timerIcon from '../../../img/icon-actionPlanTimer.png';
import dateIcon from '../../../img/icon-date.png';

import closeIcon from '../../../img/close.png';


import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ResultStatus } from '../../../Types/ResultStatus';
import { LatestStatusPath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { JoditContentEditorModule } from '../../../form-components/JoditContentEditorModuleV3';
import { TextInput } from '../../../form-components/TextInput';
import { TextInput as TextInput2 } from '../../../form-components/TextInputV2';
import { DateHelper } from '../../../helper/DateHelper';
import { ActionPlanModel } from '../../ActionPlan/model/ActionPlan';
import { ActionPlanFields } from '../../FieldInformation/ui/ActionPlanFieldsV3';
import { useURL } from '../../Search/useURL';
import { SelectOptionObject } from '../../Shared/model/SelectOptionObjectModel';
import { useAddData } from '../../Shared/useAddData';
import { useDeleteData } from '../../Shared/useDeleteData';
import { useUpdateData } from '../../Shared/useUpdateData';
import { ResultType, useSelectMemberHelper } from '../../useSelectMemberHelperV2';
import { LatestStatusModel } from '../model/LatestStatusV2';

export const LatestStatusComponent = (props: LatestStatusModel) => {
    const { history, params } = useURL()

    const { add } = useAddData(LatestStatusPath, false)
    const { update } = useUpdateData(LatestStatusPath)
    const { remove } = useDeleteData(LatestStatusPath + props.id)
    const [isDeleted, setIsDeleted] = useState(false)

    const [memberList, setMemberList] = useState<ResultType[]>([])

    const [tempContractors, setTempContractors] = useState<string>(props.contractors ?? "")
    const [tempLocation, setTempLocation] = useState<string>(props.location ?? "")

    const setPersonnelList = (personnelListData?: string) => {
        let personnelList = "-"

        if (personnelListData) {
            let datas: SelectOptionObject[] = JSON.parse(personnelListData)
            datas.forEach(data => {
                if (personnelList == "-") {
                    personnelList = data.label
                }
                else {
                    personnelList += ", " + data.label
                }
            })
        }

        return personnelList
    }

    const [latestStatusData, setLatestStatusData] = useState<LatestStatusModel>({
        ...props,
        personnelListDisplay: setPersonnelList(props.personnelList),
        date_date: props.date ? moment(props.date).toDate() : undefined
    })

    const prevProps = usePrevious(props)
    const prevLatestStatusData = usePrevious(latestStatusData)

    useSelectMemberHelper((datas) => {
        if(params.addForm) setMemberList(datas)
    })

    useEffect(() => {
        if (!prevProps) return
        if (JSON.stringify(props) === JSON.stringify(prevProps)) return

        setLatestStatusData(
            (prevProps) => {
                return (
                    {
                        ...props,
                        personnelListDisplay: setPersonnelList(props.personnelList),
                        date_date: props.date ? moment(props.date).toDate() : undefined,
                        actionPlanId: props.actionPlanId,
                        actionPlanData: props.actionPlanData
                    })
            }
        )

        setTempContractors(props.contractors ?? "")
        setTempLocation(props.location ?? "")
    }, [props])

    useEffect(() => {
        if (!prevLatestStatusData) return

        const compareNow = {...latestStatusData, actionPlanData: latestStatusData.actionPlanData?.map(a => a.id) }
        const comparePrev = {... prevLatestStatusData, actionPlanData: prevLatestStatusData.actionPlanData?.map(a => a.id) }
        
        setTempContractors(latestStatusData.contractors ?? "")
        setTempLocation(latestStatusData.location ?? "")
        
        if (JSON.stringify(compareNow) === JSON.stringify(comparePrev)) return

        
        if (!latestStatusData.id || latestStatusData.id === "") {
            addLatestStatus()
            return
        }

        doSilentUpdate()

        
    }, [latestStatusData])

    const doSilentUpdate = () => {
        update({
            id: latestStatusData.id,
            projectId: latestStatusData.projectId,
            information: latestStatusData.information,
            analysis: latestStatusData.analysis,
            contractors: latestStatusData.contractors,
            personnelList: latestStatusData.personnelList,
            projectName: latestStatusData.projectName,
            projectType: latestStatusData.projectType,
            location: latestStatusData.location,
            date: latestStatusData.date_date ? DateHelper.dateISO8601(latestStatusData.date_date.toLocaleString("en-US")) : "",
            actionPlanId: latestStatusData.actionPlanId,
            resultUpdatedDate: new Date().toLocaleString("en-US")
        })
    }

    const addLatestStatus = () => {
        if (!latestStatusData.date || !latestStatusData.date_date) return

        add({
            projectId: latestStatusData.projectId,
            information: latestStatusData.information,
            analysis: latestStatusData.analysis,
            contractors: latestStatusData.contractors,
            personnelList: latestStatusData.personnelList,
            projectName: latestStatusData.projectName,
            projectType: latestStatusData.projectType,
            location: latestStatusData.location,
            date: latestStatusData.date_date ? DateHelper.dateISO8601(latestStatusData.date_date.toLocaleString("en-US")) : "",
            actionPlanId: latestStatusData.actionPlanId,
            resultCreatedDate: new Date().toLocaleString("en-US"),
            resultUpdatedDate: new Date().toLocaleString("en-US")
        }, {
            onSuccess(result) {
                setLatestStatusData({
                    ...latestStatusData,
                    id: result.data.id
                })
            },
        })
    }

    const handleDateChange = (date: Date | [Date, Date] | null) => {
        let pDate = date as Date
        if (pDate) {
            setLatestStatusData({
                ...latestStatusData,
                date_date: pDate,
                date: pDate.toLocaleString("en-US")
            })
        }
    }

    const confirmDeleteLatestStatus = () => {
        if (!latestStatusData.id) return
        if (!window.confirm("Yakin hapus Latest Status ini?")) return

        remove(undefined, {
            onSuccess(result) {
                if (result.status == ResultStatus.fail) {
                    alert(result.message)
                    return
                }

                setIsDeleted(true)
            }
        })
    }

    const handleLocationChange = (e: React.FormEvent<HTMLInputElement>) => {
        setLatestStatusData({
            ...latestStatusData,
            location: e.currentTarget.value
        })
    }

    const handleLocationChangeTemp = (e: React.FormEvent<HTMLInputElement>) => {
        setTempLocation(e.currentTarget.value)
    }

    const handleContractorsChange = (e: React.FormEvent<HTMLInputElement>) => {
        setLatestStatusData({
            ...latestStatusData,
            contractors: e.currentTarget.value
        })
    }

    const handleContractorsChangeTemp = (e: React.FormEvent<HTMLInputElement>) => {
        setTempContractors(e.currentTarget.value)
    }

    const handleStatusChange = (content: string) => {
        console.log(content)
        setLatestStatusData(prevData => ({
            ...prevData,
            information: content
        }))
    }

    const handleAnalysisChange = (content: string) => {
        setLatestStatusData(prevData => ({
            ...prevData,
            analysis: content
        }))
    }

    const addNewActionPlanField = () => {
        let newDummyActionPlan = {
            id: "",
            date: new Date().toLocaleString("en-US"),
            location: "",
            name: "",
            objective: "",
            projectId: props.projectId
        }

        setTimeout(() => {
            setLatestStatusData({
                ...latestStatusData,
                actionPlanData: (latestStatusData.actionPlanData ?
                    [...latestStatusData.actionPlanData, newDummyActionPlan] :
                    [newDummyActionPlan]
                )
            })
        }, 300)
        
    }

    const updateActionPlan = (idx: number, actionPlanId: string, actionPlanData?: ActionPlanModel) => {
        if (!latestStatusData.actionPlanData) return
        if (idx >= latestStatusData.actionPlanData.length) return

        var newActionPlanData = [...latestStatusData.actionPlanData]
        newActionPlanData[idx] = { ...actionPlanData, id: actionPlanId }

        var newActionPlanId = latestStatusData.actionPlanId ? [...latestStatusData.actionPlanId] : []
        if (idx < newActionPlanId.length) {
            newActionPlanId[idx] = actionPlanId
        }
        else {
            newActionPlanId.push(actionPlanId)
        }

        setLatestStatusData({
            ...latestStatusData,
            actionPlanId: newActionPlanId,
            actionPlanData: newActionPlanData
        })
    }

    const newDate = new Date().toLocaleString("en-US")

    const runDeleteActionPlan = (actionPlanId: string) => {
        if (!latestStatusData.actionPlanId || !latestStatusData.actionPlanData) return

        console.log("run 2")
        let newActionPlanId = [...latestStatusData.actionPlanId.filter(id => id != actionPlanId)]
        let newActionPlanData = [...latestStatusData.actionPlanData.filter(data => data.id != actionPlanId)]

        console.log(newActionPlanId)

        setLatestStatusData({
            ...latestStatusData,
            actionPlanId: newActionPlanId,
            actionPlanData: newActionPlanData
        })
    }

    if (isDeleted) return (
        <div className="latestStatus-container full-border">
            Latest Status deleted.
        </div>
    )

    return (
        <div id="form-container" className="latestStatus-form" onClick={() => console.log(props.id)}>
            {params.addForm &&
                <div className="title">
                    <h1>Add Latest Status & Analysis</h1>
                </div>
            }

            {latestStatusData.id && <div id={latestStatusData.id} style={{marginTop:"-85px", position: "absolute"}} />}

            <div className="latestStatus-container full-border">
                <div className="row latestStatus-header"></div>

                <div className="row">
                    <div className="col">
                        <div className="col latestStatus-when">
                            <DatePicker className="text-input default time" {...params.addForm && { placeholderText: "Time" }} selected={latestStatusData.date_date} onChange={(date: Date) => { handleDateChange(date) }}
                                showTimeSelect dateFormat="MMMM d, yyyy H:mm" timeFormat='HH:mm'
                                customInput={<TextInput icon={dateIcon} placeholder="" name="time" value={latestStatusData.date} />} />
                            <div className="latestStatus-delete" title='Delete Latest Status' onClick={() => { confirmDeleteLatestStatus() }}>
                                <img src={closeIcon} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rowflex latestStatus-description">
                    <div className="col default-margin-top">
                        <div className="latestStatus-personnelList">
                            <TextInput2 name="contractors" value={tempContractors} placeholder="With"
                                onChange={handleContractorsChangeTemp}
                                onBlur={handleContractorsChange} />
                        </div>
                    </div>

                    <div className="col default-margin-top">
                        <div className="col latestStatus-where">
                            <div className="latestStatus-location">
                                <TextInput2 name="location" value={tempLocation} placeholder="Where"
                                    onChange={handleLocationChangeTemp}
                                    onBlur={handleLocationChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row latestStatus-description">
                    <h2>Status</h2>
                    <JoditContentEditorModule
                        fieldName="information"
                        placehoder="Status"
                        value={latestStatusData.information}
                        handleChange={handleStatusChange}
                    />
                </div>

                <div className="row latestStatus-description">
                    <h2>Analysis</h2>
                    <JoditContentEditorModule
                        fieldName="analysis"
                        placehoder="Analysis"
                        value={latestStatusData.analysis}
                        handleChange={handleAnalysisChange}
                    />
                </div>

                <div className={("rowflex rowActionPlan " + (latestStatusData.actionPlanData && latestStatusData.actionPlanData.length > 0 ? "" : "hidden"))}>
                    <div className="rowflex center latestStatus-description">
                        <div className="latestStatus-with">Follow Up Action Plan</div>
                        <div className="latestStatus-actionPlan">
                            <div className="icon"><img src={timerIcon} /></div>
                        </div>
                    </div>
                </div>

                <div className={("rowflex latestStatus-actionPlanList " + (latestStatusData.actionPlanData && latestStatusData.actionPlanData.length > 0 ? "" : "hidden"))}>
                    {latestStatusData.actionPlanData &&
                        latestStatusData.actionPlanData.map((actionPlan, index) => (
                            <ActionPlanFields
                                key={index}
                                projectId={props.projectId}
                                id={actionPlan.id}
                                name={actionPlan.name}
                                location={actionPlan.location}
                                memberList={props.memberList ?? memberList}
                                picIds={actionPlan.picIds}
                                personnelList={actionPlan.personnelList}
                                contractors={actionPlan.contractors}
                                objective={actionPlan.objective}
                                time={actionPlan.date ?? newDate}
                                showTimeField
                                onActionPlanIdUpdate={
                                    (actionPlanId?: string, actionPlanData?: ActionPlanModel) => {
                                        console.log(actionPlanId, actionPlanData)
                                        if (!actionPlanId) { console.log("NO ID"); return }
                                        updateActionPlan(index, actionPlanId, actionPlanData)
                                    }
                                }
                                deleteable
                                clearable={false}
                                onDeleteActionPlan={() => actionPlan.id && runDeleteActionPlan(actionPlan.id)}
                            />
                        )
                        )
                    }

                </div>
                <div className="row latestStatus-description">
                    <div className="col">
                        <ButtonInput caption="Add Action Plan" title="Add Action Plan" onClick={() => addNewActionPlanField()} />
                    </div>
                </div>

                <div className="latestStatus-options">
                    <div className="status hidden">
                        {props.resultCreatedDate && props.resultUpdatedDate &&
                            (props.resultCreatedDate == props.resultUpdatedDate ? "Created at " : "Updated at ") +
                            DateHelper.dateFormat(props.resultUpdatedDate, "MMMM DD, YYYY")
                        }
                    </div>
                </div>

                <div className="clear"></div>
            </div>

            {params.addForm &&
                <div className="row default-padding-top">
                    <div className="col">
                        <ButtonInput type="button" caption="Back" title="Back" id="cancel" onClick={history.goBack} />
                    </div>
                </div>
            }

            <div className="container-separator-block">
                <div className="separator-block" />
                <div className="separator-block" />
                <div className="separator-block" />
            </div>
        </div>
    )
}

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}