import * as React from 'react'
import AsyncSelect from 'react-select/async'
import { FilterClientPath } from '../../database_api/api'
import { GetAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient'

export class AsyncClientSelectInputHandler{
    private fieldName: string
    private placeholder: string
    private client: GetAPIHTTPClient
    private onChange: (client: ClientResultType) => void

    constructor(fieldName: string, placeholder: string,
        client: GetAPIHTTPClient,
        onChange: (client: ClientResultType) => void){
        this.fieldName = fieldName
        this.placeholder = placeholder
        this.client = client
        this.onChange = onChange
    }

    private loadClientList = (inputValue: string, callback: (options: ClientResultType[]) => void) => {
        this.client.get(FilterClientPath + inputValue, result => {
            if(result.data == undefined){
                callback([])
                return
            }

            let returnedResult: {id: string, name: string}[] = result.data
            if(returnedResult.length == 0){
                callback([])
                return
            }

            callback(returnedResult.map( client => {
                return {label: client.name, value: client.id}
            }))
        })
        
    }

    private handleSelectedChange = (inputValue: any, _: any) => {
        this.onChange(inputValue)
    }

    getComponent = (value?: ClientResultType) => {
        return (
            <AsyncSelect cacheOptions 
                placeholder={this.placeholder}
                className="selectionStyle full-border"
                classNamePrefix="si"
                value={value}
                loadOptions={this.loadClientList} defaultOptions 
                onChange={this.handleSelectedChange}
                />
        )
    }
}

export type ClientResultType = {
    label: string,
    value: string
}