import React from 'react'
import { LogsList } from '../ui/LogsList';
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';

import * as H from "history"
import { LogsItemPerPage, LogsPagePath } from '../../../database_api/api';
import { APILogsListPagingLoader } from '../LogsListPagingLoader';
import { PagingFeatureControl } from '../../Paging/PagingFeatureControlProtocol';

import { PaginationComponent } from '../../Paging/ui/PaginationComponent';
import { LogsListController } from '../LogsListController';
import { SearchFeature } from '../../Search/SearchFeatureProtocol';
import { RouteComponentProps } from 'react-router-dom';
import { HTTPHeader } from '../../../interfaces/HTTPHeader';
import { AuthTokenSessionStorage } from '../../Login/AuthPermission';

export class LogsListBuilder extends React.Component<RouteComponentProps>{
    logsListLoader = new APILogsListPagingLoader(LogsPagePath, LogsItemPerPage, new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())))
    pagingFeatureControl = new PagingFeatureControl()
    logsListController = new LogsListController(this.logsListLoader, this.pagingFeatureControl)

    logsSearch = new SearchFeature(this.logsListLoader, this.logsListController)

    paginationComponent: JSX.Element
    
    constructor(props: RouteComponentProps){
        super(props)

        this.paginationComponent = <PaginationComponent itemPerPage={LogsItemPerPage} controller={this.logsListController} pagingController={this.pagingFeatureControl} />
    }

    render(){
        return <LogsList paginationComponent={this.paginationComponent} {...this.props} 
                    controller={this.logsListController} search={this.logsSearch} 
                />
    }
}