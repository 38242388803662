import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { POBHeaderAdd } from './POBHeaderAddV2';
import { POBHeaderEdit } from './POBHeaderEditV2';
import { POBHeaderList } from './POBHeaderListV2';

export class POBHeader extends React.Component<RouteComponentProps>{
    basePath: string
    constructor(props: RouteComponentProps){
        super(props)

        this.basePath = this.props.match.url
    }

    render(){
        return (
            <React.Fragment>
                <Route path={this.props.match.url} component={POBHeaderList} exact={true} />
                <Route path={this.props.match.url + "/add"} component={POBHeaderAdd}/>
                <Route path={this.props.match.url + "/edit/:id"} component={POBHeaderEdit}/>
            </React.Fragment>
        )
    }
}