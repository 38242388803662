import { FormMode } from '../../Client/ui/ClientFormV3';
import { AddFormTemplate } from '../../Shared/template/AddFormTemplate';
import { errorValidationSchema } from '../errorValidationSchema';
import { useLawRegulationAddFormHandler } from '../formHandler/useLawRegulationAddFormHandler';
import { LawRegulationForm } from '../LawRegulationForm';
import { LawRegulationModel } from '../model/LawRegulationV2';

export const LawRegulationAdd = () => {
    const formik = useLawRegulationAddFormHandler({
        name: ""
    }, errorValidationSchema)
    return (
        <AddFormTemplate<LawRegulationModel> {...formik}>
            {LawRegulationForm(FormMode.ADD, "Add New Law Regulation", formik)}
        </AddFormTemplate>
    )
}