import React from 'react';


import { ButtonInput } from '../../../form-components/ButtonInput';
import { HistoryComponent } from './HistoryComponentV2';


import { useParams } from 'react-router';
import { HistoryPagePath } from '../../../database_api/api';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { useURL } from '../../Search/useURL';
import { useDataList } from '../../Shared/useDataList';
import { HistoryModel } from '../model/HistoryV2';

export const HistoryList = () => {
    const itemSize = 8
    const { history, location, params } = useURL()
    const mainParams = useParams<{ id: string }>()
    const { data, totalData, totalPage, isLoading } = useDataList<HistoryModel>({
        url: HistoryPagePath,
        size: itemSize,
        identifier: "histories",
        ignoreParamsAsURL: ["contentId", "contentType", "contentName"],
        defaultParamsNotURL: { "projectId": mainParams.id }
    })

    if(params.mode === "add") {
        return <HistoryComponent projectId={mainParams.id} />
    }
    
    return (
    <React.Fragment>
        <div id="top-container">
            <div className="clear"></div>
        </div>

        <div id="add-menu-container">
            <ButtonInput id="add" caption="+" title="Add New" onClick={
                () => { 
                    history.push({
                        pathname: location.pathname,
                        search: location.search + "&mode=add"
                    })
                }
            } />
        </div>
        
        <div id="history-content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

        <div id="history-list">
            {
                (data && data.length > 0 && data.map((history, index) => {
                    return <HistoryComponent id={history.id}
                        projectId={history.projectId}
                        quarter={history.quarter}
                        year={history.year}
                        information={history.information}
                        key={`historyComponent${history.id}`} />
                }))
                || 
                <div className="message">No History registered</div>
            }
        </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    </React.Fragment>
    )
}