import { useState } from "react"

export let useQuarterYearFilter = (setQuarterFilter: ((quarter: string) => void), setYearFilter: ((year: string) => void)) => {
    let currentYear = new Date().getFullYear()
    let years = []

    for (let i = 0; i < 5; i++) {
        years.push((currentYear - i).toString())
    }

    let handleQuarterChange = (quarter: string) => {
        setQuarterFilter(quarter)
    }

    let handleYearChange = (year: string) => {
        setYearFilter(year)
    }

    return {
        years,
        handleQuarterChange,
        handleYearChange
    }
}