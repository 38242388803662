import React, { useEffect } from "react"
import { useParams } from "react-router"
import { POBContentList } from "../../../../../database_api/api"
import { Lang } from "../../../../../lang/Lang"
import { useURL } from "../../../../Search/useURL"
import { useGetData } from "../../../../Shared/useGetData"
import { ContentListResult } from "../../../ContentListLoader"

export const usePOBContent = () => {
    const [content, setContent] = React.useState<ContentListResult[] | undefined>(undefined)
    const { history, params, location, replaceTo } = useURL()
    const mainParams = useParams<{
        type: string
        id: string
    }>()
    
    const { get } = useGetData(POBContentList + mainParams.type + "/" + mainParams.id, "Load Content")

    useEffect(() => {
        if(!content) return

        if(!params.contentType)
            replaceTo(location.pathname, {
                contentId: content[0].id,
                contentName: content[0].name,
                contentType: content[0].type
            })
    }, [content])

    const getContent = () => {
        get(undefined, {
            onSuccess(data) {
                setContent(sortContentData(data.data))
            }
        })
    }

    const sortContentData = (data?: ContentListResult[]) => {
        if (!data) return data

        let newData = [...data]
        let itemData = newData[2]
        newData.splice(2, 1)

        newData = [itemData, ...newData]
        return mapContentDataMenuName(newData)
    }

    const mapContentDataMenuName = (rawData?: ContentListResult[]) => {
        if (!rawData) return rawData

        rawData.forEach(data => {
            if (data.name == Lang.base_projectFieldInformation) {
                data.name = Lang.projectFieldInformation
            }

            if (data.name == Lang.base_data) {
                data.name = Lang.projectData
            }
        })

        return rawData
    }

    return { getContent, content }
}