import CreatableAsyncSelect from 'react-select/async-creatable';

import { CustomerTagPath, FilterCustomerTagPath } from '../../../database_api/api';
import { ResultStatus } from '../../../Types/ResultStatus';
import { useAddData } from '../../Shared/useAddData';
import { useGetData } from '../../Shared/useGetDataWithParam';

export type CustomerTagResultType = {
    label: string,
    value: string
}

export const CreatableAsyncCustomerTagSelectInputHandler = (props: {
    fieldName: string,
    placeholder: string,
    value?: CustomerTagResultType[],
    onChange: (tags?: CustomerTagResultType[]) => void
    onNewTagAdded: (tag: CustomerTagResultType) => void
}) => {

    const { fieldName, placeholder, onChange, onNewTagAdded, value } = props

    const { get } = useGetData(FilterCustomerTagPath, "")
    const { add } = useAddData(CustomerTagPath, false)

    const loadCustomerTagList = (inputValue: string, callback: (options: CustomerTagResultType[]) => void) => {
        if(inputValue == "" || inputValue == undefined) {
            callback([])
            return
        }

        get(inputValue, {
            onSuccess: (result) => {
                if(result.data == undefined){
                    callback([])
                    return
                }

                let returnedResult: {id: string, name: string}[] = result.data
                if(returnedResult.length == 0){
                    callback([])
                    return
                }

                callback(returnedResult.map( tag => {
                    return {label: tag.name, value: tag.id}
                }))
        }})
    }

    const handleSelectedChange = (inputValue: any, _: any) => {
        let selected: CustomerTagResultType[] | undefined = inputValue

        if(selected == undefined) {
            onChange(undefined)
            return
        }


        onChange(selected.map( sel => {
            return {
                label: sel.label,
                value: sel.label.replace(" ", "-").toLowerCase()
            }
        }))
    }

    const onCreateOption = (inputValue: string) => {
        add({name: inputValue}, {
            onSuccess: (result) => {
                if(result.status == ResultStatus.ok){
                    onNewTagAdded({
                        label: result.data.name,
                        value: result.data.id
                    })
                }
        }})
    }

    return (
        <CreatableAsyncSelect 
            placeholder={placeholder}
            className="selectionStyle full-border"
            classNamePrefix="si"
            isMulti
            onCreateOption={onCreateOption}
            value={value}
            loadOptions={loadCustomerTagList} defaultOptions 
            onChange={handleSelectedChange}
            />
    )
}

