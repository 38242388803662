import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient'
import { SelectOptionObject } from '../../../form-components/FormikSelectInputHandler'
import { TextInput } from '../../../form-components/TextInput'
import { DateHelper } from '../../../helper/DateHelper'
import dateIcon from '../../../img/icon-date.png'
import { HTTPHeader } from '../../../interfaces/HTTPHeader'
import { Lang } from '../../../lang/Lang'
import { ResultStatus } from '../../../Types/ResultStatus'
import { AddActionPlanModuleInterface } from '../../ActionPlan/ActionPlanAddAPI'
import { EditActionPlanModuleInterface } from '../../ActionPlan/ActionPlanEditAPI'
import { ActionPlanType, AsyncCreatableActionPlanSelectInputHandler } from '../../LatestStatus/ui/AsyncCreatableActionPlanSelectInputHandler'
import { AuthTokenSessionStorage } from '../../Login/AuthPermission'

export class ActionPlanFields extends React.Component<ActionPlanFieldsProps, ActionPlanFieldsState>{
    constructor(props: ActionPlanFieldsProps){
        super(props)

        var actionPlanSelected = undefined
        if (props.actionPlanName && props.actionPlanId) {
            actionPlanSelected = {
                label: props.actionPlanName,
                value: props.actionPlanId
            }
        }

        this.state = {
            date_date: moment(props.time).toDate(),
            actionPlanSelected: actionPlanSelected,
            location: props.location ?? "",
            objective: props.objective ?? "",
            contractors: props.contractors ?? "",
            personnelList: props.personnelList ?? "",
            ...props
        }
    }

    componentDidUpdate = (prevProps: ActionPlanFieldsProps) => {
        if (prevProps.time == this.props.time) return

        this.setState({
            time: this.props.time,
            date_date: moment(this.props.time).toDate()
        }, () => {
            if(this.state.actionPlanName) this.updateActionPlan(this.state.actionPlanName)
        })

        if (prevProps.actionPlanId == this.props.actionPlanId ) return

        var actionPlanSelected = undefined
        if (this.props.actionPlanName && this.props.actionPlanId) {
            actionPlanSelected = {
                label: this.props.actionPlanName,
                value: this.props.actionPlanId
            }
        }

        this.setState({
            date_date: moment(this.props.time).toDate(),
            actionPlanSelected: actionPlanSelected,
            location: this.props.location ?? "",
            objective: this.props.objective ?? "",
            personnelList: this.props.contractors ?? "",
            contractors: this.props.contractors ?? "",
            ...this.props
        })
    }

    handleActionPlanChange = (actionPlan?: ActionPlanType) => {
        if (actionPlan == undefined) {
            this.setState({
                actionPlanId: undefined,
                actionPlanName: undefined,
                actionPlanSelected: undefined,
                location: "",
                personnelList: "",
                contractors: "",
                objective: ""
            }, () => {
                this.props.doSilentUpdate(undefined, true)
            })
            return
        }

        this.setState({
            actionPlanId: actionPlan.id,
            actionPlanName: actionPlan.name,
            actionPlanSelected: {
                label: actionPlan.name,
                value: actionPlan.id
            },
            location: actionPlan.location ?? "",
            personnelList: actionPlan.personnelList,
            contractors: actionPlan.contractors ?? "",
            objective: actionPlan.objective ?? ""
        }, () => {
            this.props.doSilentUpdate(actionPlan.id)
        })
    }

    createActionPlan = (name: string) => {
        if (!this.state.date_date) return

        this.props.actionPlanAddHandler.add({
            projectId: this.state.projectId,
            date: DateHelper.dateISO8601(this.state.date_date.toLocaleString("en-US")),
            name: name,
            location: this.state.location,
            objective: this.state.objective,
            personnelList: this.state.personnelList,
            contractors: this.state.contractors
        }, result => {
            this.handleActionPlanChange({
                id: result.data.id,
                name: result.data.name
            })
        })
    }

    updateActionPlan = (name: string, refresh: Boolean = true) => {
        if(!this.state.date_date) return

        if (this.state.actionPlanId) {
            let id = this.state.actionPlanId
            this.props.actionPlanEditHandler.update({
                id: id,
                projectId: this.state.projectId,
                date: DateHelper.dateISO8601(this.state.date_date.toLocaleString("en-US")),
                name: name,
                location: this.state.location,
                objective: this.state.objective,
                personnelList: this.state.personnelList,
                contractors: this.state.contractors
            }, result => {
                if (result.status == ResultStatus.ok && refresh)
                    this.handleActionPlanChange({
                        id: id,
                        name: name
                    })
            })
        }
    }

    handleActionPlanCreate = (name: string) => {
        if (!this.state.actionPlanSelected) {
            this.createActionPlan(name)
        }
        else {
            this.updateActionPlan(name)
        }
    }

    callUpdateActionPlan = () => {
        if (this.state.actionPlanName) {
            this.updateActionPlan(this.state.actionPlanName, false)
        }
    }

    client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))
    actionPlanSelector = new AsyncCreatableActionPlanSelectInputHandler("actionPlanId", "Select " + Lang.actionPlanItem, this.client, this.props.projectId ?? "", this.handleActionPlanChange, this.handleActionPlanCreate)

    handleChangeForActionPlanFields = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState<never>({
            [`${e.currentTarget.name}`]: e.currentTarget.value
        })
    }

    handleBlurForActionPlanFields = (e: React.FormEvent<HTMLInputElement>) => {
        if (!this.state.actionPlanSelected) {
            return
        }

        this.setState<never>({
            [`${e.currentTarget.name}`]: e.currentTarget.value
        }, this.callUpdateActionPlan)
    }

    handleDateChange = (date: Date | [Date, Date] | null) => {
        let pDate = date as Date
        if (pDate) {
            this.setState({
                date_date: pDate,
                time: pDate.toLocaleString("en-US")
            }, () => {
                if(this.state.actionPlanName) this.updateActionPlan(this.state.actionPlanName)
            })
        }
    }
    
    render = () => {
        return (
            <div className="col right default-margin-left actionPlanSection">
                <div id="actionPlanSectionContainer">
                    { this.props.showTimeField && this.state.time && 
                    <div className="rowflex center">
                        <div className="col left">
                            <div className="fieldInformation-label">{Lang.actionPlanTime}</div>
                        </div>
                        <div className="col right">
                            <DatePicker className="text-input default" placeholderText="" selected={this.state.date_date} onChange={(date: Date) => { this.handleDateChange(date) }}
                                showTimeSelect dateFormat="MMMM d, yyyy H:mm" timeFormat='HH:mm'
                                customInput={<TextInput icon={dateIcon} placeholder="" name="time" value={this.state.time} />} />
                        </div>
                    </div>
                    }

                    { this.state.time &&
                    <div className="rowflex center">
                        <div className="col left">
                            <div className="fieldInformation-label">{Lang.actionPlanItem}</div>
                        </div>
                        <div className="col right">
                            {this.actionPlanSelector.getComponent(this.state.actionPlanSelected)}
                        </div>
                    </div>
                    }

                    { this.state.time && this.state.actionPlanId &&
                    <div className={"actionPlanFields"}>
                        <div className="row">
                            <div className="fieldInformation-location">
                                <TextInput name="location" value={this.state.location} placeholder={Lang.actionPlanLocation}
                                    onChange={this.handleChangeForActionPlanFields}
                                    onBlur={this.handleBlurForActionPlanFields} /></div>
                        </div>

                        <div className="row">
                            <div className="fieldInformation-personnelList">
                                    <TextInput name="personnelList" value={this.state.personnelList} placeholder={Lang.actionPlanCustomerPersonnel}
                                    onChange={this.handleChangeForActionPlanFields}
                                    onBlur={this.handleBlurForActionPlanFields} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="fieldInformation-personnelList">
                                <TextInput name="contractors" value={this.state.contractors} placeholder={Lang.actionPlanThirdParty}
                                    onChange={this.handleChangeForActionPlanFields}
                                    onBlur={this.handleBlurForActionPlanFields} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="fieldInformation-objective">
                                <TextInput name="objective" value={this.state.objective} placeholder={Lang.actionPlanObjective}
                                    onChange={this.handleChangeForActionPlanFields}
                                    onBlur={this.handleBlurForActionPlanFields} />
                            </div>
                        </div>

                        {this.props.children && this.props.children}
                    </div>
                    }
                </div>
            </div>

        )
    }
}

interface ActionPlanFieldsProps {
    actionPlanAddHandler: AddActionPlanModuleInterface
    actionPlanEditHandler: EditActionPlanModuleInterface
    doSilentUpdate(actionPlanId?: string, clearActionPlan?: boolean): void
    projectId?: string
    actionPlanId?: string
    actionPlanName?: string
    location?: string
    objective?: string
    personnelList?: string
    contractors?: string
    time: string
    showTimeField?: boolean
}

interface ActionPlanFieldsState {
    date_date?: Date
    actionPlanId?: string
    actionPlanName?: string
    projectId?: string
    location: string
    objective: string
    personnelList?: string
    contractors: string
    time: string
    actionPlanSelected?: SelectOptionObject
}