import { useContext, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { useParams } from "react-router"
import GlobalData from "../../../../GlobalData"
import { BoxButtonComponent } from "../../../../components/BoxButtonComponent"
import NextIcon from '../../../../img/next.png'
import PrevIcon from '../../../../img/prev.png'
import { SupportingDocsBuilder } from "../../../ProjectFiles/SupportingDocsBuilder"
import { useURL } from "../../../Search/useURL"
import { ContentListType } from "../../ContentListLoader"
import { POBContentHeaderDownloadButton } from "../POBBlockContentListComponent"
import { POBContentType } from "../POBContentV2"
import { DownloadActionPlan, DownloadBackground, DownloadFieldInformation, DownloadHistory, DownloadLatestStatus, DownloadProject } from "../ProjectDataDownload"
import { POBChildMenu } from "./POBChildMenu"
import { usePOBContent } from "./hook/usePOBContent"

export const POBContentMenu = () => {
    const { content, getContent } = usePOBContent()
    const { history, location, params, goTo } = useURL()
    const mainParams = useParams<{ type: string, id: string }>()
    const [openSupportingDocs, setOpenSupportingDocs] = useState(false)
    const { pageTitle } = useContext(GlobalData)

    useEffect(() => {
        getContent()
    }, [])

    useEffect(() => {
        toggleSupportingDocs(openSupportingDocs)
    }, [openSupportingDocs])

    const toggleSupportingDocs = (state: boolean) => {
        ReactDOM.render((state ? <SupportingDocsBuilder projectId={mainParams.id} /> : <></>), document.getElementById("supportingDocs"))
    }

    const downloadFn = () => {
        if (params.contentType == ContentListType.data) {
            DownloadProject(pageTitle, mainParams.id, mainParams.type === POBContentType.POBHeader ? true : false)
            return
        }

        if (params.contentType == ContentListType.content) {
            DownloadBackground(pageTitle, mainParams.id, mainParams.type === POBContentType.POBHeader ? true : false)
            return
        }

        if (params.contentType == ContentListType.latestStatus) {
            DownloadLatestStatus(pageTitle, mainParams.id)
            return
        }

        if (params.contentType == ContentListType.actionPlan) {
            DownloadActionPlan(pageTitle, mainParams.id)
            return
        }

        if (params.contentType == ContentListType.fieldInformation) {
            DownloadFieldInformation(pageTitle, mainParams.id)
            return
        }

        if (params.contentType == ContentListType.history) {
            DownloadHistory(pageTitle, mainParams.id)
            return
        }
    }

    const scrollLeft = () => {
        const scrollableContainer = document.getElementById("scrollable-container")
        if (!scrollableContainer) return

        scrollableContainer.scrollLeft -= 200
    }

    const scrollRight = () => {
        const scrollableContainer = document.getElementById("scrollable-container")
        if (!scrollableContainer) return

        scrollableContainer.scrollLeft += 200
    }

    return (
        <div id="pob-content-header-container">
            <BoxButtonComponent aclass="pagination-button" icon={PrevIcon} onClick={() => scrollLeft()} />
            <div className="pob-content-header-contentList-menu" id="scrollable-container">
                <div className="scrollable-container">
                    <POBChildMenu />
                    {
                        content && content.filter(mn => mn.type !== ContentListType.supportingDocs).map(mn => {
                            return (
                                <div key={mn.id}
                                    className={"contentList-name" + ((mn.id == params.contentId) ? " selected" : "")}
                                    onClick={() => {
                                        goTo(location.pathname, {
                                            contentId: mn.id,
                                            contentName: mn.name,
                                            contentType: mn.type
                                        })
                                    }}
                                >
                                    <div className="separator-block"></div>
                                    {mn.name}
                                </div>
                            )
                        })
                    }
                    {
                        content && content.filter(mn => mn.type === ContentListType.supportingDocs).map(mn => {
                            return (
                                <div key={mn.id}
                                    className={"contentList-name" + ((mn.id == params.contentId) ? " selected" : "")}
                                    onClick={() => setOpenSupportingDocs(!openSupportingDocs)}
                                >
                                    <div className="separator-block"></div>
                                    {mn.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <BoxButtonComponent aclass="pagination-button" icon={NextIcon} onClick={() => scrollRight()} />
            <div id="below-nav">
                <POBContentHeaderDownloadButton onClick={() => { downloadFn() }} />
                <div id="download-component"></div>
            </div>
        </div>
    )
}