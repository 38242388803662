import { HTTPMethod, HTTPHeader } from '../interfaces/HTTPHeader';

export interface PostAPIHTTPClient {
    post(url: string, jsonData: string, callback: (result: any) => void): void
}

export interface PatchAPIHTTPClient {
    patch(url: string, jsonData: string, callback: (result: any) => void): void
}

export interface GetAPIHTTPClient {
    get(url: string, callback: (result: any) => void): void
}

export interface DeleteAPIHTTPClient {
    delete(url: string, callback: (result: any) => void): void
}

export interface FormPostAPIHTTPClient<DataType> {
    postForm(url: string, data: DataType, callback: (result: any) => void): void
}

export interface FormPatchAPIHTTPClient<DataType> {
    patchForm(url: string, data: DataType, callback: (result: any) => void): void
}

export class StandardAPIHTTPClient implements 
    PostAPIHTTPClient, GetAPIHTTPClient, 
    PatchAPIHTTPClient, DeleteAPIHTTPClient,
    FormPostAPIHTTPClient<FormData>, FormPatchAPIHTTPClient<FormData>{
    header: HTTPHeader
    constructor(header: HTTPHeader){
        this.header = header
    }

    delete(url: string, callback: (result: any) => void) {
        let requestOptions = {
            method: HTTPMethod.delete,
            headers: this.header.header(),
        }
        fetch(url, requestOptions).then(response => response.json()).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }

    post(url: string, jsonData: string, callback: (result: any) => void) {
        let requestOptions = {
            method: HTTPMethod.post,
            headers: this.header.header(),
            body: jsonData
        }

        fetch(url, requestOptions).then(response => response.json()).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }

    patch(url: string, jsonData: string, callback: (result: any) => void) {
        let requestOptions = {
            method: HTTPMethod.patch,
            headers: this.header.header(),
            body: jsonData
        }

        fetch(url, requestOptions).then(response => response.json()).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }

    get(url: string, callback: (result: any) => void) {
        let requestOptions = {
            method: HTTPMethod.get,
            headers: this.header.header(),
        }
        fetch(url, requestOptions).then(response => response.json()).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }

    postForm(url: string, data: FormData, callback: (result: any) => void) {
        let requestOptions = {
            method: HTTPMethod.post,
            headers: this.header.formHeader(),
            body: data
        }

        fetch(url, requestOptions).then(response => response.json()).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }

    patchForm(url: string, data: FormData, callback: (result: any) => void) {
        let requestOptions = {
            method: HTTPMethod.patch,
            headers: this.header.formHeader(),
            body: data
        }

        fetch(url, requestOptions).then(response => response.json()).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }
}