import { PostAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient';
import { QueryComponent, QueryFilterProtocol } from '../Search/QueryFilterProtocol';
import { ResultStatus } from '../../Types/ResultStatus';

export interface ListPagingResult<DataType>{
    status: ResultStatus,
    data?: DataType[],
    message?: string,
    totalData: number,
    totalDataShowed: number,
    currentPage: number,
    totalPage: number
}

export class GenericAPIListPagingLoader<PagingResult> implements QueryFilterProtocol{
    protected client: PostAPIHTTPClient
    protected itemPerPage: number
    protected mainPath: string

    private queryComponents: QueryComponent[] = []

    constructor(mainPath: string, itemPerPage: number, client: PostAPIHTTPClient){
        this.client = client
        this.itemPerPage = itemPerPage
        this.mainPath = mainPath
    }

    load(page: number, completion: (result: PagingResult) => void){
        page = (page < 1 ? 1 : page)
        let itemPerPageURL = this.itemPerPage + "/"

        this.client.post(this.mainPath + itemPerPageURL + page, this.generateRequestOptions(), (result) => {
            this.processResult(result, completion)
        })
    }

    protected processResult(result: PagingResult, completion: (result: PagingResult) => void){
        completion(result)
    }

    protected generateRequestOptions = (): string =>  {
        return this.queryComponents.length > 0 ? JSON.stringify(this.queryComponents) : ""
    }

    addFilter(queryComponents: QueryComponent[]){
        this.queryComponents = queryComponents
    }
}