import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';
import { CustomerPath } from '../../../database_api/api';
import { useAddData } from '../../Shared/useAddData';
import { CustomerModel } from '../model/CustomerV2';

export const useCustomerAddFormHandler = (initialValues: CustomerModel, validationSchema: ObjectSchema) => { 
    const { add } = useAddData(CustomerPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let customerData = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                note: values.note,
                description: values.description,
                tags: values.tags
            }

            console.log(customerData)
            setSubmitting(false)
            add(customerData)
        }
    })

    return formik
}