import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { LawRegulationAdd } from './LawRegulationAddV2';
import { LawRegulationEdit } from './LawRegulationEditV2';
import { LawRegulationList } from './LawRegulationListV2';

export const LawRegulation = (props: RouteComponentProps) => {
    return (
        <React.Fragment>
            <Route path={props.match.url} component={LawRegulationList} exact={true} />
            <Route path={props.match.url + "/add"} component={LawRegulationAdd}/>
            <Route path={props.match.url + "/edit/:id"} component={LawRegulationEdit}/>
        </React.Fragment>
    )
}