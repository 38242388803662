import * as React from 'react';
import { FieldMessage } from './FieldMessage';

export interface TextInputProp{
    icon?: any
    helpText?: string
    inputRef?: React.RefObject<HTMLInputElement>
    placeholder: string
    [param: string]: any
}

export const TextInput = (textProps: TextInputProp) => {
    const { aclass, placeholder, type, message, touched, inputRef, name, ...props } = textProps
    
    return (
        <div className="input-container">
            <input type={type || "text"} name={name}
            className={"text-input " + (aclass ?? 'default') + (props.icon ? '' : ' no-icon') }
            placeholder={ placeholder }
            onChange={props.onChange}
            {...props}
            value={props.value} ></input>
            <label htmlFor={name}>{placeholder}</label>
            {message && touched && <FieldMessage message={message} />}
            {props.icon && 
                <div className="icon">
                    <img src={ props.icon }></img>
                </div>
            }
            {props.helpText && <div className="helper-data">{props.helpText}</div> }
        </div>
    )
}