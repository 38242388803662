import React from 'react';

import { ButtonInput } from '../../../form-components/ButtonInput';
import { DbBackupComponent, DbBackupComponentProp } from './DbBackupComponent';

import { ListLoaderControllerProtocol } from '../../ListLoaderControllerProtocol';
import { DbBackupListDisplayUpdaterProtocol } from '../DbBackupListDisplayUpdaterProtocol';

import { StaticContext } from "react-router";
import { RouteComponentProps } from 'react-router-dom';
import { MessageType } from '../../../MessageType';
import { LoadingControlProtocol } from '../../Search/SearchComponent';

export interface DbBackupListRouteProtocol{
    requestBackup(): void
    requestRestore(id: string): void
    uploadBackup(): void
}

export type DbBackupListMessage = {
    message?:{type: MessageType, message: string}
}

export type DbBackupListRouteComponentProps = RouteComponentProps<{}, StaticContext, DbBackupListMessage>

interface DbBackupListProp extends DbBackupListRouteComponentProps{
    router: DbBackupListRouteProtocol
    paginationComponent?: JSX.Element
    controller: ListLoaderControllerProtocol & DbBackupListDisplayUpdaterProtocol & LoadingControlProtocol
}

export class DbBackupList extends React.Component<DbBackupListProp, {dbBackups: DbBackupComponentProp[], message?:{type: MessageType, message: string}}> { 
    constructor(props: DbBackupListProp){
        super(props)

        let messageState = this.props.location.state ? this.props.location.state.message : undefined

        this.state = {
            dbBackups: [],
            message: messageState
        }

        this.props.controller.onLoad = this.onLoadDbBackups

        this.props.controller.load(1)
    }

    onLoadDbBackups = (dbBackups: DbBackupComponentProp[]) => {
        this.setState({
            dbBackups: dbBackups
        })
    }

    render(){
        return (
        <React.Fragment>
            <div id="top-container">
                <div className="dbBackup-options">
                    <ButtonInput caption="Request Backup" title="Request Backup" onClick={() => { this.props.router.requestBackup() }} />
                    <ButtonInput name="upload" caption="Upload Backup File" title="Upload Backup File" onClick={() => { this.props.router.uploadBackup() }} />
                </div>

                <div className="clear"></div>
            </div>

            <div id="content-container">
            {this.props.paginationComponent}

            {this.state.message && <div className={"message " + this.state.message.type}>{this.state.message.message}</div> }
            { this.state.message && setTimeout(() => { this.setState({message: undefined}) }, 3000) }

            <div id="dbBackup-list">
                {
                    (this.state.dbBackups && this.state.dbBackups.length > 0 && this.state.dbBackups.map((dbBackup, index) => {
                        return <DbBackupComponent id={dbBackup.id} name={dbBackup.name} key={dbBackup.id} router={this.props.router}
                            date={dbBackup.date} status={dbBackup.status} dbFilePath={dbBackup.dbFilePath}
                            error={dbBackup.error} restoreHistories={dbBackup.restoreHistories}
                        />
                    }))
                    || 
                    <div className="message">No dbBackup registered</div>
                }
            </div>

            {this.props.paginationComponent}
            </div>
        </React.Fragment>
        )
    }
}