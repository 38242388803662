import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"
import { Timeout } from "../../components/Timeout"

export const useURL = () => {
    const history = useHistory()
    const location = useLocation()
    const params = queryString.parse(location.search)

    const goTo = (url: string, params?: any) => {
        Timeout(() => {
            history.push({
                pathname: url,
                search: queryString.stringify(params)
            })
        })
    }

    const replaceTo = (url: string, params?: any) => {
        history.replace({
            pathname: url,
            search: queryString.stringify(params)
        })
    }

    return { history, location, params, goTo, replaceTo }
}