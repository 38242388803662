import React, { useEffect } from "react"
import { DocRenderer } from "@cyntler/react-doc-viewer"
import blockedPopup from "./../img/blocked-popup.jpg"
import allowPopup from "./../img/allow-popup.jpg"

export const XLSXRenderer: DocRenderer = ({
    mainState: { currentDocument }
}) => {
    useEffect(() => {
        setTimeout(() => {
            openDoc()
        }, 1000)
    }, [])

    const openDoc = () => {
        window.open(`https://docs.google.com/gview?url=${currentDocument!.uri}`, "_blank")
    }

    if (!currentDocument) return null

    return (
        <>
            <div>
                If you see this image on your URL bar<br />
                <img src={blockedPopup} /><br/><br />
                Please click it and allow popup.<br />
                <img src={allowPopup} />
                <br /><br />
                
                File will be opened using google drive. Please wait...<br/>
                If you wait more than 5 seconds, please refresh this page.
            </div>
        </>
    )
}

XLSXRenderer.fileTypes = ["xls", "xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
XLSXRenderer.weight = 1