import moment from 'moment'
import { useHistory } from 'react-router'
import { EditButton } from '../../../components/EditButton'
import optionMenu from '../../../img/option.png'
import { RoutePath } from '../../../page/RoutePath'
import { DatabaseTNIALModel } from '../model/DatabaseTNIAL'

export const DatabaseTNIALComponentSimpleList = (props: DatabaseTNIALModel) => {
    const history = useHistory()
    
    return (
        <tr key={props.id}>
            <td>{props.serial_number}</td>
            <td>{props.name}</td>
            <td>{props.corps}</td>
            <td>{props.nrp}</td>
            <td>{props.birthOfDate ? moment(props.birthOfDate).toNow(true) + " old" : "-" }</td>
            <td className="customerPersonnel-options">
                <EditButton basePath={RoutePath.databaseTNIALList} code={props.id} />
                <input className="option" type="button" src={optionMenu} value={"view"} onClick={() => { 
                    history.push(RoutePath.databaseTNIALList + "/view/" + props.id)
                }} />

                <input className="option add-to-print" style={{display:"none"}} type="button" src={optionMenu} value="+P"
                    onClick={() => {
                        console.log("Add to print is not implemented yet")
                    }}

                />
            </td>
        </tr>
    )
}