

import { ButtonInput } from '../../../form-components/ButtonInput';
import { POBHeaderComponent } from './POBHeaderComponentV2';


import { useHistory } from "react-router";
import { POBHeaderPagePath } from '../../../database_api/api';
import { ListLayoutFacade } from '../../../layout/ListLayoutFacade';
import { RoutePath } from '../../../page/RoutePath';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from '../../Search/FlexibleSearchComponentV2';
import { OptionInputSearchComponent } from '../../Search/OptionInputSearchComponentV2';
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { POBHeaderModel } from '../model/POBHeader';

export const POBHeaderList = () =>  { 
    const history = useHistory()
    const itemSize = 8
    const { data, totalData, totalPage, isLoading, params } = useDataList<POBHeaderModel>({
        url: POBHeaderPagePath,
        size: itemSize,
        identifier: "pobHeaders"
    })
    
    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Name"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "code",
            displayField: "Project Code"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "projectManagerId",
            displayField: "Project Manager",
            reference: {
                type: "member",
                targetField: "username",
                searchField: "name"
            }
        }
    ]

    const topSearchComponent = (
        <div id="search-container">
            <FlexibleSearchComponent isLoading={isLoading} placeholder="Name, Phone, Email" queryOptions={queryOptions} />
        </div>
    )

    const filterComponents = [
        <>
            <h2>Status</h2>
            {<OptionInputSearchComponent name='filter' options={[
                { key: 'all', value: 'All' },
                { key: '1', value: 'Active' },
                { key: '0', value: 'Not Active' }
            ]}
                initialValue={typeof params.active === "string" ? params.active : 'all'}
            />}
        </>
    ]

    const contentComponent = (
        <div id="pobHeader-content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="pobHeader-list">
                {
                    data && data.length > 0 && data.map((pobHeader, index) => {
                        return (
                            <POBHeaderComponent key={`pobHeader${pobHeader.id}`} {...pobHeader} />
                        )
                    })
                    ||
                    <div className="message">No pobHeader registered</div>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    )

    const addComponent = <ButtonInput id="add" caption="+" title="Add New" onClick={() => {
        history.push(RoutePath.pobHeaderList + "/add")
    }} />

    return (
        <ListLayoutFacade
            topSearchComponent={topSearchComponent}
            filterComponents={filterComponents}
            contentComponents={contentComponent}
            addComponent={addComponent}
        />
    )
}