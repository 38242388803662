import { FormikProps } from "formik"
import DatePicker from 'react-datepicker'
import { TextInput } from "../../form-components/TextInput"
import { Lang } from "../../lang/Lang"
import { LawRegulationModel } from "./model/LawRegulationV2"

import { AxiosAPIHTTPClient } from "../../database_api/AxiosAPIHTTPClient"
import { JoditContentEditorModule } from "../../form-components/JoditContentEditorModule"
import { UploadProgressBarComponent } from "../../form-components/UploadProgressBarComponent"
import { FormikFileUploadFactory } from "../../FormikHelper/FormikFileUploadFactory"
import dateIcon from '../../img/icon-date.png'
import fileIcon from '../../img/icon-file.png'
import nameIcon from '../../img/icon-name.png'
import { HTTPHeader } from "../../interfaces/HTTPHeader"
import { FormMode } from "../Client/ui/ClientFormV3"
import { AuthTokenSessionStorage } from "../Login/AuthPermission"
import { TagResultType } from "./ui/CreatableAsyncTagSelectInputHandler"
import { CreatableAsyncTagSelectInput } from "./ui/CreatableAsyncTagSelectInputV2"

export const LawRegulationForm: <T extends LawRegulationModel>(mode:FormMode, pageTitle: string, props: FormikProps<T>) => JSX.Element =
(mode, pageTitle, props) => {
    const { values, errors, touched, handleChange, setFieldValue } = props

    const header = new HTTPHeader(new AuthTokenSessionStorage())
    const uploadClient = new AxiosAPIHTTPClient(header)

    let fileHandler = new FormikFileUploadFactory(setFieldValue, "file_url", "file_description", "isFileChanged", "lawRegulation-file", fileIcon, values.file_url, values.file_description)

    let handleDateChange = (date: Date | [Date, Date] | null) => {
        setFieldValue('ruleDate_date', date)
        if (date && date instanceof Date) {
            setFieldValue('ruleDate', date.toLocaleDateString())
        }
    }

    let handleDescriptionChange = (content: string) => {
        setFieldValue("description", content)
    }

    let handleStatusChange = (content: string) => {
        setFieldValue("status", content)
    }

    let handleTagChange = (tags?: TagResultType[]) => {
        setFieldValue("tags", JSON.stringify(tags))
        setFieldValue("selectedTags", tags)
    }

    let handleNewTagChange = (tag: TagResultType) => {
        if (!values.tags) {
            setFieldValue("tags", JSON.stringify([tag]))
            handleTagChange([tag])
            return
        }

        var tags: TagResultType[] = [...JSON.parse(values.tags), tag]
        handleTagChange(tags)
    }


    let contentEditor = new JoditContentEditorModule().get("description", Lang.lawRegulationDescription, values.description, handleDescriptionChange)
    let status = new JoditContentEditorModule().get("status", Lang.lawRegulationStatus, values.status ?? "", handleStatusChange)


    return (
        <>
            <div className="page-title">{pageTitle}{values.name}</div>
            <div className="rowflex">
                <div className="col">
                    <TextInput icon={nameIcon} placeholder={Lang.lawRegulationName} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />

                </div>
                <div className="col default-padding-left">
                    <div className="input-container">
                        <DatePicker className="text-input default" placeholderText={Lang.lawRegulationDate} selected={values.ruleDate_date} onChange={(date) => { handleDateChange(date) }}
                            dateFormat="MMMM d, yyyy"
                            customInput={<TextInput icon={dateIcon} placeholder="Rule Date" name="ruleDate" value={values.ruleDate} />} />
                    </div>
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col">
                    <CreatableAsyncTagSelectInput
                        fieldName="tags"
                        placeholder={Lang.lawRegulationCategory}
                        value={values.selectedTags}
                        onChange={handleTagChange}
                        onNewTagAdded={handleNewTagChange} 
                    />
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col">
                    {contentEditor}
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="field-description">Status</div>
                <div className="col">
                    {status}
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col right-aligned">
                    { mode === FormMode.ADD ? 
                        fileHandler.getAddComponent():
                        <>
                            {fileHandler.getEditComponent()}
                            <UploadProgressBarComponent uploadProgress={uploadClient} hideOnFinish={true} />
                        </>
                    }
                </div>
            </div>
        </>
    )
}