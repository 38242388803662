import * as React from 'react';

export type OptionValue = {key: string, value: string}

type OptionInputProp = {
    name: string, 
    options?: OptionValue[]
    [param: string]: any
}

type OptionInputState = {
    options?: JSX.Element[]
}

export class OptionInput extends React.Component<OptionInputProp, OptionInputState>{

    constructor(props: OptionInputProp){
        super(props)
    }

    componentDidMount = () => {
        this.updateOptions()
    }

    componentDidUpdate = (prevProps: OptionInputProp, prevState: OptionInputState) => {
        if(prevProps.value == this.props.value) return
        this.updateOptions()
    }

    updateOptions = () => {
        let options: JSX.Element[]

        options = [
            <React.Fragment key="default"><input className="option" type="radio" name={this.props.name} value="default" id="default"/>
            <label htmlFor="default">Default</label>
            </React.Fragment>
        ]

        if(this.props.options){
            options = []
            
            this.props.options.map((option) => {
                options?.push(
                <React.Fragment key={option.key + option.value}>
                    <input className="option" type="radio" name={this.props.name} value={ option.key } id={ option.key + option.value } checked={this.props.value === option.key} onChange={this.props.onChange}/>
                    <label htmlFor={ option.key + option.value }>{ option.value }</label>
                </React.Fragment>)
            })
        }

        this.setState({
            options: options
        })
    }

    render(){
        return (
        <div className="option-input">
            {this.state && this.state.options && this.state.options}
        </div>
        )
    }
}