import * as React from 'react';
import { TextInput } from '../../../form-components/TextInput';
import defaultProfile from '../../../img/default-profile.png';
import dobIcon from '../../../img/icon-date.png';
import emailIcon from '../../../img/icon-email.png';
import nameIcon from '../../../img/icon-name.png';
import passwordIcon from '../../../img/icon-password.png';
import phoneIcon from '../../../img/icon-phone.png';
import usernameIcon from '../../../img/icon-user.png';

import { FormikProps } from 'formik';
import { MessageType } from '../../../MessageType';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { OptionInput, OptionValue } from '../../../form-components/OptionInput';
import { SimpleMessage } from '../../../form-components/SimpleMessage';
import bottomOrnament from '../../../img/form-ornament-bottom.png';
import topOrnament from '../../../img/form-ornament-top.png';

import DatePicker from 'react-datepicker';
import { FormikImageUploadFactory } from '../../../FormikHelper/FormikImageUploadFactory';
import { Lang } from '../../../lang/Lang';
import { MemberModel } from '../model/Member';

export interface MemberAddRouterProtocol{
    cancel(): void
    successAdd(type: MessageType, message: string): void
}

export interface MemberAddProp extends MemberModel{
    retype_password: string
    dob_date?: Date
    global: string
    router: MemberAddRouterProtocol
    photo_base64?: string
}

export const MemberAdd: React.FC<FormikProps<MemberAddProp>> = (props: FormikProps<MemberAddProp>) => {
    const {values, errors, touched, handleSubmit, handleChange, setFieldValue, isSubmitting} = props
    let genderOption: OptionValue[] = [
        {key: "male", value: "Male"},
        {key: "female", value: "Female"}
    ]

    let onCancel = () => {
        values.router.cancel()
    }

    let photoHandler = new FormikImageUploadFactory(setFieldValue, "photo_url", "photo_base64", "isPhotoChanged", "member-profile-photo", defaultProfile, values.photo_url, values.photo_base64)

    let handleDateChange = (date: Date | [Date, Date] | null) => {
        setFieldValue('dob_date', date)
        if(date && date instanceof Date){
            setFieldValue('dob', date.toLocaleDateString())
        }
    }
    
    return (
        <div id="form-container">
            {errors.global && <SimpleMessage title={Lang.mMembers + " Add Failed"} body={errors.global} onClick={() => { setFieldValue("global", null) }} /> }
            <div className="top-ornament"><img src={topOrnament}/></div>
            <div className="bottom-ornament"><img src={bottomOrnament}/></div>

            <form onSubmit={handleSubmit}>
            <div className="page-title">Add New {Lang.mMembers}</div>
            <div className="rowflex">
                <div className="col">
                    {photoHandler.getAddComponent()}
                    <OptionInput name="gender" options={genderOption} value={values.gender} onChange={handleChange} />
                </div>
                <div className="col default-padding-left">
                    <TextInput icon={usernameIcon} placeholder={Lang.memberUsername} name="username" value={values.username} onChange={handleChange} message={errors.username} touched={touched.username} />
                    <TextInput icon={nameIcon} placeholder={Lang.memberName} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                    <TextInput icon={usernameIcon} placeholder={Lang.memberPosition} name="position" value={values.position} onChange={handleChange} message={errors.position} touched={touched.position} />
                    <TextInput icon={phoneIcon} placeholder={Lang.memberContactNumber} name="phone" value={values.phone} onChange={handleChange} message={errors.phone} touched={touched.phone} />
                    <TextInput icon={emailIcon} placeholder={Lang.memberEmail} name="email" value={values.email} onChange={handleChange} message={errors.email} touched={touched.email} />

                    <div className="input-container">
                        <DatePicker className="text-input input-container default" placeholderText="Date of Birth" selected={values.dob_date} onChange={ (date) => { handleDateChange(date) }} 
                        dateFormat="MMMM d, yyyy"
                        customInput={<TextInput icon={dobIcon} placeholder="Date of Birth" name="dob" value={values.dob} />} />
                    </div>
                </div>
            </div>

            <div className="row default-padding-top">
                <div className="col">
                    <TextInput icon={passwordIcon} type="password" placeholder="Password" aclass="default full-width" name="password" value={values.password} onChange={handleChange} message={errors.password} touched={touched.password}/>
                    <TextInput icon={passwordIcon} type="password" placeholder="Retype Password" aclass="default full-width" name="retype_password" value={values.retype_password} onChange={handleChange} message={errors.retype_password} touched={touched.retype_password}/>
                </div>
            </div>
            
            <div className="row default-padding-top">
                <div className="col">
                    <ButtonInput type="button" caption="Cancel" title="Cancel" id="cancel" onClick={() => { onCancel() }} {...(isSubmitting ? {disabled:"disabled"} : {}) }/>
                    <ButtonInput id="submit" {...(isSubmitting ? {disabled:"disabled", caption: "Registering"} : {caption: "Submit"}) } />
                </div>
            </div>
            </form>
        </div>
    )
}