import { FormMode } from "../../Client/ui/ClientFormV3"
import { AddFormTemplate } from "../../Shared/template/AddFormTemplate"
import { errorValidationSchema } from "../builder/ErrorMessage/errorValidationSchema"
import { usePOBHeaderAddFormHandler } from "../builder/usePOBHeaderAddFormHandler"
import { POBHeaderModel } from "../model/POBHeader"
import { POBHeaderForm } from "./POBHeaderForm"

export const POBHeaderAdd = () => {
    const formik = usePOBHeaderAddFormHandler({
        name: "",
        budgetList: []
    }, errorValidationSchema)

    const addBudget = () => {
        document.getElementById("budgetAddSubmit")?.click()
    }

    return (
        <AddFormTemplate<POBHeaderModel> {...formik} onAdditionalSubmitTrigger={addBudget}>
            {POBHeaderForm(FormMode.ADD, "Add New", formik)}
        </AddFormTemplate>
    )
}