import * as React from 'react';

export type FooterProp = {
    note: string
}

export class Footer extends React.Component<FooterProp, {}>{
    constructor(props: FooterProp){ super(props) }
    render(){
        return <footer>
            <div className="decoration-footer"><span>{this.props.note}</span></div>
            <div className="line-decoration-footer"></div>
        </footer>
    }
}