export enum QueryCompareTypeEnum{
    equal = "=",
    like = "LIKE",
    timeYear = "YEAR"
}

type QueryReferenceComponent = {
    type: string
    targetField: string
    searchField: string
}

export type QueryComponent = {
    field: string
    compareType: QueryCompareTypeEnum
    reference?: QueryReferenceComponent
    value?: string
    displayField?: string
}

export interface QueryFilterProtocol{
    addFilter(queryComponents: QueryComponent[]): void
}