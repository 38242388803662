import { AddFormTemplate } from '../../Shared/template/AddFormTemplate'
import { useNewsAddFormHandler } from '../formHandler/useNewsAddFormHandler'
import { NewsModel } from '../model/News'
import { errorValidationSchema } from '../validation/errorValidationSchema'
import { NewsForm } from './NewsForm'

export const NewsAdd = () => {
    const formik = useNewsAddFormHandler({
        title: "", description: ""
    }, errorValidationSchema)

    return (
        <AddFormTemplate<NewsModel> {...formik}>
            {NewsForm("Add New", formik)}
        </AddFormTemplate>
    )
}