import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import { ActionPlanGoogleCalendarConnectionPath, ActionPlanPagePath } from '../../../../database_api/api'
import { ButtonInput } from '../../../../form-components/ButtonInput'
import { RoutePath } from '../../../../page/RoutePath'
import { ActionPlanModel } from '../../../ActionPlan/model/ActionPlan'
import { ActionPlanComponent } from '../../../ActionPlan/ui/ActionPlanComponentV2'
import { AddResult } from '../../../GenericAPI/GenericAddAPI'
import { PaginationComponent } from '../../../Paging/ui/PaginationComponentV2'
import { FlexibleSearchComponent } from '../../../Search/FlexibleSearchComponentV2'
import { QueryCompareTypeEnum } from '../../../Search/QueryFilterProtocol'
import { useDataList } from '../../../Shared/useDataList'
import { useGetData } from '../../../Shared/useGetData'

export const ActionPlanListForContentType = () => { 
    const history = useHistory()

    const itemSize = 8
    const { id: projectId } = useParams<{id: string}>()
    const [ googleCalendarConnectionStatus, setGoogleCalendarConnectionStatus ] = useState<boolean>(false)
    const [ showGoogleCalendarRenewalInstruction, setShowGoogleCalendarRenewalInstruction ] = useState<boolean>(false)

    const { data, totalData, totalPage, isLoading } = useDataList<ActionPlanModel>({
        url: ActionPlanPagePath,
        size: itemSize,
        identifier: "actionPlans", 
        ignoreParamsAsURL: [
            "contentId", "contentName", "contentType", "resultId"
        ], 
        defaultParamsNotURL: {projectId: projectId}
    })

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Name"
        }
    ]

    const { get, isLoading: isLoadingCheckGoogleCalendarConnection } = useGetData(ActionPlanGoogleCalendarConnectionPath)

    useEffect(() => {
        get(undefined, {
            onSuccess: (data: AddResult) => {
                console.log(data)
                setGoogleCalendarConnectionStatus(data.data.connectionStatus)

                // if data.authUrl is not empty, then open url in new tab
                if (!data.data.connectionStatus) {
                    window.open(data.data.authUrl, "_blank")
                }

            }
        })
    }, [])

    return (
    <React.Fragment>
        <div id="top-container">
            <div id="search-container">
                <FlexibleSearchComponent isLoading={isLoading} placeholder="Agenda/Event" queryOptions={queryOptions} />
            </div>
            <div className="clear"></div>
        </div>

        <div id="add-menu-container">
            <ButtonInput id="add" caption="+" title="Add New" onClick={() => {
                history.push(RoutePath.actionPlanList + "/add?projectId=" + projectId)
            }} />
        </div>
        
        <div id="content-container">
        
        <div id="google-calendar-checker" style={{marginTop: "20px"}}>
            <ButtonInput style={{width: "100%"}}
                onClick={() => {
                    setShowGoogleCalendarRenewalInstruction(!showGoogleCalendarRenewalInstruction)
                }}
                className={ isLoadingCheckGoogleCalendarConnection ? "googleCalendarCheck" : 
                    googleCalendarConnectionStatus ? "googleCalendar-tag" : "no-googleCalendar-tag" }
                caption={ isLoadingCheckGoogleCalendarConnection ? "Google Calendar Connection Checking" : 
                    googleCalendarConnectionStatus ? 'Google Calendar Connection: OK' : 
                    'Google Calendar Connection: FAIL. Klik DISINI untuk melihat instruksi.' } 
            />

            { showGoogleCalendarRenewalInstruction &&
            <div style={{border: "1px solid lightgrey", padding: "20px", textAlign: "left"}}>
                <h3>Step 1</h3>
                Apabila koneksi ke google calendar mati, pada waktu membuka action plan ( halaman ini )page, akan terbuka window di TAB baru yang menuju ke google auth untuk perbaharui token.
                <img src="/googleCalendar/image1.png" width="100%" /><br /><br />
                Pilih email account, jangan lupa set ke bravado armada email punya.<br />
                Apabila tidak ada window baru yang terbuka, lihat kanan atas browser anda dan tekan icon yang menandakan bahwa popup di block, kemudian pilih allow popup.
                <img src="/googleCalendar/image-allow-popup.png" width="100%" /><br /><br /><br />
                
                <h3>Step 2</h3>
                <img src="/googleCalendar/image4.png" width="100%" /><br /><br />
                Pilih Continue.
                <img src="/googleCalendar/image3.png" width="100%" /><br /><br />
                Pilih Continue.
                <br /><br />
                Setelah muncul halaman yang menyatakan result: success, close tab browser tersebut, dan refresh halaman action plan page ( halaman ini).

            </div>
            }
        </div>
        <PaginationComponent
            totalData={totalData}
            totalPage={totalPage}
            isLoading={isLoading}
            itemPerPage={itemSize}
        />

        <div id="actionPlan-list">
            {
                (data && data.length > 0 && data.map((ap, index) => {
                    return <ActionPlanComponent id={ap.id} name={ap.name} date={ap.date}
                        location={ap.location}
                        objective={ap.objective}
                        projectId={ap.projectId}
                        projectName={ap.projectName}
                        personnelList={ap.personnelList}
                        contractors={ap.contractors}
                        status={ap.status}
                        fieldInformationId={ap.fieldInformationId}
                        picIds={ap.picIds}
                        googleCalendarEventId={ap.googleCalendarEventId}
                        key={`actionPlan${ap.id}`} />
                }))
                || 
                <div className="message">No actionPlan registered</div>
            }
        </div>

        <PaginationComponent
            totalData={totalData}
            totalPage={totalPage}
            isLoading={isLoading}
            itemPerPage={itemSize}
        />
        </div>
        <div id="actionPlan-result"></div>
    </React.Fragment>
    )
}