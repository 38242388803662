import * as React from 'react';
import closeIcon from '../../../img/close.png';

interface ContactNumberComponentProps {
    contactNumberItem: string;
    onDelete: (contactNumber: string) => void;
    [param: string]: any;
}

export class ContactNumberComponent extends React.Component<ContactNumberComponentProps> {
    contactNumberItem: string;

    constructor(props: ContactNumberComponentProps) {
        super(props);
        this.contactNumberItem = props.contactNumberItem;
    }

    handleDelete = () => {
        this.props.onDelete(this.contactNumberItem);
    }

    render() {
        const { aclass, type, onDelete, ...props } = this.props;
        return (
            <div className="input-container budgetType">
                <input
                    type={type || "text"}
                    className={"text-input " + (aclass ?? '') + ' no-icon'}
                    value={this.contactNumberItem}
                    {...props}
                    disabled
                />
                <img src={closeIcon} onClick={() => this.handleDelete()} />
            </div>
        );
    }
}
