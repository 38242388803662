import DatePicker from 'react-datepicker';
import { ButtonInput } from "../../../form-components/ButtonInput";
import { TextInput } from "../../../form-components/TextInput";
import dateIcon from '../../../img/icon-date.png';
import { useDateRangeFilter } from '../hook/useDateRangeFilter';


interface DateRangeFilterProps {
    downloadDecisionFn: ((download: boolean) => void) | undefined
    setFromFilter: ((from: Date) => void)
    setToFilter: ((from: Date) => void)
}

let DateRangeFilter: (props: DateRangeFilterProps) => JSX.Element = ({ downloadDecisionFn, setFromFilter, setToFilter }) => {
    let {from, to,  fromDate, toDate, handleDateChange} = useDateRangeFilter(setFromFilter, setToFilter)

    return (
        <section id="download-component-container">
            <form id="form-container" className="daterange-filter">
                <div className="input-container">
                    <DatePicker className="text-input input-container default" placeholderText="From" dateFormat="MMMM d, yyyy"
                        onChange={(date) => handleDateChange("from", date)} selected={fromDate}
                        customInput={<TextInput icon={dateIcon} placeholder="From" name="from" value={from} />} />
                </div>
                <div className="input-container">
                    <DatePicker className="text-input input-container default" placeholderText="To" dateFormat="MMMM d, yyyy"
                        onChange={(date) => handleDateChange("to", date)} selected={toDate}
                        customInput={<TextInput icon={dateIcon} placeholder="To" name="to" value={to} />} />
                </div>
                <div className="row default-padding-top">
                    <div className="col">
                        <ButtonInput type="button" caption="Cancel" title="Cancel" id="cancel" onClick={() => {
                            downloadDecisionFn && downloadDecisionFn(false)
                        }} />
                        <ButtonInput type="button" id="submit" caption="Download" title="Download" onClick={() => {
                            downloadDecisionFn && downloadDecisionFn(true)
                        }} />
                    </div>
                </div>
            </form>
        </section>
    )
}

export { DateRangeFilter };
