import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';
import { POBHeaderPath } from '../../../database_api/api';
import { useSetProjectName } from '../../POBContent/useSetProjectName';
import { useUpdateFormData } from '../../Shared/useUpdateFormData';
import { POBHeaderModel } from '../model/POBHeader';

export const usePOBHeaderEditFormHandler = (initialValues: POBHeaderModel, validationSchema?: ObjectSchema) => {
    const { updateProjectName } = useSetProjectName()
    const { update } = useUpdateFormData(POBHeaderPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            const pobHeaderData = {
                id: values.id,
                code: values.code,
                name: values.name,
                fiscalYearFrom: values.fiscalYearFrom,
                fiscalYearTo: values.fiscalYearTo,
                amount: values.amount,
                active: values.active
            };

            let pobHeaderDataForm = new FormData();
            Object.entries(pobHeaderData).forEach(field => {
                if (field[1])
                    pobHeaderDataForm.append(field[0], field[1]);
            });
            pobHeaderDataForm.append("budgetList", JSON.stringify(values.budgetList));
            if (values.projectManagerId && values.projectManagerId != "0") {
                pobHeaderDataForm.append("projectManagerId", values.projectManagerId);
            }

            if (values.classificationBudgetSelected) {
                pobHeaderDataForm.append("classificationBudget", values.classificationBudgetSelected.value);
            }

            pobHeaderDataForm.append('isPhotoChanged', (values.isPhotoChanged ? "true" : "false"));

            if (values.photo_url)
                pobHeaderDataForm.append('photo_url', values.photo_url);
            
            setSubmitting(false)
            update(pobHeaderDataForm, {
                onSuccess: () => {
                    updateProjectName(values.name ?? "", "Parent Project")
                }
            })
        }
    })

    return formik
}