import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { POBAdd } from './POBAddV2';
import { POBEdit } from './POBEditV2';
import { POBList } from './POBListV2';

export class POB extends React.Component<RouteComponentProps>{
    basePath: string
    constructor(props: RouteComponentProps){
        super(props)

        this.basePath = this.props.match.url
    }

    render(){
        return (
            <React.Fragment>
                <Route path={this.props.match.url} component={POBList} exact={true} />
                <Route path={this.props.match.url + "/add"} component={POBAdd}/>
                <Route path={this.props.match.url + "/edit/:id"} component={POBEdit}/>
            </React.Fragment>
        )
    }
}