import { ButtonInput } from "../../../form-components/ButtonInput";
import { SelectInput } from '../../../form-components/SelectInput';
import { useQuarterYearFilter } from '../hook/useQuarterYearFilter';


interface QuarterYearFilterProps {
    downloadDecisionFn: ((download: boolean) => void) | undefined
    setQuarterFilter: ((quarter: string) => void)
    setYearFilter: ((year: string) => void)
}

let QuarterYearFilter: (props: QuarterYearFilterProps) => JSX.Element = ({ downloadDecisionFn, setQuarterFilter, setYearFilter }) => {
    let { years, handleQuarterChange, handleYearChange } = useQuarterYearFilter(setQuarterFilter, setYearFilter)

    return (
        <section id="download-component-container">
            <form id="form-container" className="daterange-filter">
                <div className="input-container">
                    <SelectInput onChange={handleQuarterChange} 
                        placeholder="Select Quarter"
                        options={[
                            { text: "Q4", value: "Q4" },
                            { text: "Q3", value: "Q3" },
                            { text: "Q2", value: "Q2" },
                            { text: "Q1", value: "Q1" }
                        ]}
                    />
                </div>
                <div className="input-container">
                    <SelectInput onChange={handleYearChange}
                        placeholder="Select Year"
                        options={years.map( year => { return { text: year, value: year} })}
                    />
                </div>
                <div className="row default-padding-top">
                    <div className="col">
                        <ButtonInput type="button" caption="Cancel" title="Cancel" id="cancel" onClick={() => {
                            downloadDecisionFn && downloadDecisionFn(false)
                        }} />
                        <ButtonInput type="button" id="submit" caption="Download" title="Download" onClick={() => {
                            downloadDecisionFn && downloadDecisionFn(true)
                        }} />
                    </div>
                </div>
            </form>
        </section>
    )
}

export { QuarterYearFilter };
