import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import { SearchButtonInput } from '../../form-components/SearchButtonInput';
import searchIcon from '../../img/icon-search.png';
import { QueryComponent } from './QueryFilterProtocol';
import { SearchFeatureProtocol } from './SearchFeatureProtocol';


type SearchQueryComponent = {
    label: string 
    value: string
    uniqueValue: string
    query: QueryComponent
}

export interface LoadingControlProtocol{
    register(method: (status: boolean) => void): void
    setLoading(status: boolean): void
}

type SearchComponentProp = {
    searchModule: SearchFeatureProtocol, 
    loadingController: LoadingControlProtocol, 
    placeholder: string,
    queryOptions: QueryComponent[]
    defaultValue?: {label: string, value: string, uniqueValue: string, query: QueryComponent}[]
    fixedAppliedQuery?: QueryComponent
    setRefreshTrigger?: (refresh: () => void) => void
}

interface SearchComponentState{
    searchInputValue: SearchQueryComponent[]
    options: SearchQueryComponent[]
    isLoading: boolean
}

export class SearchComponent extends React.Component<SearchComponentProp, SearchComponentState>{
    searchModule: SearchFeatureProtocol
    loadingController: LoadingControlProtocol

    constructor(props: SearchComponentProp){
        super(props)
        this.searchModule = props.searchModule
        this.loadingController = props.loadingController
        
        this.state = {
            searchInputValue: this.props.defaultValue ?? [],
            options: [],
            isLoading: false
        }

        this.loadingController.register(this.setLoading)
        this.props.setRefreshTrigger && this.props.setRefreshTrigger(this.onClick)
    }

    componentDidMount = () => {
        if(this.props.defaultValue){
            this.onClick()
        }
    }

    setLoading = (status: boolean) => {
        this.setState({
            isLoading: status
        })
    }

    onClick = () => {
        let param: QueryComponent[] = (this.state.searchInputValue ? this.state.searchInputValue.map((value) => {
            let query = value.query
            return {
                compareType: query.compareType,
                field: query.field,
                value: query.value,
                reference: query.reference
            }
        }) : [])

        this.props.fixedAppliedQuery && param.push(this.props.fixedAppliedQuery)
        this.searchModule.search(param)
    }

    handleChange = (inputValue: any, _: any) => {
        this.setState({
            searchInputValue: inputValue
        })
      };

    optionMapper = (inputValue: string): {label: string, value: string, uniqueValue: string, query: QueryComponent}[] => {
        let options: {label: string, value: string, uniqueValue: string, query: QueryComponent}[] = []
        this.props.queryOptions.forEach( query => {
            options.push(
                {
                    label: query.displayField + ": " + inputValue, value: inputValue, uniqueValue: query.field + ": " + inputValue, query: {
                        compareType: query.compareType,
                        field: query.field,
                        value: inputValue, 
                        reference: query.reference
                    }
                }
            )
        })
        return options
    } 

    handleInputChange = (inputValue: any, _: any) => {
        if(inputValue == undefined || inputValue == null || inputValue == ""){
            this.setState({
                options: []
            })
        }
        else{
            this.setState({
                options: this.optionMapper(inputValue)
            })
        }
    };

    render(){
        return (
            <form id="search-container">
                    <CreatableSelect className="search-input" placeholder={this.props.placeholder} isLoading={this.state.isLoading} classNamePrefix="si" isMulti options={this.state.options} 
                        value={this.state.searchInputValue}
                        onChange={this.handleChange} onInputChange={this.handleInputChange} getOptionValue={ option => option.uniqueValue }/>
                    <SearchButtonInput id="find" title="Search" icon={searchIcon} onClick={this.onClick} {...(this.state.isLoading ? {disabled:"disabled"} : {}) }/>
            </form>
        )
    }
}