import { RouteComponentProps, useHistory } from 'react-router';
import { NewsPagePath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { ListLayoutFacade } from '../../../layout/ListLayoutFacade';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from '../../Search/FlexibleSearchComponentV2';
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { NewsModel } from '../model/News';
import { NewsComponent } from './NewsComponent';
import { YearNewsListComponent } from './YearNews/YearNewsListComponent';

export const NewsList = (props: RouteComponentProps) => { 
    const itemSize = 8
    const { data, totalData, totalPage, isLoading } = useDataList<NewsModel>({
        url: NewsPagePath,
        size: itemSize,
        identifier: "news"
    })

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "title",
            displayField: "Title"
        }
    ]

    let filterComponents = [
        <YearNewsListComponent/>
    ]

    let contentComponent = (
        <div id="content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="news-list">
                {
                    (data && data.length > 0 && data.map((news, index) => {
                        return <NewsComponent id={news.id}
                            title={news.title} link={news.link} description={news.description}
                            tags={news.tags} time={news.time}
                            key={index} />
                    }))
                    ||
                    <div className="message">No news registered</div>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    )

    return (
        <ListLayoutFacade
            topSearchComponent={
                <div id="search-container">
                    <FlexibleSearchComponent isLoading={isLoading} placeholder="Name, Phone, Email" queryOptions={queryOptions} />
                </div>
            }
            filterComponents={filterComponents}
            contentComponents={contentComponent}
            addComponent={<AddComponent basePath={props.match.url} />}
        />
    )
}

const AddComponent = (props: { basePath: string }) => {
    const history = useHistory()
    return <ButtonInput id="add" caption="+" title="Add New" onClick={() => history.push(props.basePath + "/add")} />
}