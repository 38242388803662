import queryString from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { HTTPMethod } from "../../interfaces/HTTPHeader";
import { ListPagingResult } from "../Paging/GenericAPIListPagingLoader";
import { useURL } from "../Search/useURL";
import { useHeader } from "./useHeader";

interface DataListProps {
    url: string
    size: number
    identifier: string
    ignoreParamsAsURL?: string[]
    defaultParamsNotURL?: {[x:string]: string}
    manualFetch?: boolean
}

export const useDataList = <DATA>(props: DataListProps) => {
    const { url, size, identifier, ignoreParamsAsURL, defaultParamsNotURL, manualFetch } = props
    const { header } = useHeader()
    const {params, location} = useURL()

    const page = params.page ? parseInt(params.page as string) : 1
    if(!params.size) params.size = size.toString()

    const { data, isLoading, refetch, isFetching } = useQuery<ListPagingResult<DATA> | undefined>(identifier, () => fetchClients(), {enabled: false})

    const requestOptions = {
        method: HTTPMethod.get,
        headers: header.header()
    }

    useEffect(() => {
        if(manualFetch) return
        refetch()
    }, [location.search])
    
    const fetchClients = async () => {
        const apiParams = {...params}
        if (ignoreParamsAsURL) {
            ignoreParamsAsURL.forEach((param) => {
                delete apiParams[param]
            })
        }

        if (defaultParamsNotURL) {
            Object.keys(defaultParamsNotURL).forEach((param) => {
                if (!apiParams[param]) {
                    apiParams[param] = defaultParamsNotURL[param]
                }
            })
        }

        return fetch(`${url}?${queryString.stringify(apiParams)}`, requestOptions)
            .then((response) => response.json())
    }

    return { page, 
        data: data ? data.data : [], 
        isLoading,
        totalPage: data ? data.totalPage : 0,
        totalData: data ? data.totalData : 0, 
        totalDataShowed: data ? data.totalDataShowed : 0,
        params,
        getDataList: refetch,
        isFetching
    };
}