export class Lang{
    // PROJECT TAB NAMES
    static base_projectFieldInformation: string = "Field Information"
    static projectFieldInformation: string = "Result"
    static base_data: string = "Data"
    static projectData: string = "Project Cover"

    // CLIENT PERSONNEL
    static clientPersonnelName: string = "Name"
    static clientPersonnelCompany: string = "Company"
    static clientPersonnelPosition: string = "Position"
    static clientPersonnelContactNumber: string = "Contact Number"
    static clientPersonnelEmail: string = "Email" 

    // CLIENT COMPANY
    static clientName: string = "Client Name"
    static clientProduct: string = "Product"
    static clientBusinessUnitOfficer: string = "Business Unit Officer"
    static clientAddress: string = "Address"
    static clientPhone: string = "Phone"
    static clientEmail: string = "Email"
    static clientWebsite: string = "Website"
    static clientSocialMedia: string = "Social Media"

    // CUSTOMER PROFILE
    static customerProfileName: string = "Name"
    static customerProfileRank: string = "Rank"
    static customerProfilePosition: string = "Position"
    static customerProfileCorps: string = "Corps"
    static customerProfilePeriod: string = "Period"
    static customerProfileContactNumber: string = "Contact Number"
    static customerProfilePositionHistoryLabel: string = "Position History"
    static customerProfileSequenceNumber: string = "S. No."
    static customerProfileGeneration: string = "Class of"
    static customerProfileDescription: string = "Description"
    static customerProfileSpouse: string = "Spouse"
    static customerProfileChildren: string = "Children"
    static customerProfileEducation: string = "Education"
    static customerProfileAcknowledgement: string = "Acknowledgement"

    // ACTION PLAN
    static actionPlanItem: string = "Agenda/Event"
    static actionPlanTime: string = "Time"
    static actionPlanLocation: string = "Venue"
    static actionPlanObjective: string = "Purpose/Objective"
    static actionPlanPicIds: string = "PIC"
    static actionPlanCustomerPersonnel: string = "With"
    static actionPlanThirdParty: string = "Third Party"
    static actionPlanResult: string = "Result"

    // LAW & REGULATION
    static lawRegulationName: string = "About"
    static lawRegulationCategory: string = "Category"
    static lawRegulationDate: string = "Date"
    static lawRegulationDescription: string = "Description"
    static lawRegulationStatus: string = "Status"

    // MEMBER / SYSTEM ADMINISTRATION / PERSONNEL PROFILE
    static memberUsername: string = "Username/ID"
    static memberName: string = "Name"
    static memberPosition: string = "Position"
    static memberContactNumber: string = "Contact Number"
    static memberEmail: string = "Email"

    // PARENT PROJECT
    static parentProjectClassificationBudget: string = "Classification Budget"
    static parentProjectAmount: string = "Amount"

    //MENUS
    static mDatabaseTNIAL: string = "Database TNI AL Per Angkatan"
    static mCustomerPersonnel: string = "Customer Profile"
    static mClient: string = "Client"
    static mProspectiveClient: string = "Prospective Client"
    static mClientPartner: string = "Client Partner"
    static mClientPersonnel: string = "Client Personnel"
    static mMembers: string = "System Administration"
    static mPersonnelProfile: string = "Personnel Profile"
}