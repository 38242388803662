import { useEffect, useState } from 'react'
import { useURL } from '../../Search/useURL'

export const AngkatanComponent = () => {
    const { location, params, goTo, replaceTo } = useURL()
    const [options, setOptions] = useState<number[]>([])
    const [selectedOption, setSelectedOption] = useState<number | undefined>(params.generation ? parseInt(params.generation as string) : undefined)

    useEffect(() => {
        const angkatan = Array.from({length: 55}, (x, i) => i+1)
        setOptions([...angkatan])
    }, [])

    useEffect(() => {
        goTo(location.pathname, { ...params, generation: selectedOption })
    }, [selectedOption])
    

    return (
        <>
            <h2>Angkatan</h2>
            <ul>
                { options.map( option => (
                    <li key={option}>
                        <a 
                            className={ selectedOption == option ? "selected" : ""}
                            onClick={() => { 
                                (option === selectedOption) ? 
                                    setSelectedOption(undefined) : 
                                    setSelectedOption(option) }}>
                                
                            {option}
                        </a>
                    </li>
                )) }
            </ul>
        </>
    )
}