import { FormikProps } from "formik"
import { useEffect } from "react"
import { FormikSelectInputHandler } from "../../../form-components/FormikSelectInputHandlerV2"
import { OptionInputActiveNotActiveSelection } from "../../../form-components/OptionInputActiveNotActiveSelection"
import { TextInput } from "../../../form-components/TextInput"
import { FormikImageUploadFactory } from "../../../FormikHelper/FormikImageUploadFactoryV2"
import defaultProjectIcon from '../../../img/defaultProjectIcon.png'
import dateIcon from '../../../img/icon-date.png'
import moneyIcon from '../../../img/icon-money.png'
import nameIcon from '../../../img/icon-project.png'
import { Lang } from "../../../lang/Lang"
import { FormMode } from "../../Client/ui/ClientFormV3"
import { useSelectMemberHelper } from "../../useSelectMemberHelperV2"
import { POBHeaderModel } from "../model/POBHeader"
import { FormikBudgetFormComponent } from "./FormikBudgetFormComponentV2"

export const POBHeaderForm: <T extends POBHeaderModel>(
    mode: FormMode,
    pageTitle: string, 
    props: FormikProps<T>
) => JSX.Element = 
    
    (mode, pageTitle, props) => {

    let { values, errors, touched, handleChange, setFieldValue } = props

    useSelectMemberHelper((datas) => {
        setFieldValue("memberList", datas)
    })

    useEffect(() => {
        if (!values.memberList) return
        if (!values.projectManagerId) return

        setFieldValue("memberSelected", values.memberList.find(data => data.value == values.projectManagerId))
    }, [values.projectManagerId, values.memberList])

    return (
        <>
            <div className="page-title">{pageTitle} {values.name}</div>
            <div className="rowflex">
                <div className="col">
                    <FormikImageUploadFactory
                        mode={mode}
                        setFieldValue={setFieldValue}
                        file_fieldName="photo_url"
                        base64_fieldName="photo_base64"
                        fileChange_fieldName="isPhotoChanged"
                        className="pobHeader-profile-photo"
                        defaultImage={defaultProjectIcon}
                        file={values.photo_url}
                        base64={values.photo_base64}
                    />
                </div>
                <div className="col default-padding-left">
                    <TextInput placeholder="Code" name="code" value={values.code} onChange={handleChange} message={errors.code} touched={touched.code} />
                    <TextInput icon={nameIcon} placeholder="Name" name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                    <div className="input-container">
                        <FormikSelectInputHandler
                            fieldName="projectManagerId"
                            placeholder="Select Project Manager"
                            options={values.memberList ?? []}
                            selectedFieldName="memberSelected"
                            setFieldValue={setFieldValue}
                            selected={values.memberSelected}
                        />
                    </div>

                    <div className="input-container">
                        <div className="rowflex field-description">
                            Fiscal Year<br/><br/>
                        </div>
                        <div className="rowflex fiscalYear">
                            <div className="col">
                                <TextInput icon={dateIcon} name="fiscalYearFrom" placeholder="From" value={values.fiscalYearFrom ?? ""} onChange={handleChange} touched={touched.fiscalYearFrom} />
                            </div>
                            <div className="col spacing"></div>
                            <div className="col">
                                <TextInput icon={dateIcon} name="fiscalYearTo" placeholder="To" value={values.fiscalYearTo ?? ""} onChange={handleChange} touched={touched.fiscalYearTo} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="default-padding-top"></div>
            <div className="line-decoration"></div>

            <FormikBudgetFormComponent {...props} />

            <div className="row">
                <div className="field-description">{Lang.parentProjectClassificationBudget} </div>
                <FormikSelectInputHandler
                    fieldName="classificationBudget"
                    placeholder="Select Classification Budget"
                    options={[
                        { label: "LPKE", value: "LPKE" },
                        { label: "KSA", value: "KSA" },
                        { label: "OTHERS", value: "OTHERS" }
                    ]}
                    selectedFieldName="classificationBudgetSelected"
                    setFieldValue={setFieldValue}
                    selected={values.classificationBudgetSelected}
                />
            </div>

            <TextInput icon={moneyIcon} placeholder={Lang.parentProjectAmount} name="amount" value={values.amount} onChange={handleChange} message={errors.amount} touched={touched.amount} />
            <div className="row">
                <OptionInputActiveNotActiveSelection {...props} />
            </div>

        </>
    )
}