import { useFormik } from "formik";
import { ObjectSchema } from "yup";
import { NewsPath } from "../../../database_api/api";
import { DateHelper } from "../../../helper/DateHelper";
import { useUpdateData } from "../../Shared/useUpdateData";
import { NewsModel } from "../model/News";

export const useNewsEditFormHandler = (initialValues: NewsModel, validationSchema?: ObjectSchema) => {
    const { update } = useUpdateData(NewsPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let newsData: NewsModel = {
                id: values.id,
                title: values.title,
                tags: values.tags,
                link: values.link,
                description: values.description,
                time: DateHelper.dateISO8601(new Date().toLocaleString("en-US"))
            }

            setSubmitting(false)
            update(newsData)
        }
    })

    return formik
}