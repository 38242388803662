import { useFormik } from "formik"
import { ObjectSchema } from "yup"
import { ClientPath } from "../../../database_api/api"
import { useUpdateFormData } from "../../Shared/useUpdateFormData"
import { ClientModel } from "../model/Client"

export const useClientEditFormHandler = (initialValues: ClientModel, validationSchema: ObjectSchema) => {
    const { update } = useUpdateFormData(ClientPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let clientData: ClientModel = {
                id: values.id,
                name: values.name,
                address: values.address,
                url: values.url,
                phone: values.phone,
                businessUnitOfficer: values.businessUnitOfficer,
                product: values.product,
                socialMedia: values.socialMedia,
                email: values.email,
                note: values.note,
                active: values.active,
                prospectiveStatus: values.prospectiveStatus,
                variant: values.variant
            }

            let clientDataForm = new FormData()
            Object.entries(clientData).forEach(field => {
                if (field[1]) clientDataForm.append(field[0], field[1])
            })

            clientDataForm.append('isPhotoChanged', (values.isPhotoChanged ? "true" : "false"))

            if (values.photo_url) clientDataForm.append('photo_url', values.photo_url)

            setSubmitting(false)
            update(clientDataForm)
        }
    })

    return formik
}