import queryString from 'query-string';
import { useState } from 'react';
import { ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { QueryComponent } from './QueryFilterProtocol';
import { useURL } from './useURL';

interface FlexibleSearchComponentProp {
    isLoading: boolean
    placeholder: string
    queryOptions: QueryComponent[]
}

interface OptionValue{
    field: string,
    label: string,
    value: string
}

export const FlexibleSearchComponent = (props: FlexibleSearchComponentProp) => {
    const { location, params, goTo } = useURL()

    const [options, setOptions] = useState<OptionValue[]>([])

    const clearAll = () => {
        props.queryOptions.forEach(query => {
            delete params[query.field]
        })
    }

    const search = () => {
        goTo(location.pathname,{ ...params, page: 1 })
    }

    const handleChange = (inputValue: ValueType<OptionValue, true> | undefined, _: any) => {
        clearAll()

        if(inputValue){
            inputValue.forEach(input => {
                params[input.field] = input.value
            })
        }

        search()
    };

    const optionMapper = (inputValue: string): OptionValue[] => {
        let options: OptionValue[] = [];
        props.queryOptions.forEach(query => {
            options.push(
                {
                    label: query.displayField + ": " + inputValue,
                    field: query.field,
                    value: inputValue
                }
            );
        });
        return options;
    };

    const handleInputChange = (inputValue: any, _: any) => {
        if (inputValue == undefined || inputValue == null || inputValue == "") {
            setOptions([])
        }
        else {
            setOptions(optionMapper(inputValue))
        }
    };

    const initialSearch = () => {
        let params = { ...queryString.parse(location.search) }

        let search: OptionValue[] = []
        
        props.queryOptions.forEach(option => {
            if(!params[option.field]) return

            const displayField = props.queryOptions.find(query => query.field == option.field)?.displayField

            search.push({
                label: (displayField ?? option.field) + ": " + params[option.field],
                field: option.field,
                value: params[option.field] as string
            })
        })

        return search
    }

    return (
        <CreatableSelect className="search-input" placeholder={props.placeholder} isLoading={props.isLoading} 
            classNamePrefix="si" isMulti options={options} onChange={handleChange} onInputChange={handleInputChange}
            value={initialSearch()}
            getOptionValue={option => option.value} />
    );
}
