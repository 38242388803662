import { config } from './../Environment'

export const APIServer = config.api
export const LoginPath = APIServer + "login"

export const MemberPath = APIServer + "member/"
export const MemberPagePath = APIServer + "members/"
export const MemberItemPerPage = 8

export const ClientPath = APIServer + "client/"
export const ClientPagePath = APIServer + "v2/clients/"
export const ClientItemPerPage = 8
export const FilterClientPath = ClientPath + "find/"

export const ClientPICPath = APIServer + "client_pic/"
export const ClientPICPagePath = APIServer + "v2/client_pics/"
export const ClientPICItemPerPage = 8
export const FilterClientPICPath = ClientPICPath + "find/"

export const CustomerPath = APIServer + "customer/"
export const CustomerPagePath = APIServer + "v2/customers/"
export const CustomerItemPerPage = 4
export const FilterCustomerPath = CustomerPath + "find/"

export const ImagePath = APIServer + "images/"
export const FilePath = APIServer + "files/"

export const CustomerPersonnelPath = APIServer + "customer_personnel/"
export const CustomerPersonnelPagePath = APIServer + "v2/customer_personnels/"
export const CustomerPersonnelItemPerPage = 100
export const BlockFilterCustomerPersonnelPath = CustomerPersonnelPath + "find/"
export const CustomerPersonnelImportPath = CustomerPersonnelPath + "import"


export const CustomerPersonnelHistoryPath = APIServer + "customer_personnel_history/"
export const CustomerPersonnelHistoryPersonnelSelectionPath = CustomerPersonnelHistoryPath + "personnel/"

export const LawRegulationPath = APIServer + "law_regulation/"
export const LawRegulationPagePath = APIServer + "v2/law_regulations/"
export const LawRegulationYearTagsPath = LawRegulationPath + "tags/"
export const LawRegulationItemPerPage = 6
export const BlockFilterLawRegulationPath = LawRegulationPath + "find/"

export const POBHeaderPath = APIServer + "pob_header/"
export const POBHeaderGetChildrenPath = POBHeaderPath + "children/"
export const POBHeaderPagePath = APIServer + "v2/pob_headers/"
export const POBHeaderItemPerPage = 4

export const POBPath = APIServer + "pob/"
export const POBPagePath = APIServer + "v2/pobs/"
export const POBItemPerPage = 4

const POBContentPath = "pob_content/"
export const POBContentGetProjectName = APIServer + POBContentPath + "get_project_name/"
export const POBContentAdd = APIServer + POBContentPath + "add/"
export const POBContentList = APIServer + POBContentPath + "content_list/"
export const POBBlockContentList = APIServer + POBContentPath + "block_content_list/"
export const POBBlockContentDetail = APIServer + POBContentPath + "block_content_detail/"

export const POBContentUpdate = APIServer + POBContentPath + "update/"
export const POBContentChangeOrder = APIServer + POBContentPath + "change_order/"
export const POBBlockContentRemove = APIServer + POBContentPath + "remove/"
export const POBBlockContentUpdate = APIServer + POBContentPath + "update/block_content/"

export const ActionPlanPath = APIServer + "action_plan/"
export const ActionPlanPagePath = APIServer + "v2/action_plans/"
export const ActionPlanItemPerPage = 8
export const FilterProjectPath = ActionPlanPath + "find/project/"
export const ActionPlanUpdateResultPath = ActionPlanPath + "result"
export const ActionPlanGetResultPath = ActionPlanPath + "result/"
export const ActionPlanGoogleCalendarConnectionPath = ActionPlanPath + "google_calendar_connection"
export const ActionPlanGoogleCalendarSetTokenPath = ActionPlanPath + "google_calendar_set_token"

export const ActionPlanTodayPath = ActionPlanPath + "filter/today/all"

export const FieldInformationPath = APIServer + "field_information/"
export const FieldInformationPagePath = APIServer + "field_informations/v2/"
export const FieldInformationUpdateFileResultPath = FieldInformationPath + "result_file"
export const FieldInformationItemPerPage = 8

export const HistoryPath = APIServer + "history/"
export const HistoryPagePath = APIServer + "v2/history_list/"
export const HistoryItemPerPage = 8

export const LatestStatusPath = APIServer + "latest_status/"
export const SingleLatestStatusPath = LatestStatusPath + "latest/"
export const LatestStatusPagePath = APIServer + "v2/latest_status_list/"
export const LatestStatusItemPerPage = 8
export const FilterActionPlanPath = LatestStatusPath + "find/action_plan/"

export const TagPath = APIServer + "tag/"
export const FilterTagPath = TagPath + "find/"

export const NewsPath = APIServer + "news/"
export const NewsPagePath = APIServer + "v2/news_list/"
export const NewsYearNewsPath = NewsPath + "news_filter/"
export const NewsItemPerPage = 4
export const NewsItemWidget = 5

export const NewsTagPath = APIServer + "news_tag/"
export const FilterNewsTagPath = NewsTagPath + "find/"

export const CustomerTagPath = APIServer + "customer_tag/"
export const FilterCustomerTagPath = CustomerTagPath + "find/"

export const ProjectDownloadPath = APIServer + "project/"

export const LogsPath = APIServer + "logs/"
export const LogsPagePath = APIServer + "logs_list/"
export const LogsItemPerPage = 100

export const DbBackupPath = APIServer + "backup/"
export const DbBackupFindFilePath = DbBackupPath + "find/"
export const DbBackupUpload = DbBackupPath + "upload"
export const DbBackupRestore = DbBackupPath + "restore/"

export const DbBackupDownload = APIServer + "backup_download/"

export const DbBackupPagePath = APIServer + "backups/"
export const DbBackupItemPerPage = 100

export const SupportingDocsPath = APIServer + "project_files/"