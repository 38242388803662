import * as React from 'react';
import closeIcon from '../../../img/close.png';

interface ChildComponentProps {
    childItem: string;
    onDelete: (childItem: string) => void;
    [param: string]: any;
}

export class ChildComponent extends React.Component<ChildComponentProps> {
    childItem: string;

    constructor(props: ChildComponentProps) {
        super(props);
        this.childItem = props.childItem;
    }

    handleDelete = () => {
        this.props.onDelete(this.childItem);
    }

    render() {
        const { aclass, type, onDelete, ...props } = this.props;
        return (
            <div className="input-container budgetType">
                <input
                    type={type || "text"}
                    className={"text-input " + (aclass ?? '') + ' no-icon'}
                    value={this.childItem}
                    {...props}
                    disabled
                />
                <img src={closeIcon} onClick={() => this.handleDelete()} />
            </div>
        );
    }
}
