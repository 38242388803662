

import { ButtonInput } from '../../../form-components/ButtonInput';
import { POBComponent } from './POBComponentV2';


import { useHistory } from "react-router";
import { POBPagePath } from '../../../database_api/api';
import { ListLayoutFacade } from '../../../layout/ListLayoutFacade';
import { RoutePath } from '../../../page/RoutePath';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from '../../Search/FlexibleSearchComponentV2';
import { OptionInputSearchComponent } from '../../Search/OptionInputSearchComponentV2';
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { POBModel } from '../model/POBV2';

export const POBList = () => { 
    const history = useHistory()
    const itemSize = 8
    const { data, totalData, totalPage, isLoading, params } = useDataList<POBModel>({
        url: POBPagePath,
        size: itemSize,
        identifier: "pobs"
    })
    
    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Name"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "code",
            displayField: "Project Code"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "projectManagerId",
            displayField: "Project Manager",
            reference: {
                type: "member",
                targetField: "username",
                searchField: "name"
            }
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "customerId",
            displayField: "Customer",
            reference: {
                type: "customer",
                targetField: "id",
                searchField: "name"
            }
        }
        ,
        {
            compareType: QueryCompareTypeEnum.like,
            field: "clientId",
            displayField: "Client",
            reference: {
                type: "client",
                targetField: "id",
                searchField: "name"
            }
        }
    ]

    let topSearchComponent = (
        <div id="search-container">
            <FlexibleSearchComponent isLoading={isLoading} placeholder="Project Name, Code, Project Manager, Customer, Client" queryOptions={queryOptions} />
        </div>
    )

    const filterComponents = [
        <>
            <h2>Status</h2>
            {<OptionInputSearchComponent name='filter' options={[
                { key: 'all', value: 'All' },
                { key: '1', value: 'Active' },
                { key: '0', value: 'Not Active' }
            ]}
                initialValue={typeof params.active === "string" ? params.active : 'all'}
            />}
        </>
    ]

    const contentComponent = (
        <div id="pob-content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="pob-list">
                {
                    (data && data.length > 0 && data.map((pob, index) => {
                        return <POBComponent name={pob.name} photo_url={pob.photo_url} code={pob.code}
                            id={pob.id} projectManagerName={pob.projectManagerName} latestStatus={pob.latestStatus}
                            projectHeaderName={pob.projectHeaderName} customerName={pob.customerName}
                            projectHeaderId={pob.projectHeaderId} clientId={pob.clientId} clientName={pob.clientName}
                            customerId={pob.customerId} customerTemporary={pob.customerTemporary}
                            projectManagerId={pob.projectManagerId} projectValue={pob.projectValue}
                            key={index} active={pob.active}
                            
                        />
                    }))
                    ||
                    <div className="message">No pob registered</div>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    )

    const addComponent = <ButtonInput id="add" caption="+" title="Add New" onClick={() => {
        history.push(RoutePath.pobList + "/add")
    }} />

    return (
        <ListLayoutFacade
            topSearchComponent={topSearchComponent}
            filterComponents={filterComponents}
            contentComponents={contentComponent}
            addComponent={addComponent}
        />
    )
}