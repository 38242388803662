import { useEffect } from 'react';
import { MemberPath } from '../database_api/api';
import { AddResult } from './GenericAPI/GenericAddAPI';
import { useGetData } from './Shared/useGetData';

export type ResultType = {label: string, value: string}

export interface GetMemberSelectedProtocol{
    getMemberSelected:(username: string, callback: (returnedMember?: ResultType) => void) => void
}

export interface GetMemberListSelectionProtocol{
    getMemberListSelection:(defaultSelection: ResultType, callback: (returnedMemberList: ResultType[]) => void) => void
}

export interface SelectMemberHelperProtocol extends GetMemberSelectedProtocol, GetMemberListSelectionProtocol{}

export const useSelectMemberHelper = (onComplete?: (datas: ResultType[]) => void) => {
    const { get } = useGetData(MemberPath, "")

    useEffect(() => {
        get(undefined, {
            onSuccess: (data: AddResult) => {
                let returnedMemberList = data.data.map( (member: any) => {
                    return {
                        label: member.name,
                        value: member.username
                    }
                })

                if(onComplete) onComplete(returnedMemberList ?? [])
            }
        })
    }, [])
}