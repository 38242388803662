import { HTTPHeader } from "../interfaces/HTTPHeader";
import { FormPatchAPIHTTPClient, FormPostAPIHTTPClient } from "./StandardAPIHTTPClient";
import axios from "axios"

export interface UploadProgressProtocol{
    addProgressListener(progressListener: (progressEvent: any) => void): void
}

export class AxiosAPIHTTPClient implements FormPostAPIHTTPClient<FormData>, FormPatchAPIHTTPClient<FormData>, UploadProgressProtocol{
    header: HTTPHeader

    constructor(header: HTTPHeader){
        this.header = header
    }
    patchForm(url: string, data: FormData, callback: (result: any) => void): void {
        console.log("HM: " + url)
        axios(url, {
            onUploadProgress: this.onUploadProgress,
            headers: this.header.formHeader(),
            method: "PATCH",
            data: data
        }).then(response => response.data).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }
    progressListenerList: ((progressEvent: any) => void)[] = []


    postForm(url: string, data: FormData, callback: (result: any) => void): void {
        axios(url, {
            onUploadProgress: this.onUploadProgress,
            headers: this.header.formHeader(),
            method: "POST",
            data: data
        }).then(response => response.data).then(data => {
            callback(JSON.parse(JSON.stringify(data)))
        })
    }

    addProgressListener = (progressListener: (progressEvent: any) => void) => {
        this.progressListenerList.push(progressListener)
    }

    private onUploadProgress = (progressEvent: any) => {
        console.log(progressEvent)

        this.progressListenerList.forEach( progressListener => progressListener(progressEvent) )
    }

}