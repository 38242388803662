import React from 'react'

interface YearNewsComponentProps {
    year: string
    selectedYear: string
    onYearChoosen: (year: string) => void
}

export let YearNewsComponent: React.FunctionComponent<YearNewsComponentProps> = ({ year, selectedYear, onYearChoosen, ...props }) => {
    return (
        <li>
            <a onClick={() => { onYearChoosen(year) }}>{year}</a><br />
            {(year == selectedYear) &&
                props.children && props.children
            }
        </li>
    )
}