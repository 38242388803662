import * as yup from 'yup'
import { withFormik } from 'formik';
import * as React from 'react';
import { PasswordInput, RetypePasswordInput, NameInput, PhoneInput, EmailInput } from '../../Member/builder/ErrorMessage/Error';
import { ProfileProp, Profile } from './Profile';
import { MemberSingleLoader } from '../../Member/MemberSingleLoader';
import { EditMemberModuleInterface } from '../../Member/MemberEditAPI';
import { MemberModel } from '../../Member/model/Member';
import { ResultStatus } from '../../../Types/ResultStatus';
import { RouteComponentProps } from 'react-router-dom';
import { Session } from '../../../interfaces/Session';
import { DateHelper } from '../../../helper/DateHelper';

const errorValidationSchema = yup.object({
    name: yup.string().required(NameInput.requiredMessageError()),
    phone: yup.number().typeError(PhoneInput.requiredNumberMessageError()),
    email: yup.string().email(EmailInput.invalidMessageError()).required(EmailInput.requiredMessageError()),
    dob: yup.string().optional(),
    password: yup.string().min(6, PasswordInput.minCharacterError()),
    retype_password: yup.string().when("password", {
        is: val => { if(typeof val == "string"){ return val.length > 0 } },
        then: yup.string().oneOf([yup.ref("password")], RetypePasswordInput.notMatchedError()).required(RetypePasswordInput.requiredMessageError())
    })
})

interface ProfileBuilderProp extends RouteComponentProps{
    session: Session
    loader: MemberSingleLoader
    handler: EditMemberModuleInterface
}

export class ProfileWithFormHandler extends React.Component<ProfileBuilderProp>{ 

    private isMount = true

    componentWillUnmount = () => {
        this.isMount = false
    }

    profile = withFormik<ProfileProp, ProfileProp>({
        validationSchema: errorValidationSchema,
        handleSubmit: (values, { setSubmitting, setFieldError, setFieldValue }) => {
            setSubmitting(true)
            setFieldValue("message", undefined)
            
            let memberData: MemberModel = {
                username: values.username,
                name: values.name,
                position: values.position,
                email: values.email,
                phone: values.phone,
                gender: values.gender,
                password: values.password,
                dob: DateHelper.dateISO8601(values.dob)
            }
            
            if(values.password != "") memberData.password = values.password

            let memberDataForm = new FormData()
            Object.entries(memberData).forEach( field => {
                if(field[1]) memberDataForm.append(field[0], field[1])
            })

            memberDataForm.append('isPhotoChanged', (values.isPhotoChanged ? "true" : "false"))

            if(values.photo_url) memberDataForm.append('photo_url', values.photo_url)

            this.props.handler.update(memberDataForm, (result) => {
                setSubmitting(false)
                if(result.status == ResultStatus.ok){
                    setFieldValue("message", "Success update member " + values.username)

                    setTimeout(() => {
                        if(this.isMount){ setFieldValue("message", undefined) }
                    }, 3000)
                }
                else{
                    setFieldError("global", result.message || '')
                }
            })
            
            
        }
    })(Profile)
    render(){
        return <this.profile username={this.props.session.getUsername() ?? "Unknown User"} password="" retype_password="" 
                                position=""
                                name="" email="" phone="" gender="male" global="" isRequestData={true} isPhotoChanged={false}
                                dob="" loader={this.props.loader}/>
    }
}