
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { CustomerPersonnelPath, ImagePath } from '../../../database_api/api';
import { FormMode } from '../../Client/ui/ClientFormV3';
import { EditFormTemplate } from '../../Shared/template/EditFormTemplate';
import { useItem } from '../../Shared/useItem';
import { DatabaseTNIALSingleResult } from '../DatabaseTNIALSingleLoader';
import { errorValidationSchema } from '../formHandler/UseDatabaseTNIALAddFormHandler';
import { useDatabaseTNIALEditFormHandler } from '../formHandler/UseDatabaseTNIALEditFormHandler';
import { DatabaseTNIALAddModel, DatabaseTNIALForm } from './DatabaseTNIALForm';

export const DatabaseTNIALEdit = () => {
    const params = useParams<{ id: string }>()

    const formik = useDatabaseTNIALEditFormHandler({
        id: params.id, name: ""
    }, errorValidationSchema)

    const { setFieldValue } = formik

    const { get, data, isLoading } = useItem(CustomerPersonnelPath, params.id, "DBTNIAL")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    if (isLoading) return (<div>Loading...</div>)

    let mapData = (result: DatabaseTNIALSingleResult) => {
        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if(data[0] == "photo_url"){
                let photo_url = data[1]
                if(photo_url == "") return
                setFieldValue("photo_base64", ImagePath + photo_url)
                return
            }

            if (data[0] == "positions") {
                if (typeof data[1] == "string") {
                    try{
                        setFieldValue("positions", JSON.parse(data[1]))
                    }
                    catch(e){
                        setFieldValue("positions", [])
                    }
                }
                else {
                    setFieldValue("positions", [])
                }
                return
            }

            if(data[0] == "birthOfDate"){
                if( typeof data[1] == "string"){
                    let d = moment(data[1])
                    if(d.isValid()) setFieldValue("birthOfDate_date", d.toDate())
                }
            }

            if (data[0] == "contactNumber") {
                
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) setFieldValue("contactNumber", val)
                        else setFieldValue("contactNumber", [val])
                    }
                    catch(e){
                        if(data[1] == "") setFieldValue("contactNumber", [])
                        else setFieldValue("contactNumber", [data[1]])
                    }
                    
                }
                else {
                    setFieldValue("contactNumber", [])
                }
                return
            }

            if (data[0] == "children") {
                
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) setFieldValue("children", val)
                        else setFieldValue("children", [val])
                    }
                    catch(e){
                        if(data[1] == "") setFieldValue("children", [])
                        else setFieldValue("children", [data[1]])
                    }
                    
                }
                else {
                    setFieldValue("children", [])
                }
                return
            }

            if (data[0] == "education") {
                
                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) setFieldValue("education", val)
                        else setFieldValue("education", [val])
                    }
                    catch(e){
                        if(data[1] == "") setFieldValue("education", [])
                        else setFieldValue("education", [data[1]])
                    }
                    
                }
                else {
                    setFieldValue("education", [])
                }
                return
            }

            if (data[0] == "acknowledgement") {

                if (typeof data[1] == "string") {
                    try{
                        const val = JSON.parse(data[1])
                        if(Array.isArray(val)) setFieldValue("acknowledgement", val)
                        else setFieldValue("acknowledgement", [val])
                    }
                    catch(e){
                        if(data[1] == "") setFieldValue("acknowledgement", [])
                        else setFieldValue("acknowledgement", [data[1]])
                    }
                    
                }
                else {
                    setFieldValue("acknowledgement", [])
                }
                return
            }

            if(data[0] == "corps" && typeof data[1] == "string"){
                setFieldValue("corpsSelected", {
                    label: data[1],
                    value: data[1]
                })
            }
            setFieldValue(data[0], data[1])
        })
    }

    return (
        <EditFormTemplate<DatabaseTNIALAddModel> {...formik}>
            {DatabaseTNIALForm(FormMode.EDIT, "Update", formik)}
        </EditFormTemplate>
    )
}