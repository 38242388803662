import { GenericFormAddAPI } from '../GenericAPI/GenericFormAddAPI';
import { AddResult, AddModuleInterface } from '../GenericAPI/GenericAddAPI';

export type MemberAddResult = AddResult

export type AddMemberModuleInterface = AddModuleInterface<FormData, MemberAddResult>

export class MemberAddAPI extends GenericFormAddAPI<FormData, MemberAddResult> implements AddMemberModuleInterface{
    errorMapping = () => ({
        "12": "Username already used."
    })
}