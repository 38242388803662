import { ActionPlanComponentProp } from './ui/ActionPlanComponent';
import { ResultStatus } from '../../Types/ResultStatus';
import { SingleLoaderProtocol, GenericSingleLoader } from '../GenericSingleLoader';

type ActionPlanSingleDetail = {
    cas: string
    value: ActionPlanComponentProp
}

export type ActionPlanSingleResult = {
    status: ResultStatus,
    data?: ActionPlanSingleDetail,
    message?: string
}
export type ActionPlanSingleLoader = SingleLoaderProtocol<ActionPlanSingleResult>

export class APIActionPlanSingleLoader extends GenericSingleLoader<ActionPlanSingleResult> implements ActionPlanSingleLoader{}

type ActionPlanResultSingleResult = {
    status: ResultStatus,
    data?: {
        name: string,
        result: string,
        resultCreatedDate: string,
        resultUpdatedDate: string
    },
    message?: string
}

export type ActionPlanResultSingleLoader = SingleLoaderProtocol<ActionPlanResultSingleResult>
export class APIActionPlanResultSingleLoader extends GenericSingleLoader<ActionPlanResultSingleResult> implements ActionPlanResultSingleLoader{}