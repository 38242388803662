import * as React from 'react';
import { FieldMessage } from './FieldMessage';

export interface TextInputProp{
    icon?: any
    helpText?: string
    inputRef?: React.RefObject<HTMLInputElement>
    placeholder: string
    [param: string]: any
}

export class TextInput extends React.Component<TextInputProp>{
    constructor(props: TextInputProp){
        super(props)
    }

    render(){
        const { aclass, placeholder, type, message, touched, inputRef, name, ...props } = this.props
        return <div className="input-container">
                    
                    <input type={type || "text"} name={name} className={"text-input " + (aclass ?? 'default') + (this.props.icon ? '' : ' no-icon') } placeholder={ placeholder } ref={inputRef} {...props}></input>
                    <label htmlFor={name}>{placeholder}</label>
                    {message && touched && <FieldMessage message={message} />}
                    {this.props.icon && 
                        <div className="icon">
                            <img src={ this.props.icon }></img>
                        </div>
                    }
                    {this.props.helpText && <div className="helper-data">{this.props.helpText}</div> }
                </div>
    }
}