import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, RouteComponentProps } from 'react-router-dom';
import { NewsAdd } from './NewsAddV2';
import { NewsEdit } from './NewsEditV2';
import { NewsList } from './NewsList';
const queryClient = new QueryClient();
export class News extends React.Component<RouteComponentProps>{
    basePath: string
    constructor(props: RouteComponentProps){
        super(props)

        this.basePath = this.props.match.url
    }

    render(){
        return (
            <QueryClientProvider client={queryClient}>
            <React.Fragment>
                <Route path={this.props.match.url} component={NewsList} exact={true} />
                <Route path={this.props.match.url + "/add"} component={NewsAdd}/>
                <Route path={this.props.match.url + "/edit/:id"} component={NewsEdit}/>
            </React.Fragment>
            </QueryClientProvider>
        )
    }
}