import React from 'react';


import { ButtonInput } from '../../../form-components/ButtonInput';
import { ClientPICComponent } from './ClientPICComponent';

import { useHistory, useLocation } from "react-router";
import { ClientPICPagePath } from '../../../database_api/api';
import { Lang } from '../../../lang/Lang';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from "../../Search/FlexibleSearchComponentV2";
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { ClientPICModel } from '../model/ClientPIC';

export const ClientPICList = () => { 
    const itemSize = 8
    const { data, totalData, totalPage, isLoading, params } = useDataList<ClientPICModel>({
        url: ClientPICPagePath,
        size: itemSize,
        identifier: "clientPICs",
        ignoreParamsAsURL: ["clientName", "prevURL"]
    })
    
    const history = useHistory()
    const location = useLocation()

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: Lang.clientPersonnelName
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "phone",
            displayField: Lang.clientPersonnelContactNumber
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "email",
            displayField: Lang.clientPersonnelEmail
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "position",
            displayField: Lang.clientPersonnelPosition
        }
    ]

    const clientName = params.clientName

    return (
        <React.Fragment>
            <div id="top-container">
                <div id="search-container">
                    <FlexibleSearchComponent 
                        isLoading={isLoading} 
                        placeholder={
                            Lang.clientPersonnelName + ", " + Lang.clientPersonnelContactNumber + ", " + Lang.clientPersonnelEmail + ", " + Lang.clientPersonnelPosition
                        } 
                        queryOptions={queryOptions}
                    />
                </div>
                <div className="clear"></div>
            </div>

            <div id="add-menu-container">
                <ButtonInput id="add" caption="+" title="Add New" onClick={() => {
                    history.push(location.pathname + `/add?clientId=${params.clientId}&clientName=${params.clientName}`)
                }} />
            </div>

            { clientName && 
                <>
                    <h2>{clientName} Personnel List</h2>
                    { params.prevURL && 
                        <div id="left-top-menu-container">
                            <ButtonInput caption="Back to Client" title="Back to Client" onClick={() => { history.push(decodeURIComponent(params.prevURL as string)) }} />
                        </div>
                    }
                </>
            }
            
            <div id="content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="clientPIC-list">
                {
                    (data && data.length > 0 && data.map((clientPIC, index) => {
                        return <ClientPICComponent id={clientPIC.id} name={clientPIC.name} clientId={clientPIC.clientId}
                            photo_url={clientPIC.photo_url} clientName={clientPIC.clientName}
                            position={clientPIC.position} noted={clientPIC.noted}
                            active={clientPIC.active}
                            email={clientPIC.email} phone={clientPIC.phone} key={index} />
                    }))
                    || 
                    <div className="message">No data</div>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
            </div>
        </React.Fragment>
    )
}