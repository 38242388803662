import React, { useState } from 'react';
import { StandardAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient";
import { DbBackupItemPerPage, DbBackupPagePath } from "../../../database_api/api";
import { ButtonInput } from '../../../form-components/ButtonInput';
import { TextInput } from '../../../form-components/TextInput';
import { HTTPHeader } from "../../../interfaces/HTTPHeader";
import { AuthTokenSessionStorage } from "../../Login/AuthPermission";
import { PagingFeatureControl } from "../../Paging/PagingFeatureControlProtocol";
import { PaginationComponent } from "../../Paging/ui/PaginationComponent";
import { SearchFeature } from "../../Search/SearchFeatureProtocol";
import { DbBackupListController } from "../DbBackupListController";
import { APIDbBackupListPagingLoader } from "../DbBackupListPagingLoader";
import { DbBackupList, DbBackupListRouteComponentProps, DbBackupListRouteProtocol } from "../ui/DbBackupList";

import { UploadProgressProtocol } from '../../../database_api/AxiosAPIHTTPClient';
import { UploadProgressBarComponent } from '../../../form-components/UploadProgressBarComponent';
import fileIcon from '../../../img/icon-file.png';
import { BackupFileUpload } from '../ui/BackupFileUpload';

export let AskDBKey = (props: {submitTitle: string, onConfirm: (username?: string, password?: string) => void, onCancel: () => void}) => {
    let [username, setUsername] = useState<string>()
    let [password, setPassword] = useState<string>()
    return (
        <>
        <div className="background" />
        <div id="form-container" className="alert">
            <TextInput placeholder="username" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setUsername(event.target.value) }} />
            <TextInput type="password" placeholder="password" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value) }} />

            <div className="default-margin-top flex">
                <ButtonInput caption={props.submitTitle} onClick={() => { props.onConfirm(username, password) }} />
                <ButtonInput name="cancel" caption="Cancel" title="Cancel" onClick={() => { props.onCancel() }} />
            </div>
        </div>
        </>
    )
}

export let UploadBackupForm = (props: { upload: (file?: File, fileName?: string) => void, onCancel: () => void, progressUpdater: UploadProgressProtocol }) => {
    let [fileDescription, setFileDescription] = useState<string>()
    let [file, setFile] = useState<File>()

    let uploader = new BackupFileUpload(setFile, setFileDescription, fileIcon)
    return (
        <>
            <div className="background" />
            <div id="form-container" className="alert">
                { uploader.getComponent(fileDescription) }
                <UploadProgressBarComponent uploadProgress={props.progressUpdater}/>
                <div className="default-margin-top flex">
                    <ButtonInput name="upload" caption="Upload" title="Upload" onClick={() => { props.upload(file, fileDescription) }} />
                    <ButtonInput name="cancel" caption="Cancel" title="Cancel" onClick={() => { props.onCancel() }} />
                </div>
            </div>
        </>
    )
}

export interface DbBackupListBuilderProp extends DbBackupListRouteComponentProps{
    router: DbBackupListRouteProtocol
}

export class DbBackupListBuilder extends React.Component<DbBackupListBuilderProp>{
    router: DbBackupListRouteProtocol
    dbBackupListLoader = new APIDbBackupListPagingLoader(DbBackupPagePath, DbBackupItemPerPage, new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())))
    pagingFeatureControl = new PagingFeatureControl()
    dbBackupListController = new DbBackupListController(this.dbBackupListLoader, this.pagingFeatureControl)

    dbBackupSearch = new SearchFeature(this.dbBackupListLoader, this.dbBackupListController)

    paginationComponent: JSX.Element
    
    constructor(props: DbBackupListBuilderProp){
        super(props)

        this.router = props.router
        this.paginationComponent = <PaginationComponent itemPerPage={DbBackupItemPerPage} controller={this.dbBackupListController} pagingController={this.pagingFeatureControl} />
    }

    render(){
        let {router, ...props} = this.props
        return <DbBackupList {...props} router={this.router} paginationComponent={this.paginationComponent} 
                    controller={this.dbBackupListController}
                />
    }
}