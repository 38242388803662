import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';
import { LawRegulationPath } from '../../../database_api/api';
import { DateHelper } from '../../../helper/DateHelper';
import { useAddFormData } from '../../Shared/useAddFormData';
import { LawRegulationModel } from '../model/LawRegulationV2';

export const useLawRegulationAddFormHandler = (initialValues: LawRegulationModel, validationSchema: ObjectSchema) => { 
    const { add } = useAddFormData(LawRegulationPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(true)

            let lawRegulationData: LawRegulationModel = {
                name: values.name,
                ruleDate: !values.ruleDate ? "" : DateHelper.dateISO8601(values.ruleDate),

                description: values.description,
                status: values.status,
                tags: values.tags
            }

            let lawRegulationDataForm = new FormData()
            Object.entries(lawRegulationData).forEach(field => {
                if (field[1]) lawRegulationDataForm.append(field[0], field[1])
            })

            if (values.file_url) {
                lawRegulationDataForm.append('file_url', values.file_url)
            }

            setSubmitting(false)
            add(lawRegulationDataForm)
        }
    })

    return formik
}