import * as H from "history";
import React from 'react';
import { MemberListRouteProtocol, MemberListRouteComponentProps, MemberList } from "../ui/MemberList";
import { APIMemberListPagingLoader } from "../MemberListPagingLoader";
import { MemberPagePath, MemberItemPerPage } from "../../../database_api/api";
import { StandardAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient";
import { PagingFeatureControl } from "../../Paging/PagingFeatureControlProtocol";
import { MemberListController } from "../MemberListController";
import { SearchFeature } from "../../Search/SearchFeatureProtocol";
import { PaginationComponent } from "../../Paging/ui/PaginationComponent";
import { HTTPHeader } from "../../../interfaces/HTTPHeader";
import { AuthTokenSessionStorage } from "../../Login/AuthPermission";

export class MemberListRouter implements MemberListRouteProtocol{
    basePath: string
    history: H.History<unknown>
    constructor(basePath: string, history: H.History<unknown>){
        this.basePath = basePath
        this.history = history
    }

    goToAdd(){
        this.history.push(this.basePath + "/add")
    }

    goToEdit(id: string){
        this.history.push(this.basePath + "/edit/" + id)
    }
}


export interface MemberListBuilderProp extends MemberListRouteComponentProps{
    router: MemberListRouteProtocol
}

export class MemberListBuilder extends React.Component<MemberListBuilderProp>{
    router: MemberListRouteProtocol
    memberListLoader = new APIMemberListPagingLoader(MemberPagePath, MemberItemPerPage, new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())))
    pagingFeatureControl = new PagingFeatureControl()
    memberListController = new MemberListController(this.memberListLoader, this.pagingFeatureControl)

    memberSearch = new SearchFeature(this.memberListLoader, this.memberListController)

    paginationComponent: JSX.Element
    
    constructor(props: MemberListBuilderProp){
        super(props)

        this.router = props.router
        this.paginationComponent = <PaginationComponent itemPerPage={MemberItemPerPage} controller={this.memberListController} pagingController={this.pagingFeatureControl} />
    }

    render(){
        let {router, ...props} = this.props
        return <MemberList {...props} router={this.router} paginationComponent={this.paginationComponent} 
                    controller={this.memberListController} search={this.memberSearch} 
                />
    }
}