import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { POBContentGetProjectName } from '../../../database_api/api';
import GlobalData from '../../../GlobalData';
import { FieldInformationList } from '../../FieldInformation/ui/FieldInformationListV3';
import { HistoryList } from '../../History/ui/HistoryListV2';
import { LatestStatusList } from '../../LatestStatus/ui/LatestStatusListV2';
import { POBEdit } from '../../POB/ui/POBEditV2';
import { POBHeaderEdit } from '../../POBHeader/ui/POBHeaderEditV2';
import { useURL } from '../../Search/useURL';
import { useGetData } from '../../Shared/useGetData';
import { ContentListType } from '../ContentListLoader';
import { ActionPlanListForContentType } from '../ContentModule/ActionPlan/ActionPlanListForContentType';
import { useSetProjectName } from '../useSetProjectName';
import { BlockContentListComponent } from './v2/component/BlockContentListComponent';
import { POBContentMenu } from './v2/POBContentMenuV2';

export enum POBContentType{
    POB = "pob",
    POBHeader = "pobHeader"
}

export const POBContent = () => {
    const { params } = useURL()
    const { setPageTitle } = useContext(GlobalData)
    const mainParams = useParams<{type: string, id: string}>()
    const { get } = useGetData(POBContentGetProjectName + mainParams.type + "/" + mainParams.id, "")

    const { updateProjectName } = useSetProjectName()

    useEffect(() => {
        const type = mainParams.type === POBContentType.POBHeader ? "Parent Project" : "Child Project"
        get(undefined, {
            onSuccess: (result) => {
                updateProjectName(result.data, type)
            }
        })
    }, [])

    return (
        <div id="pobContent-container" data-cy="pobContentPage">
            <POBContentMenu />
            
            { params.contentType === ContentListType.data && 
                mainParams.type === POBContentType.POBHeader &&
                <POBHeaderEdit />
            }

            {params.contentType === ContentListType.data &&
                mainParams.type === POBContentType.POB &&
                <POBEdit />
            }

            { params.contentType === ContentListType.content &&
                <BlockContentListComponent />
            }

            {
                params.contentType === ContentListType.history &&
                <HistoryList />
            }

            {
                params.contentType === ContentListType.actionPlan &&
                <ActionPlanListForContentType />
            }

            {
                params.contentType === ContentListType.fieldInformation &&
                <FieldInformationList />
            }

            {
                params.contentType === ContentListType.latestStatus &&
                <LatestStatusList />
            }
            
            <div id="action-plan-add" />
            <div id="supportingDocs"/>
        </div>
    )
}