import { useHistory, useLocation } from 'react-router';
import { EditButton } from '../../../components/EditButton';
import { ImagePath } from '../../../database_api/api';
import statusMenu from '../../../img/checked.png';
import defaultProfile from '../../../img/default-profile.png';
import { ClientPICModel } from '../model/ClientPIC';

export const ClientPICComponent = (props: ClientPICModel) => {
    const history = useHistory()
    const location = useLocation()
    
    return (
        <div className="clientPIC-container full-border">
            <div className="photo">
                <img src={props.photo_url ? ImagePath + props.photo_url : defaultProfile}></img>
            </div>

            <div className="clientPIC-id">
                {props.name}
                <div className="clientPIC-clientId">{props.clientName}</div>
                <div className="clientPIC-position">{props.position}</div>
            </div>
            <div className="line-decoration" />
            
            <div className="clientPIC-description">
                <div className="clientPIC-phone">{props.phone}</div>
                <div className="clientPIC-email">{props.email}</div>
            </div>
                
            <div className="clientPIC-description">
                <div className="clientPIC-options">
                    <EditButton basePath={location.pathname} code={props.id} />
                    <input className="status" type="image" src={statusMenu}/>
                </div>
                <div className="clear"></div>
            </div>
        </div>
    )
}