import * as React from 'react';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { ContentEditorModuleInterface } from '../../../form-components/ContentEditorModuleInterface';
import { BlockContentDetailDisplayUpdaterProtocol } from '../BlockContentDetailDisplayUpdater';
import { BlockContentListDisplayUpdaterProtocol } from '../BlockContentListDisplayUpdater';
import { BlockContentListResult, BlockContentModuleEnum } from '../BlockContentListLoader';
import { BlockContentRemoveDisplayUpdaterProtocol } from '../BlockContentRemoveDisplayUpdater';
import { BlockContentAddIndex, BlockContentModel } from '../Features/BlockContentAddFeature';
import { BlockContentManageFeatureProtocol } from '../Features/BlockContentManageFeature';
import { BlockContentComponentUI } from './BlockContentComponentUI';
import { ReactDragAndDrop } from './ReactDragAndDrop';

import { GlobalContentUpdaterProtocol } from "../../../page/GlobalContentUpdaterProtocol";
import { BlockContentDetailResultData } from '../BlockContentDetailLoader';

export interface BlockContentWithData {
    id: string
    type: BlockContentModuleEnum
    data: string
    updatedDate?: string
    updatedBy?: string
}

export interface POBContentListComponentProp {
    manageFeature: BlockContentManageFeatureProtocol
    contentModule: BlockContentListDisplayUpdaterProtocol
    contentDetailModule: BlockContentDetailDisplayUpdaterProtocol
    removeContentModule: BlockContentRemoveDisplayUpdaterProtocol

    addBlockModulesFirstIndex?: JSX.Element[]
    addBlockModulesLastIndex?: JSX.Element[]

    contentEditorModule: ContentEditorModuleInterface

    globalContentUpdater?: GlobalContentUpdaterProtocol
}

export interface POBContentListComponentState {
    prevBlockList: BlockContentWithData[]
    blockList: BlockContentWithData[]
    error?: string
    message?: string
}

interface POBContentHeaderUpdateButtonProp {
    onClick?: () => void
}

export class POBContentHeaderDownloadButton extends React.Component<POBContentHeaderUpdateButtonProp>{
    render() {
        return (
            <ButtonInput aclass="update-button download" caption="Download" title="Download" onClick={() => { this.props.onClick && this.props.onClick() }} />
        )
    }
}

export class POBContentListComponent extends React.Component<POBContentListComponentProp, POBContentListComponentState> {
    dnd: ReactDragAndDrop<BlockContentWithData>

    constructor(props: POBContentListComponentProp) {
        super(props)

        this.state = {
            prevBlockList: [],
            blockList: [],
            error: undefined,
            message: undefined
        }

        this.dnd = new ReactDragAndDrop<BlockContentWithData>(this.state.blockList)
    }

    componentDidMount = () => {
        this.props.manageFeature.addDisplayUpdater.updateDisplay = this.addUpdateDisplay
        this.props.contentModule.updateDisplay = this.setBlockContentList
        this.props.contentDetailModule.updateDisplay = this.setBlockContentDetailFromServer
        this.props.removeContentModule.updateDisplay = this.removeBlockContent

        this.props.globalContentUpdater?.register(this.update)

        window.addEventListener("beforeunload", (e: BeforeUnloadEvent) => {
            this.update()
        })
    }

    componentWillUnmount = () => {
        window.removeEventListener("beforeunload", (e: BeforeUnloadEvent) => {
            this.update()
        })
    }

    setBlockContentList = (data?: BlockContentListResult[] | undefined) => {
        if (data == undefined) {
            this.setState({
                prevBlockList: [],
                blockList: []
            })
            return
        }

        let prevBlockContentList = data.map(blockContent => {
            return {
                id: blockContent.id,
                type: blockContent.type,
                data: ""
            }
        })

        let blockContentList = data.map(blockContent => {
            return {
                id: blockContent.id,
                type: blockContent.type,
                data: ""
            }
        })

        this.setState({
            prevBlockList: prevBlockContentList,
            blockList: blockContentList
        })
    }

    setBlockContentDetailFromServer = (id: string, data: BlockContentDetailResultData) => {
        let blockList = [...this.state.blockList]
        blockList.forEach(block => {
            if (block.id == id) {
                block.data = data.data ?? ""
                block.updatedDate = data.updatedDate ?? ""
                block.updatedBy = data.updatedBy ?? ""
            }
        })

        let prevBlockList = [...this.state.prevBlockList]
        prevBlockList.forEach(block => {
            if (block.id == id) {
                block.data = data.data ?? ""
                block.updatedDate = data.updatedDate ?? ""
                block.updatedBy = data.updatedBy ?? ""
            }
        })

        this.setState({
            blockList: blockList,
            prevBlockList: prevBlockList
        })
    }

    setBlockContentDetail = (id: string, data: string) => {
        let blockList = [...this.state.blockList]
        blockList.forEach(block => {
            if (block.id == id) block.data = data
        })

        this.setState({
            blockList: blockList
        })
    }

    addUpdateDisplay = (index: BlockContentAddIndex, data?: BlockContentModel, error?: string) => {
        if (error) {
            this.setState({
                error: error
            })
            return
        }
        if (data == undefined)
            return

        let newDataList: BlockContentWithData[] = []
        let prevNewDataList: BlockContentWithData[] = []
        let dataToAdd = { id: data.id, data: data.data ?? "", type: data.type }

        if (index == BlockContentAddIndex.first) {
            newDataList = [dataToAdd, ...this.state.blockList]
            prevNewDataList = [{ ...dataToAdd }, ...this.state.prevBlockList]
        }
        else {
            newDataList = [...this.state.blockList, dataToAdd]
            prevNewDataList = [...this.state.prevBlockList, { ...dataToAdd }]
        }

        this.setState({
            prevBlockList: prevNewDataList,
            blockList: newDataList
        })
    }

    removeBlockContent = (blockContentId: string, error?: string) => {
        if (error) {
            this.setState({
                error: error
            })
            return
        }

        let selectedIndex = -1
        this.state.blockList.forEach((block, index) => {
            if (block.id == blockContentId) {
                selectedIndex = index
            }
        })

        if (selectedIndex < 0) return

        let blockList = [...this.state.blockList]
        blockList.splice(selectedIndex, 1)

        let prevBlockList = [...this.state.prevBlockList]
        prevBlockList.splice(selectedIndex, 1)

        this.setState({
            prevBlockList: prevBlockList,
            blockList: blockList
        })
    }

    update = () => {
        let blockListToUpdate: BlockContentWithData[] = []

        for (let i: number = 0; i < this.state.blockList.length; i++) {
            if (this.state.blockList[i].data != this.state.prevBlockList[i].data) {
                blockListToUpdate.push(this.state.blockList[i])
            }
        }

        if (blockListToUpdate.length == 0) return

        this.props.manageFeature.update(blockListToUpdate, error => {
            if (error) {
                this.setState({
                    error: error
                })
            }
            else {

                let prevBlockList = [...this.state.prevBlockList]
                blockListToUpdate.forEach(updatedBlock => {
                    prevBlockList.forEach(prevBlock => {
                        if (prevBlock.id == updatedBlock.id) {
                            prevBlock.data = updatedBlock.data
                        }
                    })

                })
                this.setState({
                    message: "Success Update",
                    prevBlockList: prevBlockList
                })

                setTimeout(() => {
                    this.setState({
                        message: undefined
                    })
                }, 3000)
            }

        })
    }

    updateContents = (selectedBlockId?: string | null, targetIndex?: number) => {
        if (selectedBlockId == undefined || selectedBlockId == null || targetIndex == undefined)
            return

        let selectedIndex = -1
        this.state.blockList.forEach((block, index) => {
            if (block.id == selectedBlockId) {
                selectedIndex = index
            }
        })

        if (selectedIndex < 0) return

        let newBlockList = [...this.state.blockList]
        let tempBlock = newBlockList[selectedIndex]

        newBlockList.splice(selectedIndex, 1)
        newBlockList.splice(targetIndex, 0, tempBlock)

        let prevNewBlockList = [...this.state.prevBlockList]
        let tempPrevBlock = prevNewBlockList[selectedIndex]

        prevNewBlockList.splice(selectedIndex, 1)
        prevNewBlockList.splice(targetIndex, 0, tempPrevBlock)

        this.setState({
            prevBlockList: prevNewBlockList,
            blockList: newBlockList
        })

        this.props.manageFeature.changeOrder(tempBlock.id, targetIndex)
    }

    render() {
        return (
            <div className="block-content-area">
                {this.state.error && <div className="error-message">{this.state.error}</div>}
                {this.state.message && <div className="message update">{this.state.message}</div>}

                <div className="add-block-container">
                    <div className="add-block-button-list">
                        {this.props.addBlockModulesFirstIndex?.map(addblockModule => {
                            return addblockModule
                        })}
                    </div>
                </div>

                <div className="blockContent-container" onDragOver={this.dnd.onDragged}>
                    {this.state.blockList.map((blockContent, index) => {
                        return <BlockContentComponentUI className="block-content-container draggable" draggable="true"
                            onDragStart={this.dnd.onStartDrag} dndEndDrag={this.dnd.onEndDragContentToPosition}
                            updateContents={this.updateContents}
                            key={blockContent.id} index={index + 1} id={blockContent.id}
                            contentEditorModule={this.props.contentEditorModule}
                            content={blockContent.data}
                            updatedBy={blockContent.updatedBy}
                            updatedDate={blockContent.updatedDate}
                            removeFeature={this.props.manageFeature} handleChange={this.setBlockContentDetail} data-id={blockContent.id} />
                    })}
                </div>

                <div className="add-block-container">
                    <div className="add-block-button-list">
                        {this.props.addBlockModulesLastIndex?.map(addblockModule => {
                            return addblockModule
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
