import { asBlob } from "html-docx-js-typescript";
import React from "react";
import ReactDOM from "react-dom";
import { GetAPIHTTPClient } from "../../database_api/StandardAPIHTTPClient";
import { ResultStatus } from "../../Types/ResultStatus";
import { ContentListType } from "../POBContent/ContentListLoader";
import { DownloadStatus } from "./DownloadStatus";
import { saveAs } from "file-saver";

export interface DownloadResult<DATA> {
    status: ResultStatus
    message?: string
    data?: {
        value: DATA
    }
}

export interface DownloadDataToDocProtocol<DATA> {
    download(projectId: string, projectName: string, result: (result: DATA | undefined) => void): void
    toWord(projectName: string, data: DATA, fileName: string): void
    getPostfix(): string
}

export class DownloadDataToDoc<DATA> implements DownloadDataToDocProtocol<DATA>{
    private status = DownloadStatus.idle
    protected type = ContentListType.data
    private client: GetAPIHTTPClient
    protected basePath: string;
    protected downloadDecisionFn: ((download: boolean) => void) | undefined = undefined
    protected apiPath = (projectId: string) => (this.basePath + this.type + "/" + projectId)

    protected filterElement = () => <></>

    constructor(client: GetAPIHTTPClient, basePath: string) {
        this.client = client;
        this.basePath = basePath;
    }

    download(projectId: string, projectName: string, result: (result: DATA | undefined) => void) {
        this.showFilter( download => {
            if(!download) {
                ReactDOM.render(<></>, document.getElementById("download-component"))
                result(undefined)
                return
            }

            this.status = DownloadStatus.request;
            this.client.get(this.apiPath(projectId), clientResult => {
                this.status = DownloadStatus.idle;

                if (clientResult.message && clientResult.message == "13") {
                    clientResult.message = `Requested Data not exists on Project ${projectName}`;
                }
                console.log(clientResult)
                result(clientResult.data);
            });
        })
    }

    showFilter(callback: (download: boolean) => void){
        this.downloadDecisionFn = callback
        ReactDOM.render(this.filterElement(), document.getElementById("download-component"))
    }

    toWord(projectName: string, data: DATA, fileName: string) {
        let header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            "<head><meta charset='utf-8'><title>" + projectName + "</title>" +
            "</head>" +
            "<body>";

        let htmlToPrint = this.useTemplate(projectName, data);
        let footer = "</body></html>";
        let htmlSource = header + htmlToPrint + footer;
        let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlSource);

        asBlob(htmlToPrint).then(data => {
            saveAs(data as Blob, fileName)
        })

        return source;
    }

    getPostfix = () => ""

    protected useTemplate(projectName: string, data: DATA) {
        if (data == null)
            return "";

        var html = `<h1>${projectName}</h1>`;

        return html;
    }
}
