import React from 'react'
import { UploadProgressProtocol } from '../database_api/AxiosAPIHTTPClient'

enum ProgressStatus{
    notStarted,
    inProgress,
    done
}

interface UploadProgressBarComponentProps{
    uploadProgress: UploadProgressProtocol
    hideOnFinish?: boolean
}

interface UploadProgressBarComponentState{
    percentage: number
    percentageString: string
    progress: number
    total: number
    status: ProgressStatus
}

export class UploadProgressBarComponent extends React.Component<UploadProgressBarComponentProps, UploadProgressBarComponentState>{
    constructor(props: UploadProgressBarComponentProps){
        super(props)

        this.state = {
            percentage: 0,
            percentageString: "0%",
            progress: 0,
            total: 0,
            status: ProgressStatus.notStarted
        }

        this.props.uploadProgress.addProgressListener(this.progressUpdate)
    }

    formatNumber(num: number) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    progressUpdate = (progressEvent: any) => {
        let progress = progressEvent.loaded / 1000
        let total = progressEvent.total / 1000
        let status = (progress >= total ? ProgressStatus.done : ProgressStatus.inProgress)

        this.setState({
            progress: Math.round(progress),
            total: Math.round(total),
            percentage: Math.round(progress / total * 100),
            percentageString: Math.round(progress / total * 100).toString() + "%",
            status: status
        })
    }

    isDone = (status: ProgressStatus) => {
        let hidden = " hidden"
        if (status == ProgressStatus.notStarted) return hidden
        if (this.props.hideOnFinish && status == ProgressStatus.done) return hidden

        return ""
    }

    render(){
        return (<div id="actionplan-result-progressbar-container" 
            className={"default-margin-top" + (this.isDone(this.state.status)) }>
            <div className="bar-container">
                <div className="bar-progress-width" style={{width: this.state.percentageString }}></div>
                <div className="progress-detail">
                    <div className="percentage">{this.state.percentage} %</div>
                    <div className="progress">{this.formatNumber(this.state.progress)} Kb</div> / <div className="total"> {this.formatNumber(this.state.total)} Kb</div>
                </div>
            </div>
            
        </div>)
    }
}