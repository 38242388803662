import * as yup from 'yup';
import { EmailInput, NameInput, PhoneInput } from "../../Member/builder/ErrorMessage/Error";

export const errorValidationSchema = yup.object({

    name: yup.string().required(NameInput.requiredMessageError()),
    phone: yup.number().typeError(PhoneInput.requiredNumberMessageError()),
    email: yup.string().email(EmailInput.invalidMessageError()),

    pic_phone: yup.number().typeError(PhoneInput.requiredNumberMessageError()),
    pic_email: yup.string().email(EmailInput.invalidMessageError())
})