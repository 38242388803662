export interface ClientModel{
    id?: string
    name: string
    address?: string
    url?: string
    email?: string
    phone?: string
    note?: string
    photo_url?: File
    photo_base64?: string
    isPhotoChanged?: boolean

    product?: string
    businessUnitOfficer?: string
    socialMedia?: string
    active: string
    prospectiveStatus: string
    variant: ClientVariant

    isRequestData?: boolean
    prospective?: boolean
    isClientPartner?: boolean
}

export enum ClientVariant {
    client = "client",
    clientPartner = "clientPartner"
}