import { useFormik } from 'formik';
import * as yup from 'yup';
import { ActionPlanPath } from '../../../../database_api/api';
import { DateHelper } from '../../../../helper/DateHelper';
import { ActionPlanModel } from '../../../ActionPlan/model/ActionPlan';
import { useUpdateData } from '../../../Shared/useUpdateData';

export const useActionPlanEditFormHandler = (initialValues: ActionPlanModel, validationSchema?: yup.ObjectSchema) => {
    const { update } = useUpdateData(ActionPlanPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(true)

            let actionPlanData: ActionPlanModel = {
                id: values.id,
                name: values.name,
                date: values.date && DateHelper.dateISO8601(values.date),
                location: values.location,
                objective: values.objective,
                projectName: values.projectName,
                projectId: values.projectId,
                personnelList: values.personnelList,
                contractors: values.contractors,
                picIds: values.picIds
            }

            setSubmitting(false)
            update(actionPlanData)
        }
    })

    return formik
}