import { FormPatchAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient';
import { EditModuleInterface } from './GenericEditAPI';

export class GenericFormEditAPI<Model, Result> implements EditModuleInterface<Model, Result>{
    private client: FormPatchAPIHTTPClient<Model>
    private path: string

    constructor(path: string, client: FormPatchAPIHTTPClient<Model>){
        this.client = client
        this.path = path
    }

    update(data: Model, addCallback: (result: Result) => void){
        this.client.patchForm(this.path, data, (result) => {
            if (result.message){
                result.message = `Error code: ${result.message}. Please contact your developer.`
            }
            addCallback(result)
        })
    }
}