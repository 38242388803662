import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { Timeout } from "../../../../components/Timeout"
import { POBHeaderGetChildrenPath } from "../../../../database_api/api"
import { SelectInput } from "../../../../form-components/SelectInput"
import { RoutePath } from "../../../../page/RoutePath"
import { useGetData } from "../../../Shared/useGetData"
import { POBContentType } from '../POBContentType'

export const POBChildMenu = () => {
    const history = useHistory()
    const mainParams = useParams<{ type: string, id: string, pobHeaderId: string }>()
    const [children, setChildren] = useState<{ id: string, name: string }[]>([])
    const { get, isLoading } = useGetData(POBHeaderGetChildrenPath + mainParams.id, "")

    useEffect(() => {
        if (mainParams.type === POBContentType.POB) return

        get(undefined, {
            onSuccess: (data) => {
                if (data.data) setChildren(data.data)
            }
        })
    }, [])

    if (isLoading) return <></>

    if (mainParams.type === POBContentType.POB && (mainParams.pobHeaderId === "undefined" || mainParams.pobHeaderId === undefined)) return (
        <></>
    )

    if (mainParams.type === POBContentType.POB) return (
        <div className="pobHeader-name" onClick={() => {
            Timeout(() => {
                history.push(RoutePath.pobContent.replace(":id", mainParams.pobHeaderId)
                    .replace(":type", POBContentType.POBHeader))
            })
        }}>Parent</div>
    )

    return (
        <div className="pobHeader-name">
            <SelectInput onChange={(id: string) => {
                Timeout(() => {
                    history.push(RoutePath.pobContentWithHeader.replace(":id", id)
                        .replace(":type", POBContentType.POB)
                        .replace(":pobHeaderId", mainParams.id))
                })

            }}
                placeholder='Child Project'
                className="pobTypeSelection"
                options={children.map(cd => {
                    return ({
                        value: cd.id,
                        text: cd.name
                    })
                })} />
        </div>
    )
}