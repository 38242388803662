import { BoxButtonComponent } from '../../../components/BoxButtonComponent';
import { BoxComponent } from '../../../components/BoxComponent';
import NextIcon from '../../../img/next.png';
import PrevIcon from '../../../img/prev.png';

import queryString from "query-string";
import { useHistory, useLocation } from 'react-router-dom';

type PaginationComponentProp = {
    itemPerPage: number
    totalData: number
    totalPage: number
    isLoading: boolean
}

export const PaginationComponent = (props: PaginationComponentProp) => {
    const location = useLocation()
    const history = useHistory()
    const params = queryString.parse(location.search)
    const page = params.page ? +params.page : 1
    const totalData = props.totalData ?? 0
    const totalPage = props.totalPage ?? 0
    const itemFrom = (page - 1) * props.itemPerPage + 1
    const itemTo = (((page - 1) * props.itemPerPage) + props.itemPerPage) <= totalData ?
        ((page - 1) * props.itemPerPage) + props.itemPerPage : totalData
    
    const prev = () => {
        let newParams = {
            ...params,
            page: page - 1
        }
        history.push(location.pathname + "?" + queryString.stringify(newParams))
    }

    const next = () => {
        let newParams = {
            ...params,
            page: page + 1
        }
        history.push(location.pathname + "?" + queryString.stringify(newParams))
    }

    return (
        <div className="pagination-component">
            <BoxButtonComponent border="left-border" aclass="pagination-button" icon={PrevIcon} 
                onClick={prev}
                {...(page <= 1 || totalPage == 0 ? {disabled: "disabled"} : {})}
                {...(props.isLoading ? {disabled:"disabled"} : {}) } />
            <BoxComponent aclass="pagination-description" 
                text={`Item ${itemFrom} 
                        to ${itemTo} 
                        of ${totalData}. Page ${page} of ${totalPage}`} 
            />
            <BoxButtonComponent border="right-border" aclass="pagination-button" icon={NextIcon} 
                onClick={next} 
                {...(page == totalPage || totalPage == 0 ? {disabled: "disabled"} : {})}
                {...(props.isLoading ? {disabled:"disabled"} : {}) } />
        </div>
    )
}