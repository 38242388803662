import * as React from 'react'
import closeIcon from '../img/close.png'
import { FormMode } from '../modules/Client/ui/ClientFormV3'
import { SetFieldValueFunctionType } from './FormikFileUploadFactory'

export const FormikImageUploadFactory = (props: {
    mode: FormMode,
    setFieldValue: SetFieldValueFunctionType,
    file_fieldName: string,
    base64_fieldName: string,
    fileChange_fieldName: string,
    className?: string,
    defaultImage: any,
    file?: File,
    base64?: string}) => {

    const { mode, setFieldValue, file_fieldName, base64_fieldName, fileChange_fieldName, className, defaultImage, file, base64 } = props
    const photoRef = React.createRef<HTMLInputElement>()

    const fileToBase64 = (file: File, completion: (result: string | undefined) => void) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if(reader.result) completion(reader.result.toString())
            else completion(undefined)
        }
        reader.onerror = (error) => {
            completion(undefined)
        }
    }

    const onPhotoClick = () => {
        photoRef.current?.click()
    }

    const onPhotoChangeAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }

        let file = e.target.files[0]

        fileToBase64(file, (result) => {
            if(result){
                setFieldValue(file_fieldName, file)
                setFieldValue(base64_fieldName, result)
            }
        })
    }

    const onRemovePhotoAdd = () => {
        setFieldValue(file_fieldName, undefined)
        setFieldValue(base64_fieldName, undefined)
    }

    const onPhotoChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }

        let file = e.target.files[0]

        fileToBase64(file, (result) => {
            if(result){
                setFieldValue(file_fieldName, file)
                setFieldValue(base64_fieldName, result)
                setFieldValue(fileChange_fieldName, true)
            }
        })
    }

    const onRemovePhotoUpdate = () => {
        setFieldValue(file_fieldName, undefined)
        setFieldValue(base64_fieldName, undefined)
        setFieldValue(fileChange_fieldName, true)
    }

    return (
        <React.Fragment>
            { base64 && <img className="photo-delete" src={closeIcon} onClick={() => {
               mode === FormMode.ADD ? onRemovePhotoAdd() : onRemovePhotoUpdate()
            }} />}
            <img className={className} src={base64 || defaultImage} onClick={() => { onPhotoClick() }} />
            <input type="file" name={file_fieldName} className="hidden" accept="image/*" onChange={(e) => { 
                mode === FormMode.ADD ? onPhotoChangeAdd(e) : onPhotoChangeUpdate(e) 
            }} ref={photoRef} />
        </React.Fragment>
    )
}