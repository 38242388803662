import { FormikTouched } from 'formik'
import React from 'react'
import { SetFieldValueFunctionType } from '../../../FormikHelper/FormikFileUploadFactory'
import { ButtonInput } from '../../../form-components/ButtonInput'
import { TextInput } from '../../../form-components/TextInput'
import { IPosition } from '../model/DatabaseTNIAL'
import { PositionComponent } from './PositionComponent'

type HandleChange = (e: React.ChangeEvent<any>) => void

export interface FormikPositionProps {
    setFieldValue: SetFieldValueFunctionType,
    values: IPositionFormValues,
    handleChange: HandleChange,
    touched: FormikTouched<IPositionFormValues>
}

export interface IPositionFormValues {
    positionAddField?: string,
    timeAddField?: string,
    positions?: IPosition[]
}

export const FormikPositionsFormComponent: React.FC<FormikPositionProps> = ({ setFieldValue, values, handleChange, touched }) => {
    const positionRef = React.createRef<HTMLInputElement>()

    const handleAddPosition = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault()
            addPosition()
            positionRef.current?.focus()
        }
    }

    const addPosition = () => {
        if (!(values.positionAddField && values.timeAddField)) return
        let newPositionList = values.positions ?? []
        newPositionList.push({
            position: values.positionAddField,
            time: values.timeAddField
        })

        setFieldValue("positions", newPositionList)
        setFieldValue("positionAddField", "")
        setFieldValue("timeAddField", "")

        console.log(values)

        positionRef.current?.focus()
    }

    const removePosition = (position: string) => {
        console.log(position)
        console.log(values.positions)
        const newPositionList = values.positions?.filter(pos => pos.position !== position)

        setFieldValue("positions", newPositionList)
    }

    return (
        <>
            <div className="rowflex positionType default-padding-top" style={{gap:"10px"}}>
                <div className="col">
                    <TextInput placeholder="Position" name="positionAddField" value={values.positionAddField} onChange={handleChange} touched={touched.positionAddField} onKeyPress={handleAddPosition} inputRef={positionRef} />
                </div>
                <div className="col">
                    <TextInput placeholder="Time" name="timeAddField" value={values.timeAddField} onChange={handleChange} touched={touched.timeAddField} onKeyPress={handleAddPosition} />
                </div>
                <div className="col right-align">
                    <ButtonInput type="button" caption="Add Position" title="Add Position" id="positionAddSubmit" onClick={addPosition} />
                </div>
            </div>

            <div className="positionList">
                {
                    values.positions && values.positions.map((pos) => {
                        return <PositionComponent data={pos} key={pos.position} onDelete={removePosition} />
                    })
                }
            </div>
        </>
    )
}