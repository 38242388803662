import React from 'react';
import { Link } from 'react-router-dom';
import { NewsPagePath } from '../../database_api/api';
import { ButtonInput } from '../../form-components/ButtonInput';
import { NewsModel } from '../../modules/News/model/News';
import { NewsComponent } from '../../modules/News/ui/NewsComponent';
import { useDataList } from '../../modules/Shared/useDataList';
import { RoutePath } from '../RoutePath';

export const NewsWidget = () => { 
    const itemSize = 5
    const { data } = useDataList<NewsModel>({
        url: NewsPagePath,
        size: itemSize,
        identifier: "news"
    })

    return (
    <React.Fragment>
        <h2>Latest 5 News</h2>
        <div id="content-container">
        <div id="news-list">
            {
                (data && data.length > 0 && data.map((news, index) => {
                    return <NewsComponent id={news.id} 
                        title={news.title} link={news.link} description={news.description} 
                        tags={news.tags} time={news.time}
                        disableEdit
                        key={index} />
                }))
                || 
                <div className="message">No news registered</div>
            }
        </div>
        <Link to={RoutePath.newsList}><ButtonInput caption="See all news" title="See all news" aclass="default-margin-top"/></Link>
        </div>
    </React.Fragment>
    )
}