import Select from 'react-select'
import { SetFieldValueFunctionType } from '../FormikHelper/FormikFileUploadFactory'
import { ResultType } from '../modules/useSelectMemberHelperV2'
import { SelectOptionObject } from './FormikSelectInputHandler'

export const FormikSelectMultiInputHandler = (props: {
    fieldName: string,
    placeholder: string,
    options: SelectOptionObject[],
    selectedFieldName: string,
    setFieldValue: SetFieldValueFunctionType,
    selected?: SelectOptionObject[]
}) => {
    const {fieldName, placeholder, options, selectedFieldName, setFieldValue, selected} = props

    const handleSelectedChange = (inputValue: any, _: any) => {
        console.log(inputValue)
        if(!inputValue) {
            setFieldValue(selectedFieldName,[])
            setFieldValue(fieldName, [])
            return
        }
        setFieldValue(selectedFieldName,inputValue)
        setFieldValue(fieldName, inputValue.map((data: ResultType) => data.value))
    }

    return (
        <Select placeholder={placeholder} className="selectionStyle full-border" 
            isSearchable={true} name={fieldName} classNamePrefix="si" options={options} 
            value={selected ?? []} 
            isMulti={true}
            onChange={handleSelectedChange} />
    )
}