import { useQuery } from "react-query";
import { HTTPMethod } from "../../interfaces/HTTPHeader";
import { useHeader } from "./useHeader";

export const useItem = (url: string, id: string | undefined, identifier: string) => {
    const { header } = useHeader()

    const fetchItem = async () => {
        if(!id) { console.log("No ID"); return; }
        return fetch(`${url}${id}`, requestOptions)
            .then((response) => response.json())
    }

    const { data, isLoading, refetch } = useQuery([identifier, id], fetchItem, {enabled: false})

    const requestOptions = {
        method: HTTPMethod.get,
        headers: header.header()
    }

    return { get: refetch, isLoading, data};
}