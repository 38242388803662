

import { ButtonInput } from '../../../form-components/ButtonInput';


import { useHistory } from "react-router";
import { CustomerPersonnelPagePath } from '../../../database_api/api';
import { Lang } from '../../../lang/Lang';
import { ListLayoutFacade } from '../../../layout/ListLayoutFacade';
import { RoutePath } from '../../../page/RoutePath';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from '../../Search/FlexibleSearchComponentV2';
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { DatabaseTNIALImportBuilder } from '../builder/DatabaseTNIALImportBuilder';
import { DatabaseTNIALModel } from '../model/DatabaseTNIAL';
import { AngkatanComponent } from './AngkatanComponentV2';
import { DatabaseTNIALComponentSimpleList } from './DatabaseTNIALComponentSimpleList';


export const DatabaseTNIALList = () => { 
    const history = useHistory()
    const itemSize = 100

    const { data, totalData, totalPage, isLoading } = useDataList<DatabaseTNIALModel>({
        url: CustomerPersonnelPagePath,
        size: itemSize,
        identifier: "DBTNIAL"
    })

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Nama"
        }
    ]

    const topSearchComponent = (
        <div id="search-container">
            <FlexibleSearchComponent isLoading={isLoading} placeholder={Lang.customerProfileName} queryOptions={queryOptions} />
        </div>
    )

    const filterComponents = [
        <AngkatanComponent />
    ]

    const contentComponent = (
        <div id="content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="customerPersonnel-list">
                { !data || data.length == 0 && 
                    <div className="message">No {Lang.mDatabaseTNIAL} registered</div>
                }
                { data && data.length > 0 &&
                <table>
                    <thead>
                        <tr>
                            <th>{Lang.customerProfileSequenceNumber}</th>
                            <th>{Lang.customerProfileName}</th>
                            <th>{Lang.customerProfileCorps}</th>
                            <th>NRP</th>
                            <th>Age</th>
                            <th>Menu</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (data.map((customerPersonnel, index) => {
                            return <DatabaseTNIALComponentSimpleList {...customerPersonnel} />
                        }))
                        ||
                        <div className="message">No {Lang.mDatabaseTNIAL} registered</div>
                    }
                    </tbody>
                </table>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
        </div>
    )

    const addComponent = (
        <>
            { new DatabaseTNIALImportBuilder().get() }
            <ButtonInput id="add" caption="+" title="Add New" onClick={() => {
                history.push(RoutePath.databaseTNIALList + "/add")
            }} />
        </>
    )
    
    return (
        <ListLayoutFacade
            topSearchComponent={topSearchComponent}
            filterComponents={filterComponents}
            contentComponents={contentComponent}
            addComponent={addComponent}
        />
    )
}