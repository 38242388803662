import * as React from 'react';
import { ContentEditorModuleInterface } from '../../../form-components/ContentEditorModuleInterface';
import { DateHelper } from '../../../helper/DateHelper';
import trashIcon from '../../../img/icon-trash.png';
import { BlockContentManageFeatureRemoveProtocol } from '../Features/BlockContentManageFeature';

interface BlockContentComponentUIProp{
    index: number
    id: string
    content?: string

    updatedDate?: string
    updatedBy?: string

    contentEditorModule: ContentEditorModuleInterface
    removeFeature: BlockContentManageFeatureRemoveProtocol
    handleChange: (id: string, data: string) => void
    dndEndDrag: (event: React.DragEvent<HTMLDivElement>, callback: (selectedBlockId?: string | null, targetIndex?: number) => void) => void
    updateContents: (selectedBlockId?: string | null, targetIndex?: number) => void
    [param: string]: any
}

export const BlockContentMenu = (props: {id: string, removeFeature: BlockContentManageFeatureRemoveProtocol}) => {
    return (
        <>
        <div className="remove" onClick={ () => { props.removeFeature.remove(props.id)} }><img src={trashIcon} /></div>
        <div className="changeOrder"></div>
        </>
    )
}

export const BlockContentComponentUI = (props: BlockContentComponentUIProp) => {
    const [isHovered, setHover] = React.useState(false)
    const [draggable, setDraggable] = React.useState("true")

    const handleChange = (content: string) => {
        props.handleChange(props.id, content)
    }

    const handleOnFocus = () => {
        setDraggable("false")
    }

    const handleOnBlur = () => {
        setDraggable("true")
    }

    let { contentEditorModule, removeFeature, handleChange: hc, dndEndDrag, updateContents, draggable:dg, ...propsEv } = props
    return (
        <div onMouseEnter={() => { setHover(true); }} onMouseLeave={() => { setHover(false); }}
            onDragEnd={ event => { dndEndDrag(event, updateContents) } } 
            {...(draggable == "true") && {draggable: "true"}}
            {...propsEv}>

            <div className="menu">
                { isHovered && 
                    <BlockContentMenu id={props.id} removeFeature={props.removeFeature}/>
                }
            </div>
            <div className="content-editor">
            {contentEditorModule.get("content", "", props.content ?? "", handleChange, handleOnFocus, handleOnBlur)}
            </div>
            {props.updatedBy && props.updatedDate &&
                <div className="content-update-history">
                Updated at {DateHelper.dateFormat(props.updatedDate, "MMMM DD, YYYY HH:mm") } by {props.updatedBy}
                </div>
            }
            
        </div>
    );
}
