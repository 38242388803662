import React from 'react';
import { StaticContext } from "react-router";
import { RouteComponentProps } from 'react-router-dom';
import { MessageType } from '../../../MessageType';
import { SelectOptionProp } from '../../../form-components/SelectInput';
import { ListLoaderControllerProtocol } from '../../ListLoaderControllerProtocol';
import { LogsListDisplayUpdaterProtocol } from '../LogsListDisplayUpdaterProtocol';
import { LoadingControlProtocol } from '../../Search/SearchComponent';
import { SearchFeatureProtocol } from '../../Search/SearchFeatureProtocol';
import { LogsComponentProp, LogsComponent } from './LogsComponent';
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { StandardAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';
import { SearchDataSubmitter, BuildableSearchComponent } from '../../Search/BuildableSearchComponent';
import { FlexibleSearchComponent } from "../../Search/FlexibleSearchComponent";

interface LogsListProp extends RouteComponentProps{
    selectOption?: SelectOptionProp
    paginationComponent?: JSX.Element
    controller: ListLoaderControllerProtocol & LogsListDisplayUpdaterProtocol & LoadingControlProtocol
    search: SearchFeatureProtocol
}

export class LogsList extends React.Component<LogsListProp, {logss: LogsComponentProp[], message?:{type: MessageType, message: string}}> { 
    searchSubmitter = new SearchDataSubmitter()

    queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "title",
            displayField: "Title"
        }
    ]

    constructor(props: LogsListProp){
        super(props)

        this.state = {
            logss: []
        }

        this.props.controller.onLoad = this.onLoadLogss

        this.props.controller.load(1)
    }

    onLoadLogss = (logss: LogsComponentProp[]) => {
        this.setState({
            logss: logss
        })
    }

    render(){

        return (
        <React.Fragment>
            <div id="top-container">
                <BuildableSearchComponent loadingController={this.props.controller} searchModule={this.props.search} submitter={this.searchSubmitter}>
                    <FlexibleSearchComponent submitter={this.searchSubmitter} loadingController={this.props.controller} placeholder="Title..." queryOptions={this.queryOptions} />
                </BuildableSearchComponent>

                <div className="clear"></div>
            </div>
            
            <div id="content-container">
            {this.props.paginationComponent}

            {this.state.message && <div className={"message " + this.state.message.type}>{this.state.message.message}</div> }
            { this.state.message && setTimeout(() => { this.setState({message: undefined}) }, 3000) }

            <div id="logs-list">
                {
                    (this.state.logss && this.state.logss.length > 0 && this.state.logss.map((logs, index) => {
                        return <LogsComponent id={logs.id} moduleType={logs.moduleType}
                            user={logs.user} displayTitle={logs.displayTitle}
                            path={logs.path} actionType={logs.actionType} 
                            time={logs.time}
                            key={index} />
                    }))
                    || 
                    <div className="message">No logs registered</div>
                }
            </div>

            {this.props.paginationComponent}
            </div>
        </React.Fragment>
        )
    }
}