
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { LawRegulationPath } from '../../../database_api/api';
import { FormMode } from '../../Client/ui/ClientFormV3';
import { EditFormTemplate } from '../../Shared/template/EditFormTemplate';
import { useItem } from '../../Shared/useItem';
import { errorValidationSchema } from '../errorValidationSchema';
import { useLawRegulationEditFormHandler } from '../formHandler/useLawRegulationEditFormHandler';
import { LawRegulationForm } from '../LawRegulationForm';
import { LawRegulationSingleResult } from '../LawRegulationSingleLoader';
import { LawRegulationModel } from '../model/LawRegulationV2';
import { TagResultType } from './CreatableAsyncTagSelectInputHandler';

export const LawRegulationEdit = () => {
    const params = useParams<{ id: string }>()

    const formik = useLawRegulationEditFormHandler({
        code: params.id, name: ""
    }, errorValidationSchema)

    const { setFieldValue } = formik

    const { get, data, isLoading } = useItem(LawRegulationPath, params.id, "lawRegulation")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    if (isLoading) return (<div>Loading...</div>)

    const mapData = (result: LawRegulationSingleResult) => {
        if (result.data == undefined) return

        Object.entries(result.data.value).forEach(data => {
            if(data[0] == "file_url"){
                let file_url = data[1]
                if(file_url == "") return
                setFieldValue("file_description", file_url)
                return
            }

            if(data[0] == "ruleDate"){
                if( typeof data[1] == "string"){
                    let d = moment(data[1])
                    if(d.isValid()) setFieldValue("ruleDate_date", d.toDate())
                }
            }

            if(data[0] == "tags" && typeof data[1] == "string"){
                try {
                    let value: TagResultType[] = JSON.parse(data[1])
                    setFieldValue("selectedTags", value)
                } catch (error) {
                    console.log(error)
                }
            }

            setFieldValue(data[0], data[1])
        })
    }

    return (
        <EditFormTemplate<LawRegulationModel> {...formik}>
            {LawRegulationForm(FormMode.EDIT, "Update", formik)}
        </EditFormTemplate>
    )
}