import { useContext } from "react"
import GlobalData from "../../GlobalData"

export const useSetProjectName = () => {
    const { setPageTitle } = useContext(GlobalData)

    const updateProjectName = (projectName: string, type: string) => {
        setPageTitle(projectName + " <span class='pobContentTypeLabel'>" + type + "</span>")
    }

    return { updateProjectName }
}