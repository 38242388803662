import parse from 'html-react-parser';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ResultStatus } from '../../../Types/ResultStatus';
import { CustomerPath } from '../../../database_api/api';
import optionDelete from '../../../img/close.png';
import optionMenu from '../../../img/option.png';
import { useDeleteData } from '../../Shared/useDeleteData';
import { CustomerModel } from '../model/CustomerV2';

export const CustomerComponent = (props: CustomerModel) => {
    const history = useHistory()
    const location = useLocation()
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const { remove } = useDeleteData(CustomerPath + props.id, "Success delete " + props.name)

    const deleteNews = (id?: string) => {
        if(!id) return
        if(!window.confirm("Are you sure you want to delete this news?")){
            return
        }

        remove(undefined, {
            onSuccess: (result) => {
                if(result.status === ResultStatus.ok)
                    setIsDeleted(true)
            }
        })
    }
    
    if(isDeleted) return (
        <div className="customer-container full-border">
            {props.id && <div id={props.id} style={{marginTop:"-85px", position: "absolute"}} />}

            <div className="customer-id" style={{backgroundColor: "red"}}>
                <div className="name" style={{color: "white"}}>{props.name} [Deleted]</div>
            </div>
        </div>
    )

    return (
        <div className="customer-container full-border">
            {props.id && <div id={props.id} style={{marginTop:"-85px", position: "absolute"}} />}

            <div className="customer-id">
                <div className="name">{props.name}</div>
            </div>
            <div className="customer-tags">
                {props.tags && JSON.parse(props.tags).map( (tag: {label: string, value: string}) => {
                    return <div className="tag" key={tag.value}>{tag.label}</div>
                })
            }
            </div>
            <div className="customer-phone default-margin-top">Phone: {props.phone ? props.phone : "N/A"}</div>
            <div className="customer-email">Email: {props.email ? props.email : "N/A"}</div>

            { props.note && 
            <div className="customer-note default-margin-top">
                <div className="customer-id">Note</div>
                <div className="customer-note-content">{ props.note }</div>
            </div>
            }

            <div className="customer-id default-margin-top">Job Description</div>
            <div className="line-decoration"></div>
            <div className="customer-description sun-editor-content" suppressContentEditableWarning>{(props.description != "" && props.description != undefined) ? parse(props.description) : "-" }</div>

            <div className="customer-options">
                <input className="option" type="image" src={optionMenu} onClick={ () => { 
                    history.push(location.pathname + "/edit/" + props.id)
                 }} />
                <input className="status" type="image" src={optionDelete} title="Delete" onClick={ () => {
                    deleteNews(props.id)
                 } }/>
            </div>

            <div className="clear"></div>
        </div>
    )
}