import React from 'react'

interface YearTagComponentProps {
    key?: string
    year: string
    selectedYear?: string
    onYearChoosen: (year: string) => void
}

export let YearTagComponent: React.FunctionComponent<YearTagComponentProps> = ({ year, selectedYear, onYearChoosen, ...props }) => {
    return (
        <li key={props.key}>
            <a onClick={() => { onYearChoosen(year) }}>{year}</a><br />
            {(year == selectedYear) &&
                props.children && props.children
            }
        </li>
    )
}