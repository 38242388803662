
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { ImagePath, POBPath } from '../../../database_api/api';
import { ResultStatus } from '../../../Types/ResultStatus';
import { FormMode } from '../../Client/ui/ClientFormV3';
import { EditFormTemplate } from '../../Shared/template/EditFormTemplate';
import { useItem } from '../../Shared/useItem';
import { errorValidationSchema } from '../builder/ErrorMessage/errorValidationSchema';
import { usePOBEditFormHandler } from '../builder/usePOBEditFormHandler';
import { POBModel } from '../model/POBV2';
import { POBSingleResult } from '../POBSingleLoader';
import { POBForm } from './POBForm';

export const POBEdit = () => {
    const params = useParams<{ id: string }>()

    const formik = usePOBEditFormHandler({
        id: params.id, name: "", 
    }, errorValidationSchema)

    const { setFieldValue } = formik

    const { get, data, isLoading } = useItem(POBPath, params.id, "pob")

    useEffect(() => {
        get()
    }, [])

    useEffect(() => {
        if (!data) return

        mapData(data)
    }, [data])

    const mapData = (result: POBSingleResult) => {
        setFieldValue("isRequestData", false)

        if (result.status == ResultStatus.fail) {
            return
        }

        if (result.data == undefined) return

        var clientId = ""
        var clientName = ""

        Object.entries(result.data.value).forEach(data => {


            if (data[0] == "photo_url") {
                let photo_url = data[1]
                if (photo_url == "") return
                setFieldValue("photo_base64", ImagePath + photo_url)
                return
            }

            if (data[0] == "projectManagerId") {
                setFieldValue("projectManagerId", data[1])
                return
            }

            if (data[0] == "projectHeaderId") {
                setFieldValue("projectHeaderId", data[1])
                return
            }

            if (data[0] == "customerId") {
                setFieldValue("customerId", data[1])
                return
            }

            if (data[0] == "clientId") {
                setFieldValue("clientId", data[1])
                clientId = data[1]
                return
            }

            if (data[0] == "clientName") {
                setFieldValue("clientName", data[1])
                clientName = data[1]
                return
            }

            setFieldValue(data[0], data[1])
        })

        if (clientId != "") {
            setFieldValue("clientSelected", {
                label: clientName, value: clientId
            })
        }
    }

    return (
        <EditFormTemplate<POBModel> {...formik} >
            { POBForm(FormMode.EDIT, "Update Project", formik) }
        </EditFormTemplate>
    )
}