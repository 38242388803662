import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { AuthPermissionSessionStorage } from '../../Login/AuthPermission';
import { MemberAddBuilder, MemberAddRouter } from '../builder/MemberAddBuilder';
import { MemberEditBuilder, MemberEditParameterRoute, MemberEditRouter } from '../builder/MemberEditBuilder';
import { MemberListBuilder, MemberListRouter } from '../builder/MemberListBuilder';
import { MemberListRouteComponentProps } from './MemberList';

export class Member extends React.Component<RouteComponentProps>{
    auth = new AuthPermissionSessionStorage()

    render(){
        return (
            <React.Fragment>
                { (!this.auth.isSuperAdmin()) ? <div className="message">User not permitted to access this page</div> :
                <>
                    <Route path={this.props.match.url} component={(props: MemberListRouteComponentProps) => { return <MemberListBuilder {...props} router={ new MemberListRouter(this.props.match.url, this.props.history) }/> }} exact={true} />
                    <Route path={this.props.match.url + "/add"} component={() => { return new MemberAddBuilder(new MemberAddRouter(this.props.match.url, this.props.history)).get() }}/>
                    <Route path={this.props.match.url + "/edit/:username"} component={(props: MemberEditParameterRoute) => { return new MemberEditBuilder(new MemberEditRouter(this.props.match.url, this.props.history), props).get() }}/>
                </>
                }
            </React.Fragment>
        )
    }
}