import { useFormik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { POBPath } from '../../../database_api/api';
import { GetClientListSelectionProtocol } from '../../SelectClientHelper';
import { GetCustomerListSelectionProtocol } from '../../SelectCustomerHelper';
import { GetMemberListSelectionProtocol } from '../../SelectMemberHelper';
import { GetPOBHeaderListSelectionProtocol } from '../../SelectPOBHeaderHelper';
import { useAddFormData } from '../../Shared/useAddFormData';
import { POBModel } from '../model/POBV2';
import { AddPOBModuleInterface } from '../POBAddAPI';
import { POBAddRouterProtocol } from '../ui/POBAdd';
import { NameInput } from './ErrorMessage/Error';

const errorValidationSchema = yup.object({
    name: yup.string().required(NameInput.requiredMessageError())
})

interface POBAddWithFormHandlerProps {
    router: POBAddRouterProtocol
    pobHandler: AddPOBModuleInterface
    selectMemberHelper: GetMemberListSelectionProtocol
    selectClientHelper: GetClientListSelectionProtocol
    selectCustomerHelper: GetCustomerListSelectionProtocol
    selectProjectHeaderHelper: GetPOBHeaderListSelectionProtocol
}

export const usePOBAddFormHandler = (initialValues: POBModel, validationSchema: ObjectSchema) => {
    const { add } = useAddFormData(POBPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            const pobDataForm = getPOBAddDataToSubmit(values);

            setSubmitting(false)
            add(pobDataForm)
        }
    })

    return formik
}

const getPOBAddDataToSubmit = (values: POBModel) => {
    let pobData = {
        id: values.id,
        code: values.code,
        name: values.name,
        projectValue: values.projectValue,
        customerTemporary: values.customerTemporary,
        active: values.active
    }

    let pobDataForm = new FormData()
    Object.entries(pobData).forEach(field => {
        if (field[1] == undefined) return
        pobDataForm.append(field[0], field[1])
    })

    if (values.projectManagerId && values.projectManagerId != "0") {
        pobDataForm.append("projectManagerId", values.projectManagerId)
    }

    if (values.projectHeaderId && values.projectHeaderId != "0") {
        pobDataForm.append("projectHeaderId", values.projectHeaderId)
    }

    if (values.customerId && values.customerId != "0") {
        pobDataForm.append("customerId", values.customerId)
    }

    if (values.clientId && values.clientId != "0") {
        pobDataForm.append("clientId", values.clientId)

    }

    if (values.clientName && values.clientName != "0") {
        pobDataForm.append("clientName", values.clientName)

    }


    if (values.photo_url) {
        pobDataForm.append('photo_url', values.photo_url)
    }

    return pobDataForm
}