import "normalize.css"
import "../src/css/main.scss"
import * as React from "react"
import * as ReactDOM from "react-dom"

import { setChonkyDefaults } from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { StartApp } from "./StartApp";

setChonkyDefaults({
    iconComponent: ChonkyIconFA
})

ReactDOM.render(
    <StartApp />
    , document.getElementById("displayComponent")
)