import { useMutation } from "react-query";
import { ResultStatus } from "../../Types/ResultStatus";
import { HTTPMethod } from "../../interfaces/HTTPHeader";
import { useHeader } from "./useHeader";

import { toast } from 'react-toastify';
import { EditResult } from "../GenericAPI/GenericEditAPI";

export const useUpdateFormData = (url: string) => {
    const { header } = useHeader()

    const requestFormOptions = {
        method: HTTPMethod.patch,
        headers: header.formHeader()
    }

    const updateFormData = async (data: FormData) => {
        return fetch(`${url}`, {...requestFormOptions, body: data})
            .then((response) => response.json())
    }

    const { isLoading, mutate } = useMutation(updateFormData, {
        onSuccess: (data: EditResult) => {
            if(data.status === ResultStatus.fail){
                toast.error(data.message, { autoClose: 5000 })
                return
            }

            toast("Success Update")
        },
        onError: (error) => {
            toast.error(JSON.stringify(error), { autoClose: 5000 })
        }
    })

    return { update: mutate, isLoading };
}