import { GetAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient';
import { ResultStatus } from "../../Types/ResultStatus";
import { GetResult } from './GetResult';

export enum ContentListType{
    content = "content",
    data = "data",
    fieldInformation = "fieldInformation",
    actionPlan = "actionPlan",
    history = "history",
    latestStatus = "latestStatus",
    supportingDocs = "supportingDocs"
}

export interface ContentListResult{
    id: string
    name: string
    type: string
}

export interface ContentListLoaderResult extends GetResult<ContentListResult>{}

export interface ContentListLoaderHeaderSetProtocol{
    setHeader(isHeader: boolean): void
}

export interface ContentListLoaderHeaderGetProtocol {
    isHeaderState(): boolean
}

export interface ContentListLoaderProtocol{
    load(id: String, callback: (result?: ContentListResult[]) => void): void
}

export class ContentListLoader implements 
    ContentListLoaderProtocol, 
    ContentListLoaderHeaderSetProtocol,
    ContentListLoaderHeaderGetProtocol{
    private client: GetAPIHTTPClient;
    private url: string;
    isHeader: boolean

    constructor(client: GetAPIHTTPClient, url: string, isHeader: boolean) {
        this.client = client;
        this.url = url;
        this.isHeader = isHeader
    }

    load(id: String, callback: (result?: ContentListResult[]) => void) {
        this.client.get(this.url + (this.isHeader ? "pobHeader/" : "pob/") + id, result => {
            let returnedResult: ContentListLoaderResult = result;

            if (returnedResult.status == ResultStatus.fail || (returnedResult.data && returnedResult.data.length == 0)) {
                callback(undefined);
                return;
            }

            callback(returnedResult.data);
        });
    }

    setHeader(isHeader: boolean){
        this.isHeader = isHeader
    }

    isHeaderState(){
        return this.isHeader
    }
}
