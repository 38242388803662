import { FormikProps } from "formik"
import { StandardAPIHTTPClient } from "../../../database_api/StandardAPIHTTPClient"
import { JoditContentEditorModule } from "../../../form-components/JoditContentEditorModule"
import { TextInput } from "../../../form-components/TextInput"
import { FormikImageUploadFactory } from "../../../FormikHelper/FormikImageUploadFactory"
import defaultProfile from '../../../img/default-profile.png'
import emailIcon from '../../../img/icon-email.png'
import nameIcon from '../../../img/icon-name.png'
import phoneIcon from '../../../img/icon-phone.png'
import { HTTPHeader } from "../../../interfaces/HTTPHeader"
import { FormMode } from "../../Client/ui/ClientFormV3"
import { AuthTokenSessionStorage } from "../../Login/AuthPermission"
import { AsyncClientSelectInputHandler, ClientResultType } from "../AsyncClientSelectInputHandler"
import { ClientPICModel } from "../model/ClientPIC"

interface ClientPICLangInterface{
    mClientPersonnel: string
    clientPersonnelName: string
    clientPersonnelCompany: string
    clientPersonnelPosition: string
    clientPersonnelContactNumber: string
    clientPersonnelEmail: string
}

export let ClientPICForm: <T extends ClientPICModel>(mode: FormMode, pageTitle: string, Lang: ClientPICLangInterface, props: FormikProps<T>, clientId?: string, clientName?: string) => JSX.Element =
    (mode, pageTitle, Lang, props, clientId, clientName) => {
    let { values, errors, touched, handleChange, setFieldValue } = props

    let handleClientChange = (client: ClientResultType) => {
        setFieldValue("clientId", client.value)
    }

    let handleNotedChange = (text: string) => {
        setFieldValue("noted", text)
    }
    
    let client = new StandardAPIHTTPClient(new HTTPHeader(new AuthTokenSessionStorage()))
    let clientSelector = new AsyncClientSelectInputHandler("client", "Select " + Lang.clientPersonnelCompany, client, handleClientChange)
    let photoHandler = new FormikImageUploadFactory(setFieldValue, "photo_url", "photo_base64", "isPhotoChanged", "clientPIC-profile-photo", defaultProfile, values.photo_url, values.photo_base64)
    let contentEditorModule = new JoditContentEditorModule()

    return (
    <>
        <div className="page-title">{pageTitle} {Lang.mClientPersonnel} {values.name}</div>
        <div className="rowflex">
            <div className="col clientPICProfile">
                { (mode === FormMode.ADD) ? photoHandler.getAddComponent() : photoHandler.getEditComponent() }
            </div>
            <div className="col default-padding-left">
                <TextInput icon={nameIcon} placeholder={Lang.clientPersonnelName} name="name" value={values.name} onChange={handleChange} message={errors.name} touched={touched.name} />
                <div className="default-padding-top">
                    { (mode === FormMode.ADD && clientId && clientName) ? 
                        clientSelector.getComponent({
                            label: clientName,
                            value: clientId
                        })
                    : 
                        clientSelector.getComponent(values.clientSelected)}
                </div>
                <TextInput placeholder={Lang.clientPersonnelPosition} name="position" value={values.position} onChange={handleChange} message={errors.position} touched={touched.position} />
            </div>
        </div>

        <div className="row">
            <TextInput icon={phoneIcon} placeholder={Lang.clientPersonnelContactNumber} name="phone" value={values.phone} onChange={handleChange} message={errors.phone} touched={touched.phone} />
            <TextInput icon={emailIcon} placeholder={Lang.clientPersonnelEmail} name="email" value={values.email} onChange={handleChange} message={errors.email} touched={touched.email} />
        </div>

        <div className="field-description">
            Noted
        </div>
        {contentEditorModule.get("noted", "Noted", values.noted, handleNotedChange)}
    </>
    )
}