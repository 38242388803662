
import { AddFormTemplate } from '../../Shared/template/AddFormTemplate'
import { errorValidationSchema } from '../errorValidationSchema'
import { useCustomerAddFormHandler } from '../formHandler/useCustomerAddFormHandler'
import { CustomerModel } from '../model/CustomerV2'
import { CustomerForm } from './CustomerForm'

export const CustomerAdd = () => {
    const formik = useCustomerAddFormHandler({
        name: ""
    }, errorValidationSchema)
    
    return (
        <AddFormTemplate<CustomerModel> {...formik}>
            { CustomerForm("Add New", formik) }
        </AddFormTemplate>
    )
}