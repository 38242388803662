interface Config{
    api: string
}

export let config: Config

console.log(process.env.REACT_APP_API)
if(process.env.NODE_ENV === 'production' && process.env.REACT_APP_API){
    config = {
        api: process.env.REACT_APP_API
    }
}
else{
    config = {
        api: 'http://localhost:3000/'
    }
}