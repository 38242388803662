import { useFormik } from "formik"
import { ObjectSchema } from "yup"
import { ClientPICPath } from "../../../database_api/api"
import { useAddFormData } from "../../Shared/useAddFormData"
import { ClientPICModel } from "../model/ClientPIC"

export const useClientPICAddFormHandler = (initialValues: ClientPICModel, validationSchema: ObjectSchema) => {
    const { add } = useAddFormData(ClientPICPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let clientPICData: ClientPICModel = {
                clientId: values.clientId,
                name: values.name,
                email: values.email,
                phone: values.phone,
                position: values.position,
                active: values.active,
                noted: values.noted
            }

            let clientPICDataForm = new FormData()
            Object.entries(clientPICData).forEach(field => {
                clientPICDataForm.append(field[0], field[1] || "")
            })

            if (values.photo_url) {
                clientPICDataForm.append('photo_url', values.photo_url)
            }

            setSubmitting(false)
            add(clientPICDataForm)
        }
    })

    return formik
}