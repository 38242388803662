import { PatchAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient';
import { ResultStatus } from '../../Types/ResultStatus'

export type EditResult = {
    status: ResultStatus,
    data?: any,
    message?: string
}

export interface EditModuleInterface<Model, Result> {
    update(data: Model, addCallback: (result: Result) => void): void
}

export class GenericEditAPI<Model, Result>{
    private client: PatchAPIHTTPClient
    private path: string

    constructor(path: string, client: PatchAPIHTTPClient){
        this.client = client
        this.path = path
    }

    update(data: Model, addCallback: (result: Result) => void){
        let requestBody = JSON.stringify(data)
        this.client.patch(this.path, requestBody, (result) => {
            if (result.message){
                result.message = `Error code: ${result.message}. Please contact your developer.`
            }
            addCallback(result)
        })
    }
}