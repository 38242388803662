import AsyncSelect from 'react-select/async-creatable';

import { FilterActionPlanPath } from '../../../database_api/api';
import { GetAPIHTTPClient } from '../../../database_api/StandardAPIHTTPClient';

export type ActionPlanType = {
    id: string,
    name: string,
    location?: string,
    personnelList?: string,
    contractors?: string,
    objective?: string,
    picIds?: string[],
    date?: string
}

type ActionPlanResultType = {
    label: string,
    value: string, 
    location?: string,
    personnelList?: string,
    contractors?: string,
    objective?: string,
    picIds?: string[],
    date?: string
}

export class AsyncCreatableActionPlanSelectInputHandler{
    private fieldName: string
    private placeholder: string
    private client: GetAPIHTTPClient
    private projectId?: string
    private onChange: (actionPlan?: ActionPlanType) => void
    private onCreate: (actionPlanName: string) => void

    constructor(fieldName: string, placeholder: string,
        client: GetAPIHTTPClient, projectId: string,
        onChange: (actionPlan?: ActionPlanType) => void,
        onCreate: (actionPlanName: string) => void){
        this.fieldName = fieldName
        this.placeholder = placeholder
        this.client = client
        this.projectId = projectId
        this.onChange = onChange
        this.onCreate = onCreate
    }

    private loadActionPlanList = (inputValue: string, callback: (options: ActionPlanResultType[]) => void) => {
        if(inputValue == undefined || inputValue == ""){
            callback([])
            return
        }

        this.client.get(FilterActionPlanPath + this.projectId + "/" + inputValue, result => {
            if(result.data == undefined){
                callback([])
                return
            }

            let returnedResult: {id: string, name: string, location?: string, personnelList?: string, contractors?: string, picIds?: string[], objective?: string, date?: string}[] = result.data
            if(returnedResult.length == 0){
                callback([])
                return
            }

            callback(returnedResult.map( project => {
                return {label: project.name, value: project.id,
                    personnelList: project.personnelList,
                    contractors: project.contractors, location: project.location,
                    objective: project.objective,
                    picIds: project.picIds,
                    date: project.date
                }
            }))
        })
        
    }

    private handleSelectedChange = (inputValue: any, _: any) => {
        console.log("inputValue", inputValue)
        if(!inputValue){
            this.onChange(undefined)
            return
        }

        this.onChange({
            id: inputValue.value,
            name: inputValue.label,
            personnelList: inputValue.personnelList,
            contractors: inputValue.contractors,
            picIds: inputValue.picIds,
            location: inputValue.location,
            objective: inputValue.objective,
            date: inputValue.date
        })
    }

    private handleOnCreate = (inputValue: string) => {
        this.onCreate(inputValue)
    }

    private getNewOptionData = ((inputValue: any, optionLabel: any) => ({
        value: inputValue,
        label: optionLabel,
        __isNew__: true,
        isEqual: () => false
        }))

    getComponent = (value?: ActionPlanResultType, clearable: boolean = true) => {
        return (
            <div className="input-container" style={{
                width: "100%"
            }}>
                <AsyncSelect cacheOptions 
                    name={this.fieldName}
                    placeholder={this.placeholder}
                    className="selectionStyle full-border full-width"
                    classNamePrefix="si"
                    loadOptions={this.loadActionPlanList} defaultOptions 
                    value={value}
                    {...(clearable ? {isClearable: true} : {})}
                    getOptionLabel={ option => `${option.label}` }
                    onChange={this.handleSelectedChange}
                    onCreateOption={this.handleOnCreate}
                    getNewOptionData={this.getNewOptionData}
                    />
            </div>
        )
    }
}

