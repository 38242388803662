import { useContext, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import GlobalData from '../GlobalData';
import logo from '../img/logo.png';
import { menus, moreMenus, NavigationHeaderMenu } from '../NavigationHeaderMenuV2';

export const Header = () => {
    const location = useLocation();
    const params = useParams()
    const {pageTitle, setPageTitle} = useContext(GlobalData)

    const loadTitleForTheFirstTime = () => {
        let allMenus = [...menus, ...moreMenus]
        for (let index = 0; index < allMenus.length; index++) {
            let menu = allMenus[index];
            let path = !menu.path ? "NO PAGE" : "/" + menu.path.split("/")[1] + "/"
            let locationName = "/" + location.pathname.replaceAll("/", "") + "/"
            
            if (path === locationName) {
                menu.name && setPageTitle(menu.name)
                break
            }
        }
    }

    useEffect(() => {
        loadTitleForTheFirstTime()
    }, [params])

    return <header>
        <div className="decoration">
            <Link to="/"><img id="nav-logo" src={logo} onClick={() => {
                setPageTitle("Dashboard")
            }}/></Link>
            <h1 dangerouslySetInnerHTML={{
                __html: pageTitle
            }}/>
            <NavigationHeaderMenu />
        </div>
        <div className="line-decoration"></div>
        
        
    </header>
}