import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { LatestStatusPagePath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { DateHelper } from '../../../helper/DateHelper';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from '../../Search/FlexibleSearchComponentV2';
import { QueryCompareTypeEnum, QueryComponent } from '../../Search/QueryFilterProtocol';
import { useURL } from '../../Search/useURL';
import { useDataList } from '../../Shared/useDataList';
import { ResultType, useSelectMemberHelper } from '../../useSelectMemberHelperV2';
import { LatestStatusModel } from '../model/LatestStatusV2';
import { LatestStatusComponent } from './LatestStatusComponentV2';

export const LatestStatusList = React.memo(() => { 
    const { history, location, params } = useURL()
    const itemSize = 8
    const { id: projectId } = useParams<{ id: string }>()

    const { data, totalData, totalPage, isLoading, isFetching } = useDataList<LatestStatusModel>({
        url: LatestStatusPagePath,
        size: itemSize,
        identifier: "latestStatuses",
        ignoreParamsAsURL: ["contentId", "contentName", "contentType", "addForm"],
        defaultParamsNotURL: { projectId: projectId }
    })

    const queryOptions: QueryComponent[] = useMemo(() => [
        {
            compareType: QueryCompareTypeEnum.equal,
            field: "actionPlanName",
            displayField: "Action Item"
        }
    ], [])

    const [memberList, setMemberList] = React.useState<ResultType[]>([])

    const handleAddClick = useCallback(() => {
        history.push({
            pathname: location.pathname,
            search: location.search + "&addForm=true"
        })
    }, [history, location.pathname, location.search])

    useSelectMemberHelper((datas) => {
        setMemberList(datas)
    })

    if(memberList.length == 0) return <div className="message" style={{marginTop: "120px"}}>Fetching data...</div>
    
    return (
    <React.Fragment>
        <div id="top-container">
            <div id="search-container">
                <FlexibleSearchComponent isLoading={isLoading} placeholder="Name" queryOptions={queryOptions} />
            </div>
            <div className="clear"></div>
        </div>

        <div id="add-menu-container">
            <ButtonInput id="add" caption="+" title="Add New" onClick={handleAddClick} />
        </div>

        { !isFetching && data && data.length > 0 &&
        <div id="floater-nav">
            { data.map((latestStatus) => {
                return (
                    <a className="floater-nav-item" key={latestStatus.id} href={"#" + latestStatus.id}>
                            <span className="overview">-</span>
                            <span className="detail">{latestStatus.date ? DateHelper.dateFormat(latestStatus.date, "MMMM DD, YYYY") : "-" }</span>
                    </a>
                )
            }) }
        </div>
        }
        
        <div id="latestStatus-content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="latestStatus-list">
                {
                    (!isFetching && data && data.length > 0 && data.map((latestStatus) => {
                        if(!latestStatus.id) return <div>No ID</div>

                        return <LatestStatusComponent 
                            key={latestStatus.id}
                            {...latestStatus}
                            memberList={memberList}
                        />
                    }))
                }
                {
                    !isFetching && data && data?.length == 0 &&
                    <div className="message">No LatestStatus registered</div>
                }
                {
                    isFetching && <div className="message">Fetching data...</div>
                }
            </div>
            
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            {
                params.addForm && 
                    <div id="field-information-add">
                        <LatestStatusAdd />
                    </div>
            }
        </div>
    </React.Fragment>
    )
})

const LatestStatusAdd = () => {
    const params = useParams<{type: string, id: string}>()
    console.log(params)
    return (
        <div className={"container"}>
            <div style={{
                position: "fixed",
                background: 'rgba(0,0,0,0.5)',
                width: "100%",
                height: "100%",
                top: 0, left: 0
            }}></div>
            <LatestStatusComponent
                projectId={params.id}
                projectType={params.type}
            />
        </div>
    )
}
