import { HTTPHeader } from '../../interfaces/HTTPHeader'
import { StandardLogin } from '../../StandardLogin'
import { ResultStatus } from '../../Types/ResultStatus'
import { AuthTokenSessionStorage } from './AuthPermission'
import { LoginHTTPClient } from './LoginHTTPClient'
import { FormikHelpers } from 'formik'
import { goToDashboard } from '../RouteManager'
import * as yup from 'yup'
import { LoginSessionStorage } from './LoginSessionStorage'

const session = new LoginSessionStorage()
const loginHandler = new StandardLogin(new LoginHTTPClient(new HTTPHeader(new AuthTokenSessionStorage())), session)

export type HandleLoginSubmit = (values: LoginFormikProp, { setSubmitting, setFieldError }: FormikHelpers<LoginFormikProp>) => void | Promise<any>

export interface LoginFormikProp {
    username: string
    password: string
    global: string
}

export let handleLoginSubmit: HandleLoginSubmit = (values: LoginFormikProp, { setSubmitting, setFieldError }: FormikHelpers<LoginFormikProp>) => {
    setSubmitting(true)

    loginHandler.doLogin(values.username, values.password, (result) => {
        if (result.status == ResultStatus.ok) {
            goToDashboard()
        }
        else {
            setSubmitting(false)
            setFieldError("global", result.message || '')
        }
    })
}

export const errorValidationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required().min(6)
})

export let ifLoggedInThenRedirectToDashboard = () => {
    if (session.getUsername() != null) {
        goToDashboard()
    }
}