import { PostAPIHTTPClient } from '../../database_api/StandardAPIHTTPClient';
import { ResultStatus } from '../../Types/ResultStatus'

interface ErrorMapping{
    [index: string]: string
}

export type AddResult = {
    status: ResultStatus,
    data?: any,
    message?: string
}

export interface AddModuleInterface<Model, Result>{
    add(data: Model, addCallback: (result: Result) => void): void
}

export class GenericAddAPI<Model, Result> implements AddModuleInterface<Model, Result>{
    private client: PostAPIHTTPClient
    private path: string

    errorMapping: () => (ErrorMapping) = () => ({})

    constructor(path: string, client: PostAPIHTTPClient){
        this.path = path
        this.client = client
    }

    add(data: Model, addCallback: (result: Result) => void){
        let requestBody = JSON.stringify(data)
        this.client.post(this.path, requestBody, (result) => {
            if (result.message){
                if(this.errorMapping()[result.message]){
                    result.message = this.errorMapping()[result.message]
                }
                else{
                    result.message = `Error code: ${result.message}. Please contact your developer.`
                }
                
            }
            setTimeout(() => {
                addCallback(result)
            }, 300)
        })
    }
}