import { useMutation } from "react-query";
import { ResultStatus } from "../../Types/ResultStatus";
import { HTTPMethod } from "../../interfaces/HTTPHeader";
import { useHeader } from "./useHeader";

import { toast } from 'react-toastify';
import { EditResult } from "../GenericAPI/GenericEditAPI";

export const useUpdateData = <T>(url: string, successMessage?: string) => {
    const { header } = useHeader()

    const requestOptions = {
        method: HTTPMethod.patch,
        headers: header.header()
    }

    const updateData = async (data: T) => {
        return fetch(`${url}`, { ...requestOptions, body: JSON.stringify(data) })
            .then((response) => response.json())
    }

    const { isLoading, mutate } = useMutation(updateData, {
        onSuccess: (data: EditResult) => {
            if(data.status === ResultStatus.fail){
                toast.error(data.message, { autoClose: 5000 })
                return
            }

            if(successMessage === "") return
            toast(successMessage ?? "Success Update")
        },
        onError: (error) => {
            toast.error(JSON.stringify(error), { autoClose: 5000 })
        }
    })

    return { update: mutate, isLoading };
}