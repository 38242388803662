import React from 'react';
import { useHistory } from 'react-router';
import { CustomerPagePath } from '../../../database_api/api';
import { ButtonInput } from '../../../form-components/ButtonInput';
import { RoutePath } from '../../../page/RoutePath';
import { PaginationComponent } from '../../Paging/ui/PaginationComponentV2';
import { FlexibleSearchComponent } from "../../Search/FlexibleSearchComponentV2";
import { QueryCompareTypeEnum } from '../../Search/QueryFilterProtocol';
import { useDataList } from '../../Shared/useDataList';
import { CustomerModel } from '../model/CustomerV2';
import { CustomerComponent } from './CustomerComponentV2';

export const CustomerList = () => { 
    const history = useHistory()
    
    const itemSize = 8
    const { data, totalData, totalPage, isLoading, params, isFetching } = useDataList<CustomerModel>({
        url: CustomerPagePath,
        size: itemSize,
        identifier: "clients",
        ignoreParamsAsURL: ["prospectiveMode"]
    })

    const queryOptions = [
        {
            compareType: QueryCompareTypeEnum.like,
            field: "name",
            displayField: "Name"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "phone",
            displayField: "Phone"
        },
        {
            compareType: QueryCompareTypeEnum.like,
            field: "email",
            displayField: "Email"
        },

    ]

    return (
        <React.Fragment>
            <div id="top-container">
                <div id="search-container">
                    <FlexibleSearchComponent isLoading={isLoading} placeholder="Name, Phone, Email" queryOptions={queryOptions} />
                </div>

                <div className="clear"></div>
            </div>

            <div id="add-menu-container">
                <ButtonInput id="add" caption="+" title="Add New" onClick={() => {
                    history.push(RoutePath.customerList + "/add")
                }} />
            </div>

            { !isFetching && data && data.length > 0 &&
                <div id="floater-nav">
                    { data.map((customer) => {
                        return (
                            <a className="floater-nav-item" key={customer.id} href={"#" + customer.id}>
                                    <span className="overview">-</span>
                                    <span className="detail">{customer.name ? customer.name : "-" }</span>
                            </a>
                        )
                    }) }
                </div>
            }
            
            <div id="content-container">
            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />

            <div id="customer-list">
                {
                    (data && data.length > 0 && data.map((customer, index) => {
                        return <CustomerComponent id={customer.id} 
                            tags={customer.tags}
                            name={customer.name} note={customer.note} description={customer.description} 
                            email={customer.email} phone={customer.phone} key={`customer${customer.id}`} />
                    }))
                    || 
                    <div className="message">No Customer registered</div>
                }
            </div>

            <PaginationComponent
                totalData={totalData}
                totalPage={totalPage}
                isLoading={isLoading}
                itemPerPage={itemSize}
            />
            </div>
        </React.Fragment>
    )
}