import React from 'react'
import { RouteComponentProps, Route } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from 'react-query';
import { ClientVariant } from '../model/Client';
import { ClientList } from './ClientListV2';
import { ClientAdd } from './ClientAddV3';
import { ClientEdit } from './ClientEditV3';

const queryClient = new QueryClient();

export const ClientPartner =  (props: RouteComponentProps) => {
    return (
        <QueryClientProvider client={queryClient}>
            <React.Fragment>
                <Route path={props.match.url + "/v2/add"} component={() =>
                    <ClientAdd variant={ClientVariant.clientPartner} />
                } />
                <Route path={props.match.url + "/v2/edit/:id"} component={ClientEdit} />
                <Route exact path={props.match.url + "/v2"} component={ClientList} />
            </React.Fragment>
        </QueryClientProvider>
    )
}