import { useFormik } from 'formik';
import { ObjectSchema } from 'yup';
import { CustomerPath } from '../../../database_api/api';
import { useUpdateData } from '../../Shared/useUpdateData';
import { CustomerModel } from '../model/CustomerV2';

export const useCustomerEditFormHandler = (initialValues: CustomerModel, validationSchema?: ObjectSchema) => {
    const { update } = useUpdateData(CustomerPath)

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            let customerData: CustomerModel = {
                id: values.id,
                name: values.name,
                email: values.email,
                phone: values.phone,
                note: values.note,
                description: values.description,
                tags: values.tags
            }

            setSubmitting(false)
            update(customerData)
        }
    })

    return formik
}