import React from 'react';

import { MessageType } from '../../MessageType';
import { ActionPlanListDisplayUpdaterProtocol } from '../../modules/ActionPlan/ActionPlanListDisplayUpdaterProtocol';
import { ActionPlanListRouteProtocol } from '../../modules/ActionPlan/model/ActionPlanListModel';
import { ReactActionPlanPostResultControllerProtocol } from '../../modules/ActionPlan/resultController/ActionPlanPostResultController';
import { ActionPlanComponent, ActionPlanComponentProp } from '../../modules/ActionPlan/ui/ActionPlanComponentV2';
import { ActionPlanListPresenterProtocol } from '../../modules/ActionPlan/ui/ActionPlanListPresenter';
import { ActionPlanToFieldInformation } from '../../modules/POBContent/ContentModule/ActionPlan/ActionPlanListBuilderForContentType';

export interface ListLoaderTodayControllerProtocol{
    load(): void
}

interface ActionPlanListProp{
    controller: ListLoaderTodayControllerProtocol & ActionPlanListDisplayUpdaterProtocol
    presenter: ActionPlanListRouteProtocol & ActionPlanListPresenterProtocol & ActionPlanToFieldInformation
    resultController: ReactActionPlanPostResultControllerProtocol
}

interface ActionPlanListState{
    actionPlans: ActionPlanComponentProp[]
    message?:{type: MessageType, message: string}

    resultFormOpen: boolean
}

export class ActionPlanTodayWidget extends React.Component<ActionPlanListProp, ActionPlanListState> { 
    constructor(props: ActionPlanListProp){
        super(props)

        this.state = {
            actionPlans: [],
            resultFormOpen: false
        }

        this.props.presenter.setState = this.defaultSetState
        this.props.controller.onLoad = this.props.presenter.onLoadActionPlans

        this.props.presenter.onLoad()
        
    }

    defaultSetState = (data: any) => {
        this.setState(data)
    }

    render(){

        return (
        <React.Fragment>
            <h2>Action Plan Today</h2>
            
            <div id="content-container">

            {this.state.message && <div className={"message " + this.state.message.type}>{this.state.message.message}</div> }
            { this.state.message && setTimeout(() => { this.setState({message: undefined}) }, 3000) }

            <div id="actionPlan-list">
                {
                    (this.state.actionPlans && this.state.actionPlans.length > 0 && this.state.actionPlans.map((actionPlan, index) => {
                        return <ActionPlanComponent id={actionPlan.id} name={actionPlan.name} date={actionPlan.date}
                            projectId={actionPlan.projectId}
                            location={actionPlan.location}
                            objective={actionPlan.objective}
                            projectName={actionPlan.projectName}
                            personnelList={actionPlan.personnelList}
                            contractors={actionPlan.contractors}
                            status={actionPlan.status}
                            fieldInformationId={actionPlan.fieldInformationId}
                            picIds={actionPlan.picIds}
                            disableEdit
                            key={index} />
                    }))
                    || 
                    <div className="message">No actionPlan registered</div>
                }
            </div>
            </div>
            <div id="actionPlan-result"></div>
        </React.Fragment>
        )
    }
}