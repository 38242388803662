import * as React from 'react'
import closeIcon from '../img/close.png'
import { TextInput } from '../form-components/TextInput'

export type SetFieldValueFunctionType = (field: string, value: any, shouldValidate?: boolean | undefined) => void

export class FormikFileUploadFactory{
    private setFieldValue: SetFieldValueFunctionType
    private file_fieldName: string
    private fileDescription_fieldName: string
    private fileChange_fieldName: string
    private file?: File
    private fileDescription?: string

    private className: string
    private defaultFile: any

    constructor(setFieldValue: SetFieldValueFunctionType, file_fieldName: string, fileDescription_fieldName: string, fileChange_fieldName: string, className: string = "", defaultFile: any, file?: File, fileDescription?: string){
        this.setFieldValue = setFieldValue
        this.file_fieldName = file_fieldName
        this.fileDescription_fieldName = fileDescription_fieldName
        this.fileChange_fieldName = fileChange_fieldName

        this.file = file
        this.fileDescription = fileDescription

        this.className = className
        this.defaultFile = defaultFile
    }

    private fileRef = React.createRef<HTMLInputElement>()

    private onFileClick = () => {
        this.fileRef.current?.click()
    }

    private onFileChangeAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }

        let file = e.target.files[0]
        this.setFieldValue(this.file_fieldName, file)
        this.setFieldValue(this.fileDescription_fieldName, file.name)
    }

    private onRemoveFileAdd = () => {
        this.setFieldValue(this.file_fieldName, undefined)
        this.setFieldValue(this.fileDescription_fieldName, undefined)
    }

    private onFileChangeUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files){ return }

        let file = e.target.files[0]
        this.setFieldValue(this.file_fieldName, file)
        this.setFieldValue(this.fileDescription_fieldName, file.name)
        this.setFieldValue(this.fileChange_fieldName, true)
    }

    private onRemoveFileUpdate = () => {
        this.setFieldValue(this.file_fieldName, undefined)
        this.setFieldValue(this.fileDescription_fieldName, undefined)
        this.setFieldValue(this.fileChange_fieldName, true)
    }

    getAddComponent = () => (
        <React.Fragment>
            <TextInput icon={this.defaultFile} placeholder="Attachment" name="file_description" value={this.fileDescription || ""} readOnly onClick={ () => { this.onFileClick() } } />
            {this.file && <img className="file-delete" src={closeIcon} onClick={() => this.onRemoveFileAdd() } /> }
            <input type="file" name={this.file_fieldName} className="hidden" onChange={(e) => { this.onFileChangeAdd(e) }} ref={this.fileRef} />
        </React.Fragment>
    )

    getEditComponent = () => (
        <React.Fragment>
            <TextInput icon={this.defaultFile} placeholder="Attachment" name="file_description" value={this.fileDescription || ""} readOnly onClick={ () => { this.onFileClick() } } />
            {this.fileDescription && <img className="file-delete" src={closeIcon} onClick={() => this.onRemoveFileUpdate() } /> }
            <input type="file" name={this.file_fieldName} className="hidden" onChange={(e) => { this.onFileChangeUpdate(e) }} ref={this.fileRef} />
        </React.Fragment>
    )
}
